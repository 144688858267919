import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { I18Service } from '../services/i18.service';
import { PartecipantRoles } from '../models/WebServer/partecipantRoles';
import { PartecipantStatus } from '../models/WebServer/partecipantStatus';
import { AppHelper } from './app.helper';
import { AppConfigService } from '../services/app.config.service';

@Pipe({
  name: 'statusColor'
})
export class StatusColorPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer, private config: AppConfigService) { }

  public transform(partecipantId: string | PartecipantStatus | undefined, partecipants: PartecipantStatus[] | undefined = undefined): {color: string, tooltip: string } {

    if( !partecipantId ) return { color: '', tooltip: ''}

    partecipants = partecipants ?? this.config.getPartecipantsStatus();

    const p = (typeof partecipantId === 'string' || partecipantId instanceof String) 
        ? partecipants.find( z => z.partecipantId == partecipantId)
        : partecipantId;

    const f1 = (p) ? p.partecipantRole == PartecipantRoles.PrimaryClient : false;
    const f2 = partecipants.some( z => z.partecipantRole == PartecipantRoles.PrimaryClient);
    const f3 = partecipants.some( z => z.partecipantRole == PartecipantRoles.SecondaryClient);
    const operatorReady = f1 && f2 && f3
    var color = AppHelper.getStatusColor(p, operatorReady);
    return color;
  }
}
