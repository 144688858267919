import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, timer, throwError, of, EMPTY } from 'rxjs';
import { retryWhen, tap, mergeMap } from 'rxjs/operators';
import { NotificationService } from '../services/notification.service';
import { I18Service } from '../services/i18.service';

const expceptPath=['frontendlog','signalrsm','translate']
@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  retryDelay = 5000;
  retryMaxAttempts = 3;
  constructor() { console.debug(`HttpRequestInterceptor constructor`)}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(expceptPath.find( p => request.url.includes(p))){this.retryMaxAttempts=0;}
    return next.handle(request)
      .pipe(
        this.retryAfterDelay(),
      );
  }

  retryAfterDelay(): any {
    return retryWhen(errors => {
      return errors.pipe(
        mergeMap((err, count) => {
          // throw error when we've retried ${retryMaxAttempts} number of times and still get an error
          console.log(`httperror`,err);
          // NotificationService.showError(msg);
          if (count === this.retryMaxAttempts || err.status == 404) {
            const msg = `HTTP error: ${err.status} ${err.statusText}`;
            err.message = msg;
            err.statusText = I18Service.get('Error.Signalr');
            //err.showReload = true;
            return throwError(err);
          }

          return of(err).pipe(
            tap(error => 
              { 
                if(!expceptPath.find( p => error.url.includes(p))){
                  const msg =  I18Service.get('SNACKBAR.APIPROBLEM', {current: count+1, max: this.retryMaxAttempts});
                  NotificationService.showError(msg);
                }
                console.log(`Retrying ${error.url}. Retry count ${count + 1}/${this.retryMaxAttempts}`)
              }
            ),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
  }
}