import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { MarkingReadObject } from 'src/app/models/WebServer/markingReadObject';
import { Message } from 'src/app/models/WebServer/message';
import { AppConfigService } from 'src/app/services/app.config.service';

import { EventService } from 'src/app/services/event.service';
import { markMediaAsSeen } from 'src/app/store/actions/app.actions';
import { AppState } from 'src/app/store/states/app.state';
import { SvgConstants } from 'src/app/svgConstants';



@Component({
  selector: 'app-camera-gallery',
  templateUrl: './camera-gallery.component.html',
  styleUrls: ['./camera-gallery.component.scss']
})
export class CameraGalleryComponent {
  @Input() AS!: AppState;
  @Input() set display(value: boolean) {
    console.log(`mediaService - CameraGalleryComponent display: ${value}`);
    if (value) {
      this.setCurrentImageAsRead();
    }
    this._display = value;
  }
  _display = false;
  currentPhoto = 0;
  currentImageUrl: any;
  imageLoadPath = '/assets/images/imgLoad.svg';
  svgConstants = SvgConstants;

  constructor(
    private store: Store,
    private eventService: EventService,
    private config: AppConfigService
  ) { }

  manageImageError(event: any, message: Message) {
    const image = event.target;
    if (image.src) {
      if (image.src !== '/assets/images/notFound.jpg') {
        if (image.src.startsWith('unsafe')) {
          image.src = image.src.replace('unsafe:', '');
          // message.content = 'Image here has been removed from server'; //TODO non può essere modificato (immutable)
        } else {
          image.src = '/assets/images/notFound.jpg';
          image.onerror = null;
          // message.content = 'Image here has been removed from server';//TODO non può essere modificato (immutable)
        }
      }
    }
  }

  // private CheckRead() {
  //   if (!this.AS.mediaMessages || this.AS.mediaMessages.length == 0) return true;

  //   var image = this.AS.mediaMessages[this.currentPhoto];
  //   if (!image) return true;
  //   return !!image.messageInfo && !!image.messageInfo.readAt && !this.badgeBlink;
  // }

  badgeBlink = false;
  private blinkBadgeUnseen() {
    const self = this;
    var blinkCount = 5;
    this.badgeBlink = true;
    const blinkInterval = window.setInterval( () =>{
      self.badgeBlink = !self.badgeBlink;
      blinkCount--;
      if (blinkCount < 0) {
        self.badgeBlink = false;
        window.clearInterval(blinkInterval);
      }
      // console.log(`****** blink ${self.badgeBlink}`)
    }, 600)
  }

  setCurrentImageAsRead() {
    if (!this.AS.mediaMessages || this.AS.mediaMessages.length == 0) return;

    var image = this.AS.mediaMessages[this.currentPhoto];

    if (!image || (image.messageInfo && image.messageInfo.readAt)) return;

    this.store.dispatch(markMediaAsSeen({mediaIdx: this.currentPhoto}));
    this.blinkBadgeUnseen();

  }

  prevPhoto() {
    this.currentPhoto = (this.currentPhoto - 1) >= 0 ? (this.currentPhoto - 1) % this.AS.mediaMessages.length : this.AS.mediaMessages.length - 1;
    this.setCurrentImageAsRead();
  }

  nextPhoto() {
    this.currentPhoto = (this.currentPhoto + 1) % this.AS.mediaMessages.length;
    this.setCurrentImageAsRead();
  }

  downloadImage(idx: number) {
    const url = this.AS.mediaMessages[idx].content;
    const fileName = this.AS.mediaMessages[idx].fileName
    // const a: any = document.createElement('a');
    // a.href = url;
    // a.download = fileName;
    // // document.body.appendChild(a);
    // // a.style = 'display: none';
    // a.click();
    // // a.remove();
    window.open( url, '_system');
  }

  imageLoaded() {
    if(this._display)
    this.setCurrentImageAsRead();
  }
}
