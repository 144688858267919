import { Injectable } from "@angular/core";
import { CreateEvent } from "../models/WebServer/createEvent";
import { EventTypeEnum } from "../models/WebServer/eventTypeEnum";
import { MediaTypesEnum } from "../models/WebServer/mediaTypesEnum";
import { EventData } from "../models/WebServer/eventData";
import { Phone } from "../models/WebServer/phone";
import { Media } from "../models/WebServer/media";


@Injectable()
export class ImageHelper {

  static resizeImage(url: string, desiredUrlSize: number, canvas: any) {
    let scaleFactor = 0.7;
    let scalingFacotor = 0.7;
    let exitGuard = 0;
    let guardExit = 5;
    const mimeType = 'image/jpeg';
    const originalSize = url.length;
    while (url.length > desiredUrlSize && exitGuard < guardExit) {
      // Logger.warn(
      //   `url length: ${url.length} > ${desiredUrlSize} -- scaleFactor: ${scaleFactor}`
      // );
      scaleFactor *= scalingFacotor;
      scalingFacotor -= 0.1;
      url = canvas.toDataURL(mimeType, scaleFactor);
      exitGuard++;
      if (originalSize == url.length) {
        exitGuard = guardExit;
      }
    }
  }

  // private static createDataEvent(
  //   url: string,
  //   name: string,
  //   partecipantId: string,
  //   batteryRaw: string,
  //   phoneModel: string,
  //   mimeType: string = ".jpg",
  //   type: MediaTypesEnum = MediaTypesEnum.Image
  // ): CreateEvent {
  //   const urlParts = url.split(';');
  //   let base64Image: string;
  //   let newName: string;
  //   if (urlParts.length <= 1) { throw new Error(`createDataEvent: wrong urlParts`);}

  //   base64Image = urlParts[1].replace('base64,', '');
  //   mimeType = type == MediaTypesEnum.Image ? mimeType : '';
  //   if (!name) {
  //     switch (type) {
  //       case MediaTypesEnum.Image:
  //         name = `${Date.now()}_capture.jpeg`;
  //         newName = this.changeExtension(name, 'jpeg');
  //         break;
  //       case MediaTypesEnum.Document:
  //         name = `${Date.now()}_document`;
  //         break;
  //       default:
  //         name = `${Date.now()}_file`;
  //         break;
  //     }
  //   }
  //   const media: Media = { mediaType: type, mimeType, mediaContent: base64Image, mediaName: name };
  //   const phone: Phone = { batteryLife: batteryRaw, model: phoneModel };
  //   const eventData: EventData = { partecipantId, media, phone }
  //   const createEvent: CreateEvent = { eventType: EventTypeEnum.Media, data: eventData }
  //   return createEvent;
  // }

  static changeExtension(fileName: string, newExtension: string): string {
    const nameParts = fileName.split('.');
    if (nameParts.length > 1) {
      const ext = nameParts[nameParts.length - 1];
      return fileName.replace(ext, newExtension);
    } else {
      return fileName;
    }
  }

}
