
<div class="loading-spinner" *ngIf="(!AS?.appReady || AS.loadingMessage) && !errorInfo ">
  <div class="spinner-waiting"><span>{{'Waiting' | translate }} {{AS.loadingMessage}}</span></div>
  <div class="spinner-load"></div>
</div>

<div class="main-container" *ngIf="AS?.appReady || errorInfo">
  <!-- <ng-template #mainBlock > -->
    <app-business-error id="error-page" *ngIf="errorInfo; else mainOK" [errorInfo]="errorInfo"></app-business-error>

    <ng-template #mainOK>
      <div *ngIf="afterInit" class="main-app">
        <app-phone-title [AS]="AS" [title]="title" [agency]="session.agency" *ngIf="AS.isCaller && !AS.errorInfo && session"
            [hidden]="AS.appInMultimedia && MYSTATUS.disclaimerAccepted">
          </app-phone-title>
        <app-caller #caller id="app-container" *ngIf="config.isCaller" [AS]="AS" [MYSTATUS]="MYSTATUS" [session]="session"></app-caller>
        <app-receiver #receiver id="app-container" *ngIf="config.isReceiver" [AS]="AS" [MYSTATUS]="MYSTATUS"></app-receiver>
        <app-guest #guest id="app-container" *ngIf="config.isGuest" [AS]="AS" [MYSTATUS]="MYSTATUS"></app-guest>
      </div>
    </ng-template>
  <!-- </ng-template> -->
</div>

<div hidden>
<div class="download-file-popup" #popupDownload>
  <div>{{config.popupLabelNewDocument}}</div>
  <div id="swal-document-popup" class="link-div">
    <a [download]="config.popupFilename" [href]="config.popupUrl" target="_blank">
      {{'LABELS.DOWNLOADDOCUMENT' | translate }}
    </a>
  </div>
  {{'LABELS.ORCHATDOCUMENT' | translate }}
</div>
</div>
