import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'remainingTime'
})
export class RemainingTimePipe implements PipeTransform {

  constructor(protected date: DatePipe) { }

  public transform(millisecs: number): string {
    const days = Math.floor(millisecs / (1000*60*60*24));
    const sDays = (days > 0) ? `${days}d `: '';
    const sHours = (millisecs >= 1000*60*60) ? this.date.transform(millisecs, 'HH', 'UTC')+':' : '';
    const sMins = this.date.transform(millisecs, 'mm:ss');
    return `${sDays}${sHours}${sMins}`;
  }
}
