import { fabric } from 'fabric';
import { IObjectOptions } from 'fabric/fabric-impl'

import { CanvasTool } from './tool'

export class Text extends CanvasTool {

  name = 'Text'
  coords:{ x: number; y: number } | undefined;
  textBox: fabric.Textbox | undefined;

  configureCanvas(props: IObjectOptions) {
    this.canvas.isDrawingMode = false
    this.canvas.selection = false
    this.canvas.defaultCursor = 'text'
    this.canvas.forEachObject((object) => {
      object.selectable = object.evented = false
    })
    this.props = props
  }

  onMouseDown(event: fabric.IEvent) {
    if (!this.props) return

    const coords = this.canvas.getPointer(event.e);
    // console.log(`fabric text mousedown coords:${coords} - target: ${event.target}`)
    if (event.target) return; //no text over another object

    if (!this.coords) { // new text
      this.coords = coords;
      return
    }

    if(this.textBox) {
      this.textBox.exitEditing();
    }

    this.coords = coords;

    // if (this.coords.x != coords.x || this.coords.y != coords.y) {
    // //   this.canvas.
    // }
  }

  onMouseUp(event: fabric.IEvent) {
    if (!this.props) return
    if (!this.coords) return
    
    const e = this.canvas.getPointer(event.e);
    if (e.x != this.coords.x || e.y != this.coords.y) return;
    
    const { strokeDashArray, strokeDashOffset } = this.props

    const stroke = this.props.stroke || '#000000'
    const strokeWidth = this.props.strokeWidth || 8
    const pointer = this.canvas.getPointer(event.e)
    const points = [pointer.x, pointer.y, pointer.x, pointer.y]

    this.textBox = new fabric.Textbox('', {
      fill: stroke,
      width: this.canvas.width! - pointer.x,
      fontSize: strokeWidth * 5,
      fontFamily: "-apple-system,system-ui,BlinkMacSystemFont,'Segoe UI'",
      left: pointer.x,
      top: pointer.y,
      selectable: false
      // selected: true,
      // editable: true,
      // evented: true
    })
    this.canvas.add(this.textBox)
    this.textBox.enterEditing();
    this.textBox.setCursorByClick(event.e);
    const selfThis = this;
    this.canvas.on('text:editing:exited', ()=>{
      selfThis.coords = undefined;
      // textObject.off();
    })
    // canvas.viewportCenterObject(textObject)
    // canvas.setActiveObject(textObject)
    // handleToolChange(null)

  }

  onMouseMove: undefined

}