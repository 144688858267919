export class BusinessEventTypes {
  public static readonly LandOnPage = 'LandOnPage';
  public static readonly RegisterOnSip = 'RegisterOnSip';
  public static readonly SIPFirstInvite = 'SIPFirstInvite';
  public static readonly SIPReinvite = 'SIPReinvite';
  public static readonly DeviceInfo = 'DeviceInfo';
  public static readonly DevicePermissionUpdate = 'DevicePermissionUpdate';
  public static readonly LeaveOrReloadPage = 'LeaveOrReloadPage';
  public static readonly CounterpartSeen = 'CounterpartSeen';
  public static readonly FirstLocationSend = 'FirstLocationSend';
  public static readonly AskForNewInvite = 'AskForNewInvite';
  public static readonly ForceRefresh = 'ForceRefresh';
  public static readonly ForceRefreshAfterDisconnect = 'ForceRefreshAfterDisconnect';
  public static readonly OperatorEndSession = 'OperatorEndSession';
  public static readonly OperatorExtendSession = 'OperatorExtendSession';
  public static readonly OperatorGuideUser = 'OperatorGuideUser';
  public static readonly OperatorToggleLockUser = 'OperatorToggleLockUser';
  public static readonly OperatorSendAttachment = 'OperatorSendAttachment';
  public static readonly DroppingMessageError = 'DroppingMessageError';
  public static readonly LoadingTimes = 'LoadingTimes';
  public static readonly NonCompatibleDevice = 'NonCompatibleDevice';
  public static readonly DisclaimerChoice = 'DisclaimerChoice';
  public static readonly StreamStats = 'StreamStats';

  public static readonly ExcludedEvents = [
    // BusinessEventTypes.LandOnPage,
    // BusinessEventTypes.RegisterOnSip,
    // BusinessEventTypes.SIPFirstInvite,
    // BusinessEventTypes.SIPReinvite,
    // BusinessEventTypes.DeviceInfo,
    // BusinessEventTypes.DevicePermissionUpdate,
    // BusinessEventTypes.LeaveOrReloadPage,
    // BusinessEventTypes.CounterpartSeen,
    // BusinessEventTypes.FirstLocationSend,
    // BusinessEventTypes.AskForNewInvite,
    // BusinessEventTypes.ForceRefresh,
    BusinessEventTypes.OperatorEndSession,
    BusinessEventTypes.OperatorExtendSession,
    // BusinessEventTypes.OperatorGuideUser,
    // BusinessEventTypes.OperatorToggleLockUser,
    // BusinessEventTypes.OperatorSendAttachment,
    // BusinessEventTypes.DroppingMessageError,
    // BusinessEventTypes.LoadingTimes,
    // BusinessEventTypes.NonCompatibleDevice,
    // BusinessEventTypes.StreamStats,
    // BusinessEventTypes.DisclaimerChoice
  ];
}
