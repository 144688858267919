import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Constants } from "src/app/constants";
import { AppHelper } from "src/app/helpers/app.helper";
import { AppConfigService } from "src/app/services/app.config.service";
import { I18Service } from "src/app/services/i18.service";
import { NotificationService } from "src/app/services/notification.service";
import { MenuSettingsComponent } from "./menu/settings.component";



@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],

})
export class MenuComponent {
  @Input() showMenuGuest!: boolean;

  @Output() showSessionInfo = new EventEmitter<boolean>;
  @Output() showCitizenInfo = new EventEmitter<boolean>;
  
  // AppHelper = AppHelper;
  // NotificationService = NotificationService;
  title =  AppHelper.getTitleName();

  @ViewChild('credits',{static: false}) credits!: ElementRef;
  @ViewChild('settings',{static: false}) settings!: ElementRef;
  @ViewChild(MenuSettingsComponent,{static: false}) settingsComponent!: MenuSettingsComponent;

  // LANGUAGES = Constants.LANGUAGES_LIST;
  // selectedLang = Constants.DEFAULTLANGUAGE;

  constructor(public config: AppConfigService) {}

  shownCredits=false;
  
  public showCredits() {
    this.shownCredits = true;
    // NotificationService.showElement(this.credits.nativeElement, '').then(() =>{this.shownCredits=false;});
    NotificationService.showCredits();
  }

  shownSettings=false;
  public showSettings() {
    this.shownSettings=true;
    NotificationService.showElement(this.settings.nativeElement, '')
      .then((x) =>{
        // console.log(`=========== showSettings result`, x);
        if (!x || x.isConfirmed == false) {
          this.settingsComponent.undoPreferences();
        }
        this.shownSettings=false;

      });
    //NotificationService.showSettings()
  }

  public toggleShowSessionInfo() {
    this.showSessionInfo.emit(true);//this._toggleShowSessionInfo); // visualizza sempre al click
  }
  public toggleShowCitizenInfo() {
    this.showCitizenInfo.emit(true);//this._toggleShowSessionInfo); // visualizza sempre al click
  }

  close() {
      NotificationService.popupClose();
  }
}