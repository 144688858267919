import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAccessibilityContent } from 'src/app/models/WebServer/partecipant';
import { AppConfigService } from 'src/app/services/app.config.service';


@Component({
  selector: 'app-dialog-accessibility',
  templateUrl: './dialog-accessibility.component.html',
  styleUrls: ['./dialog-accessibility.component.scss']
})
export class DialogAccessibilityComponent implements OnInit {
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAccessibilityComponent>,
    public config: AppConfigService,
    // private sipService: GenericSipServer,
    // private signalr: SignalrService,
    // private partecipantService: PartecipantService,
    @Inject(MAT_DIALOG_DATA) public data: DialogAccessibilityContent
  ) { }

  ngOnInit(): void {
    // if (!this.config.partecipant) {
    //   this.loading = true;
    //   this.partecipantService.getPartecipant(this.config.partecipantId).subscribe(p => {
    //     this.config.partecipant = p;
    //     this.data = this.config.partecipant.parameters.accessibility;
    //     Logger.info('Partecipant', this.config.partecipant);
    //   }, err => {
    //     Logger.error(`Error during partecipant retrieve operation`, err);
    //   }, () => {
    //     this.loading = false;
    //   });
    // }
  }

  close(returnValue: any) {
    // if (returnValue && this.config.partecipant) {
    //   this.loading = true;
    //   this.partecipantService.updatePartecipantParameters(this.config.partecipantId, this.config.partecipant.parameters).subscribe(p => {
    //     this.config.partecipant = p;
    //     // this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.AccessibilityUpdate, p);
    //     this.signalr.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.AccessibilityUpdate, p);
    //   }, err => {
    //     Logger.error(`Error during partecipant save operation`, err);
    //   }, () => {
    //     this.loading = false;
    //     this.dialogRef.close(returnValue);
    //   });
    // }
  }

}
