import { SessionDialog } from "../dialogs/session-dialog";
import { C } from "../messages/methods/constants";
import { OutgoingByeRequest } from "../messages/methods/bye";
import { OutgoingRequestDelegate, RequestOptions } from "../messages/outgoing-request";
import { NonInviteClientTransaction } from "../transactions/non-invite-client-transaction";
import { UserAgentClient } from "./user-agent-client";

/**
 * BYE UAC.
 * @public
 */
export class ByeUserAgentClient extends UserAgentClient implements OutgoingByeRequest {
  constructor(dialog: SessionDialog, delegate?: OutgoingRequestDelegate, options?: RequestOptions) {
    const message = dialog.createOutgoingRequestMessage(C.BYE, options);
    super(NonInviteClientTransaction, dialog.userAgentCore, message, delegate);
    dialog.dispose();
  }
}
