
import { PartecipantStatus, initialPartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { SessionStatus, initialSessionStatus } from "src/app/models/WebServer/sessionStatus";


export enum SessionStatusOperation {
    UNKNOWN = 'UNKNOWN',
    ADDED = 'ADDED',
    REMOVED = 'REMOVED',
    UPDATED = 'UPDATED'
}

export type SessionStatusState = {
    operation: SessionStatusOperation;
    sessionStatus: SessionStatus;
    partecipantStatus: PartecipantStatus;
}

export const initialSessionStatusState = {
    operation: SessionStatusOperation.UNKNOWN,
    sessionStatus: initialSessionStatus,
    partecipantStatus: initialPartecipantStatus
}



