import { createAction, props } from '@ngrx/store';
import { Session } from '../../models/WebServer/session';

import { PartecipantInfo } from 'src/app/models/WebServer/partecipantInfo';
import { Message } from 'src/app/models/WebServer/message';
import { CreateEvent } from 'src/app/models/WebServer/createEvent';



export const sessionCheck = createAction('[Session] check session');
export const sessionReload = createAction('[Session] reload session', props<{ session: Session }>());
export const sessionOk = createAction('[Session] session OK', props<{ session: Session }>()); // session ha un param "type" 

export const sessionFailure = createAction('[Session] session failure', props<{ error: any, showReload?: boolean }>());

export const partecipantCheck = createAction('[Session] check partecipant');
export const partecipantOk = createAction('[Session] partecipant OK', props<{ partecipant: PartecipantInfo }>());

export const sessionLoaded = createAction('[Session] session loaded');

export const chatHistoryLoaded = createAction('[Session] chat history loaded', props<{ messages: Message[] }>());

export const subscribeToSession = createAction('[Session] Subscribe to SessionStatus', props<{reconnect: boolean}>());
export const terminateSession = createAction('[Session] Terminate session');
export const extendSession = createAction('[Session] Extend session');
export const endSession = createAction('[Session] End session');

export const addGuest = createAction('[Session] add guest', props<{ phoneNumber: string, email: string, alias: string, viewPreviousChat: boolean, superUser: boolean}>());
export const removeGuest = createAction('[Session] remove guest', props<{guestId: string}>());

export const notifyExpiration = createAction('[Session] notify expiration', props<{ in_msec: number }>())

export const lastServerPositionLoad = createAction('[Session] last server position LOAD')
export const lastServerPosition = createAction('[Session] last server position', props<{ evt: CreateEvent }>())

export const expirationUpdated = createAction('[Session] Expiration Updated', props<{ newExpiration: Date }>());