<div class="video-component">

  <div #videoContainer class="video-container"
    [ngStyle]="{'border-color': AS.videoEnabled && AS.phoneStatus == phoneStatuses.TALKING ? 'red' : 'black'}"
    [hidden]="AS.appModes.includes(appModes.Photo)">
    

    <div class="image-to-edit" *ngIf="mediaService.imageToEdit || false">
      <span *ngIf="config.isReceiver && (config.anyoneOnCall)"  class="material-symbols-outlined edit-off-button"
        [matTooltip]="'Video.edit' | translate" (click)="editOff()">edit_off</span>
      <app-image-stream-editor
        [blob]="mediaService.imageToEdit"
        [borderCss]="'2px solid gray'"
        (editingCanvas)="editingCanvas($event)"
      ></app-image-stream-editor>
    </div>

    <div id="videoThumbs">
      <div *ngIf="!config.anyoneOnCall" class="not-in-call">{{'Video.not_in_call' | translate}}</div>
      <ng-container #myVideoContainerRef></ng-container>

      <!-- <span class="scroll-button" *ngIf="list.isOverflow" [class.disable]="!list.canScrollStart" (click)="list.scroll(-1)" id="scroll-left">
        <span class="material-symbols-outlined">chevron_left</span>
      </span> -->
      <!-- <div id="remoteVideoThumbs" appScrollable #list="appScrollable" [scrollUnit]="150"> -->
      <!-- <div id="remoteVideoThumbs" overlay-scrollbars [defer]="true"> -->
      <div id="remoteVideoThumbs" [hidden]="!config.anyoneOnCall">
      <ng-container #remoteVideoContainerRef></ng-container>
      </div>
      <!-- <span class="scroll-button" *ngIf="list.isOverflow"  [class.disable]="!list.canScrollEnd" (click)="list.scroll(1)">
        <span class="material-symbols-outlined">chevron_right</span>
      </span> -->
    </div>

  </div>
  
  <div *ngIf="config.isCaller" class="snapshot-container" [hidden]="!AS.appModes.includes(appModes.Photo)" >
    <div class="snapshot-operations">
        <div class="operations-row">
        <button mat-raised-button id="enable-camera-button"   aria-label="Capture Image" 
          (click)="enableSnapshot()"
          [matTooltip]="'LABELS.TAKEAPHOTO' | translate"
          [hidden]="!AS.appModes.includes(appModes.Photo) || AS_video.captureUrl || AS_video.cameraEnabledForSnapshot"
          [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired || !atLeast1Camera() || AS.mediaPermission == 2 || !AS.switchCameraOn">
          <span class="button-svg font-icon-button material-symbols-outlined">photo_camera</span>
          <span class="no-camera" *ngIf="!atLeast1Camera() || AS.mediaPermission == 2">{{'LABELS.NOCAMERA' | translate}}</span>
        </button>

        <button mat-raised-button id="gallery-button"  [matTooltip]="'LABELS.GETFROMGALLERY' | translate" 
          (click)="openImageGalleryInput()"
          [hidden]="!AS.appModes.includes(appModes.Photo) || AS_video.captureUrl || AS_video.cameraEnabledForSnapshot"
          [ngClass]="{'disabled-label': !MYSTATUS.isRegistered || AS.sessionExpired}">
          <span class="button-svg font-icon-button material-symbols-outlined">collections</span>
        </button>
        <input id="imageUploader" type="file" style="display: none;" [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired" 
          accept="image/*" #fileInput (change)="onImageGalleryChange($event)" />
      </div>
      <div class="operations-row">

        <button mat-raised-button id="zz-button"  [matTooltip]="'LABELS.SENDATTACHMENT' | translate" 
          (click)="openFileInput('attachmentUploader')"
          [hidden]="!AS.appModes.includes(appModes.Photo) || AS_video.captureUrl || AS_video.cameraEnabledForSnapshot"
          [ngClass]="{'disabled-label': !MYSTATUS.isRegistered || AS.sessionExpired}">
          <span class="button-svg font-icon-button material-symbols-outlined">attach_file</span>
        </button>
        <input id="attachmentUploader" type="file" style="display: none;" accept="*" #fileInput
          (change)="attachDocument($event)"
          [disabled]="!(AS.session) || AS.sessionExpired" />
      </div>
    </div>
    <video id="localVideoSnapshot" oncontextmenu="return false;" #localVideoSnapshot playsinline [hidden]="!config.isCaller || !AS_video.cameraEnabledForSnapshot">
    </video>
    
    <button aria-label="Switch Camera" (click)="switchCamera()" class="roundborder-dark-background-button"
      class="roundborder-dark-background-button switch-camera-button-caller"
      [hidden]="!AS.appModes.includes(appModes.Photo) || !AS_video.cameraEnabledForSnapshot || AS_video.captureUrl || !doubleCamera()"
      [matTooltip]="(config.isMobilePhone ? 'LABELS.FLIPCAMERA' :'LABELS.SWITCHCAMERA') | translate"
      [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired || !AS.switchCameraOn || (!AS.videoEnabled && config.isReceiver) || AS.mediaPermission == 2">
      <span class="font-icon-button-big material-symbols-outlined">switch_camera</span>
    </button>

    <button id="capture-button" *ngIf="atLeast1Camera() && AS.mediaPermission != 2" aria-label="Capture Image" (click)="captureVideoImage()" class="roundborder-dark-background-button"
      [hidden]="!AS.appModes.includes(appModes.Photo) || AS_video.captureUrl || !AS_video.cameraEnabledForSnapshot"
      [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired || !AS.switchCameraOn">

      <span class="font-icon-button-big material-symbols-outlined">radio_button_checked</span>
    </button>

    <button id="cancel-button" aria-label="Back" (click)="disableSnapshot()" class="roundborder-red-background-button"
      [hidden]="!AS.appModes.includes(appModes.Photo) || !AS_video.cameraEnabledForSnapshot || AS_video.captureUrl"
      [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired ">
      <span class="font-icon-button-big material-symbols-outlined">clear</span>
    </button>

    <canvas #canvas id="canvas"> </canvas>
    <img id="capture" [hidden]="!AS_video.captureUrl || !AS.appModes.includes(appModes.Photo)"
        src="{{AS_video.captureUrl}}" [ngClass]="{'img-height': higher, 'img-width': !higher}">

    <button id="back-button" aria-label="Back" (click)="backToVideo()" class="roundborder-dark-background-button"
      [hidden]="!AS.appModes.includes(appModes.Photo) || !AS_video.captureUrl" [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired ">
      <span class="font-icon-button-big material-symbols-outlined">arrow_back</span>
    </button>

    <button id="send-image-button" aria-label="Send Image" (click)="sendCameraImage()" class="roundborder-dark-background-button"
      [hidden]="!AS.appModes.includes(appModes.Photo) || !AS_video.captureUrl"
      [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired ">
      <span class="font-icon-button-big material-symbols-outlined">send</span>
    </button>
  </div>
</div>
