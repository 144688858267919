<div id="media-container">
  <!-- BUTTONS -->
  <app-multimedia-buttons #mediaButtons [AS]="AS" [MYSTATUS]="MYSTATUS" (changeModeEvent)="changeMode($event, false)"></app-multimedia-buttons>

  <div id="multimedia-part">

    <!-- VIDEO -->
    <app-video #video class="video-container" [AS]="AS" [MYSTATUS]="MYSTATUS" [OTHERSTATUS]="PRIMARYSTATUS" 
      [hidden]="!AS.appModes.includes(appModes.Video) && !AS.appModes.includes(appModes.Photo)">
    </app-video>

    <!-- CHAT -->
    <app-chat #chat id="chat-container" [AS]="AS" [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired" [shown] = "AS.appModes.includes(appModes.Chat)"
      [hidden]="!AS.appModes.includes(appModes.Chat)">
    </app-chat>
  </div>
</div>
