import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SessionStatusState } from "../states/sessionStatus.state";
import { selectPartecipantId } from "./app.selectors";
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";


const sessionFeatureKey = 'status';

export const selectSessionStatusState = createFeatureSelector<SessionStatusState>(sessionFeatureKey);

export const selectSessionStatus = createSelector(
    selectSessionStatusState,
    (state: SessionStatusState) => state
);

export const selectPartecipantsStatus = createSelector(
    selectSessionStatusState,
    (state: SessionStatusState) => state.sessionStatus.partecipants
);

export const myPartecipantStatus = createSelector(
    selectSessionStatusState,
    selectPartecipantId,
    (state: SessionStatusState, partecipantId: string) => {
        const myStatus = state.sessionStatus.partecipants.find(p => p.partecipantId == partecipantId)
        return myStatus;
    }
);

export const otherPartecipantStatus = createSelector(
    selectSessionStatusState,
    selectPartecipantId,
    (state: SessionStatusState, partecipantId: string) => {
        const otherStatus = state.sessionStatus.partecipants.find(p => p.partecipantId != partecipantId)
        return otherStatus;
    }
);


export const primaryPartecipantStatus = createSelector(
    selectSessionStatusState,
    (state: SessionStatusState) => {
        const primaryStatus = state.sessionStatus.partecipants.find(p => p.partecipantRole == PartecipantRoles.PrimaryClient)
        return primaryStatus;
    }
);

export const secondaryPartecipantStatus = createSelector(
    selectSessionStatusState,
    (state: SessionStatusState) => {
        const secondaryStatus = state.sessionStatus.partecipants.find(p => p.partecipantRole == PartecipantRoles.SecondaryClient)
        return secondaryStatus;
    }
);

// export const guestPartecipantStatus = createSelector(
//     selectSessionStatusState,
//     (state: SessionStatusState) => {
//         const guestStatus = state.sessionStatus.partecipants.find(p => p.partecipantRole == PartecipantRoles.GuestClient)
//         return guestStatus;
//     }
// );

export const guestPartecipantStatus = createSelector(
    selectPartecipantsStatus,
    (partecipants: PartecipantStatus[]) => {
        const guestStatus = partecipants.find(p => p.partecipantRole == PartecipantRoles.GuestClient)
        return guestStatus;
    }
);