export enum EventTypeEnum {
  GeoPosition = 0,
  ChatMessage = 1,
  Battery = 2,
  Media = 3,

  //server 2 server use
  MediaStart = 4,
  MediaStop = 5,
  ChatHistory = 6,
  EndSession = 7,
  ExtendSession = 8,
  BusinessEvent = 9,
  Recording = 10,
  AddGuest = 11
}
