
import { HttpClient } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { Observable, catchError, map } from "rxjs";
import { loadingMessage } from "../store/actions/app.actions";
import { I18Service } from "./i18.service";
import { AppConfigService } from "./app.config.service";


export class BaseHttp {

    constructor(private http: HttpClient, protected readonly store: Store, protected readonly config: AppConfigService) { }

    protected getRequest<T>(url: string, showLoading = false): Observable<T> {
        
        const startDate = this.startRequest(showLoading,url);
        
        return this.http.get<T>(url, {headers:{'SM-PartecipantId': this.config.partecipantId}}).pipe(
            map((response: T) => {
                console.debug(`getRequest ${url} ${this.finishRequest(startDate, showLoading)}`)
                return response
            })
            , catchError(err => this.throwError<T>(err, startDate, showLoading))
        );

    }

    protected putRequest<T>(url: string, body: any, showLoading = false): Observable<T> {
        const startDate = this.startRequest(showLoading,url);

        return this.http.put<T>(url, body, {headers:{'SM-PartecipantId': this.config.partecipantId}}).pipe(
            map((response: T) => {
                console.debug(`putRequest ${url} ${this.finishRequest(startDate, showLoading)}`)
                return response
            })
            , catchError(err => this.throwError<T>(err, startDate, showLoading))
        );
    }
    protected deleteRequest<T>(url: string, showLoading = false): Observable<T> {
        const startDate = this.startRequest(showLoading,url);

        return this.http.delete<T>(url, {headers:{'SM-PartecipantId': this.config.partecipantId}}).pipe(
            map((response: T) => {
                console.debug(`deleteRequest ${url} ${this.finishRequest(startDate, showLoading)}`)
                return response
            })
            , catchError(err => this.throwError<T>(err, startDate, showLoading))
        );
    }
    protected postRequest<T>(url: string, body: any, showLoading = false): Observable<T> {
        const startDate = this.startRequest(showLoading, url);

        return this.http.post<T>(url, body, {headers:{'SM-PartecipantId': this.config.partecipantId}}).pipe(
            map((response: T) => {
                console.debug(`postRequest ${url} ${this.finishRequest(startDate,showLoading)}`)
                return response
            })
            , catchError(err => this.throwError<T>(err, startDate, showLoading))
        );
    }
    
    private throwError<T>(err: any, start: number, showLoading: boolean): Observable<T> {
        console.error(`base.http error ${this.finishRequest(start, showLoading)}`,err);
        throw err; 
    }

    private startRequest(showLoading: boolean, url:string){ 
        if (showLoading) {
            console.log(`dispatiching loading.now for ${url}`)
            this.store.dispatch(loadingMessage({message: I18Service.get('Loading.now')}))
        }
        // if (showLoading)
        // console.trace(`startRequest showLoading:${showLoading}`)
        return new Date().getTime();
    }
    private finishRequest(start: number, showLoading: boolean): string { 
        if (showLoading) {
            this.store.dispatch(loadingMessage({message: ''}))
        }
        return `elapsed: ${(new Date()).getTime() - start}ms showLoading:${showLoading}`;
    }
}