export class ErrorInfo {
  imagePath: string = 'assets/images/error-robot.png';
  message: string = '';
  originalError: any;
  type: number = -1;
  showReloadButton: boolean = true;

  // constructor(imagePath: string, message: string, type: number = -1, originalError: any = undefined, showReloadButton: boolean = true) {
  //   this.imagePath = imagePath;
  //   this.message = message;
  //   this.originalError = originalError;
  //   this.type = type;
  //   this.showReloadButton = showReloadButton;
  // }
}
