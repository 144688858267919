import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { ImageHelper } from 'src/app/helpers/image.helper';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
import { PartecipantStatus, partToStr } from 'src/app/models/WebServer/partecipantStatus';
import { PhoneStatusEnum } from 'src/app/models/phoneStatus';
import { AppConfigService } from 'src/app/services/app.config.service';
import { EventService } from 'src/app/services/event.service';
import { FELoggerService, LogSource } from 'src/app/services/feLogger.service';
import { I18Service } from 'src/app/services/i18.service';
import { MediaService } from 'src/app/services/media.service';
import { NotificationService } from 'src/app/services/notification.service';
import * as appActions from 'src/app/store/actions/app.actions';
import { AppState } from 'src/app/store/states/app.state';
import * as loadImage from 'blueimp-load-image';


import * as sessionSelector from 'src/app/store/selectors/session.selectors'
import * as appSelector from 'src/app/store/selectors/app.selectors'
import { PartecipantInfo } from 'src/app/models/WebServer/partecipantInfo';
import { ThumbVideoComponent } from './thumb-video.component';
import { FileSenderHelper } from 'src/app/helpers/fileSender.helper';
@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() AS!: AppState;
  @Input() MYSTATUS!: PartecipantStatus;
  @Input() OTHERSTATUS?: PartecipantStatus;

  @ViewChild('localVideoSnapshot') localVideoSnapshot!: ElementRef;
  @ViewChild("canvas") public canvas!: ElementRef;
  @ViewChild("videoContainer") public videoContainer!: ElementRef;


  private destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('remoteVideoContainerRef', { read: ViewContainerRef, static: true })
  public remoteVideoContainer!: ViewContainerRef;

  @ViewChild('myVideoContainerRef', { read: ViewContainerRef, static: true })
  private myVideoContainer!: ViewContainerRef;

  actualBigVideo!: ElementRef;
  actualThumbBig!: ComponentRef<ThumbVideoComponent>;


  higher = false;
  appModes = AppModesEnum;
  phoneStatuses = PhoneStatusEnum;

  AS_video = { cameraEnabledForSnapshot: false, localBig: false, captureUrl: ''/*, sending: false*/ }

  constructor(
    private store: Store<AppState>,
    public mediaService: MediaService,
    private eventService: EventService,
    private cdr: ChangeDetectorRef,
    public config: AppConfigService) {
  }
  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   const citizen = this.config.partecipantIds.find(z => z.partecipantRole == PartecipantRoles.SecondaryClient);
    //   // let initialBigVideo = citizen?.partecipantId;
    //   // if (this.config.isCaller) {
    //   //   this.setFullVideo(this.config.partecipantId);
    //   // }
    //   this.setFullVideo(citizen!.partecipantId);
    // }, 300)
  }

  ngOnDestroy(): void {
    // if (this.deviceChangeEvent) {
    //   this.deviceChangeEvent.unsubscribe();
    // }
    // clearInterval(this.drawInterval);
    console.log(`*************** video.component remotevideo DESTROY **************`);
    this.mediaService.remoteVideoRefs.forEach( ref => ref.destroy());
    this.mediaService.remoteVideoRefs = [];
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();

  }

  ngOnInit() {
    // this.isIphone = AppHelper.isAppleOS(); //TODO
    setTimeout(() => {

      this.mediaService.init();
      if (this.config.isCaller) {
        this.mediaService.setLocalVideoSnapshot( this.localVideoSnapshot.nativeElement);
      }
      /*//##this.mediaService.init({
        localVideoSnapshot: this.localVideoSnapshot.nativeElement,
        localVideo: this.localVideo.nativeElement,
        outAudio: this.outAudio.nativeElement,
        remoteVideo: this.remoteVideo.nativeElement
      });*/

    }, 0);


    this.store.select(sessionSelector.selectPartecipants).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      if (data) {
        const myInfo = data.find( z => z.partecipantId == this.config.partecipantId);
        const others = data.filter( z => z.partecipantId != this.config.partecipantId && z.active);
        this.addMyVideoContainer(myInfo);
        this.addVideoComponent(others);
      } // dividere tra my comp e gli altri

    });

    this.store.select(appSelector.guiLanguage).pipe(
      takeUntil(this.destroy$)
    ).subscribe(lang => {
      const parts = this.config.getPartecipantsStatus();
      this.mediaService.remoteVideoRefs.forEach(ref => 
        ref.instance.name = I18Service.roleAndAlias(parts.find( p=> p.partecipantId == ref.instance.id))
      )
    });

    // this.deviceChangeEvent = this.mediaService.deviceSourceChange.subscribe((sourceChangeInfo: any) => {
    //   if (sourceChangeInfo && sourceChangeInfo.type) {
    //     switch (sourceChangeInfo.type) {
    //       case DeviceType.AUDIO_OUT:
    //         this.changeAudioOutputDestination();
    //         break;
    //     }
    //   }
    // });
    if (this.config.AS?.isCaller) {
      this.AS_video.localBig = true;
    }
    // this.createCanvas();
  }

  private async addMyVideoContainer(info: PartecipantInfo | undefined){

    if (info) {
      const me = await this.addThumbVideo(info, this.myVideoContainer);
      me.instance.name = I18Service.get('Chat.Me');
      // const { VideFillThumbnailComponent } = await import('./video-fill-thumbnail.component');
      // const componentRef = this.remoteVideoContainer.createComponent(VideFillThumbnailComponent); // spaziatura!!

    }
  }

  private async addVideoComponent(data: PartecipantInfo[])  {
    if(!data) return;


    // const myP = data.find( z => z.partecipantId == this.config.partecipantId);
    // if (myP) {
    //   const me = await this.addDynamicFoodChoice(myP);
    //   me.instance.name = I18Service.get('Chat.Me');
    //   const { VideFillThumbnailComponent } = await import('./video-fill-thumbnail.component');
    //   const componentRef = this.remoteVideoContainer.createComponent(VideFillThumbnailComponent); // spaziatura!!

    // }

    data.forEach( p => {
          if(p.active)this.addThumbVideo(p, this.remoteVideoContainer);
    })
    // const x = (100 - 10) / data.length;
    // this.elementRef.nativeElement.style.setProperty('--thumbnail-width', `${x}%`)
  }

  private async addThumbVideo(partecipant: PartecipantInfo, container: ViewContainerRef): Promise<ComponentRef<ThumbVideoComponent>> {

    const already =this.mediaService.remoteVideoRefs.find( z => z.instance.id == partecipant.partecipantId)
    if (already) {
      return already; //created
    }


    const { ThumbVideoComponent: ThumbVideoComponent } = await import('./thumb-video.component')
    const componentRef = container.createComponent(ThumbVideoComponent)

    componentRef.instance.id = partecipant.partecipantId;
    componentRef.instance.AS = this.AS;
    componentRef.instance.name = I18Service.roleAndAlias(partecipant);
    // componentRef.instance.enabled = false;

    // componentRef.instance.videoFull.subscribe( id => {
    //   this.setFullVideo(id);
    // })
    componentRef.instance.imageToEdit.subscribe( blob => {
      this.mediaService.imageToEdit = blob;
      if (!blob) {
        this.editingCanvas(null);
      }
      // if (canvas) {
      //   canvas.toBlob( blob => {
      //     this.imageToEdit = blob;
      //   })
      // }
      // this.mediaService.setImageToEditStream(canvas)
    })

    console.log(`mediaService - creating remotevideo for ${partecipant.alias} ${partToStr(partecipant)}`)

    this.mediaService.remoteVideoRefs.push(componentRef);

    // this.orderedFood = [...this.orderedFood, choice]
    this.cdr.detectChanges()
    return componentRef;
  }

  editOff() {
    this.mediaService.imageToEdit = null;
    this.mediaService.setVideoZoom('');
    this.editingCanvas(null);
  }

  editingCanvas(canvas: HTMLCanvasElement | null) {
    this.mediaService.setImageToEditStream(canvas)
  }

  // setFullVideo(id: string) {

  //   this.mediaService.setVideoZoom(id);

  // }

  async enableSnapshot() {
    this.mediaService.startLocalVideoSnapshot();//TODO ?? this.sipService.sessionStore?.currentSession);
    if (this.AS.mediaPermission != 2) {
      this.AS_video.cameraEnabledForSnapshot = true;
      this.AS_video.localBig = true;
    }
  }

  disableSnapshot() {
    this.AS_video.cameraEnabledForSnapshot = false;
    this.AS_video.localBig = true;
    this.mediaService.stopLocalVideoSnapshotStream()
  }


  // async toggleReceiverStream() {

  //   this.store.dispatch(appActions.toggleVideo());

  // }


  isLocalVideoEnabled(): boolean { //TODO -- ok?

      if (this.AS.appModes.includes(AppModesEnum.Photo) == false && this.AS.isCaller) {
        //this.AS_video.cameraEnabledForSnapshot = false;
        this.disableSnapshot();
      }


    return this.AS.appModes.includes(AppModesEnum.Video)
      || (!!this.AS_video.cameraEnabledForSnapshot && !this.AS_video.captureUrl);
    // return true;
  }

  isRemoteVideoEnabled(): boolean {
    return !!this.AS.remoteVideoEnabled;
  }

  switchCamera() {
    this.store.dispatch(appActions.switchCamera({forSnapshot: this.AS_video.cameraEnabledForSnapshot}))
  }

  doubleCamera(): boolean {
    return this.mediaService.multiCamera();
  }

  atLeast1Camera(): boolean {
    return this.mediaService.atLeast1Camera();
  }


  captureVideoImage() {
    setTimeout(() => {
      var scaleFactor = 1;

      var snapshotElement = this.localVideoSnapshot.nativeElement
      var w = snapshotElement.videoWidth * scaleFactor;
      var h = snapshotElement.videoHeight * scaleFactor;

      this.canvas.nativeElement.width = w;
      this.canvas.nativeElement.height = h;
      var ctx = this.canvas.nativeElement.getContext('2d');
      ctx.drawImage(snapshotElement, 0, 0, w, h);
      this.AS_video.captureUrl = this.canvas.nativeElement.toDataURL("image/png");
      if (this.videoContainer.nativeElement) {
        if (this.videoContainer.nativeElement.clientWidth > this.videoContainer.nativeElement.clientHeight) {
          this.higher = false;
        } else {
          this.higher = true;
        }
      }
    }, 200);
  }

  sendCameraImage() {
    try {
      ImageHelper.resizeImage(this.AS_video.captureUrl + '', this.config.desiredImageSize, this.canvas.nativeElement);
      // var createEvent = ImageHelper.createDataEvent(this.AS_video.captureUrl + '', '', this.AS.partecipantId, this.AS.batteryRaw + '', '');
      // this.sendImage(createEvent);
      FileSenderHelper.sendCameraImage(this.AS_video.captureUrl + '', this.AS.partecipantId)
      .then((b: boolean)=>{
        // this.AS_video.sending = false;
        this.AS_video.captureUrl = '';
      });
    } catch (error) {
      // Logger.error('Error Sending Capture Image', error);
      FELoggerService.error('Error Sending Capture Image', LogSource.MediaAudio, error);
      NotificationService.showError('Immagine corrotta');
      NotificationService.popupError({msg: `Non è stato possibile inviare l'immagine`});
      // this.AS_video.sending = false;
      this.AS_video.captureUrl = '';
    }
  }

  backToVideo() {
    this.AS_video.captureUrl = '';
  }

  openImageGalleryInput() {
    document.getElementById("imageUploader")?.click();
  }

  async onImageGalleryChange(e: any) {
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.match(/^image\//)) {
        // this.AS.sending = true;

        // manipolo il file prima di inviarlo al session Manger per ridurne la dimensione
        this.scaleSize(files[i]);
        var inputElement = <any>document.getElementById('imageUploader');
        console.log(inputElement);
        inputElement.value = "";
        break;
      } else {
        NotificationService.showError(I18Service.get('POPUP.IMAGEERRORTITLE'))
      }
    }
  }

  private scaleSize(file: File) {
    // this.AS_video.sending = true;
    const self = this;
    let scaleFactor = 0.7;

    loadImage(
      file,
      (canvas: any, data: any) => {
        // Estraggo informazioni sul cellulare a partire da EXIF. 271 --> Produttore, 272 --> Modello
        const phoneModel = (data && data.exif) ? `${data.exif['271']} - ${data.exif['272']}` : ''

        const mimeType = 'image/jpeg';
        try {
          let url: string = canvas.toDataURL(mimeType, scaleFactor);

          // salvo l'evento sul Session manager
          ImageHelper.resizeImage(url, self.config.desiredImageSize, canvas);
          // const createEvent = ImageHelper.createDataEvent(url, file.name, self.AS.partecipantId || '', self.AS.batteryRaw || '', phoneModel);
          // self.sendImage(createEvent);
          FileSenderHelper.sendGalleryImageAttachment(url, file.name, self.config.partecipantId).finally(()=>{
            // this.AS_video.sending = false;

          });
        } catch (error) {
          // this.AS_video.sending = false;
          NotificationService.popupWarn({msg: I18Service.get('POPUP.CORRUPTEDIMGBODY')});
          error && FELoggerService.error(`Error during image Sharing: ${error}`);
          // self.AS.sending = false;
        }
      },
      { meta: true, canvas: true, orientation: true }
    );
  }

  // private sendImage(createEvent: CreateEvent) {
  //   this.AS_video.sending = true;
  //   this.eventService.saveEvent(createEvent).subscribe({
  //     next: (id: number) => {
  //       NotificationService.showSuccess(`${I18Service.get('POPUP.IMAGESENTBODY')}`);
  //       this.eventService.getEvent(id).subscribe({
  //         next: (ev: CreateEvent) => {
  //           if (!ev.data.media || !ev.data.media.mediaUrl) return;
  //           const mediaLink = ev.data.media.mediaUrl;

  //           this.store.dispatch(appActions.sendMediaMessage({content: mediaLink, fileName: ev.data?.media?.mediaName || '', mediaType: ev.data.media.mediaType, toIds: [appActions.SEND_TO_ALL] }))

  //         }
  //       });
  //       this.AS_video.sending = false;
  //       this.AS_video.captureUrl = '';
  //     },
  //     error: () => {
  //       NotificationService.showError(`${I18Service.get('POPUP.IMAGEERRORBODY')}`);
  //       this.AS_video.sending = false;
  //     }
  //   });;

  // }

  openFileInput(divId: string) {
    document.getElementById(divId)?.click();
  }
  
  async attachDocument(e: any) {

    FileSenderHelper.attachDocument(e, this.config.partecipantId);
    var inputElement = <any>document.getElementById('attachmentUploader');
    inputElement.value = "";
  }
}
