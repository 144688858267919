import { PartecipantRoles } from './partecipantRoles';

export type PartecipantInfo = { //TODO dave, sembra un set di dati comunque presente e ricavabile in altro modo
    info: string;
    alias: string;
    partecipantId: string;
    partecipantRole: PartecipantRoles;
    // partecipantId64: string;
    active: boolean;
    viewPreviousChat: boolean;
}

export const initialPartecipantInfo: PartecipantInfo = {
    info: '',
    alias: '',
    partecipantId: '',
    partecipantRole: PartecipantRoles.PrimaryClient,
    // partecipantId64: ''
    active: true,
    viewPreviousChat: false
}
