
export enum StatusChangeEvent {

  DisclaimerAccepted = 'DisclaimerAccepted',
  AddressInfo = 'AddressInfo',
  GeoLocCoords = 'GeoLocCoords',
  BrowserControlResult = 'BrowserControlResult',
  GeoPermissionUpdated = 'GeoPermissionUpdated',

  IsRegistered = 'IsRegistered',
  DeviceInfoUpdate = 'DeviceInfoUpdate',
  UserLanguage = 'UserLanguage',
  MultipleUpdateStatus = 'MultipleUpdateStatus',
  PageInfoUpdate = 'PageInfoUpdate',
  AppModesChange = 'AppModesChange',
  AppInMultimedia = 'AppInMultimedia',
  MediaPermissionUpdated = 'MediaPermissionUpdated',
  MicrophonePermissionUpdated = 'MicrophonePermissionUpdated',
  MicrophoneEnabled = 'MicrophoneEnabled',
  VideoEnabled = 'VideoEnabled',
  CommandsDisabled = 'CommandsDisabled',
  BatteryStatusUpdate = 'BatteryStatusUpdate',
  NotifySpeed = 'NotifySpeed',
  PhoneStatus = 'PhoneStatus',
  DisclaimerChoiceRevealed = 'DisclaimerChoiceRevealed',
  AvailableDevices = 'AvailableDevices'
}

