import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { PhoneStatusEnum } from 'src/app/models/phoneStatus';
import { AppConfigService } from 'src/app/services/app.config.service';
import { EventService } from 'src/app/services/event.service';
import { AppState } from 'src/app/store/states/app.state';
import { SvgConstants } from 'src/app/svgConstants';
import { FELoggerService } from 'src/app/services/feLogger.service';
import { I18Service } from 'src/app/services/i18.service';
import { PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';
import { RootState } from 'src/app/store/root.state';
import { Store } from '@ngrx/store';
import * as sessionActions from "src/app/store/actions/session.actions";
import * as sessionSelectors from "src/app/store/selectors/session.selectors";
import { CheckerService } from 'src/app/services/checker.service';
import { ImageHelper } from 'src/app/helpers/image.helper';
import { NotificationService } from 'src/app/services/notification.service';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';
import { AppHelper } from 'src/app/helpers/app.helper';
import { FileSenderHelper } from 'src/app/helpers/fileSender.helper';

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl,
    form: FormGroupDirective | NgForm
  ): boolean {
    // if(control.value === '') console.log('phone is empty************************')
    return form.submitted && control.invalid || control.invalid && control.value !== '';
  }
}
@Component({
  selector: 'app-operator-actions',
  templateUrl: './operator-actions.component.html',
  styleUrls: ['./operator-actions.component.scss'],
    // Need to remove view encapsulation so that the custom tooltip style defined in
  // `tooltip-custom-class-example.css` will not be scoped to this component's view.
  encapsulation: ViewEncapsulation.None,
})
export class OperatorActionsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() AS!: AppState;
  @Input() MYSTATUS!: PartecipantStatus;
  @Input() SECONDARYSTATUS?: PartecipantStatus;
  @ViewChild('downloadUrl') downloadUrl!: ElementRef;
 
  showOperatorButtons = false;
  disableForwardChat = false;
  addGuestDisabled = false;
  showAddGuestForm = false;
  svgConstants = SvgConstants;

  preferredCountries: string[] = [];
  // // appStates = MultimediaNodeEnum;
  phoneStatuses = PhoneStatusEnum;
 
  showSessionInfo = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  phoneForm = new FormGroup({
    alias: new FormControl<string>('', [Validators.required]),
    phoneNumber: new FormControl<string>(''), //[Validators.required]),
    email: new FormControl<string>('', [Validators.email]),
    sendSms: new FormControl<boolean>(true, [Validators.required]),
    viewPreviousChat: new FormControl<boolean>(false, [Validators.required]),
    superUser: new FormControl<boolean>(false, [Validators.required]),
  });;

// phoneForm!: FormGroup<PhoneForm>

customErrorStateMatcher = new CustomErrorStateMatcher();

  constructor(
    public checkerService: CheckerService,
    public store: Store<RootState>,
    public config: AppConfigService,
    private eventService: EventService
  ) {
    this.setForm();

    this.store.select(sessionSelectors.remainingGuests).pipe(
      takeUntil(this.destroy$)
    ).subscribe( remaining => {
      console.log(`Remaining guests to add: ${remaining}`);
      this.addGuestDisabled = remaining <= 0;
    })

    this.preferredCountries.push(this.config.agencySettings?.defaultPreferredCountry || 'it')

  }

  private setForm() {
    this.phoneForm = new FormGroup({
      alias: new FormControl('', {validators: [Validators.required]}),
      phoneNumber: new FormControl(''),//, {validators: [Validators.required]}),
      email: new FormControl('', [Validators.email]),
      sendSms: new FormControl(true, [Validators.required]),
      viewPreviousChat: new FormControl(false, [Validators.required]),
      superUser: new FormControl<boolean>(false, [Validators.required]),
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();

  }

  async ngOnInit() {
    this.showOperatorButtons = this.config.operatorButtons;
  }

  async ngAfterViewInit() {
  }

  AskForReinvite() {
    // Swal.fire({
    //   // title: I18Service.get('LABELS.SETNOTINCALL'),
    //   html: I18Service.get('LABELS.SETNOTINCALLBODY'),
    //   showCancelButton: true,
    //   icon: 'question',
    //   cancelButtonText: I18Service.get('POPUP.CANCEL')
    // }).then((value) => {
    //   if (value.isConfirmed) {
    //     this.config.duringPSTNCall = !this.config.duringPSTNCall;
    //     this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.NotifyInCallSession, this.config.duringPSTNCall);
    //     this.feLogger.LogInfo(`Operator is preparing to notify to Caller that duringPSTNCall = ${this.config.duringPSTNCall}`);
    //   }
    // });
    // this.config.firstReinviteAfterInvite = false;
  }

  aksForNewInvite(ask: boolean = true) {
    // if (ask) {
    //   Swal.fire({
    //     // title: I18Service.get('LABELS.ASKNEWINVITE'),
    //     html: I18Service.get('LABELS.ASKNEWINVITEBODY'),
    //     showCancelButton: true,
    //     cancelButtonText: I18Service.get('POPUP.CANCEL'),
    //     icon: 'question'
    //   }).then((value) => {
    //     if (value.isConfirmed) {
    //       this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.AskForNewInvite);
    //       this.eventService.saveBusinessEvent(AppHelper.getDynamicCreateData(this.config, BusinessEventTypes.AskForNewInvite));
    //       if (!this.config.sendVideoOn) {
    //         this.config.firstOperatorReinvite = true;
    //       }
    //     }
    //   });
    // } else if (!this.config.phoneStatus || this.config.phoneStatus == PhoneStatusEnum.IDLE) {
    //   this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.AskForNewInvite);
    //   this.eventService.saveBusinessEvent(AppHelper.getDynamicCreateData(this.config, BusinessEventTypes.AskForNewInvite));
    //   if (!this.config.sendVideoOn) {
    //     this.config.firstOperatorReinvite = true;
    //   }
    // }
  }


  async attachDocument(e: any) {
    FileSenderHelper.attachDocument(e, this.config.partecipantId)
    .then( (b:boolean) => {
      var inputElement = <any>document.getElementById('attachmentUploader');
      inputElement.value = "";
    });
  }


  addGuest() {
    if(!this.showAddGuestForm) this.setForm();
    this.showAddGuestForm = true;
  }
 
  createGuest() {

    this.sendSmsChange(true);


    this.phoneForm.markAllAsTouched();
    if (!this.phoneForm.valid) return;

    const alias = this.phoneForm.controls.alias.value || '';
    const phoneNumber = this.phoneForm.controls.phoneNumber.value || ''
    const email = this.phoneForm.controls.email.value || ''
    const viewPreviousChat = !!this.phoneForm.controls.viewPreviousChat.value;
    const superUser = !!this.phoneForm.controls.superUser.value;

    
    this.store.dispatch(sessionActions.addGuest({ alias, email, phoneNumber, viewPreviousChat, superUser: superUser }));

    this.setForm();

    this.showAddGuestForm = false;
  }
  sendSmsChange(checkRequired = false) {
    const sendSms = this.phoneForm.controls.sendSms.value;
    
    if (sendSms == true){this.phoneForm.controls.email.reset();}
    else{this.phoneForm.controls.phoneNumber.reset();}

    if (checkRequired) {
      this.phoneForm.controls.email.clearValidators();      
      this.phoneForm.controls.phoneNumber.clearValidators();
    
      if (sendSms == true){
        this.phoneForm.controls.phoneNumber.setValidators(Validators.required);
      }
      else {
        this.phoneForm.controls.email.setValidators([Validators.email, Validators.required]);
      }
      this.phoneForm.controls.phoneNumber.updateValueAndValidity();
      this.phoneForm.controls.email.updateValueAndValidity();
    }
      
  }


  forwardChatHistory() {//TODO ??
    this.disableForwardChat = true;
    this.eventService.forwardChatHistory(this.AS.session.sessionId).subscribe( {
      next: (res) => {
        if (res) {
          NotificationService.popupSuccess({msg:I18Service.get('LABELS.CHATHISTORYFORWARDOK')});
        } else {
          NotificationService.popupError({msg:I18Service.get('LABELS.CHATHISTORYFORWARDKO')});
        }
      }, 
      error: err => {
          NotificationService.popupError({msg:I18Service.get('LABELS.CHATHISTORYFORWARDKO')});
          this.disableForwardChat = false;
      }, 
      complete: () => { this.disableForwardChat = false; }
    });
  }

  add_n_Minutes() { //TODO
    NotificationService.popupQuestion({msg:I18Service.get('POPUP.EXTENDSESSIONBODY')})
    .then(async (val) => {
      if (val.isConfirmed) {
        this.store.dispatch(sessionActions.extendSession());
        //let key: string = `${this.config.AS?.session.parameters.partecipantIds[0].partecipantId}${this.config.AS?.session.parameters.partecipantIds[1].partecipantId}`;
        //await this.sessionService.extendSession(this.config.AS?.session.sessionId || '', this.config.minutesExtension, key); //TODO: cosa c'è da notificare per ext session? forse su SM
        // this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.ExtendSession);
        // this.eventService.saveBusinessEvent(AppHelper.getDynamicCreateData(this.config, BusinessEventTypes.OperatorExtendSession));
      }
    });
  }

  endSession() { 
    NotificationService.popupQuestion({msg:I18Service.get('POPUP.ENDSESSIONBODY')})
    .then(async (val) => {
      if (val.isConfirmed) {
                if (this.config.isGuest) {
          this.store.dispatch(sessionActions.endSession()); 
        }
        else {
          this.store.dispatch(sessionActions.terminateSession()); 
        }
      }
    });
  }



  public getDisplayMedia(options: any) {
    if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
      // @ts-ignore
      return navigator.mediaDevices.getDisplayMedia(options);
    }
    if ((<any>navigator).getDisplayMedia) {
      return (<any>navigator).getDisplayMedia(options);
    }
    if ((<any>navigator).webkitGetDisplayMedia) {
      return (<any>navigator).webkitGetDisplayMedia(options);
    }
    if ((<any>navigator).mozGetDisplayMedia) {
      return (<any>navigator).mozGetDisplayMedia(options);
    }
    throw new Error('getDisplayMedia is not defined');
  }

  public getUserMedia(options: any) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(options);
    }
    if ((<any>navigator).getUserMedia) {
      return (<any>navigator).getUserMedia(options);
    }
    if ((<any>navigator).webkitGetUserMedia) {
      return (<any>navigator).webkitGetUserMedia(options);
    }
    if ((<any>navigator).mozGetUserMedia) {
      return (<any>navigator).mozGetUserMedia(options);
    }
    throw new Error('getUserMedia is not defined');
  }

  public async takeScreenshotStream() {
    // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/screen
    const width = screen.width * (window.devicePixelRatio || 1);
    const height = screen.height * (window.devicePixelRatio || 1);

    const errors = []
    let stream;
    try {
      stream = await this.getDisplayMedia({
        audio: false,
        // see: https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints/video
        video: {
          width,
          height,
          frameRate: 1,
        },
      })
    } catch (ex) {
      errors.push(ex);
    }

    // for electron js
    if (navigator.userAgent.indexOf('Electron') >= 0) {
      try {
        stream = await this.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              // chromeMediaSourceId: source.id,
              minWidth: width,
              maxWidth: width,
              minHeight: height,
              maxHeight: height,
            },
          },
        })
      } catch (ex) {
        errors.push(ex);
      }
    }

    if (errors.length) {
      console.debug(...errors);
      if (!stream) {
        throw errors[errors.length - 1]
      }
    }

    return stream;
  }

  public async takeScreenshotCanvas(): Promise<HTMLCanvasElement> {
    const stream = await this.takeScreenshotStream();

    // from: https://stackoverflow.com/a/57665309/5221762
    const video = document.createElement('video');
    const result = await new Promise<HTMLCanvasElement>((resolve, reject) => {
      video.onloadedmetadata = () => {
        video.play();
        setTimeout(() => {
          const canvas = AppHelper.getCanvasFromVideo(video);
          if (canvas) resolve(canvas);
          else reject();
        }, 0);
      }
      setTimeout(() => {
        video.srcObject = stream;
      }, 1000);
    });

    stream.getTracks().forEach(function (track: any) {
      track.stop();
    });

    if (result == null) {
      throw new Error('Cannot take canvas screenshot');
    }

    return result;
  }

  // from: https://stackoverflow.com/a/46182044/5221762
  public async getJpegBlob(canvas: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      // docs: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
      canvas.toBlob((blob: any) => resolve(blob), 'image/jpeg', 0.95);
    });
  }

  public async getJpegBytes(canvas: any) {
    return new Promise(async (resolve, reject) => {
      const fileReader = new FileReader();
      const blob = await this.getJpegBlob(canvas);
      fileReader.addEventListener('loadend', function () {
        if (this.error) {
          reject(this.error);
          return;
        }
        resolve(this.result);
      });
      fileReader.readAsArrayBuffer(blob);
    });
  }

  public async takeScreenshotJpegBlob() {
    const canvas = await this.takeScreenshotCanvas();
    return this.getJpegBlob(canvas);
  }

  public async takeScreenshotJpegBytes() {
    const canvas = await this.takeScreenshotCanvas();
    return this.getJpegBytes(canvas);
  }

  public async takeAndSendScreenshot() { 
    const mimeType = 'image/jpeg';
    // var previewCanvas = await html2canvas(document.body);
    var previewCanvas = await this.takeScreenshotCanvas();
    previewCanvas.style.position = 'fixed'
    try {
      if (previewCanvas.width < 100 || previewCanvas.height < 10) throw new Error('Sharing stopped');
      let url: string = previewCanvas.toDataURL(mimeType);
      // salvo l'evento sul Session manager
      ImageHelper.resizeImage(url, this.config.desiredImageSize, previewCanvas);
      const fileName = `Screenshot_${(new Date()).getTime()}`;
      // const createEvent = ImageHelper.createDataEvent(url, fileName, this.config.partecipantId, this.AS.batteryRaw || '--', BrowserHelper.getModel(this.SECONDARYSTATUS?.parsedUA));
      // this.sendMediaEvent(createEvent);
      FileSenderHelper.sendScreenshotAttachment(url, fileName, this.config.partecipantId)
      .then( (b: boolean)=>{

      });
    } catch (error) {
      NotificationService.popupWarn({msg:I18Service.get('POPUP.CORRUPTEDIMGBODY')});
      error && FELoggerService.error(`Error during image Sharing: ${error}`);
      // this.AS_boh.sending = false;
    }
  }

  openFileInput(divId: string) {
    document.getElementById(divId)?.click();
  }

}
