import { PartecipantInfo } from './partecipantInfo';

export type SessionParameters = {
    sessionId: string;
    creationDate: Date;
    partecipantIds: PartecipantInfo[];
}

export const initialSessionParameters: SessionParameters = {
    sessionId: '',
    creationDate: new Date(),
    partecipantIds:[]
}
