import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { fabric } from 'fabric';
import { Observable, of, switchMap } from "rxjs";
import { CanvasTool, CanvasToolType } from "./tools/tool";
import { Arrow } from "./tools/arrow";
import { Pencil } from "./tools/pencil";
import { Circle } from "./tools/circle";
import { Line } from "./tools/line";
import { Rectangle } from "./tools/rectangle";
import { Text } from "./tools/text";
import { Select } from "./tools/select";

//https://github.com/GroupeCurious/ngx-image-drawing

// insieme a https://github1s.com/AmrEsyd/annotate/blob/v2/src/components/Toolbar.tsx


@Component({
    selector: "app-image-stream-editor",
    templateUrl: "./image-stream-editor.component.html",
    styleUrls: ["./image-stream-editor.component.scss"]
  })
  export class ImageStreamEditorComponent implements OnInit, OnChanges, OnDestroy {

    @Input() public src?: string;
    @Input() public blob?: Blob | null;
    @Input() public width?: number;
    @Input() public height?: number;

    @Input() public forceSizeCanvas = true;
    @Input() public forceSizeExport = false;
    @Input() public enableRemoveImage = false;
    @Input() public enableLoadAnotherImage = false;
    @Input() public enableTooltip = true;
    @Input() public showCancelButton = true;

    // @ts-ignore
    @Input() public saveBtnText = 'Save';
    /* @deprecated Use i18n.cancelBtn */
    @Input() public cancelBtnText = 'Cancel';
    /* @deprecated Use i18n.loading */
    @Input() public loadingText = 'Loading…';
    /* @deprecated Use i18n.loadError */
    @Input() public errorText = 'Error loading %@';

    @Input() public loadingTemplate?: TemplateRef<any>;
    @Input() public errorTemplate?: TemplateRef<any>;

    @Input() public outputMimeType = 'image/jpeg';
    @Input() public outputQuality = 0.8;

    @Input() public borderCss: string = 'none';

    @Input() public drawingSizes: { [name: string]: number } = {
        small: 5,
        medium: 10,
        large: 25,
    };

    @Input() public colors: { [name: string]: string } = {
        black: '#000',
        white: '#fff',
        yellow: '#ffeb3b',
        red: '#f44336',
        blue: '#2196f3',
        green: '#4caf50',
        purple: '#7a08af',
    };

    @Output() public editingCanvas: EventEmitter<HTMLCanvasElement|null> = new EventEmitter<HTMLCanvasElement|null>();

    // @Output() public save: EventEmitter<Blob|null> = new EventEmitter<Blob|null>();
    // @Output() public cancel: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('canvasContainerEditor') canvasContainerEditor!: ElementRef;

    CanvasToolType = CanvasToolType;
    public currentTool: CanvasToolType = CanvasToolType.pencil;
    public currentSize = 'small';
    public currentColor = 'red';
    public canUndo = false;
    public canRedo = false;




    public isLoading = false;
    public hasError = false;
    public errorMessage = '';

    private canvas!: fabric.Canvas;
    private stack: fabric.Object[] = [];

    public colorsName: string[] = [];
    public drawingSizesName: string[] = [];

    private imageUsed?: fabric.Image;


    ngOnInit() {
        this.colorsName = Object.keys(this.colors);
        this.drawingSizesName = Object.keys(this.drawingSizes);
    
        this.canvas = new fabric.Canvas('canvas', {
            hoverCursor: 'pointer',
            // isDrawingMode: true,
        });
        this.canvas.backgroundColor = 'white';
        // this.canvas.isDrawingMode
    
        if (this.src) {
            this.importPhotoFromSrc(this.src);
        } else if (this.blob) {
            this.importPhotoFromBlob(this.blob);
        }
        else {
            if (!this.width || !this.height) {
                throw new Error('No width or hight given !');
            }
    
            this.canvas.setWidth(this.width);
            this.canvas.setHeight(this.height);
        }
    
        // this.selectColor(this.currentColor);
        // this.selectDrawingSize(this.currentSize);
        this.selectTool(this.currentTool);

        this.editingCanvas.emit(this.canvas.getElement());
    }
    ngOnDestroy(): void {
        this.canvas?.dispose();
    }

    public selectTool(tool: CanvasToolType) {
        if (!this.canvas) return;


        this.currentTool = tool;
        let canvasTool: CanvasTool = new Pencil(this.canvas);
        switch( tool ) {
            case CanvasToolType.arrow:
                canvasTool = new Arrow(this.canvas);
                break;
            case CanvasToolType.circle:
                // canvasTool = eval(`new ${tool}(this.canvas)`);
                canvasTool = new Circle(this.canvas);
                break;    
            case CanvasToolType.line:
                // canvasTool = eval(`new ${tool}(this.canvas)`);
                canvasTool = new Line(this.canvas);
                break;                            
            case CanvasToolType.rectangle:
                // canvasTool = eval(`new ${tool}(this.canvas)`);
                canvasTool = new Rectangle(this.canvas);
                break;
            case CanvasToolType.text:
                // canvasTool = eval(`new ${tool}(this.canvas)`);
                canvasTool = new Text(this.canvas);
                break;
            case CanvasToolType.select:
                // canvasTool = eval(`new ${tool}(this.canvas)`);
                canvasTool = new Select(this.canvas);
                break;                
            case CanvasToolType.pencil:
            default:
                canvasTool = new Pencil(this.canvas);
                break;
        }
    
        this.canvas.off();
        // this.canvas.on('path:created', () => { this.setUndoRedo(); });
        // this.canvas.on('object:added', () => { this.setUndoRedo(); });


        canvasTool.configureCanvas &&  canvasTool.configureCanvas({  
            stroke: this.colors[this.currentColor],
            strokeWidth: this.drawingSizes[this.currentSize],
            fill: 'transparent',}
        );
        
        this.canvas.on('mouse:move', (e: fabric.IEvent) => canvasTool.onMouseMove && canvasTool.onMouseMove(e) );
        this.canvas.on('mouse:down', (e: fabric.IEvent) => canvasTool.onMouseDown && canvasTool.onMouseDown(e) );
        this.canvas.on('mouse:up',   (e: fabric.IEvent) => {canvasTool.onMouseUp  && canvasTool.onMouseUp(e); this.setUndoRedo() });
    }
  
    public selectDrawingSize(size: string) {
        if (!this.canvas) return;
        this.currentSize = size;
        this.selectTool(this.currentTool);
    }
  
    public selectColor(color: string) {
        if (!this.canvas) return;
        this.currentColor = color;
        this.selectTool(this.currentTool);
    }
  
    // Actions
  
    public undo() {
        if (this.canUndo) {
            const lastId = this.canvas.getObjects().length - 1;
            const lastObj = this.canvas.getObjects()[lastId];
            this.stack.push(lastObj);
            this.canvas.remove(lastObj);
            this.setUndoRedo();
        }
    }
  
    public redo() {
        if (this.canRedo) {
            const firstInStack = this.stack.splice(-1, 1)[0];
            if (firstInStack) {
                this.canvas.insertAt(firstInStack, this.canvas.getObjects().length - 1, false);
            }
            this.setUndoRedo();
        }
    }
  
    public clearCanvas() {
        if (this.canvas) {
            this.canvas.remove(...this.canvas.getObjects());
            this.setUndoRedo();
        }
    }
  
    // public saveImage() {
    //     if (!this.forceSizeExport || (this.forceSizeExport && this.width && this.height)) {
    //         const canvasScaledElement: HTMLCanvasElement = document.createElement('canvas');
    //         const canvasScaled = new fabric.Canvas(canvasScaledElement);
    //         canvasScaled.backgroundColor = 'white';
  
    //         new Observable<fabric.Canvas>(observer => {
    //             if (this.imageUsed) {
    //                 if (this.forceSizeExport) {
    //                     canvasScaled.setWidth(this.width);
    //                     canvasScaled.setHeight(this.height);
  
    //                     this.imageUsed.cloneAsImage( (imageCloned: any) => {
    //                         imageCloned.scaleToWidth(this.width, false);
    //                         imageCloned.scaleToHeight(this.height, false);
  
    //                         canvasScaled.setBackgroundImage(imageCloned, (img: HTMLImageElement) => {
    //                             if (!img) {
    //                                 observer.error(new Error('Impossible to draw the image on the temporary canvas'));
    //                             }
  
    //                             observer.next(canvasScaled);
    //                             observer.complete();
    //                         }, {
    //                             crossOrigin: 'anonymous',
    //                             originX: 'left',
    //                             originY: 'top'
    //                         });
    //                     });
    //                 } else {
    //                     canvasScaled.setBackgroundImage(this.imageUsed, (img: HTMLImageElement) => {
    //                         if (!img) {
    //                             observer.error(new Error('Impossible to draw the image on the temporary canvas'));
    //                         }
  
    //                         canvasScaled.setWidth(img.width);
    //                         canvasScaled.setHeight(img.height);
  
    //                         observer.next(canvasScaled);
    //                         observer.complete();
    //                     }, {
    //                         crossOrigin: 'anonymous',
    //                         originX: 'left',
    //                         originY: 'top'
    //                     });
    //                 }
    //             } else {
    //                 canvasScaled.setWidth(this.width);
    //                 canvasScaled.setHeight(this.height);
    //             }
    //         }).pipe(
    //             switchMap(() => {
    //                 let process = of(canvasScaled);
  
    //                 if (this.canvas.getObjects().length > 0) {
    //                     const ratioX = canvasScaled.getWidth() / this.canvas.getWidth();
    //                     const ratioY = canvasScaled.getHeight() / this.canvas.getHeight();
  
    //                     this.canvas.getObjects().forEach((originalObject: fabric.Object, i: number) => {
    //                         process = process.pipe(switchMap(() => {
    //                             return new Observable<fabric.Canvas>(observerObject => {
    //                                 originalObject.clone((clonedObject: fabric.Object) => {
    //                                     clonedObject.set('left', originalObject.left! * ratioX);
    //                                     clonedObject.set('top', originalObject.top! * ratioY);
    //                                     clonedObject.scaleToWidth(originalObject.width! * ratioX);
    //                                     clonedObject.scaleToHeight(originalObject.height! * ratioY);
  
    //                                     canvasScaled.insertAt(clonedObject, i, false);
    //                                     canvasScaled.renderAll();
  
    //                                     observerObject.next(canvasScaled);
    //                                     observerObject.complete();
    //                                 });
    //                             });
    //                         }));
    //                     });
    //                 }
    //                 return process;
    //             }),
    //         ).subscribe(() => {
    //             canvasScaled.renderAll();
    //             canvasScaled.getElement().toBlob(
    //                 (data: Blob | null) => {
    //                     this.save.emit(data);
    //                 },
    //                 this.outputMimeType,
    //                 this.outputQuality
    //             );
    //         });
    //     } else {
    //         this.canvas.getElement().toBlob(
    //             (data: Blob | null) => {
    //                 this.save.emit(data);
    //             },
    //             this.outputMimeType,
    //             this.outputQuality
    //         );
    //     }
    // }
  
    // public cancelAction() {
    //     this.cancel.emit();
    // }
  
    public getTextTranslated(name: string): string {
        let strOk = name; //().split('.').reduce((o, i) => o[i], this.i18n as any);
  
        // if (this.i18nUser) {
        //     try {
        //         const str = name.split('.').reduce((o, i) => o[i], this.i18nUser as any);
        //         if (str) {
        //             strOk = str;
        //         }
        //     } catch (e) {
        //         // if we pass here, ignored
        //     }
        // }
  
        // if (!strOk) {
        //     console.error(name + ' translation not found !');
        // }
  
        return strOk;
    }
  
    public getTooltipTranslated(name: string): string {
        if (this.enableTooltip) {
            return this.getTextTranslated(name)
        } else {
            return '';
        }
    }
  
    private setUndoRedo() {

      this.canUndo = this.canvas.getObjects().length > 0;
      this.canRedo = this.stack.length > 0;
      // this.canvas.renderAll();
      console.log(`mediaService - setUndoRedo canUndo:${this.canUndo}, canRedo:${this.canRedo}`)
  }
  
  public importPhotoFromFile(event: Event | any) {
      if (event.target.files && event.target.files.length > 0) {
          const file = event.target.files[0];
          if (file.type.match('image.*')) {
              this.importPhotoFromBlob(file);
          } else {
              throw new Error('Not an image !');
          }
      }
  }
  
  public removeImage() {
      if (this.imageUsed) {
          this.imageUsed.dispose();
          this.imageUsed = undefined;
      }
      this.canvas.backgroundImage = undefined;
  
      if (this.width && this.height) {
          this.canvas.setWidth(this.width);
          this.canvas.setHeight(this.height);
      }
  
      this.canvas.renderAll();
  }
  
  public get hasImage(): boolean {
      return !!this.canvas.backgroundImage;
  }
  
  private importPhotoFromSrc(src: string) {
      this.isLoading = true;
      let isFirstTry = true;
      const imgEl = new Image();
      imgEl.setAttribute('crossOrigin', 'anonymous');
      imgEl.src = src;
      imgEl.onerror = () => {
          // Retry with cors proxy
          if (isFirstTry) {
              imgEl.src = 'https://cors-anywhere.herokuapp.com/' + this.src;
              isFirstTry = false;
          } else {
              this.isLoading = false;
              this.hasError = true;
              this.errorMessage = this.getTextTranslated('loadError').replace('%@', this.src as string);
          }
      };
      imgEl.onload = () => {
          this.isLoading = false;
          this.imageUsed = new fabric.Image(imgEl);
  
          this.imageUsed.cloneAsImage((image: any) => {
            
            const container=this.canvasContainerEditor.nativeElement;
              let width = imgEl.width;//this.canvasContainerEditor.nativeElement.clientWidth; //imgEl.width;
              let height = imgEl.height;// this.canvasContainerEditor.nativeElement.clientHeight; //imgEl.height;

              if (width > container.clientWidth) {
                width = container.clientWidth;
                const perc = (width - imgEl.width)/imgEl.width;
                height = height + height*perc;
              }
              if (height > container.clientHeight) {
                height = container.clientHeight;
                const perc = (height - imgEl.height)/imgEl.height;
                width = width + width*perc;
              }
            

            console.log(`mediaService - image draw width:${width}, height: ${height} - container w:${this.canvasContainerEditor.nativeElement.clientWidth}, h:${this.canvasContainerEditor.nativeElement.clientHeight}`)

              if (this.width) {
                  width = this.width;
              }
              if (this.height) {
                  height = this.height;
              }
  
              image.scaleToWidth(width, false);
              image.scaleToHeight(height, false);
  
              this.canvas.setBackgroundImage(image, ((img: HTMLImageElement) => {
                  if (img) {
                      if (this.forceSizeCanvas) {
                          this.canvas.setWidth(width);
                          this.canvas.setHeight(height);
                      } else {
                          this.canvas.setWidth(image.getScaledWidth());
                          this.canvas.setHeight(image.getScaledHeight());
                      }
                  }
              }), {
                  crossOrigin: 'anonymous',
                  originX: 'left',
                  originY: 'top'
              });
          });
      };
  }
  
  private importPhotoFromBlob(file: Blob | File) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (evtReader: any) => {
          if (evtReader.target.readyState == FileReader.DONE) {
              this.importPhotoFromSrc(evtReader.target.result);
          }
      };
  }
  
  public importPhotoFromUrl() {
      const url = prompt(this.getTooltipTranslated('loadImageUrl'));
      if (url) {
          this.importPhotoFromSrc(url);
      }
  }
  
  ngOnChanges(changes: SimpleChanges): void {
     const changes_src = changes['src'];
      if (changes_src && !changes_src.firstChange && changes_src.currentValue) {
          if (typeof changes_src.currentValue === 'string') {
              this.importPhotoFromSrc(changes_src.currentValue);
          } else if (changes_src.currentValue instanceof Blob) {
              this.importPhotoFromBlob(changes_src.currentValue);
          }
      }
  }

}