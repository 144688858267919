import { IResult } from "ua-parser-js";
import { DeviceControl } from "../models/deviceControl";
import * as UAParser from "ua-parser-js";
import { FELoggerService, LogSource } from "../services/feLogger.service";
import { I18Service } from "../services/i18.service";

var DetectRTC = require('detectrtc');

export class BrowserHelper {
  // public resultBrowserComp: any;
  private static techErrors: string[] = [];
  private static isCompatible: boolean = true;
  private static parsedUA: IResult;

  constructor(resultBrowserComp: any, techErrors: string[]) {
    // this.resultBrowserComp = resultBrowserComp;
    // this.techErrors = techErrors;
    // if (this.resultBrowserComp && !this.resultBrowserComp.success) {
    //   this.isCompatible = false;
    // } else {
    //   if (this.techErrors.length > 0) {
    //     this.isCompatible = false;
    //   }
    // }
  }

  static check(): boolean {
    this.isCompatible = this.techErrors.length === 0;// && (this.resultBrowserComp && this.resultBrowserComp.success);
    return this.isCompatible;
  }


  static getBrowserCompatibility(deviceControls: DeviceControl[]): {success: boolean, message: string} {
    const parsedUA = this.getParsedUa();
    try {
      let os: string = parsedUA.os?.name ? parsedUA.os?.name.toLowerCase().trim() : '';
      let browser: string = parsedUA.browser?.name ? parsedUA.browser?.name.toLowerCase().trim() : '';
      let osVersion: string = parsedUA.os?.version ? parsedUA.os?.version : '';
      let osv = this.getMajorMinor(osVersion);
      let browserVersion: string = parsedUA.browser?.version ? parsedUA.browser?.version : '';
      let brv = this.getMajorMinor(browserVersion);
      if (os && browser) {
        let osc = deviceControls.filter(dc => dc.OSs.some(operSys => operSys == os));
        if (osc.length <= 0) return { success: false, message: `%%UNSUPPORTEDOS%%: ${os}` };
        let osbc = osc.filter(dc => dc.browserNames.some(brn => brn == browser));
        if (osbc.length <= 0) return { success: false, message: `%%UNSUPPORTEDBROWFOROS%% ${os} + ${browser}` };
        if (osv.major > -1 && osv.minor > -1) {
          if (osv.major < osbc[0].minMajorOSVersion ||
            (osbc[0].minMajorOSVersion == osv.major && osv.minor < osbc[0].minMinorOSVersion))
            return { success: false, message: `%%UNSUPPORTEDOSVERSION%%: ${os} ${osVersion} < ${osbc[0].minMajorOSVersion}.${osbc[0].minMinorOSVersion}` };
          if (brv.major > -1 && brv.minor > -1) {
            if (brv.major < osbc[0].minMajorBrowserVersion ||
              (osbc[0].minMajorBrowserVersion == brv.major && brv.minor < osbc[0].minMinorBrowserVersion))
              return { success: false, message: `%%UNSUPPORTEDBROWSERVERSION%%: ${browser} ${browserVersion} < ${osbc[0].minMajorBrowserVersion}.${osbc[0].minMinorBrowserVersion}` };
          }
        }
      }
    } catch (error) {
      FELoggerService.error(`Error Checking device ${error}`, LogSource.Default, error);
    }
    return { success: true, message: '' };
  }
  private static getMajorMinor(version: string): any {
    let versionParts = version.split('.');
    let major: number = -1;
    let minor: number = 0;
    if (versionParts && versionParts.length > 0) {
      major = versionParts.length > 0 ? +versionParts[0] : major;
      minor = versionParts.length > 1 ? +versionParts[1] : minor;
    }
    return { major: major, minor: minor };
  }
  static getParsedUa(): IResult {
    if (this.parsedUA) return this.parsedUA;

    const parser = new UAParser();
    this.parsedUA = parser.getResult();
    // let browserVersion: string = this.parsedUA.browser?.version ? this.parsedUA.browser?.version : '';
    // let brv = this.getMajorMinor(browserVersion);
    // this.parsedUA.browser.version = brv;
    
    const parsedUA = this.parsedUA;
    if ((!parsedUA?.device?.model && !parsedUA?.device?.vendor) &&  this.isAppleOS(parsedUA)) {
      parsedUA.device.model = "iPhone";
      parsedUA.device.vendor = "Apple";
    }
    if (parsedUA.ua && parsedUA.browser && parsedUA.ua.toLowerCase().indexOf('huaweibrowser') > -1) {
      parsedUA.browser.name = "HuaweiBrowser";
    }
    console.log(`parsedUA`, parsedUA);
    return parsedUA;
  }

  public static isAppleOS(parsedUA: IResult | null = null): boolean  {
    if (!parsedUA) {
      parsedUA = this.getParsedUa();
    }
    if (parsedUA && parsedUA.os) {
      var name: string = parsedUA.os.name ? parsedUA.os.name : '';
      return name.toLowerCase() == 'ios' || name.toLowerCase() == 'mac os';;
    } else {
      return false;
    }
  }
  public static isSafari(parsedUA: IResult | null = null): boolean | undefined {
    if (!parsedUA) {
      parsedUA = this.getParsedUa();
    }
    if (parsedUA && parsedUA.browser) {
      return parsedUA.browser.name?.toLowerCase() == 'safari' || parsedUA.browser.name?.toLowerCase() == 'mobile safari' 
        || (parsedUA.browser.name?.indexOf('Safari') || 0) > 0;
    } else {
      return undefined;
    }
  }
  public static isMobilePhone(parsedUA: IResult | null | undefined = undefined): boolean {
    let ua = parsedUA || this.getParsedUa();
    let mobileOs = ['android', 'ios'];//, 'mac os'];
    return mobileOs.some(mos => mos == ua.os?.name?.toString().toLowerCase());
  }

  static isFirefox(parsedUA: IResult | null = null): boolean | undefined {
    if (!parsedUA) {
      parsedUA = this.getParsedUa();
    }
    if (parsedUA && parsedUA.browser) {
      return parsedUA.browser.name == 'firefox' || parsedUA.browser.name == 'Firefox' 
        || (parsedUA.browser.name?.indexOf('irefox') || 0) > 0;
    } else {
      return undefined;
    }
  }

  static getModel(parsedUA: IResult | undefined): string {
    // const parsedUA = this.getParsedUa();
    if (!parsedUA) return '';
    if (parsedUA.device && (parsedUA.device.model || parsedUA.device.vendor)) {
      let model = `${parsedUA.device.model ? 'Model: ' + parsedUA.device.model : ''}`;
      let vendor = `${parsedUA.device.vendor ? 'Vendor: ' + parsedUA.device.vendor : ''}`;
      return `${vendor} ${model}`.trim();
    }
    return '';
  }

  static getBrowserInfo(): string {
    const parsedUA = BrowserHelper.getParsedUa();
    const browserInfo = `Browser: '${parsedUA.browser.name}' version: '${parsedUA.browser.version}' and os: '${parsedUA.os.name} (${parsedUA.os.version})'`;
    return browserInfo;
  }

  static checkBrowserTech(): string[] {
    var errors: string[] = [];
    let isApple: boolean = this.isAppleOS();
    if (DetectRTC) {
      if (!DetectRTC.isWebRTCSupported) {
        errors.push(I18Service.get('POPUP.UNSUPPORTEDWEBRTC'));
      }
      if (!DetectRTC.isSctpDataChannelsSupported && !isApple) {
        errors.push(I18Service.get('POPUP.UNSUPPORTEDSTCPDATA'));
      }
      if (!DetectRTC.isRtpDataChannelsSupported && !isApple) {
        errors.push(I18Service.get('POPUP.UNSUPPORTEDRTP'));
      }
    }
    if (!navigator.geolocation) {
      errors.push(I18Service.get('POPUP.UNSUPPORTEDGEOLOC'))
    }

    // return []; //TODO: //dave come mai viene ritornato [] e non l'elenco errori???
    return errors;
  }

  static supportsPlainText()
  {
      var d = document.createElement("div");
      try {
          d.contentEditable="PLAINtext-onLY";
      } catch(e) {
          return false;
      }
      return d.contentEditable=="plaintext-only";
  }
}
