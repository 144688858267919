import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Constants } from "src/app/constants";
import { AppHelper } from "src/app/helpers/app.helper";
import { AppConfigService } from "src/app/services/app.config.service";
import { I18Service } from "src/app/services/i18.service";
import { NotificationService } from "src/app/services/notification.service";



@Component({
  selector: 'app-menu-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],

})
export class MenuCreditsComponent {
  
  AppHelper = AppHelper;
  NotificationService = NotificationService;

  constructor(public config: AppConfigService) {}

  close() {
      NotificationService.popupClose();
  }
}