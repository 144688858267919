<button mat-icon-button [matMenuTriggerFor]="menu" ><span class="material-symbols-outlined">more_vert</span></button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="showSettings()">{{'menu.settings' | translate }}</button>
  <button mat-menu-item *ngIf="showMenuGuest" (click)="toggleShowCitizenInfo()">{{'menu.citizen' | translate}}</button>
  <button mat-menu-item *ngIf="config.isGuest" (click)="toggleShowSessionInfo()">{{'menu.info' | translate}}</button>
  <button mat-menu-item (click)="showCredits()">{{'menu.about' | translate:{title:title} }}</button>
</mat-menu>

<div hidden>
  <div #credits class="basic-font-medium">
    <app-menu-credits *ngIf="shownCredits"></app-menu-credits>
  </div>

  <div #settings class="basic-font-medium">
    <app-menu-settings #settingsComponent *ngIf="shownSettings"></app-menu-settings>
  </div>

</div> <!-- display none -->


