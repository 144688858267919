// Helpful name and version exports
import { LIBRARY_VERSION } from "./version";
const version = LIBRARY_VERSION;
const name = "sip";
export { name, version };

// Export api
export * from "./api/index";

// Export grammar
export * from "./grammar/index";

// Export namespaced core
import * as Core from "./core/index";
export { Core };

// Export namespaced web
import * as Web from "./platform/web/index";
export { Web };
