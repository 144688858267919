import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { FELoggerService, LogSource } from './feLogger.service';
import { PartecipantStatus } from '../models/WebServer/partecipantStatus';
import { PartecipantInfo } from '../models/WebServer/partecipantInfo';
import { AppConfigService } from './app.config.service';
import { Constants } from '../constants';

// @ts-ignore
import * as isoCountiesLanguages from 'iso-countries-languages'
import { LanguageCode } from '../models/languageCode';
import { AppState } from '../store/states/app.state';
import { Store } from '@ngrx/store';
import { changeLanguage } from '../store/actions/app.actions';

@Injectable()
export class I18Service {
    private static navigatorLanguage = (navigator.language || (navigator as any)['userLanguage'] || Constants.DEFAULT_LANGUAGE).split('-')[0];
    private static languageCodes: LanguageCode[] = [];

    static setLanguageCodes(languageCodes: LanguageCode[]) {I18Service.languageCodes = languageCodes.map( z =>{return {...z, ...{code: z.code || z.language}} }); }
    static getLanguageCode(lang: string) { 
        var lc = I18Service.languageCodes.find( l => l.language == lang || l.code == lang);
        if (!lc) lc = {language: lang.split('-')[0], code: lang.split('-')[0], tip:''}
        lc = {...lc};
        const translatedLanguageName=I18Service.languageName(lc.language, I18Service.PREFERRED_LANG) || lc.language;
        const originalLanguageName = lc.endonym || I18Service.languageName(lc.language, lc.language);
        lc.label = translatedLanguageName;
        lc.tip = `${translatedLanguageName} (${originalLanguageName})`;
        lc.code = lc.code || lc.language;
        return lc;
    }

    static getNavigatorLanguage() {return I18Service.navigatorLanguage;}
    static setNavigatorLanguage(lang: string){
        console.log(`*** set navigatorLanguage ${lang}`)
        I18Service.navigatorLanguage = lang;
    }

    private static PREFERRED_LANG = Constants.DEFAULT_LANGUAGE;

    // static LANGUAGE_CHANGE = new BehaviorSubject(I18Service.navigatorLanguage);

    private static translations: any = {};
    constructor(private translation: TranslateService, private store: Store<AppState>) {
        console.debug(`TranslationHelperService constructor`)

        this.translation.onLangChange.subscribe( (e) => {
            console.log(`TranslationHelperService onLangChange ${e.lang} translations: ${Object.keys(e.translations).length}`)
            I18Service.translations = e.translations;
        })
        
    }
    saveLanguage(lang: string): Promise<string>{
        
        localStorage.setItem(`LanguagePref.${AppConfigService.role}`, lang);
        return this.setLanguage(lang);
    }
    static get language(){ return I18Service.PREFERRED_LANG;}
    getLanguage() { return this.translation.currentLang; }
    setLanguage(lang: string): Promise<string> {

        if (lang == this.translation.defaultLang){ 
            return new Promise( (res, rej) =>{res(lang)});
        }
        const langPref = localStorage.getItem(`LanguagePref.${AppConfigService.role}`);
        if (langPref) {
            lang = langPref;
            console.log(`Default Translation, using saved language ${lang}`);
            I18Service.setNavigatorLanguage(lang);
        }
        if (!Constants.ALLOWED_LANGUAGES.includes(lang)) {
            console.warn(`Language ${lang} not allowed using preferred ${I18Service.PREFERRED_LANG}`);
            lang = I18Service.PREFERRED_LANG;
            
        }


        this.translation.setDefaultLang(lang);
        
        console.log(`LANGUAGE_CHANGE Setting default lang '${I18Service.PREFERRED_LANG}'`)
        return new Promise( (res, rej) =>{
            try {
                this.translation.use(lang).subscribe({
                    next: z => {
                        console.log(`Translation loaded for lang '${lang}'`, z)
                        I18Service.PREFERRED_LANG = lang;
                        // I18Service.LANGUAGE_CHANGE.next(lang);
                        this.store.dispatch(changeLanguage({lang}));
                        res(lang);
                    },
                    error: (err) => {
                        FELoggerService.error(`Errors loading tranlation '${lang}'`, LogSource.Default, err)
                        rej(lang);
                    }
                })
            }
            catch(e:any){ console.error(e); rej(lang);}
        });
    }
    
    static languageName(lang: string, translateTo: string) {
        var lname='';
        try {lname = isoCountiesLanguages.getLanguage(translateTo, lang);}
        catch(e){
            console.error(e);
        }
        return lname
    }

    static availableTranslations() { //ordinare in base al nome nella propria lingua
        // const availableTranslation =  [...this.languageCodes.filter( z => AppConfigService.translationLanguages.includes(z.language))];
        // availableTranslation.forEach( l => l.tip = `${I18Service.languageName(l.language, I18Service.PREFERRED_LANG)} (${I18Service.languageName(l.language, l.language)})`)
    
        const availableTranslation:LanguageCode[] = AppConfigService.translationLanguages.map( t => I18Service.getLanguageCode(t));

        return availableTranslation.sort((a,b) => a.tip!.localeCompare(b.tip!))
    }


    static get(key: string, replaces: any = {}): string {
        if (I18Service.translations[key]) {
          let tr = I18Service.translations[key];

          Object.keys(replaces).forEach(  r => {
            tr = tr.replace('{{'+r+'}}', replaces[r]);
          })
          return tr;
        }
        FELoggerService.warn(`translation getting ${key} on translations: ${Object.keys(I18Service.translations).length}`)
        return key;
    }


    static roleAndAlias(partecipant: PartecipantStatus | PartecipantInfo | undefined): string {
        // var alias = ''; 
        // switch (role) {
        //     case PartecipantRoles.PrimaryClient:   alias = I18Service.get('Operator'); break;
        //     case PartecipantRoles.SecondaryClient: alias = I18Service.get('Caller'); break;
        //     case PartecipantRoles.GuestClient:     alias = I18Service.get('Guest'); break;
        // }
        // return alias;

        if (!partecipant) return 'undefined';
        var who = `${I18Service.get(partecipant.partecipantRole)}${(partecipant.alias) ?  ': '+ partecipant.alias : ''}`;
        if(!partecipant.active) {
            who += ` (${I18Service.get('chat.removedPartecipant')})`;
        }
        return who;


        // let who = '';
        // if (partecipant.partecipantRole == PartecipantRoles.PrimaryClient) {
        //   who = 'Operator';
        // }
        // if (partecipant.partecipantRole == PartecipantRoles.SecondaryClient) {
        //   who = 'Caller';
        // }
        // if (partecipant.partecipantRole == PartecipantRoles.GuestClient) {
        //   who = 'Guest';
        // }
        // who = !!!I18Service.get(who);
        // if (partecipant.alias) who += ` - ${partecipant.alias}`
        // return who;

    }

    // async GetPopUpValues(translationKeys: string[]): Promise<Map<string, string>> {
    //     const translatedValue = new Map<string, string>();
    //     translationKeys.forEach(async tk => {
    //         await this.translation.get(tk).subscribe((res: string) => translatedValue.set(tk, res));
    //     });
    //     return translatedValue;
    // }

    // async TranslateSingleValue(value: string): Promise<string> {
    //     let translated: string;
    //     await this.translation.get(value).subscribe((res: string) => translated = res);
    //     return translated;
    // }
}
