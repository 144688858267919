import { Pipe, PipeTransform } from "@angular/core";
import { PartecipantStatus } from "../models/WebServer/partecipantStatus";
import { PhoneStatusEnum } from "../models/phoneStatus";
import { AppState } from "../store/states/app.state";

@Pipe({
  name: 'isOnCall',
})
export class IsOnCallPipe implements PipeTransform {
  //constructor(protected sanitizer: DomSanitizer, private config: AppConfigService) { }
  public transform(phoneStatus: PhoneStatusEnum | undefined, early = false): boolean {
    // console.log(`********** IsOnCallPipe ${phoneStatus} => early ${early}`)
    if (!early)
    return phoneStatus == PhoneStatusEnum.TALKING; 

    if(!phoneStatus) return false;
    return [PhoneStatusEnum.CALLING, PhoneStatusEnum.RINGING, PhoneStatusEnum.REMOTE_RINGING, PhoneStatusEnum.TALKING].includes(phoneStatus);
  }
}
