<div id="media-button-container" class="app-background" (click)="openDebug()">
  <div class="single-button" >
    <div class="button-space" [matTooltip]="AS.appModes.includes(appModes.Chat) ? ('LABELS.DISABLECHAT' | translate) : ('LABELS.ENABLECHAT' | translate)">
      <button class="no-border-no-background-button"
        [matBadge]="AS.unreadMessage" [matBadgeHidden]="!AS.unreadMessage" matBadgePosition="above after" matBadgeColor="warn"
        aria-label="Chat" (click)="changeMode(appModes.Chat)"
        [disabled]="AS.commandsDisabled || !AS.session.config.chat">
          <span class="font-icon-button material-symbols-outlined">chat</span>
      </button>
    </div>
    <div [ngClass]="AS.appModes.includes(appModes.Chat) ? 'underline-space-white': 'underline-space-blue' "></div>
  </div>
  <div class="single-button">
    <div class="button-space" matTooltipClass="tooltip-class" [matTooltip]="AS.appModes.includes(appModes.Photo) ? ('LABELS.DISABLEPHOTO' | translate) : ('LABELS.ENABLEPHOTO' | translate)">
      <button class="no-border-no-background-button" aria-label="Photo" (click)="changeMode(appModes.Photo)"
        [matBadge]="AS.unseenImages" [matBadgeHidden]="!AS.unseenImages || config.isCaller" matBadgePosition="above after" matBadgeColor="warn"
        [disabled]="AS.commandsDisabled  || !AS.switchCameraOn || !AS.session.config.customerAttachments">
          
        <span *ngIf="config.isReceiver || config.isGuest" class="font-icon-button material-symbols-outlined">collections</span>
        <span *ngIf="config.isCaller" class="font-icon-button material-symbols-outlined">photo_camera</span>
      </button>
    </div>
    <div [ngClass]="AS.appModes.includes(appModes.Photo) ? 'underline-space-white': 'underline-space-blue' "></div>
  </div>
  
  <div class="single-button">
    <div class="button-space" [matTooltip]="(AS.appModes.includes(appModes.Video) ? ( config.isReceiver ? 'LABELS.DISABLE_VIDEO_SECTION': 'LABELS.DISABLEVIDEO' ) : (config.isReceiver ? 'LABELS.ENABLE_VIDEO_SECTION':'LABELS.ENABLEVIDEO')) | translate">
      <button class="no-border-no-background-button"  aria-label="Video" color="primary" (click)="changeMode(appModes.Video)"
        [disabled]="!(config.partecipantsStatus$ | targetPresence) || AS.phoneStatus == phoneStatuses.RINGING || !AS.isRegistered
          || AS.phoneStatus == phoneStatuses.REMOTE_RINGING || AS.commandsDisabled || !AS.switchCameraOn || !AS.session.config.video">
          <span class="font-icon-button material-symbols-outlined" [style.color]="AS.videoEnabled && !config.isReceiver ? 'crimson!important': ''">{{ config.isReceiver ? 'contact_emergency' : 'videocam'}}</span>
      </button>
    </div>
    <div [ngClass]="AS.appModes.includes(appModes.Video) ? 'underline-space-white': 'underline-space-blue' "></div>
  </div>

  <div class="single-button" *ngIf="!config.isReceiver" [ngStyle]="config.isGuest ? {'margin-right': '60px'}:{}">
    <div class="button-space" [matTooltip]="(AS.microphoneEnabled ? 'LABELS.DISABLEMIC' : 'LABELS.ENABLEMIC') | translate">
      <button class="no-border-no-background-button" aria-label="Phone" (click)="toggleMic()"
        [disabled]="AS.commandsDisabled || AS.phoneStatus == phoneStatuses.RINGING || AS.phoneStatus == phoneStatuses.REMOTE_RINGING || !AS.isRegistered
          || !(config.partecipantsStatus$ | targetPresence) || AS.availableDevices.audioInput.length == 0 || !AS.session.config.audio">
          <span *ngIf="AS.microphoneEnabled" class="font-icon-button material-symbols-outlined" [style.color]="AS.microphoneEnabled ? 'crimson!important': ''">mic</span>
          <span *ngIf="!AS.microphoneEnabled" class="font-icon-button material-symbols-outlined">mic_off</span>
      </button>
    </div>
  </div>

  <div class="single-button" *ngIf="config.isCaller">
    <div class="button-space" [matTooltip]="'LABELS.BACKMAP' | translate" style="margin-right: 30px;">
      <button class="no-border-no-background-button" aria-label="Photo" (click)="exit()"
        [disabled]="AS.commandsDisabled || !AS.session.config.localization">
        <span class="font-icon-button material-symbols-outlined">home</span>
      </button>
    </div>
  </div>

  <div *ngIf="config.isGuest && config.actualWidth < config.minGuestWidth" class="menu-panel-button">
    <app-menu (showSessionInfo)="showGuestSessionInfo($event)" (showCitizenInfo)="showGuestCitizenInfo($event)" [showMenuGuest]="true"></app-menu>
  </div>


  <div class="single-button media-buttons" *ngIf="config.isReceiver" >
    <div class="button-space" [matTooltip]="(AS.videoEnabled ? 'LABELS.DISABLEVIDEO'  : 'LABELS.ENABLEVIDEO') | translate">
      <button class="no-border-no-background-button"  aria-label="Video" color="primary" (click)="toggleLocalStream()"
        [disabled]="!(config.partecipantsStatus$ | targetPresence) || !config.iamOnCall">
          <span class="font-icon-button material-symbols-outlined" [style.color]="AS.videoEnabled ? 'crimson!important': ''">videocam</span>
      </button>
    </div>
    <div class="button-space" [matTooltip]="AS.microphoneEnabled ? ('LABELS.DISABLEMIC' | translate) : ('LABELS.ENABLEMIC' | translate)">
      <button class="no-border-no-background-button" aria-label="Phone" (click)="toggleMic()"
        [disabled]="!(config.partecipantsStatus$ | targetPresence) || !config.iamOnCall">
          <span *ngIf="AS.microphoneEnabled" class="font-icon-button material-symbols-outlined" [style.color]="AS.microphoneEnabled ? 'crimson!important': ''">mic</span>
          <span *ngIf="!AS.microphoneEnabled" class="font-icon-button material-symbols-outlined">mic_off</span>
      </button>
    </div>
  </div>



</div>
