import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AppHelper } from 'src/app/helpers/app.helper';
import { PhoneStatusEnum } from 'src/app/models/phoneStatus';
import { AppConfigService } from 'src/app/services/app.config.service';
import { AppState } from 'src/app/store/states/app.state';
import { PhoneComponent } from './phone.component';


@Component({
  selector: 'app-phone-title',
  templateUrl: './phone-title.component.html',
  styleUrls: ['./phone-title.component.scss']
})
export class PhoneTitleComponent implements OnInit, OnDestroy {
  @Input() AS!: AppState;
  @Input() title!: string;
  @Input() agency!: string;

  logoPath?: string
  // @Input() selectorPlaceHolder: Map<string, string>;
  // @ViewChild('phone') phone: PhoneComponent;
  // @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
  //   if (this.phone) {
  //     console.debug("Close Call throug Before Unload");
  //     this.phone.onCancelClick(true);
  //   }
  // }

  // phoneCancelSubscription: Subscription;
  // dialogStatusChangedSubscription: Subscription;
  navigator = navigator;
  release: string;

  clickLogCounter = 0;
  phoneStatuses = PhoneStatusEnum;

  constructor(
    public dialog: MatDialog,
    public config: AppConfigService,
    // private sipService: GenericSipServer,
    // private signalr: SignalrService,
    // private eventService: EventService,
  ) {
    this.release = AppHelper.getRelease();
  }

  ngOnDestroy(): void {



    // if (this.phoneCancelSubscription) {
    //   this.phoneCancelSubscription.unsubscribe();
    // }
    // if (this.dialogStatusChangedSubscription) {
    //   this.dialogStatusChangedSubscription.unsubscribe();
    // }
  }

  ngOnInit(): void {
    if(!this.logoPath)
    [this.agency, '_default'].forEach( x => {
      const logo = `/assets/images/logo/${x}/logo.png`;
      if(AppHelper.fileExists(logo)) {
        this.logoPath = logo;
        return
      }
    });
    // this.phoneCancelSubscription = this.AS.phoneCancelEvent.subscribe((justCancelCall: boolean = false) => {
    //   if (this.phone) {
    //     if (!justCancelCall) {
    //       // !this.AS.errorInfo && this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.NotifyAbsence);
    //       !this.AS.errorInfo && this.signalr.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.NotifyAbsence);
    //       this.eventService.saveBusinessEvent(AppHelper.getDynamicCreateData(this.config, BusinessEventTypes.LeaveOrReloadPage))
    //       Logger.debug("Close Call throug PhoneCancelEvent Unload");
    //     }
    //     this.phone.onCancelClick(true);
    //   }
    // });
  }

  async countShowLog() { //TODO log su div per mobile
    this.clickLogCounter++;
    // if (this.clickLogCounter % 7 === 0 && ConfigService.settings.UseNestedEmergencyLog) {
    //   this.AS.sectretLogDisplay = true;
    // } else {
    //   this.AS.sectretLogDisplay = false;
    // }
  }

  // disableInCall(): boolean {
  //   return this.AS.phoneStatus === PhoneStatusEnum.TALKING || this.AS.phoneStatus === PhoneStatusEnum.NONE;
  // }

  openDeviceSettings() { //TODO ? serve ancora 
    // const dialogRef = this.dialog.open(DeviceSettingsComponent, {
    //   width: '50%',
    //   data: {}
    // });
  }
}
