<div *ngIf="! AS.errorInfo" id="dashboard-container">
  <div id="main-content-container">
    <!-- BUTTONS -->
    <app-multimedia-buttons #multimediabuttons [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS" [MYSTATUS]="MYSTATUS"
      (changeModeEvent)="changeMode($event)"></app-multimedia-buttons>

    <app-multimedia-manager-receiver #multimediamanager id="multimedia-manager-container" [AS]="AS"
      [SECONDARYSTATUS]="SECONDARYSTATUS" [MYSTATUS]="MYSTATUS">
    </app-multimedia-manager-receiver>
  </div>
  <div id="remote-section-container" class="basic-font-medium" [ngClass]="showRightPanel ? 'showRightPanel' : 'hideRightPanel'">
    <div #panelShowHide class="panel-show-hide">
      <span (click)="showRightPanel=!showRightPanel" class="font-icon-button material-symbols-outlined">{{showRightPanel ? 'right_panel_close' :'right_panel_open'}}</span>
    </div>
    <div class="remote-section-container basic-font-medium hide-content">
      <!-- INFO -->
      <div id="card-info" class="card-info">
        <mat-label class="card-info-title" (click)="printStuff()">
          <div style="flex:1">{{'LABELS.CALLERINFO' | translate}}</div>
          <div class="caller-activity-info-container" *ngIf="AS.sessionExpired">
            <span class="caller-activity-info">[{{'LABELS.SESSIONEXPIRED' | translate}}]</span>
          </div>

          <div class="caller-activity-info-container"
              *ngIf="!AS.sessionExpired && expirationMillisec < MAX_NOTIFY_EXPIRATION">
            <span class="caller-activity-info">{{'Labels.ExpiresIn' | translate }} {{expirationMillisec | remainingTime}}</span>
          </div>

          <div class="caller-activity-info-container" *ngIf="!AS.sessionExpired && AS.sessionExpired != undefined">
            <app-status-circle 
              [color]="( MYSTATUS | statusColor).color"
              [tooltip]="( MYSTATUS | statusColor).tooltip"

              [size]="StatusCircleSize.medium"
            ></app-status-circle>
          </div>

          <div class="session-panel-button">
            <app-menu (showSessionInfo)="toggleSessionInfo($event)"></app-menu>
          </div>
        </mat-label>

        <mat-tab-group animationDuration="0ms">
          <mat-tab  class="basic-font-medium" [label]="'SecondaryClient' | translate">
            <div id="remote-info" class="card-info-content">
              <app-remote-info-content [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS"></app-remote-info-content>
            </div> 
          </mat-tab>
          <mat-tab  class="basic-font-medium" [label]="'Session' | translate">
            <div id="session-info" class="card-info-content">
              <app-session-info [AS]="AS" [expiresIn]="expirationMillisec"  [PARTECIPANTS]="(config.partecipantsStatus$)" [inTab]="true" [showSessionInfo]="true" (sessionInfoClose)="toggleSessionInfo(false)"></app-session-info>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div class="generic-tooltip hide-when-panel-show" [hidden]="!showUserInfo  && !showSessionInfo">
        <app-remote-info [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS" [showUserInfo]="showUserInfo" (userInfoClose)="toggleUserInfo(false)"></app-remote-info>
        <app-session-info [AS]="AS" [expiresIn]="expirationMillisec"  [PARTECIPANTS]="(config.partecipantsStatus$)"  [showSessionInfo]="showSessionInfo" (sessionInfoClose)="toggleSessionInfo(false)"></app-session-info>
      </div>

      <div *ngIf="!SECONDARYSTATUS?.isRegistered && false" id="function-spinner">
        <mat-spinner [diameter]="50"></mat-spinner>
        {{'LABELS.PROCESSING' | translate }}
      </div>
      <!-- REMOTE COMMAND -->
      <div id="card-user-state"  class="card-info basic-font-medium" [ngStyle]="{'opacity': !SECONDARYSTATUS?.isRegistered ? '0.5' : '1'}">
        <mat-label class="card-info-title">{{'LABELS.CALLERCOMMAND' | translate}}</mat-label>
        <div id="user-state-panel" class="card-info-content-buttons"
          [ngClass]="{'user-state-panel-big': !mapVisible, 'user-state-panel-small': mapVisible }">
          <div id="buttons-panel">
            <div class="user-state-row-button">
              <div [matTooltip]="'LABELS.SUM' | translate" matTooltipClass="tooltip-class" class="user-state-button">
                <button mat-raised-button class="max-size-button" id="user-home-button"
                  [disabled]="!SECONDARYSTATUS?.isRegistered || !SECONDARYSTATUS?.isRegistered || mirrorUser || AS.sessionExpired || !AS.session.config.localization"
                  (click)="changeUserMode(appModes.Map)"
                  [ngClass]="!SECONDARYSTATUS?.appInMultimedia ? 'state-selected' : 'state-unselected'">

                  <span class="font-icon-button material-symbols-outlined">home</span>
                  <div class="showing-disclaimer" *ngIf="SECONDARYSTATUS?.disclaimerChoiceRevealed" [matTooltip]="'LABELS.SHOWINGDISCLAIMER' | translate" matTooltipClass="tooltip-class">
                    <span class="font-icon-button material-symbols-outlined">assignment</span></div>
                </button>
              </div>
              <div [matTooltip]="'LABELS.SUC' | translate" matTooltipClass="tooltip-class" class="user-state-button">
                <button mat-raised-button class="max-size-button" id="user-chat-button"
                  [disabled]="!(config.partecipantsStatus$ | targetPresence) || !SECONDARYSTATUS?.isRegistered || !SECONDARYSTATUS?.disclaimerAccepted || AS.sessionExpired || !AS.session.config.chat"
                  (click)="changeUserMode(appModes.Chat)"
                  [ngClass]=" !SECONDARYSTATUS?.appModes?.includes(appModes.Chat) ? 'state-unselected' : 'state-selected'">
                  <span class="font-icon-button material-symbols-outlined">chat</span>
                </button>
              </div>
            </div>
            <div class="user-state-row-button">
              <div matTooltipClass="tooltip-class" [matTooltip]="'LABELS.SUP' | translate" class="user-state-button">
                <button mat-raised-button class="max-size-button" id="user-photo-button"
                  [disabled]="!SECONDARYSTATUS?.isRegistered|| !SECONDARYSTATUS?.isRegistered || !SECONDARYSTATUS?.disclaimerAccepted || AS.sessionExpired  || !AS.session.config.customerAttachments"
                  (click)="changeUserMode(appModes.Photo)"
                  [ngClass]="!SECONDARYSTATUS?.appModes?.includes(appModes.Photo) ? 'state-unselected' : 'state-selected'">
                  <span class="font-icon-button material-symbols-outlined">photo_camera</span>
                </button>
              </div>
              <div matTooltipClass="tooltip-class" [matTooltip]="'LABELS.SUV' | translate" class="user-state-button">
                <button mat-raised-button class="max-size-button" id="user-camera-button"
                  [disabled]="!SECONDARYSTATUS?.isRegistered || ! AS.isRegistered || !SECONDARYSTATUS?.disclaimerAccepted || AS.sessionExpired || !AS.session.config.video"
                  (click)="changeUserMode(appModes.Video)"
                  [ngClass]="SECONDARYSTATUS?.appModes?.includes(appModes.Video) ? 'state-selected' : 'state-unselected'">
                  <span class="font-icon-button material-symbols-outlined">videocam</span>
                </button>
              </div>
            </div>
            <div class="user-state-row-button">
              <div matTooltipClass="tooltip-class" [matTooltip]="'LABELS.FORCERELOAD' | translate"
                class="user-state-button">
                <button mat-raised-button class="max-size-button state-unselected" id="user-reload-button"
                  [disabled]="!SECONDARYSTATUS?.isRegistered || AS.sessionExpired" (click)="forceRefresh()">
                  <span class="font-icon-button material-symbols-outlined">refresh</span>
                </button>
              </div>
              <div class="user-state-button"
                [matTooltip]="SECONDARYSTATUS | permissionStatusTooltip"
                matTooltipClass="multiline-tooltip">
                <button mat-raised-button class="max-size-button state-unselected" id="user-permission-button"
                  [disabled]="!SECONDARYSTATUS?.isRegistered || !SECONDARYSTATUS?.disclaimerAccepted || (SECONDARYSTATUS?.geoLocPermissionGiven == 1 && SECONDARYSTATUS?.mediaPermissionGiven == 1 && SECONDARYSTATUS?.microphonePermissionGiven == 1) || AS.sessionExpired"
                  [ngClass]="{
                    'state-selected-danger': SECONDARYSTATUS?.isRegistered && (SECONDARYSTATUS?.geoLocPermissionGiven == 2 || SECONDARYSTATUS?.mediaPermissionGiven == 2 || SECONDARYSTATUS?.microphonePermissionGiven == 2)
                  }" (click)="askPermission()">
                  <span class="font-icon-button material-symbols-outlined">privacy_tip</span>
                </button>
              </div>
            </div>
            <div class="user-state-row-button">
              <div matTooltipClass="tooltip-class" class="user-state-button" id="user-microphone-button"
                [matTooltip]="SECONDARYSTATUS?.microphoneEnabled ? ('LABELS.DMU' | translate) : ('LABELS.EMU' | translate)">
                <button mat-raised-button class="max-size-button"
                  [disabled]="!SECONDARYSTATUS?.isRegistered || ! AS.isRegistered || !SECONDARYSTATUS?.disclaimerAccepted || AS.sessionExpired || !AS.session.config.audio"
                  (click)="toggleRemoteMicrophone()"
                  [ngClass]="SECONDARYSTATUS?.microphoneEnabled ? 'state-selected' : 'state-unselected'">
                  <span class="font-icon-button material-symbols-outlined">{{SECONDARYSTATUS?.microphoneEnabled ? 'mic' : 'mic_off'}}</span>
                </button>
              </div>
              <div matTooltipClass="tooltip-class" class="user-state-button" id="user-lock-button"
                [matTooltip]="(!!SECONDARYSTATUS?.commandsDisabled ? 'LABELS.UUC': 'LABELS.LUC') | translate">
                <button mat-raised-button class="max-size-button"
                  [disabled]="!SECONDARYSTATUS?.isRegistered || !SECONDARYSTATUS?.disclaimerAccepted || AS.sessionExpired" (click)="toggleRemoteCommand()"
                  [ngClass]="!!SECONDARYSTATUS?.commandsDisabled ? 'state-selected' : 'state-unselected'">
                    <span class="font-icon-button material-symbols-outlined">{{!!SECONDARYSTATUS?.commandsDisabled ? 'lock' : 'lock_open'}}</span>
                </button>
              </div>
            </div>
            <div class="user-state-row checkbox-info">
              <mat-checkbox [disabled]="!SECONDARYSTATUS?.isRegistered || AS.sessionExpired" [(ngModel)]="mirrorUser"
                (click)="onMirrorChange()">{{ 'LABELS.MIRRORUSER' | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <!-- MAP -->
      <div id="map-container" [hidden]="!mapVisible">
        <app-position-manager id="receiver-position" #position *ngIf="!AS.errorInfo" [AS]="AS"
          [MYSTATUS]="SECONDARYSTATUS">
        </app-position-manager>
      </div>
      <app-operator-actions [AS]="AS" [MYSTATUS]="MYSTATUS" [SECONDARYSTATUS]="SECONDARYSTATUS"></app-operator-actions>
    </div>
  </div>

</div>

<div style="display: none;" > 
  <div #callerinfo> 
    <div class="swal-without-margin guest-caller-info basic-font-medium" style="height: 50vh; overflow: hidden;">
      <app-remote-info  [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS" [showUserInfo]="true" (userInfoClose)="toggleUserInfo(false)"></app-remote-info>
    </div>
  </div>
</div>
<div style="display: none;" > 
  <div #sessioninfo> 
    <div class="swal-without-margin guest-caller-info basic-font-medium" style=" overflow: hidden;">
      <app-session-info  [AS]="AS" [expiresIn]="expirationMillisec"  [PARTECIPANTS]="(config.partecipantsStatus$)" [showSessionInfo]="true" (sessionInfoClose)="toggleSessionInfo(false)"></app-session-info>
    </div>
  </div>
</div>

<!-- ERROR PAGE -->
<!-- <app-business-error id="error-page" *ngIf="AS.errorInfo" [errorInfo]="AS.errorInfo"></app-business-error> //TODO forse inutile, basta quello generale -->