import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { I18Service } from '../services/i18.service';
import { Message } from '../models/WebServer/message';

@Pipe({
  name: 'textMessageIcon'
})
export class TextMessageIconPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(message: Message): SafeUrl {
    // let image = '/assets/images/document.png';

    // if (!message.fileName || !message.type) { image = '/assets/images/document.png'; }
    // else {
    //   var fileParts: string[] = message.fileName.split('.');
    //   if (fileParts.length <= 1) { image = '/assets/images/document.png'; }
    //   else {
    //     const extension = fileParts[fileParts.length - 1];

    //     if (['docx', 'doc'].includes(extension)) { image = '/assets/images/docx.png'; }
    //     else if (['pdf'].includes(extension)) { image = '/assets/images/pdf.png'; }
    //   }
    // }

    let image = '/assets/images/files/unknown.svg';


    if (message.fileName && message.type) {
      var fileParts: string[] = message.fileName.split('.');
      if (fileParts.length >= 2) {
        const extension = fileParts[fileParts.length - 1];
        if (['docx', 'doc', 'rtf', 'txt'].includes(extension)) { image = '/assets/images/files/word.svg'; }
        if (['xlsx', 'xls', 'csv'].includes(extension)) { image = '/assets/images/files/excel.svg'; }
        if (['pdf'].includes(extension)) { image = '/assets/images/files/pdf.svg'; }
        if (['zip', 'rar', '7z','gz'].includes(extension)) { image = '/assets/images/files/zip.svg'; }
        if (['png', 'jpg', 'jpeg', 'tiff', 'bmp', 'gif', 'svg'].includes(extension)) { image = '/assets/images/files/img.svg'; }
        if (['mp3', 'wav', 'ogg'].includes(extension)) { image = '/assets/images/files/voice.svg'; }
        if (['avi', 'mp4', 'mkv'].includes(extension)) { image = '/assets/images/files/video.svg'; }
      }
    }



    return this.sanitizer.bypassSecurityTrustUrl(image);
  }
}
