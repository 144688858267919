import Swal from "sweetalert2";
import { IResult } from "ua-parser-js";
import { AppHelper } from "./app.helper";
import { BrowserHelper } from "./browser.helper";
import { I18Service } from "../services/i18.service";
import { NotificationService } from "../services/notification.service";

export class ManHelper {

    static async getGeoMessageAccordingToBrowser(languageSet: string): Promise<void> {
        return new Promise<void>(async (resolve)=>{
            const cw: string = `style="max-width: 600px; width:100%; height: auto"`;
            const nl: string = '</br>';
            let browser: string = '';
            let progressSteps = ['1', '2', '3', '4', '5'];
            const imagesIndex = ['1', '2', '3'];
            let queueMessages = [];

            const parsedUA = BrowserHelper.getParsedUa();

            if (parsedUA && parsedUA.browser.name) {
                const isSafari = BrowserHelper.isSafari(parsedUA);// || true; //dave occcio, mettere true solo per test
                let message: string;

                let gpsCheck, image;

                if (!isSafari) {
                    gpsCheck = I18Service.get('POPUP.GPSCHECK').toString();
                    image = `/assets/images/man/gpsCheck.png`;
                } else {
                    gpsCheck = I18Service.get('POPUP.GPSCHECKSFR').toString();
                    image = `/assets/images/man/gpsCheckSfr.png`;
                }

                if (languageSet != 'it' && AppHelper.fileExists(image.replace('.png', `.${languageSet}.png`))) {
                    image = image.replace('.png', `.${languageSet}.png`);
                }

                message = `<div>${I18Service.get('POPUP.PERMISSIONRESTOREBODY')}</div>` +
                    `<div>${gpsCheck}</div>` +
                    `<div><img src='${image}' ${cw}></div>`;

                queueMessages.push({ title: '', html: message });
                let isNew = parsedUA.browser.major && !Number.isNaN(Number(parsedUA.browser.major)) && Number(parsedUA.browser.major) > 12 ? true : false;
                switch (parsedUA.browser.name.toLowerCase()) {
                    case 'chrome':
                        browser = 'ggl';
                        break;
                    case 'edge':
                        browser = 'edg';
                        break;
                    case 'firefox':
                        browser = 'ffx';
                        break;
                    case 'safari':
                    case 'mobile safari':
                        browser = isNew ? 'sfr' : 'sfrOld';
                        if (!isNew) {
                            progressSteps.push(`${progressSteps.length + 1}`);
                            imagesIndex.push(`${imagesIndex.length + 1}`);
                        }
                        break;
                    default:
                        progressSteps = ['1'];
                        browser = '';
                        queueMessages.push({ title: '', html: `${message}<div>${I18Service.get('POPUP.PERMISSIONRESTOREOPTIONAL3')}</div>` });
                        break;
                }
                // browser='sfr'; isNew = false;///dave occcio: solo per test
                if (browser) {
                    let text: string;
                    text = isSafari && !isNew ? `<div>${I18Service.get('POPUP.PERMISSIONRESTOREOPTIONAL1SFR', {title:AppHelper.getTitleName()})}</div>` :
                        `<div>${I18Service.get('POPUP.PERMISSIONRESTOREOPTIONAL1')}</div>`;
                    imagesIndex.forEach(ii => {
                        text = ii == '1' ? text : '';
                        image = `/assets/images/man/${browser}${ii}.png`;
                        if (languageSet != 'it' && AppHelper.fileExists(image.replace('.png', `.${languageSet}.png`))) {
                            image = image.replace('.png', `.${languageSet}.png`);
                        }

                        queueMessages.push({ title: '', html: `${text}<div><img src='${image}' ${cw}></div>` });
                    });
                    text = isSafari ? `<div>${I18Service.get('POPUP.PERMISSIONRESTOREOPTIONAL2SFR')}</div>` : `<div>${I18Service.get('POPUP.PERMISSIONRESTOREOPTIONAL2')}</div>`;
                    const imageRefresh = isSafari ? 'sfrReload' : 'localize';
                    queueMessages.push({ title: '', html: `${text}<div><img src='/assets/images/man/${imageRefresh}.png' ${cw}><div>` });
                }
            }
            if (progressSteps.length > 1) {
                const queue = Swal.mixin({
                    title: `${I18Service.get('POPUP.PERMISSIONRESTORETITLE')}`,
                    width: '95%',
                    heightAuto: false,
                    showCancelButton: true,
                    cancelButtonText: I18Service.get('POPUP.CLOSE'),
                    customClass: {
                        popup: 'my-swal2-popup',
                        htmlContainer: 'my-swal2-content'
                    },
                    showClass: {icon: 'swal2-icon-show'},
                    iconHtml : '<span class="material-symbols-outlined azure">help</span>'
                });
                for (let i = 0; i < queueMessages.length; i++) {
                    const message = queueMessages[i];
                    // message.currentProgressStep = i; inutilizzato e impossibile da fare
                    var res = await queue.fire(message);
                    if (res.isDismissed) {
                        i = queueMessages.length;
                    }
                }
                resolve();

            } else {
                NotificationService.popupQuestion({msg: queueMessages[0].html, title: I18Service.get('POPUP.PERMISSIONRESTORETITLE')}).finally(()=> resolve());
            }
        });
    }

    static getMediaMessageAccordingToBrowser(languageSet: string) {
        NotificationService.popupInfo({msg: I18Service.get('LABELS.ASKMEDIABODY')});
    }

}