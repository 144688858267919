import { Pipe, PipeTransform } from "@angular/core";
import { PartecipantStatus } from "../models/WebServer/partecipantStatus";
import { PhoneStatusEnum } from "../models/phoneStatus";
import { AppState } from "../store/states/app.state";
import { PartecipantInfo } from "../models/WebServer/partecipantInfo";

@Pipe({
  name: 'activePartecipant',
})
export class ActivePartecipantPipe implements PipeTransform {
  //constructor(protected sanitizer: DomSanitizer, private config: AppConfigService) { }
  public transform(partecipants: PartecipantInfo[]): number {

    const p = partecipants.filter(z => z.active);

    return p.length

  }
}
