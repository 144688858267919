import { AgencySettings, initialAgencySettings as initialAgencySettings } from "./agencySettings"

export type SessionConfig = {
  chat: boolean,
  // audioVideo: boolean, //obsolete
  audio: boolean,
  video: boolean,
  customerAttachments: boolean,
  operatorAttachments: boolean,
  localization: boolean,
  // defaultAfterNull: boolean, //unused!
  maxGuestsNumber: number,
  agencySettings: AgencySettings // dynamic in c#
}

export const initialSessionConfig: SessionConfig = {
  chat: true,
  // audioVideo: true,
  audio: true,
  video: true,
  customerAttachments: true,
  operatorAttachments: true,
  localization: true,
  // defaultAfterNull: true,
  maxGuestsNumber: 6,
  agencySettings: initialAgencySettings
}
