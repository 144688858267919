export type GeoLocCoords = {
  longitude: number;
  latitude: number;
  accuracy?: number | null;
  heading?: number | null;
  speed?: number | null;
  altitude?: number | null;
  altitudeAccuracy?: number | null;
}

export function CoordsClone(o: GeolocationCoordinates | GeoLocCoords): GeoLocCoords {
  const x: GeoLocCoords = {
    latitude: o.latitude,
    longitude: o.longitude,
    accuracy: o.accuracy,
    altitude: o.altitude,
    altitudeAccuracy: o.altitudeAccuracy,
    heading: o.heading,
    speed: o.speed
  }
  return x;
}

export function CoordsToGMaps(coord: GeoLocCoords ):  google.maps.LatLngLiteral {
  return {
    lat: coord.latitude,
    lng: coord.longitude
  }
}