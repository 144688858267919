import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, Renderer2, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageCode } from 'src/app/models/languageCode';
import { I18Service } from 'src/app/services/i18.service';


@Component({
  selector: 'ngx-flag-picker',
  templateUrl: './ngx-flag-picker.component.html',
  styleUrls: ['./ngx-flag-picker.component.scss']
  // ,encapsulation: ViewEncapsulation.None
})
export class NgxFlagPickerComponent {
  _selectedFlag: LanguageCode = {language: 'it', code:'it', tip:''};
  get selectedLanguageCode(): string {
      return this._selectedFlag.language;
  }
  @Input() set selectedLanguageCode(value: string) {      
      setTimeout(() => this.changeSelectedFlagByLanguage(value), 0 ); //dare il tempo che codes sia valorizzato
  }

  _countryCodes: LanguageCode[] = [];
  get languageList(): LanguageCode[] {
    return this._countryCodes;
  }
  @Input() set languageList(value: LanguageCode[]){
    this._countryCodes = [...value];
  }

  @Input() customLabels?: Record<string, string>;
  @Input() showFlags = true;
  @Input() showLabels = true;
  @Input() showArrow = true;
  @Input() buttonDisabled = false;
  @Input() selectDisabled = false;
  @Output() changedLanguageCode = new EventEmitter<string>();
  @Output() flagClickEvent = new EventEmitter();

  @ViewChild('selectFlags') selectFlagsElementRef!: ElementRef;

  private _isShowListCountryFlags = false;
  set isShowListCountryFlags(value: boolean) {
    this._isShowListCountryFlags = value;
    this.changeDetectorRef.markForCheck();
  }
  get isShowListCountryFlags(): boolean {
    return this._isShowListCountryFlags;
  }

  outsideClickSelectFlags: Function | undefined = () => {};

  constructor(
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
  ) { }

  public changeSelectedFlagByLanguage(value: string): void {
    if(!this.languageList)
      return;
    
    let tempFlag = this.languageList.find(z=> z.language == value); 

    if(!tempFlag){

      tempFlag = I18Service.getLanguageCode(value);
      this._countryCodes.push(tempFlag);
    }

    this._selectedFlag = tempFlag;    
    // this.closeListCountryFlags();
    this.changedLanguageCode.emit(this._selectedFlag.language);
  }
  public flagClick() { this.flagClickEvent.emit()}

  // public toggleListCountryFlags(): void {
  //   if (this.isShowListCountryFlags) {
  //     this.closeListCountryFlags();
  //   } else {
  //     this.openListCountryFlags();
  //   }
  // }


  // private openListCountryFlags(): void {
  //   this.isShowListCountryFlags = true;
  //   this.subscribeOutsideClickSelectFlags();
  // }

  // private closeListCountryFlags(): void {
  //   this.isShowListCountryFlags = false;
  //   this.unsubscribeOutsideClickSelectFlags();
  // }

  // private subscribeOutsideClickSelectFlags(): void {
  //   this.outsideClickSelectFlags = this.renderer.listen('document', 'click', (event) => {
  //     if (!this.selectFlagsElementRef.nativeElement.contains(event.target)) {
  //       this.closeListCountryFlags();
  //     }
  //   });
  // }

  // private unsubscribeOutsideClickSelectFlags(): void {
  //   if (this.outsideClickSelectFlags) {
  //     this.outsideClickSelectFlags();
  //     this.outsideClickSelectFlags = undefined;
  //   }
  // }
}
