import { Observable } from "rxjs";

import { BaseHttp } from "./base.http";
import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from './app.config.service';
import { Store } from '@ngrx/store';
import { TranslationResponse, toTranslationInfo } from "../models/WebServer/translationResponse";
import { TranslationInput } from "../models/WebServer/translationInput";
import { FELoggerService, LogSource } from "./feLogger.service";
import { NotificationService } from "./notification.service";
import { Message } from "../models/WebServer/message";
import { PartecipantRoles } from "../models/WebServer/partecipantRoles";
import * as appActions from "src/app/store/actions/app.actions";
import { AppHelper } from "../helpers/app.helper";
import { I18Service } from "./i18.service";
import { TranslationInfo } from "../models/WebServer/translationInfo";

@Injectable()
export class TextTranslationService extends BaseHttp{

    constructor(http: HttpClient, config: AppConfigService, store: Store) {
        super(http, store, config);
    }

    private translate(input: TranslationInput) : Observable<TranslationResponse> {
        return this.postRequest<TranslationResponse>(`${this.config.apiBaseUrl}/translator/translate`, input);
    }

    private _getLanguageInformation(input: TranslationInput) : Observable<string> {
        return this.postRequest<string>(`${this.config.apiBaseUrl}/translator/getLanguage`, input)
    }


    public translateChatMessage(message: Message, selectedLanguage: string, autoTranslate: boolean, translateOnly = false): Promise<{tr: TranslationResponse, isCaller: boolean}> {
        return new Promise<{tr: TranslationResponse, isCaller: boolean}>( (res, rej) => {

            const fromPartecipant = this.config.partecipantIds.find( z => z.partecipantId == message.from);
            const fromIsCaller = !!(fromPartecipant && fromPartecipant.partecipantRole == PartecipantRoles.SecondaryClient);
            const targetLanguage = this.config.language;
            const startingLanguage = (autoTranslate) ? (fromIsCaller ? selectedLanguage : (message.contentLanguage || selectedLanguage)) : undefined
        
            this.translateText(message.content, startingLanguage, targetLanguage)
                .then( (tr : TranslationResponse) => {
                    const translatedMessage : Message = AppHelper.deepCopy(message);
                    const translationInfo: TranslationInfo = toTranslationInfo(tr, this.config.partecipantId);
                    translatedMessage.translationInfo = [translationInfo]
                    translatedMessage.contentLanguage = tr.startingLanguage;
                    this.store.dispatch(appActions.updateMessage({message : translatedMessage})) ;
                    
                    if(!translateOnly && fromIsCaller && translationInfo.confidence >= this.config.agencySettings.translationMaxConfidence){
                        this.store.dispatch(appActions.translationSelectedLanguage({lang: I18Service.getLanguageCode(tr.startingLanguage)}));
                    }
                    if(translationInfo.confidence <= this.config.agencySettings.translationMinConfidence) {
                        NotificationService.showError(I18Service.get('Chat.Translate-Unreliable'));
                    }
                    
                    res({tr, isCaller: fromIsCaller});

                })
                .catch (e => rej(e))
        })
    }

    public translateText(msg: string, startingLanguage: string | undefined, targetLanguage: string): Promise<TranslationResponse> {
        return new Promise<TranslationResponse>( (res, rej) => {
            
            // if (targetLanguage == startingLanguage) {
            //     rej(`same language '${targetLanguage}'`);
            //     return;
            // }
            
            let input : TranslationInput = {
                inputText: msg,
                targetLanguage,
                startingLanguage
            }
        
            this.translate(input).subscribe({
                next: (tr : TranslationResponse) => {
                    res(tr);
                },
                error: (error: any) => {
                    FELoggerService.error(`translateMessage error`, LogSource.Default, error)
                    const msg = //error?.statusText || error?.message || error?.toString() || 'failure';
                            I18Service.get('Chat.Translate-Error')
                    NotificationService.showError(msg);
                    rej();
                }
            })
        });
    }
} 