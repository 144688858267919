export class Constants {
  
    static readonly PARAM_BROWSER_LANG = 'browserLang';
    static readonly PARAM_LANG = 'lang';

    static readonly DEFAULT_LANGUAGE = 'it';
    static readonly LANGUAGES_LIST = [
      {'value': 'en', 'label': 'English'},
      {'value': 'it', 'label': 'Italiano'},
    ];
    static readonly ALLOWED_LANGUAGES = Constants.LANGUAGES_LIST.map( z => z.value );

    static readonly DEFAULT_LATITUDE = 41.063631;
    static readonly DEFAULT_LONGITUDE = 11.593449;
    static readonly DEFAULT_ALTIITUDE = 0;
  }