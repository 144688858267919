<div class="select-flags" #selectFlags>
  <mat-form-field class="language-form" appearance="fill" subscriptSizing="dynamic">
    
    <mat-select panelClass="flag-select-option" [value]="_selectedFlag.language" [disabled]="selectDisabled" [matTooltip]="_selectedFlag.tip" >
      <mat-select-trigger>
        <span *ngIf="_selectedFlag" [ngClass]="{ 'flag-icon-margin-right': showLabels }" class="fi fi-{{ _selectedFlag.flag || _selectedFlag.language }}"></span>        
        <span class="selected-flag" *ngIf="_selectedFlag">
          {{ _selectedFlag.label }}
        </span>
      </mat-select-trigger>
      <mat-option class="item-option" *ngFor="let code of languageList" [value]="code.language" 
          (click)="changeSelectedFlagByLanguage(code.language)">
        <span *ngIf="showFlags" [ngClass]="{ 'flag-icon-margin-right': showLabels }" class="fi fi-{{ code.flag || code.language }}"></span>
        <span *ngIf="showLabels" class="country-code">{{ code.tip }}&nbsp;</span>     
      </mat-option>
    </mat-select>
  </mat-form-field>
  <!-- <div [matTooltip]="'LABELS.TRANSLATE' | translate">
    <button mat-icon-button class="send-translate-message-button" aria-label="Translate Message" (click)="flagClick()"
      [disabled]="buttonDisabled" >
      <span *ngIf="_selectedFlag.language && showFlags" matPrefix 
        [ngClass]="{ 'flag-icon-selected': showLabels }" 
        class="fi fi-{{ _selectedFlag.flag || _selectedFlag.language }}"
      ></span>
      <span class="request-translate-icon font-icon-button material-symbols-outlined">double_arrow</span>
    </button>
  </div> -->
</div>