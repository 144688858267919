import { ActionReducerMap } from "@ngrx/store";
import { RootState } from "./root.state";
import { appReducer } from "./reducers/app.reducer";
import { sessionReducer } from "./reducers/session.reducer";
import { signalrReducer } from "ngrx-signalr-core";
import { sessionStatusReducer } from "./reducers/sessionStatus.reducer";


export const reducers: ActionReducerMap<RootState, any> = {
    app: appReducer,
    // session: sessionReducer,
    status: sessionStatusReducer,
    signalr: signalrReducer
  };