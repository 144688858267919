export enum PhoneStatusEnum {
  NONE           = 'none',
  UNREGISTERED   = 'unregistered',
  IDLE           = 'idle',
  CALLING        = 'calling',
  TALKING        = 'talking',
  RINGING        = 'ringing',
  REMOTE_RINGING = 'remote_ringing',
  ERROR          = 'error',
  // GEOLOCATION    = 'geolocation',
  // STATUS         = 'status',
  DISCONNECTED   = 'disconnected'
}
