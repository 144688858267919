import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';

// import { MultimediaMachineStatus } from 'src/app/models/multimediaMachineState';
import { EventService } from 'src/app/services/event.service';
import { MediaService } from 'src/app/services/media.service';
import { AppState } from 'src/app/store/states/app.state';
import { PhoneTitleComponent } from '../phone/phone-title.component';
import { VideoComponent } from '../video/video.component';
import { MultimediaButtonsComponent } from './multimedia-buttons.component';
import { ChatComponent } from '../chat/chat.component';
import { AppConfigService } from 'src/app/services/app.config.service';
import { FELoggerService } from 'src/app/services/feLogger.service';
import { PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';
import { Store } from '@ngrx/store';
import * as appActions from "src/app/store/actions/app.actions";

@Component({
  selector: 'app-multimedia-manager-caller',
  templateUrl: './multimedia-manager-caller.component.html',
  styleUrls: ['./multimedia-manager-caller.component.scss']
})
export class MultimediaManagerCallerComponent implements OnInit {
  @Input() AS!: AppState;
  @Input() MYSTATUS!: PartecipantStatus;
  @Input() PRIMARYSTATUS?: PartecipantStatus;
  // @Input() selectorPlaceHolder: Map<string, string>;
  @ViewChild('video') video?: VideoComponent;
  @ViewChild('chat') chat?: ChatComponent;
  @ViewChild('phoneTitle') phoneTitle?: PhoneTitleComponent;
  @ViewChild('mediaButtons') mediaButtons?: MultimediaButtonsComponent;

  appModes = AppModesEnum;
  videoPreviewWidth?: number;
  videoPreviewHeight?: number;
  // multimediaMS = MultimediaMachineStatus;

  //JQ Element
  header: any;
  menuItems: any;
  headerBg: any;

  constructor(
    private store: Store<AppState>,
    public config: AppConfigService,
    // private sipService: GenericSipServer,
    // private signalr: SignalrService,
    private mediaService: MediaService,
    private feLogger: FELoggerService,
    private eventService: EventService,
    // private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }


  async changeMode(mode: AppModesEnum, localEvent: boolean = true) { 
    // TODO??? aggiungere lo start/reprise della chiamata video, meglio nell'effect status - dovrebbe essere ok.. con video "muto" e singolo getUserMedia => localVideo
    this.store.dispatch(appActions.appModeChange({ mode }));
  }

  // closeHeaderBgIfOpen() { // TODO ??
  //   // if (this.AS.selectedMode == AppModesEnum.Map) {
  //   //   this.header.removeClass('open');
  //   //   this.header.removeClass('selected');
  //   //   this.menuItems.removeClass('active');
  //   // }
  // }

  calcWidth(): string {
    const totalWidth = window.innerWidth;
    const calcWidth = totalWidth - 12;
    return `${calcWidth}px`;
  }

  calcHeight(): string { //TODO ??
    const totalHeight = window.innerHeight;
    // let reccomend = this.MYSTATUS?.currentMultimediaState?.photoVideo_C_H;
    let calcHeight = 0;
    // let pixelDecrease = 0;
    // if (reccomend) {
    //   if (reccomend == '100%') {
    //     pixelDecrease = AppHelper.isAppleOS() ? 50 : 0;
    //     calcHeight = totalHeight - 12 - pixelDecrease;
    //   } else {
    //     pixelDecrease = AppHelper.isAppleOS() ? 23 : 0;
    //     calcHeight = ((totalHeight * 50) / 100) - 12 - pixelDecrease;
    //   }
    // } else {
    //   return '0px';
    // }
    return `${calcHeight}px`;
  }

}
