import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { Constants } from "src/app/constants";
import { AppHelper } from "src/app/helpers/app.helper";
import { AppConfigService } from "src/app/services/app.config.service";
import { I18Service } from "src/app/services/i18.service";
import { MediaService } from "src/app/services/media.service";
import { NotificationService } from "src/app/services/notification.service";



@Component({
  selector: 'app-menu-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],

})
export class MenuSettingsComponent {
  
  LANGUAGES = Constants.LANGUAGES_LIST;
  selectedLang = Constants.DEFAULT_LANGUAGE;
  selectedAudioOutput = '';
  selectedAudioInput = '';
  selectedCamera = '';

  initialSelectedLang = Constants.DEFAULT_LANGUAGE;
  initialSelectedAudioOutput = '';
  initialSelectedAudioInput = '';
  initialSelectedCamera = '';

  loading = true;

  constructor(public config: AppConfigService, private translation: I18Service, public mediaService: MediaService) {

    // if(!mediaService.enumeratedDevicesAfterGetUserMedia) {
      mediaService.getUserMediaForEnumerateDevices()
      .finally( () =>{
        this.selectedLang = this.translation.getLanguage();
        this.selectedAudioOutput = this.mediaService.audioOutputSource;
        this.selectedAudioInput = this.mediaService.audioInputSource;
        this.selectedCamera = this.mediaService.videoSource;

        const preferred = this.mediaService.getPreferredDevices();
        if (preferred) {
          this.selectedAudioInput = preferred.audioInput;
          this.selectedAudioOutput = preferred.audioOutput;
          this.selectedCamera = preferred.camera
        }

        this.loading = false
        this.initialSelectedAudioInput = this.selectedAudioInput;
        this.initialSelectedAudioOutput = this.selectedAudioOutput;
        this.initialSelectedCamera = this.selectedCamera;
        this.initialSelectedLang = this.selectedLang;
      })
    // }
    // else {
    //   this.selectedLang = this.translation.getLanguage();
    //   this.selectedAudioOutput = this.mediaService.audioOutSourceId;
    //   this.selectedAudioInput = this.mediaService.audioInSourceId;
    //   this.selectedCamera = this.mediaService.videoSourceId;
  
    // }


  }

  public changeLanguage($event: any){
    this.translation.saveLanguage($event.value);
  }

  public changeMediaDevice(type: string, $event: any) {
    console.log(`change media device '${type}' => ${$event.value}`);
    this.mediaService.savePreferredDevices({audioInput: this.selectedAudioInput, audioOutput: this.selectedAudioOutput, camera: this.selectedCamera})
  }

  public undoPreferences() {
    const d={audioInput: this.initialSelectedAudioInput, audioOutput: this.initialSelectedAudioOutput, camera: this.initialSelectedCamera};
    // console.log(`===== undo preferences lang: ${this.initialSelectedLang}`, d);
    
    this.mediaService.savePreferredDevices(d)
    this.translation.saveLanguage(this.initialSelectedLang);
  }
  
  close() { NotificationService.popupClose();  }
}