import { Component, Input, ViewChild } from '@angular/core';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
// import { MultimediaMachineStatus } from 'src/app/models/multimediaMachineState';
import { AppConfigService } from 'src/app/services/app.config.service';
import { MediaService } from 'src/app/services/media.service';
import { AppState } from 'src/app/store/states/app.state';
import { PhoneTitleComponent } from '../phone/phone-title.component';
import { CameraGalleryComponent } from '../video/camera-gallery.component';
import { VideoComponent } from '../video/video.component';
import { PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';

@Component({
  selector: 'app-multimedia-manager-receiver',
  templateUrl: './multimedia-manager-receiver.component.html',
  styleUrls: ['./multimedia-manager-receiver.component.scss']
})
export class MultimediaManagerReceiverComponent {

  @Input() AS!: AppState;
  @Input() SECONDARYSTATUS?: PartecipantStatus;
  @Input() MYSTATUS!: PartecipantStatus;

  // @Input() selectorPlaceHolder: Map<string, string>;
  @ViewChild('video') video?: VideoComponent;
  // @ViewChild('chat') chat?: ChatComponent;//TODO
  @ViewChild('phoneTitle') phoneTitle?: PhoneTitleComponent;
  @ViewChild('cameraGallery') cameraGallery?: CameraGalleryComponent;

  appModes = AppModesEnum;
  videoPreviewWidth?: number;
  videoPreviewHeight?: number;

  constructor(
    public config: AppConfigService,
    private mediaService: MediaService
  ) { }

  public checkPhotoOrVideo(): boolean {
    console.log('chechPhotoOrVideo')
    return [this.appModes.Video, this.appModes.Photo].some(z => this.AS.appModes.includes(z))
  }
}
