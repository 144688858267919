import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { StoreModule } from '@ngrx/store';
import { SignalREffects } from 'ngrx-signalr-core';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './store/effects/app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppConfigService } from './services/app.config.service';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { SessionService } from './services/session.service';
import { SessionEffects } from './store/effects/session.effects';
import { BusinessErrorComponent } from './components/business-error.component';
import { AppHelper } from './helpers/app.helper';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SignalRHubEffects } from './store/effects/signalrhub.effects';
import { reducers } from './store/root.reducer';
import { SipService } from './services/sip.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MaterialModule } from './material.module';
import { DialogBodyComponent } from './components/dialogs/dialog-body.component';
import { PositionManagerComponent } from './components/partecipants/position-manager.component';
import { FELoggerService } from './services/feLogger.service';
import { I18Service } from './services/i18.service';
import { SpeedTestModule } from 'ng-speed-test';
// import { AgmCoreModule } from '@agm/core';
import { CallerComponent } from './components/partecipants/secondary/caller.component';
import { DialogAccessibilityComponent } from './components/dialog-accessibility/dialog-accessibility.component';
import { MultimediaDialogComponent } from './components/multimedia-manager/multimedia-dialog.component';
import { GeoCastingService } from './services/geocasting.service';
import { SessionStatusEffects } from './store/effects/sessionStatus.effects';
import { VideoComponent } from './components/video/video.component';
import { SafeHtmlPipe } from './helpers/safeHtml.pipe';
import { NotificationService } from './services/notification.service';
import { HttpRequestInterceptor } from './helpers/httpInterceptor';
import { ReceiverComponent } from './components/partecipants/primary/receiver.component';
import { MultimediaButtonsComponent } from './components/multimedia-manager/multimedia-buttons.component';
import { MultimediaManagerReceiverComponent } from './components/multimedia-manager/multimedia-manager-receiver.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChatComponent } from './components/chat/chat.component';
import { SafeResourceUrlPipe } from './helpers/safeResourceUrl.pipe';
import { CameraGalleryComponent } from './components/video/camera-gallery.component';
import { PhoneComponent } from './components/phone/phone.component';
import { PhoneTitleComponent } from './components/phone/phone-title.component';
import { MultimediaManagerCallerComponent } from './components/multimedia-manager/multimedia-manager-caller.component';
import { EventService } from './services/event.service';
import { CheckerService } from './services/checker.service';
import { GuestComponent } from './components/partecipants/guest/guest.component';
import { SessionInfoComponent } from './components/partecipants/primary/session-info.component';
import { RemoteInfoComponent } from './components/partecipants/primary/remote-info.component';
import { ConnectionSpeedPipe } from './helpers/connectionSpeed.pipe';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateRolePipe } from './helpers/translateRole.pipe';
import { TextMessageIconPipe } from './helpers/textMessageIcon.pipe';
import { OperatorActionsComponent } from './components/partecipants/operator-actions.component';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { GlobalErrorHandler } from './helpers/globalErrorHandler';
import { StatusCircleComponent } from './components/partecipants/status-circle.component';
import { StatusColorPipe } from './helpers/statusColor.pipe';
import { NgxFullscreenModule } from '@ultimate/ngx-fullscreen';
import { GuestChatColorPipe } from './helpers/guestChatColor.pipe';
import { MatIconRegistry } from '@angular/material/icon';
import { PermissionStatusTooltipPipe } from './helpers/permissionStatusTooltip.pipe';
import { ThumbVideoComponent } from './components/video/thumb-video.component';
import { VideFillThumbnailComponent } from './components/video/video-fill-thumbnail.component';
import { MenuComponent } from './components/partecipants/menu.component';
import { ScrollableDirective } from './components/video/scrollable.directive';
import { ImageStreamEditorComponent } from './components/image-stream-editor/image-stream-editor.component';
import { TextTranslationService } from './services/textTranslation.service';
import { NgxFlagPickerComponent } from './components/chat/ngx-flag-picker/ngx-flag-picker.component';
import { IsOnCallPipe } from './helpers/isOnCall.pipe';
import { TargetPresencePipe } from './helpers/targetPresence.pipe';
import { ThumbVideoToolbarComponent } from './components/video/thumb-video-toolbar.component';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { DownloadDirective } from './helpers/download.directive';
import { OverlayscrollbarsModule } from "overlayscrollbars-ngx";
import { MenuSettingsComponent } from './components/partecipants/menu/settings.component';
import { MenuCreditsComponent } from './components/partecipants/menu/credits.component';
import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs';
import { DisclaimerHelper } from './helpers/disclaimer.helper';
import { IsMobilePhonePipe } from './helpers/isMobilePone.pipe';
import { RemainingTimePipe } from './helpers/remainingTime.pipe';
import { SessionInfoContentComponent } from './components/partecipants/primary/session-info-content.component';
import { RemoteInfoContentComponent } from './components/partecipants/primary/remote-info-content.component';
import { CoordinatesPipe } from './helpers/coordinates.pipe';
import { GoogleMapsModule } from '@angular/google-maps';
import { ConfidenceColorPipe } from './helpers/confidenceColor.pipe';
import { ActivePartecipantPipe } from './helpers/activePartecipant.pipe';


// export class LazyTranslateLoader implements TranslateLoader {
//   constructor(private http: HttpClient) {}

//   getTranslation(lang: string): Observable<any> {
//     return this.http
//       .get(`/assets/i18n/${lang}.json`)
//       .pipe(
//         catchError(() => this.http.get(`/assets/i18n/${I18Service.DEFAULT_LANG}.json`))
//       );
//   }
// }


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  console.log(`translation HttpLoaderFactory`)
  return new TranslateHttpLoader(http, undefined, `.json?v=${AppHelper.getBuild()}`);
}
export function initializeApp(appConfig: AppConfigService) {
  return () => appConfig.load();
}

@NgModule({
  declarations: [
    SafeHtmlPipe,
    TextMessageIconPipe,
    TranslateRolePipe,
    GuestChatColorPipe,
    ConnectionSpeedPipe,
    RemainingTimePipe,
    CoordinatesPipe,
    SafeResourceUrlPipe,
    StatusColorPipe,
    ConfidenceColorPipe,
    IsOnCallPipe,
    ActivePartecipantPipe,
    IsMobilePhonePipe,
    TargetPresencePipe,
    PermissionStatusTooltipPipe,
    AppComponent,
    BusinessErrorComponent,
    DialogBodyComponent,
    CallerComponent,
    MultimediaManagerCallerComponent,
    PositionManagerComponent,
    DialogAccessibilityComponent,
    MultimediaDialogComponent,
    ReceiverComponent,
    RemoteInfoContentComponent,
    SessionInfoComponent,
    SessionInfoContentComponent,
    RemoteInfoComponent,
    SessionInfoComponent,
    MultimediaManagerReceiverComponent,
    MultimediaButtonsComponent,
    ChatComponent,
    CameraGalleryComponent,
    VideoComponent,
    ThumbVideoComponent,
    ThumbVideoToolbarComponent,
    VideFillThumbnailComponent,
    PhoneTitleComponent,
    GuestComponent,
    OperatorActionsComponent,
    StatusCircleComponent,
    MenuComponent,
    ScrollableDirective,
    PhoneComponent, //TODO probabilmente inutile!!
    ImageStreamEditorComponent,
    MenuSettingsComponent,
    MenuCreditsComponent,
    NgxFlagPickerComponent,
    DownloadDirective
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFullscreenModule,
    NgxMatIntlTelInputComponent,
    StoreModule.forRoot( reducers ), 
    EffectsModule.forRoot([AppEffects, SessionEffects, SessionStatusEffects, SignalREffects, SignalRHubEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          // useClass: LazyTranslateLoader,
          deps: [HttpClient]
        }
      }
    ),
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBaucKQF8G5k54fp1v51gGeN3V1tUDt3NM' // di iLocate
    // }),
    GoogleMapsModule, 
    HttpClientModule,
    BrowserAnimationsModule,
    SpeedTestModule,
    OverlayscrollbarsModule,
  ],
  providers: [    
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: undefined},
    { provide: MatBottomSheetRef, useValue: undefined},
    DatePipe,
    DecimalPipe,
    TranslateService,
    StatusColorPipe,
    IsOnCallPipe,
    TargetPresencePipe,
    PermissionStatusTooltipPipe,
    SessionService,
    SipService,
    FELoggerService,
    I18Service,
    GeoCastingService,
    NotificationService,
    DisclaimerHelper,
    EventService,
    CheckerService,
    TextTranslationService,
    DownloadDirective
    // PhoneComponent,

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(private readonly iconRegistry: MatIconRegistry) {
  //   const defaultFontSetClasses = iconRegistry.getDefaultFontSetClass();
  //   const outlinedFontSetClasses = defaultFontSetClasses
  //     .filter((fontSetClass) => fontSetClass !== 'material-icons')
  //     .concat(['material-symbols-outlined']);
  //   iconRegistry.setDefaultFontSetClass(...outlinedFontSetClasses);
  // }
 }
