<!-- <div #children> -->
<div #children class="toolbar" *ngIf="(partecipantStatus?.phoneStatus | isOnCall) ">
    <!-- <div mat-menu-item >
        <span class="font-icon-button material-symbols-outlined" >{{!partecipantStatus.videoEnabled ? 'play_circle': 'stop_circle'}}</span>
        <span class="label">^^cc:{{camsCount}} ve:{{partecipantStatus.videoEnabled}} b:{{(config.isReceiver || itsMe)}}</span>
    </div> -->
    <div mat-menu-item *ngIf="camsCount && (config.isReceiver || itsMe)" (click)="toggleLocalStream()">
        <span class="font-icon-button material-symbols-outlined" >{{!partecipantStatus.videoEnabled ? 'videocam': 'videocam_off'}}</span>
        <span class="label">{{(!partecipantStatus.videoEnabled ? 'LABELS.ENABLEVIDEO': 'LABELS.DISABLEVIDEO') | translate}}</span>
    </div>
    <div mat-menu-item *ngIf="camsCount > 1 && partecipantStatus?.videoEnabled && (config.isReceiver || itsMe)" (click)="switchCamera()">
        <span class="font-icon-button material-symbols-outlined">switch_camera</span>
        <span class="label">{{((partecipantStatus.parsedUA | isMobilePhone) ? 'LABELS.FLIPCAMERA' :'LABELS.SWITCHCAMERA') | translate}}</span>
    </div>

    <div mat-menu-item *ngIf="partecipantStatus?.videoEnabled && !zoom" (click)="showBigVideo()">
        <span class="font-icon-button material-symbols-outlined">frame_person</span>
        <span class="label">{{'video.toolbar.bringToFront' | translate}}</span>
    </div>
    
    <div mat-menu-item *ngIf="config.isReceiver && partecipantStatus?.videoEnabled" (click)="showBigVideoForAll()">
        <span class="font-icon-button material-symbols-outlined">jump_to_element</span>
        <span class="label">{{'video.toolbar.bringToFrontForAll' | translate}}</span>
    </div>

    <div mat-menu-item *ngIf="config.isReceiver || itsMe" (click)="switchMicrophone()">
        <span class="font-icon-button material-symbols-outlined">{{partecipantStatus.microphoneEnabled ? 'mic' : 'mic_off'}}</span>                        
        <span class="label">{{(partecipantStatus.microphoneEnabled ? 'LABELS.DISABLEMIC' : 'LABELS.ENABLEMIC') | translate}}</span>
    </div>
    <div mat-menu-item *ngIf="config.isReceiver && zoom && config.agencySettings.videoEdit" (click)="edit(video)">
        <span class="font-icon-button material-symbols-outlined">{{ video.paused ? 'edit_off':'draw'}}</span>
        <span class="label">{{(video.paused ? 'video.toolbar.editOff' : 'video.toolbar.editOn') | translate}}</span>
    </div>
    <div mat-menu-item *ngIf="config.isReceiver && zoom && pipAPIEnabled" (click)="togglePictureInPicture(video)">
        <span class="font-icon-button material-symbols-outlined">{{pictureInPictureEnabled ? 'settings_overscan' : 'picture_in_picture'}}</span>
        <span class="label">{{(pictureInPictureEnabled ? 'Video.pip_disable' : 'Video.pip_enable') | translate}}</span>
    </div>
    <div mat-menu-item *ngIf="config.isReceiver && zoom" (click)="fullscreen.toggle()">
        <span class="font-icon-button material-symbols-outlined">fullscreen</span>
        <span class="label">{{'Video.zoom' | translate}}</span>
    </div>
    <div mat-menu-item *ngIf="partecipantStatus?.videoEnabled && zoom" (click)="toggleVideoCover()">
        <span class="font-icon-button material-symbols-outlined">{{videoCover ? 'fit_screen': 'aspect_ratio'}}</span>
        <span class="label">{{(videoCover ? 'Video.contain': 'Video.cover') | translate}}</span>
    </div>

    <!-- <div mat-menu-item *ngIf="config.isReceiver && partecipantStatus?.videoEnabled && zoom" (click)="videoCoverForAll()">
        <span class="font-icon-button material-symbols-outlined">transition_push</span>
        <span class="label">{{(!videoCover ? 'Video.containForAll': 'Video.coverForAll') | translate}}</span>
    </div> -->
</div>
<!-- </div> -->
<!-- purtroppo mat-menu "nasconde automaticamente" diventa difficile far condividere il codice tra toolbar e menu
<mat-menu #toolbarMenu="matMenu" class="toolbar" *ngIf="(partecipantStatus?.phoneStatus | isOnCall) && (config.isReceiver || config.partecipantId == partecipantStatus?.partecipantId)">
    
    <div *ngIf="config.isReceiver && camsCount" (click)="toggleLocalStream()">
        <span class="font-icon-button material-symbols-outlined" >{{!partecipantStatus.videoEnabled ? 'play_circle': 'stop_circle'}}</span>
        <span class="label">toggle local stream</span>
    </div>
    <div *ngIf="camsCount > 1 && partecipantStatus?.videoEnabled" (click)="switchCamera()">
        <span class="font-icon-button material-symbols-outlined">switch_camera</span>
        <span class="label">switch camera</span>
    </div>
    <div *ngIf="false && config.isReceiver" (click)="switchMicrophone()">
        <span class="font-icon-button material-symbols-outlined">{{partecipantStatus.microphoneEnabled ? 'mic' : 'mic_off'}}</span>                        
        <span class="label">switch mic</span>
    </div>
    <div *ngIf="zoom && config.isReceiver" (click)="edit(video)" class="font-icon-button material-symbols-outlined">
        <span [matTooltip]="'Video.edit' | translate">{{ video.paused ? 'edit_off':'draw'}}</span>
        <span class="label">edit video</span>
    </div>
    <div *ngIf="zoom && config.isReceiver" (click)="togglePictureInPicture(video)">
        <span class="font-icon-button material-symbols-outlined"
            [matTooltip]="(pictureInPictureEnabled? 'Video.pip_disable': 'Video.pip_enable') | translate"
            >{{pictureInPictureEnabled ? 'settings_overscan' : 'picture_in_picture'}}</span>
        <span class="label">picture in picture</span>
    </div>
    <div *ngIf="zoom && config.isReceiver" (click)="fullscreen.toggle()">
        <span class="font-icon-button material-symbols-outlined"
            [matTooltip]="'Video.zoom' | translate">fullscreen</span>
        <span class="label">fullscreen</span>
    </div>
</mat-menu> -->
