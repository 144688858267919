<div id="session-info basic-font-medium" class="card-info" *ngIf="showSessionInfo">
  <mat-label class="card-info-title" *ngIf="!inTab">{{ "LABELS.SESSIONINFO" | translate }}
    <span class="tooltip-row" (click)="close()">
      <button mat-icon-button (click)="close()">
        <span class="material-symbols-outlined icon-close-button">close</span>
      </button>
    </span>
  </mat-label>
  <app-session-info-content [AS]="AS" [expiresIn]="expiresIn" [PARTECIPANTS]="PARTECIPANTS"></app-session-info-content>
</div>
