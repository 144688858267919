import { AfterContentChecked, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Action, Store } from "@ngrx/store";
import { Subject, takeUntil } from "rxjs";
import { AppHelper, PageStatusEnum } from "src/app/helpers/app.helper";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { PhoneStatusEnum } from "src/app/models/phoneStatus";
import { AppConfigService } from "src/app/services/app.config.service";
import * as appActions from "src/app/store/actions/app.actions";
import * as sessionStatusActions from "src/app/store/actions/sessionStatus.actions";
import { selectPartecipantsStatus } from "src/app/store/selectors/sessionStatus.selectors";
import { AppState } from "src/app/store/states/app.state";
import { ThumbVideoToolbarComponent } from "./thumb-video-toolbar.component";
import { NgxFullscreenDirective } from "@ultimate/ngx-fullscreen";
import { CommandEvent } from "src/app/models/commandEvent";
import { BrowserHelper } from "src/app/helpers/browser.helper";
import { IsOnCallPipe } from "src/app/helpers/isOnCall.pipe";


@Component({
    selector: 'app-thumb-video',
    templateUrl: './thumb-video.component.html',
    styleUrls: ['./thumb-video.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })

export class ThumbVideoComponent implements AfterViewInit, OnDestroy, AfterContentChecked  {
    @Input() AS!: AppState;
    @Input() id!: string;
    @Input() name!: string;
    // @Input() enabled!: boolean;
    _zoom = false;
    get zoom(): boolean { return this._zoom; }

    @Input() set zoom(b: boolean){
        this._zoom = b;
        this.changeDetector.markForCheck();
    }
    
    // @Input() multiCamera!: boolean;
    // @Input() atLeast1Camera!: boolean;


    @ViewChild('video') video!: ElementRef;
    @ViewChild('fullscreen') fullscreen!: NgxFullscreenDirective;
    @ViewChild('toolbar') toolbar!: ThumbVideoToolbarComponent;

    // @Output() videoFull: EventEmitter<string> = new EventEmitter<string>()
    // @Output() imageToEdit: EventEmitter<HTMLCanvasElement | null> = new EventEmitter<HTMLCanvasElement | null>()
    @Output() imageToEdit: EventEmitter<Blob | null> = new EventEmitter<Blob | null>()

    mouseEnter = false
    camsCount = 0;
    PhoneStatusEnum = PhoneStatusEnum;
    audioDisabled = false;
    videoFreeze = false

    public partecipantStatus: PartecipantStatus | undefined;
    public enableMyVideo = false;
    public onCall = false;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    
    constructor (public config:AppConfigService, private store: Store, private bottomSheet: MatBottomSheet,private changeDetector: ChangeDetectorRef, private isOnCall: IsOnCallPipe) {
        this.store.select(selectPartecipantsStatus).pipe(
            takeUntil(this.destroy$)
        ).subscribe(data => {
            this.partecipantStatus = data.find( z=>z.partecipantId == this.id);
            this.camsCount = this.partecipantStatus?.availableDevices?.video.length ?? 0;
            this.onCall = this.isOnCall.transform(this.partecipantStatus?.phoneStatus, true);
            
            this.enableMyVideo = config.partecipantId == this.partecipantStatus?.partecipantId && this.partecipantStatus?.phoneStatus == PhoneStatusEnum.TALKING;
            this.audioDisabled = !this.partecipantStatus?.microphoneEnabled;
            const isMobile = BrowserHelper.isMobilePhone(this.partecipantStatus?.parsedUA);
            this.videoFreeze = this.partecipantStatus?.pageStatus != PageStatusEnum.active && isMobile;
            // this.videoEnabled = this.partecipantStatus?.videoEnabled
            this.changeDetector.markForCheck();
        });

    }
    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
        this.destroy$.unsubscribe();
      }
    ngAfterContentChecked(): void {
        // this.changeDetector.detectChanges();
    }
    ngAfterViewInit(): void {
        this.changeDetector.detectChanges();
        // this.video.nativeElement.onplaying = () => {
        //     this.enabled = true;
        //     console.log(`mediaService remoteVideoPlayng => true`)
        // }
          
        // this.video.nativeElement.onpause = () => {
        //     this.enabled = false;
        //     console.log(`mediaService remoteVideoPlayng => true`)
        // }
      
    }

    private isSingleClick: boolean = true; 
    onVideoClick() {
        this.isSingleClick = true;
        setTimeout(()=>{
            if(this.isSingleClick){
                if (this.config.isMobilePhone) {
                    this.openToolbar();
                } else {
                    // console.log(`show big video ${this.id}`)
                    // this.store.dispatch(appActions.videoFull({id: this.id}))
                    // this.videoFull.emit(this.id);
                    // this.store.dispatch(appActions.zoomTo({id: this.id}));
                }
            }
         }, 250);

    }
    onVideoDoubleClick() {
        console.log(`dblclick start`);
        this.isSingleClick = false;
        this.store.dispatch(appActions.zoomTo({id: this.id}));
    }


    pictureInPictureEnabled = document.pictureInPictureElement != null;
    togglePictureInPicture(videoEl: HTMLVideoElement) {
        if(BrowserHelper.isFirefox()){return;}
        if (!this.pictureInPictureEnabled) {
            videoEl.requestPictureInPicture().finally( () => this.pictureInPictureEnabled = document.pictureInPictureElement != null );
        } else {
            document.exitPictureInPicture().finally( () => this.pictureInPictureEnabled = document.pictureInPictureElement != null)
        }
    }
    edit(videoEl: HTMLVideoElement) { 
        if (videoEl.paused) { 
            videoEl.play();
            this.imageToEdit.emit(null);
        }
        else { 
            videoEl.pause();
            const canvas = AppHelper.getCanvasFromVideo(videoEl);
            if (canvas) {
                canvas.toBlob( blob => {
                    this.imageToEdit.emit(blob);
                })
            }
        }
    }
    imageToEditEmit(b: Blob | null) {
        this.imageToEdit.emit(b);
    }

    private doAction(localAction: Action, remoteCommand: CommandEvent) {

        if (this.config.partecipantId == this.partecipantStatus?.partecipantId) {
            this.store.dispatch(localAction);
        }
        else if (this.config.isReceiver) {
            this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: remoteCommand, val: '', toIds: [this.partecipantStatus!.partecipantId] }));
        }
    }


    toggleLocalStream() { 
        this.doAction(appActions.toggleVideo(), CommandEvent.ToggleVideo)
    }

    switchCamera() {
        this.doAction(appActions.switchCamera({forSnapshot: false}), CommandEvent.SwitchCamera);
    }
    switchMicrophone() {
        this.doAction(appActions.toggleMicrophone(), CommandEvent.ToggleMicrophone);
    }


    close() {}

    openToolbar(){
        this.config.isMobilePhone && this.bottomSheet.open(ThumbVideoToolbarComponent, {
            data:  {
                video: this.video,
                partecipantStatus: this.partecipantStatus,
                fullscreen: this.fullscreen,
                camsCount: this.camsCount,
                zoom: this.zoom,
                videoCover: this.toolbar.videoCover
            }
        });
    }
}