import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { I18Service } from '../services/i18.service';
import { PartecipantInfo } from '../models/WebServer/partecipantInfo';

@Pipe({
  name: 'translateRole'
})
export class TranslateRolePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(partecipant: PartecipantInfo | undefined): string {
    return I18Service.roleAndAlias(partecipant);
  }
}
