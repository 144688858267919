import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { BaseHttp } from './base.http';
import { AppConfigService } from './app.config.service';
import { Message } from '../models/WebServer/message';
import { CreateEvent } from '../models/WebServer/createEvent';
import { DynamicCreateEvent } from '../models/WebServer/dynamicCreateEvent';
import { BusinessEventTypes } from '../businessEventTypes';
import { MarkingReadObject } from '../models/WebServer/markingReadObject';
import { Store } from '@ngrx/store';
import { AppHelper } from '../helpers/app.helper';

@Injectable()
export class EventService extends BaseHttp {

  // private subChatHistory: Subject<Message[]> = new Subject<Message[]>();
  // private subSaveEvent: Subject<number> = new Subject<number>();
  // private subSaveBusinessEvent: Subject<number> = new Subject<number>();
  // private subGetEvent: Subject<CreateEvent> = new Subject<CreateEvent>();
  // private subGetLastPositionEvent: Subject<CreateEvent> = new Subject<CreateEvent>();
  // private subMarkAsDelivered: Subject<CreateEvent> = new Subject<CreateEvent>();
  // private subMarkAllAsRead: Subject<boolean> = new Subject<boolean>();
  // private subMarkMediaAsSeen: Subject<boolean> = new Subject<boolean>();

  constructor(http: HttpClient, config: AppConfigService, store: Store) {
    super(http, store, config);
  }

  // getChatHistorySubscription(): Subject<Message[]> {
  //   return this.subChatHistory;
  // }

  // getGetEventSubscription(): Subject<CreateEvent> {
  //   return this.subGetEvent;
  // }

  // getGetLastPositionEventSubscription(): Subject<CreateEvent> {
  //   return this.subGetLastPositionEvent;
  // }

  // getMarkMessageAsDelivered(): Subject<CreateEvent> {
  //   return this.subMarkAsDelivered;
  // }

  // getMarkAllAsRead(): Subject<boolean> {
  //   return this.subMarkAllAsRead;
  // }

  // getMarkMediaAsSeen(): Subject<boolean> {
  //   return this.subMarkMediaAsSeen;
  // }

  // getSaveEventSubscription(): Subject<number> {
  //   return this.subSaveEvent;
  // }

  // getSaveBusinessEventSubscription(): Subject<number> {
  //   return this.subSaveBusinessEvent;
  // }

  getChatHistory(partecipantId: string): Observable<Message[]> {
    return this.getRequest<Message[]>(`${this.config.apiBaseUrl}/event/chathistorybypartecipant/${partecipantId}`);
    // this.http.get<Message[]>(`${this.config.apiBaseUrl}/event/chathistory/${sessionId}`).toPromise().then((e) => {
    //   this.subChatHistory.next(e);
    // });
  }

  saveEvent(createEvent: CreateEvent): Observable<number> {
    return this.postRequest<number>(`${this.config.apiBaseUrl}/event`, createEvent, false);
    // this.http.post<number>(`${this.config.apiBaseUrl}/event`, createEvent).toPromise().then((e) => {
    //   this.subSaveEvent.next(e);
    // });
  }

  updateEvent(updateEvent: CreateEvent): Observable<number> {
    return this.postRequest<number>(`${this.config.apiBaseUrl}/event/update`, updateEvent, false);
  }

  saveBusinessEvent(businessType: string, eventInfo: any = undefined): Observable<number> {
    const createEvent = AppHelper.getDynamicCreateData(this.config.partecipantId, businessType, eventInfo);
    if(this.config.saveBusinessEvent && !BusinessEventTypes.ExcludedEvents.some(ee => ee == createEvent.businessEventCode)) {
      return this.postRequest<number>(`${this.config.apiBaseUrl}/event/business`, createEvent, false);
    }
    return of(0);
  }

  getEvent(eventId: number): Observable<CreateEvent> {
    return this.getRequest<CreateEvent>(`${this.config.apiBaseUrl}/event/${eventId}`);
  }

  forwardChatHistory(sessionId: string): Observable<boolean> {
    return this.getRequest<boolean>(`${this.config.apiBaseUrl}/event/forwardchathistory/${sessionId}`);
  }

  getLastPositionEvent(partecipantId: string): Observable<CreateEvent> {
    return this.getRequest<CreateEvent>(`${this.config.apiBaseUrl}/event/position/partecipant/${partecipantId}`);
  }

  markMessageAsDelivered(eventId: number): Observable<CreateEvent> {
    return this.putRequest<CreateEvent>(`${this.config.apiBaseUrl}/event/markdelivered/${eventId}`, undefined);
  }

  markAllAsRead(partecipantId: string): Observable<boolean> {
    return this.putRequest<boolean>(`${this.config.apiBaseUrl}/event/markread/${partecipantId}`, undefined);
  }

  markMediaAsSeen(readObj: MarkingReadObject): Observable<boolean> {
    return this.putRequest<boolean>(`${this.config.apiBaseUrl}/event/markseen`, readObj);
  }
  
}
