<div class="agmContainer" *ngIf="currentLocation">
  <div id="over-panel" *ngIf="config.isCaller && AS.session?.config?.localization">
    <mat-expansion-panel class="mat-elevation-z0">
      <mat-expansion-panel-header [collapsedHeight]="'var(--expansion-panel-collapse-height)'" [expandedHeight]="'var(--expansion-panel-collapse-height)'">
        <div id="panel-header">
          <span class="header-city-font">{{ MYSTATUS?.addressInfo?.city ? MYSTATUS?.addressInfo?.city : 'LABELS.GETADDRESSMESSAGE' | translate }}</span>
          <span class="header-address-font">{{ MYSTATUS?.addressInfo?.address }}</span>
        </div>
      </mat-expansion-panel-header>
      <div class="panel-body">
        <div class="nearto">
          {{'LABELS.LOCALIZATION' | translate}}
        </div>
        <div class="address">{{ MYSTATUS?.addressInfo?.city }}</div>
        <div class="full-address">{{ MYSTATUS?.addressInfo?.address }}</div>
        <div class="coordinates">
          <div class="coordinate">
            <span class="coordinate-label">Lat:&nbsp;</span>
            <span class="coordinate-content">{{ currentLocation.latitude | number : '.4'}}</span>
          </div>
          <div class="coordinate">
            <span class="coordinate-label">Lon:&nbsp;</span>
            <span class="coordinate-content">{{ currentLocation.longitude | number : '.4'}}</span>
          </div>
          <div class="coordinate">
            <span class="coordinate-label">Alt:&nbsp;</span>
            <span>{{ currentLocation.altitude | number : '1.0-0'}}</span>
          </div>
        </div>
        <div class="stats">
          <div class="stat-battery">
            <span class="coordinate-label">{{'LABELS.BATTERY' | translate}}:&nbsp;</span>
            <span *ngIf="!AS.batteryLevel || AS.batteryLevel == '--'">{{'LABELS.MISSINGINFO' | translate}}</span>
            <span *ngIf="AS.batteryLevel && AS.batteryLevel != '--'">{{MYSTATUS?.batteryLevel}}</span>
          </div>
          <div class="stat-model">
            <span class="coordinate-label">{{ 'LABELS.MODEL' | translate }}:&nbsp;</span>
            <span>
              <span *ngIf="!AS?.parsedUA?.device?.vendor">{{'LABELS.MISSINGINFO' | translate}} / </span>
              <span *ngIf="AS?.parsedUA?.device?.vendor">{{ MYSTATUS?.parsedUA?.device?.vendor }} / </span>
              <span *ngIf="!AS?.parsedUA?.device?.model">{{'LABELS.MISSINGINFO' | translate}}</span>
              <span *ngIf="AS?.parsedUA?.device?.model">{{ MYSTATUS?.parsedUA?.device?.model }}</span>
            </span>
          </div>
        </div>
        <div class="stats">
          <div class="stat">
            <span class="coordinate-label">{{'LABELS.CONNECTION' | translate}}:&nbsp;</span>
            <app-status-circle
              [color]="(MYSTATUS | statusColor).color"
              [tooltip]="(MYSTATUS | statusColor).tooltip"
            ></app-status-circle>

          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>

  <mat-spinner class="spinnerPosition" mode="indeterminate" *ngIf="sendingEvent && !AS.sessionExpired">
  </mat-spinner>
  <div *ngIf="MYSTATUS?.browserCompatibility?.success === false && !AS.appInMultimedia && config.isCaller" class="unsupported-device">
    {{ 'ERRORPAGE.UNSUPPORTEDDEVICE' | translate}}
  </div>

  <div id="welcome-section" *ngIf="!AS.session?.config?.localization && config.isCaller">
    <div id="welcome-img">
      <img src="/assets/images/givingHelp.png" alt="" >
    </div>
    <div id="welcome-msg">
      <div id="welcome-txt">
        <div style="align-self:center;padding:20px">{{'WELCOMEMESSAGE' | translate}} <b>{{title}}</b></div>
        <div id="welcome-connection-status">
          <app-status-circle
            [color]="(MYSTATUS | statusColor).color"
            [tooltip]="(MYSTATUS | statusColor).tooltip"
          ></app-status-circle>
          <div [ngClass]="{ 'loading' : !MYSTATUS?.isRegistered }" class="connection-status">
            {{ MYSTATUS?.isRegistered ? ('LABELS.CONNECTEDSIP' | translate) :
              ('LABELS.CONNECTINGSIP' | translate) }}
          </div>
        </div>
        <div id="welcome-connection-status" *ngIf="MYSTATUS?.isRegistered">

          <app-status-circle
            [color]="(config.partecipantsStatus$ | targetPresence) ? 'var(--status-green)' : 'var(--status-red)'"
            
          ></app-status-circle>

          <div [ngClass]="{ 'loading' : !(config.partecipantsStatus$ | targetPresence)}" class="connection-status">
            {{ !(config.partecipantsStatus$ | targetPresence) ? ('WELCOMEMESSAGEWAIT' | translate) : ('WELCOMEMESSAGEWAIT' | translate)}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(!AS.sessionExpired || config.isReceiver) && AS.session?.config?.localization">
    <div class="mapContainer">
      <!-- <agm-map #mapContainer [zoom]="zoomLevel" [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" (mapReady)="onMapReady($event)">
        <agm-marker style="z-index: 3;" *ngIf="currentLocation" [latitude]="currentLocation.latitude" [longitude]="currentLocation.longitude" [markerDraggable]="false">
        </agm-marker>
      </agm-map> --> 
      <google-map #mapContainer [zoom]="zoomLevel" [center]="mapCenter" (mapInitialized)="onMapReady($event)">
        <map-marker style="z-index: 3;"  *ngIf="currentLocation" [options]="{draggable: false}" [position]="mapCenter"></map-marker>
      </google-map>
    </div>
  </div>
</div> 
 
<!-- <script async defer
  src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBaucKQF8G5k54fp1v51gGeN3V1tUDt3NM&callback=initMap"></script> -->
