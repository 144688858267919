<div id="header-container" class="app-background">
  <!-- TITLE  -->
  <div id="title-section" [hidden]="AS.appInMultimedia && false">
    <h2 class="container">
      <img *ngIf="logoPath" src="{{logoPath}}?v={{release}}" class="logo"
      (click)="countShowLog()"> 
      <div class="basic-font-big">
        {{ title }}
      </div>
    </h2>
  </div>
</div>
