import { Component, Input } from "@angular/core";
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";
import { AppState } from "src/app/store/states/app.state";
import { StatusCircleSize } from "../status-circle.component";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { I18Service } from "src/app/services/i18.service";
import { NotificationService } from "src/app/services/notification.service";
import {Clipboard} from '@angular/cdk/clipboard';
import { Store } from "@ngrx/store";
import * as sessionActions from "src/app/store/actions/session.actions";
import { PartecipantInfo } from "src/app/models/WebServer/partecipantInfo";

@Component({
    selector: 'app-session-info-content',
    templateUrl: './session-info-content.component.html',
    styleUrls: ['./session-info-content.component.scss']
  })
  export class SessionInfoContentComponent {
    @Input() AS!: AppState;
    @Input() expiresIn!: number;
    @Input() PARTECIPANTS!: PartecipantStatus[];

    StatusCircleSize = StatusCircleSize;
    PartecipantRoles = PartecipantRoles;

    constructor(
        public store: Store<AppState>,
        // public config: AppConfigService,
        private clipboard: Clipboard
      ) {  }

    copyLinksOnClipboard() {
        let str = '';
        this.AS.session.parameters.partecipantIds.forEach( (p, idx) => {
          const link = this.AS.session.links[idx];
          str = `${str}${I18Service.roleAndAlias(p)} => ${link.href}\n`;
        })
    
        this.clipboard.copy(str);
        NotificationService.showSuccess(`${I18Service.get(`SNACKBAR.TEXTCOPIED`)}`);
      }
      copyLink(idx: number) {
        const link = this.AS.session.links[idx];
        this.clipboard.copy(link.href);
        NotificationService.showSuccess(`${I18Service.get(`SNACKBAR.TEXTCOPIED`)}`);
      }
      removeGuest(guest: PartecipantInfo) {
        NotificationService.popupQuestion({msg:I18Service.get('LABELS.REMOVEGUESTTITLE')})
        .then((value) => {
          if (value.isConfirmed) {
            this.store.dispatch(sessionActions.removeGuest({guestId: guest.partecipantId}))
          }
        });
      }
  }