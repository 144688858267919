<div class="session-info-content">
    <div class="piece-info button-up-right">
      <button mat-icon-button matTooltipClass="tooltip-class" [matTooltip]="'LABELS.LINKSCOPY' | translate"
        (click)="copyLinksOnClipboard()">
        <span class="font-icon-button material-symbols-outlined">integration_instructions</span>
      </button>
    </div>
    <div class="piece-info piece-info-column">
      <span>{{ "TTP.SESSION_EXPIRE" | translate }}</span>
      <div class="imp-info session-expire">{{ AS.session.expiration | date : "yyyy-MM-dd HH:mm:ss" }}
        <span  *ngIf="expiresIn > 0 && !AS.sessionExpired">{{ "TTP.SESSION_EXPIRES_IN" | translate }}:
          [{{expiresIn | remainingTime}}]
        </span>
        <span *ngIf="expiresIn <= 0 || AS.sessionExpired">[{{ "LABELS.SESSIONEXPIRED" | translate }}]</span>
    </div>
    </div>
    <div class="piece-info piece-info-column for-scrolling">
      <span>{{ "TTP.SESSION_PartecipantS" | translate }}: {{AS.session.parameters.partecipantIds | activePartecipant}}</span>
      <div class="session-partecipant" *ngFor="let p of AS.session.parameters.partecipantIds; index as idx">
        <div class="part-flex"  *ngIf="p.active">
          <div class="part-name">{{ p | translateRole }}
            <!-- {{(p.partecipantRole!=PartecipantRoles.PrimaryClient) ? 
            ((p.partecipantRole==PartecipantRoles.SecondaryClient) ? ': '+p.info : ' ('+p.info+')' ) : ''}}{{ p.info }} -->
            <div class="part-info" >{{p.partecipantRole!=PartecipantRoles.PrimaryClient ? p.info: ''}}</div>  
          </div>
          <div class="part-status">
              <span class="font-icon-button material-symbols-outlined remove-part" 
                  *ngIf="AS.session.config.agencySettings.showAddGuest && (p.partecipantRole == PartecipantRoles.GuestClient || p.partecipantRole == PartecipantRoles.SuperClient)"
                  matTooltipClass="tooltip-class" matTooltipShowDelay="800" [matTooltip]="'LABELS.REMOVEGUEST' | translate"
                  (click)="removeGuest(p)"
              >delete_forever</span>
            <span class="font-icon-button material-symbols-outlined copy-link-part" 
              matTooltipClass="tooltip-class" matTooltipShowDelay="800" [matTooltip]="'LABELS.LINKCOPY' | translate"
              (click)="copyLink(idx)"
              >integration_instructions</span>
            <app-status-circle *ngIf="!AS.sessionExpired && AS.sessionExpired != undefined"
              [size]="StatusCircleSize.small"
              [color]="(p.partecipantId | statusColor: PARTECIPANTS).color"
              [tooltip]="(p.partecipantId | statusColor: PARTECIPANTS).tooltip"
            ></app-status-circle>
          </div>
        </div>
      </div>
    </div>
  </div>