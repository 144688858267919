import { Observable, Subject } from 'rxjs';

// export type InlineWorkerRequest = {
//   action: string,
//   value: string
// }

// export type InlineWorkerMessage = { }


export class InlineWorker {

  private readonly worker: Worker;
  private onMessage = new Subject<MessageEvent>();
  private onError = new Subject<ErrorEvent>();

  constructor(func: Function) {

    const WORKER_ENABLED = !!(Worker);

    if (WORKER_ENABLED) {
      const functionBody = func.toString().replace(/^[^{]*{\s*/, '').replace(/\s*}[^}]*$/, '');

      this.worker = new Worker(URL.createObjectURL(
        new Blob([ functionBody ], { type: 'text/javascript' })
      ));

      this.worker.onmessage = (data) => {
        this.onMessage.next(data);
      };

      this.worker.onerror = (data) => {
        this.onError.next(data);
      };

    } else {
      throw new Error('WebWorker is not enabled');
    }
  }

  postMessage(data: any) { this.worker?.postMessage(data); }

  onmessage(): Observable<MessageEvent> { return this.onMessage.asObservable(); }

  onerror(): Observable<ErrorEvent> { return this.onError.asObservable(); }

  terminate() { this.worker?.terminate(); }
}
