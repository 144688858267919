<div id="info-tooltip" class="card-info" *ngIf="showUserInfo">
  <mat-label class="card-info-title">{{'LABELS.MOREINFO' | translate}}
    <span *ngIf="!showMenu" class="tooltip-row">
      <button mat-icon-button (click)="close()">
        <span class="material-symbols-outlined icon-close-button">close</span>
      </button>
    </span>
    <div *ngIf="showMenu" class="menu-panel-button">
      <app-menu (showSessionInfo)="showGuestSessionInfo($event)"></app-menu>
    </div>
  </mat-label>
  <div class="remote-info-container">
    <app-remote-info-content [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS"></app-remote-info-content>
  </div>
</div>