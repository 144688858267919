
<div *ngIf="AS.errorInfo; else ok"></div>
<ng-template #ok>
  <div id="guest-container">
    <div id="main-content-container">
      <app-multimedia-buttons #multimediabuttons [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS" [MYSTATUS]="MYSTATUS"
        (changeModeEvent)="changeMode($event)" (showSessionInfo)="showGuestSessionInfo($event)" (showCitizenInfo)="showGuestCitizenInfo($event)"></app-multimedia-buttons>
      <app-multimedia-manager-receiver #multimediamanager id="multimedia-manager-container" 
        [AS]="AS" [MYSTATUS]="MYSTATUS" [SECONDARYSTATUS]="SECONDARYSTATUS">
      </app-multimedia-manager-receiver>
    </div>
    <div id="remote-section-container">
      <div class="guest-caller-info basic-font-medium"  *ngIf=" config.actualWidth > config.minGuestWidth" > 
          <app-remote-info [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS" [showUserInfo]="showUserInfo" [showMenu]="true" (showSessionInfo)="showGuestSessionInfo($event)" ></app-remote-info>
      </div>
      <app-operator-actions [AS]="AS" [MYSTATUS]="MYSTATUS" [SECONDARYSTATUS]="SECONDARYSTATUS"
        style="align-self: center; max-width: 460px; width: 100%;">
      </app-operator-actions>
    </div>
  </div>
</ng-template>

<div style="display: none;" > 
  <div #callerinfo> 
    <div class="swal-without-margin guest-caller-info basic-font-medium" style="height: 50vh; overflow: hidden;">
      <app-remote-info  [AS]="AS" [SECONDARYSTATUS]="SECONDARYSTATUS" [showUserInfo]="true" (userInfoClose)="showGuestCitizenInfo(false)"></app-remote-info>
    </div>
  </div>
</div>
<div style="display: none;" > 
  <div #sessioninfo> 
    <div class="swal-without-margin guest-caller-info basic-font-medium" style=" overflow: hidden;">
      <app-session-info  [AS]="AS" [expiresIn]="expirationMillisec"  [PARTECIPANTS]="(config.partecipantsStatus$)" [showSessionInfo]="true" (sessionInfoClose)="showGuestSessionInfo(false)"></app-session-info>
    </div>
  </div>
</div>