import { PageStatusEnum } from "src/app/helpers/app.helper";
import { PartecipantRoles } from "./partecipantRoles";
import { ParsedUA } from "../parsedUA";
import { AddressInfo } from "./addressInfo";
// import { MultimediaNode } from "../multimediaMachineState";
import { StreamStatSummary } from "../streamStats";
import { GeoLocCoords } from "../coordinates";
import { BrowserCompatibility } from "./browserCompatibilty";
import { GeoLocInfo } from "../geolocinfo";
import { AppModesEnum } from "./appModesEnum";
import { PhoneStatusEnum } from "../phoneStatus";
import { AvailableDevices, initialiAvailableDevices } from "../device";
import { PartecipantInfo } from "./partecipantInfo";

export type PartecipantParameters = {
    permissions: string;
    partecipantId: string;
    lat: number;
    log: number;
    phoneNumber: string;
    batteryChargeLevel: number;
    accessibility: DialogAccessibilityContent;
};

export type DialogAccessibilityContent = {
    deaf: boolean;
    mute: boolean;
    handless: boolean;
}
const initialPartecipantParametes: PartecipantParameters = {
    accessibility: { deaf: false, mute: false, handless: false },
    batteryChargeLevel: 0,
    lat: 0,
    log: 0,
    partecipantId: '',
    permissions: '',
    phoneNumber: ''
}


export type PartecipantStatus = {
    // roleId: string; //"2951f2c4-1194-46f3-9494-c1455a32e6e0",
    // userName: string; //"SecondaryClient_a0288e32-1d55-478f-9b42-7b6a361ec241",
    // userPassword: string; //"!7$T?eQT",
    // parameters: PartecipantParameters;
    // tenantId: string; //"3AA95771-59C0-4CCD-9BA0-207763826074",
    //    claims: string; //null,
    info: string; //"+393489343208",
    alias: string;
    active: boolean;
    
    creationDate?: Date;
    viewPreviousChat?: boolean;

    partecipantId: string; //"a0288e32-1d55-478f-9b42-7b6a361ec241",
    partecipantRole: PartecipantRoles; //0
    sessionId: string; //"84cd1d87-8151-4086-a34d-8acfb2b55a26",
    connectionId: string; //"tmgw23RlQnh_teM6qXn60A",
    // status: DialogStatusEnum;

    geoLocPermissionGiven: number; //sarebbe meglio enum
    mediaPermissionGiven: number;
    microphonePermissionGiven: number;
    microphoneEnabled?: boolean;
    videoEnabled?: boolean;

    batteryLevel: string;
    parsedUA?: ParsedUA; //class//
    userLanguage: string;
    pageStatus?: PageStatusEnum;
    browserCompatibility?: BrowserCompatibility;
    // camsCount: number;



    //currentMultimediaState?: MultimediaNode; // da rigenerare con "new", forse inutile?? //TODO
    // currentTargetMultimediaState?: MultimediaNodeEnum; // si può prelevare dal campo sopra!
    // streamStatSummary?: StreamStatSummary; //TODO: da vedere se ha senso "loggare/tenere lo stato"
    disclaimerAccepted?: boolean;
    // disclaimerRead?: boolean;  // mi sa inutile
    // disclaimerChoiceRevealed?: boolean; // pure questo.. revealed = accepted da undefined => bool
    isRegistered?: boolean;

    commandsDisabled?: boolean; //useato sul secondary
    connectionSpeed: string;
    addressInfo?: AddressInfo;
    geoLocCoords?: GeoLocCoords;

    // geoLocInfo?: GeoLocInfo; //inutile?? c'è il dato "info"

    lastLocalizationDate?: Date;


    appModes: AppModesEnum[];
    appInMultimedia: boolean;

    phoneStatus: PhoneStatusEnum;
    phoneStatusTime: Date;

    disclaimerChoiceRevealed?: boolean;
    //ttl?: number;
    // pendingCallInstauration?: boolean;
    availableDevices: AvailableDevices;
}

export const initialPartecipantStatus: PartecipantStatus = {
    // claims: '',
    connectionId: '',
    info: '',
    alias: '',
    active: true,
    // parameters: initialPartecipantParametes,
    partecipantId: '',
    partecipantRole: PartecipantRoles.PrimaryClient,
    // roleId: '',
    sessionId: '',
    // tenantId: '',
    // userName: '',
    // userPassword: ''
    // status: DialogStatusEnum.
    addressInfo: { address: '', city: '', country: '' },
    batteryLevel: '',
    geoLocPermissionGiven: 0,
    mediaPermissionGiven: 0,
    microphonePermissionGiven: 0,
    connectionSpeed: "0",
    userLanguage: "it",
    // camsCount: 0,

    appModes: [],
    appInMultimedia: false,
    phoneStatus: PhoneStatusEnum.NONE,
    phoneStatusTime: new Date(),
    availableDevices: initialiAvailableDevices
}

export function partToStr(p: PartecipantStatus | PartecipantInfo | undefined): string {return  p ? `[${p.partecipantRole}] ${p.partecipantId}`:''};