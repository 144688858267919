import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
import { AppConfigService } from 'src/app/services/app.config.service';
import { DialogAccessibilityComponent } from '../dialog-accessibility/dialog-accessibility.component';
import { SvgConstants } from 'src/app/svgConstants';
import { AppState } from 'src/app/store/states/app.state';
import { NotificationService } from 'src/app/services/notification.service';


@Component({
  selector: 'app-multimedia-dialog',
  templateUrl: './multimedia-dialog.component.html',
  styleUrls: ['./multimedia-dialog.component.scss']
})
export class MultimediaDialogComponent implements OnInit {
  @Input() AS!: AppState;
  appModes = AppModesEnum;
  svgConstants = SvgConstants;

  constructor(
    public config: AppConfigService,
  ) { }

  ngOnInit(): void {
  }

  openAccessibilityDialog() {
    const dr = NotificationService.showAccessibilityDialog();
    dr.afterClosed().subscribe(val => {
      console.log(val);
    });
  }

}
