import { Call } from "./call";
import { PartecipantLink } from "./partecipantLink";
import { PartecipantStatus } from "./partecipantStatus"
import { SessionParameters, initialSessionParameters } from "./sessionParameters";

export type SessionStatus = { // TO DO: Rinominare in RealTimePartecipantStatus
  sessionId: string; //"84cd1d87-8151-4086-a34d-8acfb2b55a26",
  tenantId: string; //"3AA95771-59C0-4CCD-9BA0-207763826074",
  agency: string; //"testConfigAgency",


  partecipants: PartecipantStatus[];

  conference: Call[];
  onConf: boolean;
  // parameters: SessionParameters;
  // links: PartecipantLink[];
  // primaryClientInfo: string; //null,
  // secondaryClientInfo: string; //null,
  // type: string; //3,
  // clientID: string; //null,
  // entityLabel: string; //"Session",
  // entityCode: string; //"Davide",
  // subEntityLabel: string; //"Session",
  // subEntityCode: string; //"Session"
}

export const initialSessionStatus: SessionStatus = {
  sessionId: '', //"84cd1d87-8151-4086-a34d-8acfb2b55a26",
  tenantId: '', //"3AA95771-59C0-4CCD-9BA0-207763826074",
  agency: '', //"testConfigAgency",
  // expiration: '', //"2025-04-28T13:36:52.1479972Z",

  partecipants: [],
  conference: [],
  onConf: false

  // parameters: initialSessionParameters,
  // links: [],
  // primaryClientInfo: '', //null,
  // secondaryClientInfo: '', //null,
  // type: '', //3,
  // clientID: '', //null,
  // entityLabel: '', //"Session",
  // entityCode: '', //"Davide",
  // subEntityLabel: '', //"Session",
  // subEntityCode: '' //"Session"    
}