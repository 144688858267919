import { ErrorInfo } from "../../models/errorInfo";
import { AddressInfo } from "src/app/models/WebServer/addressInfo";
import { ParsedUA } from "src/app/models/parsedUA";
import { PhoneStatusEnum } from "src/app/models/phoneStatus";
// import { MultimediaNode } from "src/app/models/multimediaMachineState";
import { Message } from "src/app/models/WebServer/message";
import { AvailableDevices, initialiAvailableDevices } from "src/app/models/device";
import { PageStatusEnum } from "src/app/helpers/app.helper";
import { AppModesEnum } from "src/app/models/WebServer/appModesEnum";
import { Session, initialSession } from "src/app/models/WebServer/session";
import { PartecipantInfo, initialPartecipantInfo } from "src/app/models/WebServer/partecipantInfo";
import { GeoLocCoords } from "src/app/models/coordinates";
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";
import { Constants } from "src/app/constants";
import { LanguageCode } from "src/app/models/languageCode";

export type AppState = {
    appReady: boolean;
    sessionLoaded: boolean;
    loadingMessage?: string;

    session: Session;
    partecipantInfo: PartecipantInfo;

    partecipantId: string,
    isCaller: boolean,
    isReceiver: boolean,
    isGuest: boolean,
    role: PartecipantRoles,
    errorInfo?: ErrorInfo
    // config: AppConfig;
    // signalRHub: HubConfig;
    signalrConnectionId?: string;
    wssSipRegistered?: boolean;
    connectionSpeed?: string;//presente in partStatus!

    lastPositionData?: { time: Date, coords?: GeoLocCoords, address?: AddressInfo }


    // da rivedere !!! spostati in parte da config :-(
    // localBig?:boolean;
    // captureUrl?: string;
    // sending?: boolean;
    cameraEnabledForSnapshot?: boolean;
    // currentMultimediaState?: MultimediaNode; // spostato in partStatus appModes + isInMultimedia

    batteryRaw?: string;

    switchCameraOn: boolean;
    switchingCameraIndex: number;

    microphoneEnabled: boolean;//presente in partStatus!
    videoEnabled: boolean;//presente in partStatus!

    remoteVideoEnabled?: boolean; //TODO da calcolare in altro modo!
    mediaPermission?: number;

    messages: Message[];
    mediaMessages: Message[];
    messagesLoaded: boolean;
    // documentMessages: Message[];



    sessionExtending: boolean;
    sessionExpired: boolean;//dave da rimuovere e calcolare dallo stato!!

    batteryLevel: string;//presente in partStatus!
    isRegistered: boolean; //TODO da "spostare e considerare su MYSTATUS"
    // callerMultimediaMode: boolean; diventato appInMultimedia
    parsedUA?: ParsedUA;//presente in partStatus!
    phoneStatus: PhoneStatusEnum;//presente in partStatus!
    pageStatus: PageStatusEnum;//presente in partStatus!
    // disclaimerRead: boolean;
    commandsDisabled: boolean;//presente in partStatus!
    
    // geoPermission: number;
    realTimePosition?: boolean;  //non usato più??


    mirrorUser?: boolean; //TODO dave è giusto che stia qui??
    unseenImages: number;
    unreadMessage: number;
    // audioInputDevices: Device[];
    availableDevices: AvailableDevices;//presente in partStatus!

    // browserCompatibility: BrowserCompatibility, //dave da rimuovere o cambiare logica!! (è da mettere nello stato del partecipante)
    // operatorBrowserCompatibility: BrowserCompatibility, //dave da rimuovere o cambiare logica!! (è da mettere nello stato del partecipante/operatore)
    appModes: AppModesEnum[];  //presente in partStatus!
    appInMultimedia: boolean; //presente in partStatus!

    guiLanguage: string;
    // translationSelectedLanguage: string;
    translationSelectedLanguage: LanguageCode;
    translationAuto: boolean;

    operatorZoomId: string;
};

export const initialAppState: AppState = {
    appReady: false,
    sessionLoaded: false,
    partecipantId: '',
    role: PartecipantRoles.PrimaryClient,
    isCaller: false,
    isReceiver: false,
    isGuest: false,
    // config: initialAppConfig,
    // signalRHub: initialHubConfig,
    // callerMap: new GeoLocInfo(Constants.DEFAULT_LATITUDE, Constants.DEFAULT_LONGITUDE, Constants.DEFAULT_ALTIITUDE, ''),
    sessionExpired: false,
    // targetPresence: true,
    batteryLevel: '--',
    isRegistered: true,
    // callerMultimediaMode: false,
    phoneStatus: PhoneStatusEnum.NONE,
    // disclaimerRead: false,
    commandsDisabled: false,

    switchCameraOn: true,
    switchingCameraIndex: 0,

    unreadMessage: 0,
    // geoPermission: 2,
    // browserCompatibility: new BrowserCompatibility(null, []),
    // operatorBrowserCompatibility: new BrowserCompatibility(null, [])
    // documentMessages: [],
    mediaMessages: [],
    messages: [],
    // audioInputDevices: [],
    availableDevices: initialiAvailableDevices,
    messagesLoaded: false,
    pageStatus: PageStatusEnum.active,
    appModes: [],
    appInMultimedia: false,
    partecipantInfo: initialPartecipantInfo,
    session: initialSession,
    microphoneEnabled: false,
    videoEnabled: false,
    unseenImages: 0,
    sessionExtending: false,
    guiLanguage: Constants.DEFAULT_LANGUAGE,
    // translationSelectedLanguage: Constants.DEFAULT_LANGUAGE,
    translationSelectedLanguage: {language: Constants.DEFAULT_LANGUAGE, code: Constants.DEFAULT_LANGUAGE, tip:''},
    translationAuto: false,
    operatorZoomId: ''
};

