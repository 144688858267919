import { SessionDialog } from "../dialogs/session-dialog";
import { C } from "../messages/methods/constants";
import { OutgoingNotifyRequest } from "../messages/methods/notify";
import { OutgoingRequestDelegate, RequestOptions } from "../messages/outgoing-request";
import { NonInviteClientTransaction } from "../transactions/non-invite-client-transaction";
import { UserAgentClient } from "./user-agent-client";

/**
 * NOTIFY UAS.
 * @public
 */
export class NotifyUserAgentClient extends UserAgentClient implements OutgoingNotifyRequest {
  constructor(dialog: SessionDialog, delegate?: OutgoingRequestDelegate, options?: RequestOptions) {
    const message = dialog.createOutgoingRequestMessage(C.NOTIFY, options);
    super(NonInviteClientTransaction, dialog.userAgentCore, message, delegate);
  }
}
