import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { BotInfo, BrowserInfo, detect, NodeInfo, ReactNativeInfo, SearchBotDeviceInfo } from 'detect-browser';
import { DialogContent } from '../models/dialogContent';
import { DialogBodyComponent } from '../components/dialogs/dialog-body.component';
import { DynamicCreateEvent } from '../models/WebServer/dynamicCreateEvent';
import { AppConfigService } from '../services/app.config.service';
import { DynamicEventData } from '../models/WebServer/dynamicEventData';
import { SessionConfig } from '../models/WebServer/sessionConfig';
import { PartecipantRoles } from '../models/WebServer/partecipantRoles';
import { AppModesEnum } from '../models/WebServer/appModesEnum';
import { PartecipantStatus } from '../models/WebServer/partecipantStatus';
import { I18Service } from '../services/i18.service';
import { FELoggerService } from '../services/feLogger.service';
import { InjectionToken } from '@angular/core';


export const SAMEORIGIN = new InjectionToken<RegExp>("sameorigin.regex", { factory: () => {

  // Test the given URL to start with "data:" or "blob:" or the current host
  return new RegExp(`^data:|^blob:|^http(?:s)?:\/\/${window.location.host}`);

}});

export enum PageStatusEnum {
  active = "active",
  passive = "passive",
  hidden = "hidden",
  frozen = "frozen",
  terminated = "terminated"
}

export class AppHelper {
  static getTitleName():string { return `DinamiCall`;}
  static getVersion(): string { return document.head.getAttribute('client-version') || '0.0'; }
  static getBuild(): string { return document.head.getAttribute('client-build') || '0'; }
  static getRelease(): string { return `${this.getVersion()}.${this.getBuild()}`; }
  static getTitleVersion(): string { return `${this.getTitleName()} v${this.getVersion()}`;}
  static getTitleRelease(): string { return `${this.getTitleName()} v${this.getRelease()}`;}

  static browserDetection(): BrowserInfo | SearchBotDeviceInfo | BotInfo | NodeInfo | ReactNativeInfo | undefined {
    const browser = detect();
    if (browser) {
      return browser;
    }
    return undefined;
  }

  static getEmptyDynamicCreateData(partecipantId: string, businessEventCode: string): DynamicCreateEvent {
    const dynEvtData: DynamicEventData = { eventInfo: null, partecipantId };
    const dynCreateEvt: DynamicCreateEvent = { data: dynEvtData, timestamp: new Date(), businessEventCode }
    return dynCreateEvt;
  }

  static getDynamicCreateData(partecipantId: string, businessEventCode: string, eventInfo: any = undefined): DynamicCreateEvent {
    var emptyData = this.getEmptyDynamicCreateData(partecipantId || 'none', businessEventCode);
    emptyData.data.eventInfo = eventInfo;
    return emptyData;
  }

  static getPageState(): PageStatusEnum {
    if (document.visibilityState === 'hidden') {
      return PageStatusEnum.hidden;
    }
    if (document.hasFocus()) {
      return PageStatusEnum.active;
    }
    return PageStatusEnum.passive;
  }

  // static openTab(url: any) {
  //   // Create link in memory
  //   console.log('URL ALLEGATO', url);
  //   var a = window.document.createElement("a");
  //   a.target = '_blank';
  //   a.href = url;

  //   // Dispatch fake click
  //   var e = window.document.createEvent("MouseEvents");
  //   e.initMouseEvent("click", true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  //   a.dispatchEvent(e);
  // }

  static openDialog(matDialog: MatDialog, dialogContent: DialogContent, width: string = '60%', height: string | undefined = undefined): MatDialogRef<DialogBodyComponent, any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = dialogContent;
    dialogConfig.disableClose = dialogContent.disableClose;
    dialogConfig.width = width;
    if (height) {
      dialogConfig.height = height;
    }
    let dialogRef = matDialog.open(DialogBodyComponent, dialogConfig);
    return dialogRef;
  }

  static deepCopy(obj: any): any {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      const copy: any = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
  }


  // static openSnackBar(snackBar: MatSnackBar, message: string, action: string, snackDuration: number = 2000) {
  //   action = '';
  //   snackBar.open(message, action, {
  //     duration: snackDuration,
  //     verticalPosition: 'top',
  //     horizontalPosition: 'center'
  //   });
  // }

  static imageExists(url: string, callback: Function) {
    const img = new Image();
    img.onload = () => { callback(true); };
    img.onerror = () => { callback(false); };
    img.src = url;
  }

  static fileExists(fileUrl: string): boolean {
    console.log(`fileExists ${fileUrl}`);
    var http = new XMLHttpRequest();
    try {
      http.open('HEAD', fileUrl, false);
      http.send();
    } catch (e) { }
    return http.status >= 200 && http.status <= 299;
  }
  static loadTextFile(fileUrl: string): string {
    var http = new XMLHttpRequest();
    try {
      http.open('GET', fileUrl, false);
      http.send();
    } catch (e) { }
    return http.responseText;
  }



  static getInitialAppMode(config: SessionConfig, role: PartecipantRoles): AppModesEnum | undefined {
    switch (role) {
      case PartecipantRoles.SecondaryClient:
        return undefined;

      case PartecipantRoles.PrimaryClient:
      case PartecipantRoles.SuperClient:
        if (config.chat) return AppModesEnum.Chat;
        if (config.customerAttachments) return AppModesEnum.Photo;
        if (config.video) return AppModesEnum.Video;

        break;
      case PartecipantRoles.GuestClient:
        if (config.chat) return AppModesEnum.Chat;
        if (config.video) return AppModesEnum.Video;
        if (config.customerAttachments) return AppModesEnum.Photo;
        break;
    }
    return undefined;
  }
  static getModeWhenPrimaryArrives(config: SessionConfig, role: PartecipantRoles): AppModesEnum | undefined {
    switch (role) {
      case PartecipantRoles.SecondaryClient:
        if (config.localization) return undefined; //AppModesEnum.Map; //undefined se non si vuole andare a modificare le logiche 
        if (config.chat) return AppModesEnum.Chat;
        if (config.customerAttachments) return AppModesEnum.Photo;
        if (config.video) return AppModesEnum.Video;

        return undefined;

      // case PartecipantRoles.PrimaryClient:
      //   if (config.chat) return AppModesEnum.Chat;
      //   if (config.customerAttachments) return AppModesEnum.Photo;
      //   if (config.audioVideo) return AppModesEnum.Video

      //   break;
      // case PartecipantRoles.GuestClient:
      //   if (config.chat) return AppModesEnum.Chat;
      //   if (config.audioVideo) return AppModesEnum.Video
      //   break;
    }
    return undefined;
  }

  static getStatusColor(p: PartecipantStatus | undefined, operatorReady: boolean = false): {color: string, tooltip: string } {
    
    if (!p || !p.isRegistered) return {color: 'var(--status-red)', tooltip: I18Service.get('LABELS.NOTCONNECTEDSIP')};

    if (p.videoEnabled || p.microphoneEnabled) return {color: 'var(--status-azure)', tooltip: I18Service.get('LABELS.READYTOSTREAM')};

    // if (operatorReady){
    //   return {color: 'var(--status-green)', tooltip: I18Service.get('LABELS.USERPRESENCE')};
    // }
    // return {color: 'var(--status-yellow)', tooltip: I18Service.get('LABELS.CONNECTEDSIP')};

    // uniformare pallino verde quando online #44792
    return {color: 'var(--status-green)', tooltip: I18Service.get('LABELS.CONNECTEDSIP')};
  }


   static getCanvasFromVideo(video: HTMLVideoElement): HTMLCanvasElement | undefined {
        video.pause();
        // from: https://github.com/kasprownik/electron-screencapture/blob/master/index.js
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');

        if (!context) {
          FELoggerService.error(`getCanvasFromVideo context '2d' not found`)
          return;
        }
        // see: https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement
        context?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        console.log(`****** editVideo w:${video.videoWidth} h:${video.videoHeight}`);
        return canvas;
   }

}
