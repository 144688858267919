import { Pipe, PipeTransform } from "@angular/core";
import { PartecipantStatus } from "../models/WebServer/partecipantStatus";
import { OPERATOR_ROLES } from "../models/WebServer/partecipantRoles";
import { AppConfigService } from "../services/app.config.service";

@Pipe({
  name: 'targetPresence',
})
export class TargetPresencePipe implements PipeTransform {
  constructor(private config: AppConfigService) { }
  public transform(partecipantsStatus: PartecipantStatus[] | undefined): boolean {

    const z = (this.config.isCaller)
      ? partecipantsStatus?.some(z => OPERATOR_ROLES.includes(z.partecipantRole))
      : partecipantsStatus?.some(z => OPERATOR_ROLES.includes(z.partecipantRole))    
    
    return !!z;
  }
}
