import { ComponentRef, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { AvailableDevices, Device } from '../models/device';
import { AppConfigService } from './app.config.service';

import { FELoggerService, LogSource } from './feLogger.service';
import { BrowserHelper } from '../helpers/browser.helper';
import { Store } from '@ngrx/store';
import { AppState } from '../store/states/app.state';
import * as appActions from "src/app/store/actions/app.actions";
import * as sessionStatusActions from "src/app/store/actions/sessionStatus.actions";

import { MediaStreamFactory } from 'src/libs/SipJS/platform/web';
import { NotificationService } from './notification.service';
import { I18Service } from './i18.service';
import { OPERATOR_ROLES, PartecipantRoles } from '../models/WebServer/partecipantRoles';
import { ThumbVideoComponent } from '../components/video/thumb-video.component';
import { partToStr } from '../models/WebServer/partecipantStatus';
import { StatusChangeEvent } from '../models/statusChangeEvent';
import { CommandEvent } from '../models/commandEvent';
import { IsOnCallPipe } from '../helpers/isOnCall.pipe';



export type MediaEnable = { video: boolean, audio: boolean }


@Injectable({
  providedIn: 'root'
})
export class MediaService {

  public deviceSourceChange = new BehaviorSubject<any>(null);
  // public mediaGranted = new Subject<boolean>()
  // public microphoneGranted = new Subject<boolean>()

  private isMultiCamera!: boolean;
  private atLeastACamera!: boolean;

  private videoSourceId!: string;      // information of which video device to use during the acquisition
  private audioInSourceId!: string;    // information of which audio device to use during the acquisition
  private audioOutSourceId!: string;   // information of which output audio device to use during play audio
  public cameraFacing: string = 'user';

  private localVideoSnapshotElement!: HTMLVideoElement;  // HTML element used to render local video preview
  private localVideoSnapshotPlaying=false;
  
  private localAudioStream: MediaStream | undefined;
  private localVideoStream: MediaStream | undefined;

  public dummyVideoStream: MediaStream | undefined;
  public dummyAudioStream: MediaStream | undefined;
  private localVideoSnapshotStream: MediaStream | undefined;
  public videoEditStream: MediaStream | undefined;
    
  // ENUMERATE DEVICES
  public audioInputDevices: Device[] = [];
  public audioOutputDevices: Device[] = [];
  public videoDevices: Device[] = [];

  private dummyImage!: HTMLImageElement;
  private dummyCanvas?: HTMLCanvasElement;

  private currentMediaEnabled?: MediaEnable;

  public remoteVideoRefs: ComponentRef<ThumbVideoComponent>[] = []
  imageToEdit: Blob | null = null;

  hasMicrophonePermissionGiven?: number = undefined;
  hasCameraPermissionGiven?: number = undefined;


  constructor(
    public store: Store<AppState>,
    public config: AppConfigService,
    private isOnCall: IsOnCallPipe
) {    
  }

  public get audioOutputSource() {return this.audioOutSourceId};
  public get audioInputSource() {return this.audioInSourceId};
  public get videoSource() {return this.videoSourceId};


  public async getUserMediaForEnumerateDevices():Promise<boolean>{
    return new Promise<boolean>( (res,rej)=>{
      const constraints={audio: this.hasMicrophonePermissionGiven != 1, video: this.hasCameraPermissionGiven != 1};
      if (!constraints.audio && !constraints.video) {
        res(true);
        return;
      }
      navigator.mediaDevices.getUserMedia(constraints)
      .then(stream=>{
        stream.getTracks().forEach( t => t.stop());
        this.detectDevices(true)
          .then(b => { this.setDevicesSource(); res(b)})
          .catch(e => { rej(e)})
      })
      .catch(e => {
        rej(false);
        const causes: string[]=[];
        if(constraints.audio) causes.push('audio');
        if(constraints.video) causes.push('video');
        this.dispatchMediaPermissionError(e, {cause: causes.join('/')});
        //FELoggerService.error('mediaService - Error Enumerating devices', LogSource.MediaAudio, e)
      })
    });
  }

  private detectDevices(afterGetUserMedia: boolean = false):Promise<boolean> {
    const self = this;
    return new Promise<boolean>( (res,rej)=>{

      if (navigator.mediaDevices) {
        navigator.mediaDevices.enumerateDevices()
          .then( (devices) => self.gotDevices(devices, afterGetUserMedia))
          .then( () => { res(true)} )
          .catch((reason) => {
            FELoggerService.error('mediaService - Error Enumerating devices', LogSource.MediaAudio, reason)
            // this.feLogger.error('Error Enumerating devices', reason);
            rej(false);
          });
      }
    })
  }

  private oldDeviceInfos: any;
  private gotDevices(deviceInfos: MediaDeviceInfo[], afterGetUserMedia: boolean) {
    this.videoDevices = [];
    this.audioInputDevices = [];
    this.audioOutputDevices = [];

    var mobileVideoInserted = false;
    var hasMicrophonePermissionGiven = -1;
    var hasCameraPermissionGiven = -1;

    for (let i = 0; i !== deviceInfos.length; ++i) {
      const deviceInfo = deviceInfos[i];

      if (deviceInfo.kind === 'audioinput') {
        hasMicrophonePermissionGiven = !!deviceInfo.label ? 1 : this.hasMicrophonePermissionGiven || 0;
        const text = deviceInfo.label || `microphone ${this.audioInputDevices.length + 1}`;
        if (!this.audioInputDevices.some(z => z.deviceInfo.groupId && z.deviceInfo.groupId == deviceInfo.groupId)) {
          this.audioInputDevices.push({ deviceInfo: deviceInfo, value: deviceInfo.deviceId, text: text });
        }
      } else if (deviceInfo.kind === 'audiooutput') {
        const text = deviceInfo.label || `speaker ${this.audioOutputDevices.length + 1}`;
        if (!this.audioOutputDevices.some(z => z.deviceInfo.groupId && z.deviceInfo.groupId == deviceInfo.groupId)) {
          this.audioOutputDevices.push({ deviceInfo: deviceInfo, value: deviceInfo.deviceId, text: text });
        }
      } else if (deviceInfo.kind === 'videoinput') {
        hasCameraPermissionGiven = !!deviceInfo.label ? 1 : this.hasCameraPermissionGiven || 0;
        const text = deviceInfo.label || `camera ${this.videoDevices.length + 1}`;
        if (!this.videoDevices.some(z => z.deviceInfo.groupId && z.deviceInfo.groupId == deviceInfo.groupId)) {

          if(this.config.isMobilePhone){
            if (!mobileVideoInserted) {
              mobileVideoInserted = true;
              this.videoDevices.push({value:'user', deviceInfo, text: I18Service.get('video.webcam.front')});
              this.videoDevices.push({value:'environment', deviceInfo, text: I18Service.get('video.webcam.back')});
            }
          }
          else
            this.videoDevices.push({ deviceInfo: deviceInfo, value: deviceInfo.deviceId, text: text });
        }
      } else {
        console.log('mediaService - Some other kind of source/device: ', deviceInfo);//, Framework.MediaAudio);
      }
    }

    //this.videoDevices = [...this.videoDevices, ...this.videoDevices, ...this.videoDevices, ...this.videoDevices]; 
    const devices: AvailableDevices = { audioInput: this.audioInputDevices, audioOutput: this.audioOutputDevices, video: this.videoDevices }

    if (JSON.stringify(devices) != JSON.stringify(this.oldDeviceInfos)) {
      this.store.dispatch(appActions.retrieveDevices({availableDevices: devices}))
      FELoggerService.log(`mediaService - found devices`, LogSource.Default, devices);//, Framework.MediaAudio);
      this.oldDeviceInfos = devices;
      
    }
    console.log(`mediaService - found devices afterGetUserMedia: ${afterGetUserMedia}`, devices);

    const multiStatus: any = [];
    if (this.hasCameraPermissionGiven != hasCameraPermissionGiven) {
      console.log(`**dispatching audio: video ${hasCameraPermissionGiven}`);
      multiStatus.push({ evt: StatusChangeEvent.MediaPermissionUpdated, val: hasCameraPermissionGiven });
    }
    if (this.hasMicrophonePermissionGiven != hasMicrophonePermissionGiven) {
      console.log(`**dispatching audio: ${hasMicrophonePermissionGiven}`);
      multiStatus.push({ evt: StatusChangeEvent.MicrophonePermissionUpdated, val: hasMicrophonePermissionGiven });
    }
    if (multiStatus.length)      
      this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MultipleUpdateStatus, val: multiStatus }));

    this.hasCameraPermissionGiven = hasCameraPermissionGiven;
    this.hasMicrophonePermissionGiven = hasMicrophonePermissionGiven;

  }

  oneSendAfterGetUserMedia = false;
  private setDevicesSource(afterGetUserMedia: boolean = false){

    this.isMultiCamera = this.videoDevices.length > 1;
    this.atLeastACamera = this.videoDevices.length > 0;

    var firstVideo = this.videoDevices.length > 0 ? this.videoDevices[0] : undefined
    var firstAudioOut = this.audioOutputDevices.length > 0 ? this.audioOutputDevices[0] : undefined;
    var firstAudioIn = this.audioInputDevices.length > 0 ? this.audioInputDevices[0] : undefined;

    const selectedDevices = this.getPreferredDevices();
    if(selectedDevices) {
      console.log(`mediaService - setDevicesSource using selected stored devices `, selectedDevices)
      const audioIn = this.audioInputDevices.find( z => z.value == selectedDevices.audioInput);
      if(audioIn) firstAudioIn = audioIn;
      const audioOut = this.audioOutputDevices.find( z => z.value == selectedDevices.audioOutput);
      if(audioOut) firstAudioOut = audioOut;
      const camera = this.videoDevices.find( z => z.value == selectedDevices.camera);
      if(camera) firstVideo = camera;
    }

    //inizializza solo una volta
    if(!this.videoSource) {
      this.videoSourceId = firstVideo ? firstVideo.value : undefined;
      if(this.config.isMobilePhone && this.videoSource) this.cameraFacing = this.videoSource;
    }
    if(!this.audioOutputSource) this.audioOutSourceId = firstAudioOut ? firstAudioOut.value : undefined;
    if(!this.audioInputSource) this.audioInSourceId = firstAudioIn ? firstAudioIn.value : undefined;




    
    const infoDevice=`mediaService - setDevicesSource: atLeastACamera:${this.atLeastACamera} - isMulticamera:${this.isMultiCamera}
    Audio Output Devices - Chosen: ${this.audioOutputSource} - ${firstAudioOut?.text}
    Audio Input Devices - Chosen: ${this.audioInputSource} - ${firstAudioIn?.text}
    Video Devices. - Chosen: ${this.videoSource} - ${firstVideo?.text}
    `;
    // console.log(infoDevice);
    if(!this.oneSendAfterGetUserMedia) {
      FELoggerService.info(infoDevice)
    }
    this.oneSendAfterGetUserMedia = afterGetUserMedia;

    // if (navigator.permissions ) {
    //   const permissionName = PermissionName; 
    //   try {
    //     var permissionStatus = await navigator.permissions.query({ name: permissionName });
    //   }
    //   catch (error) {
    //     FELoggerService.error(`Unable to check '${permissionName}' permission in this browser`, LogSource.StartGeoLocationTracking, error);
    //     this.setPermission(permissionName, 0);
    //   }


  }

  public init() {
    console.log(`mediaService init`)
    this.detectDevices().then(()=>{
      this.setDevicesSource();
    });
  }

  savePreferredDevices(devices: {audioInput: string, audioOutput: string, camera: string}) {
    
    localStorage.setItem(`DevicesPref.${AppConfigService.role}`, JSON.stringify(devices));
    if(this.config.isMobilePhone) this.cameraFacing = devices.camera;
    else this.videoSourceId = devices.camera;
    this.audioOutSourceId = devices.audioOutput;
    this.audioInSourceId = devices.audioInput;
    // this.store.dispatch(appActions.useSelectedCamera({forSnapshot: false})); //cambio dinamico camera in chiamata
    this.store.dispatch(appActions.changeMediaDevice());
  }

  getPreferredDevices():{audioInput: string, audioOutput: string, camera: string} | undefined {
    
    const devices = localStorage.getItem(`DevicesPref.${AppConfigService.role}`);
    if(devices) return JSON.parse(devices);
    return undefined;
  }

  public setLocalVideoSnapshot(localVideoSnapshot: HTMLVideoElement) {
    this.localVideoSnapshotElement = localVideoSnapshot;

    this.localVideoSnapshotElement.onplaying = () => {
      this.localVideoSnapshotPlaying = true;
      console.log(`mediaService localVideoSnapshotPlaying => true`)
    }

    this.localVideoSnapshotElement.onpause = () => {
      this.localVideoSnapshotPlaying = false;
      console.log(`mediaService localVideoSnapshotPlaying => false`)
    }
  }
  /**
   * Start the video preview in current HTML element,
   * using direct access camera acquisition
   */
  public async startLocalVideoSnapshot() {


    console.info(`mediaService - startLocalVideoSnapshot ???? logica da bogare????? using videoId ${this.videoSource} with id ${this.config.AS?.switchingCameraIndex}`);//, currentSession);
    const self = this;
    const isSafari = BrowserHelper.isSafari();
    const isAppleOS = BrowserHelper.isAppleOS();


    console.debug('video:startLocalVideoPreview ID BEFORE: ' + this.videoSource);
    // IMPORTANTE: Non chiedere l'audio in questo punto. Ci pensarà l'invite dopo. Serve per iphone
    let constraints;
    
    if (this.canUseFacingMode()) {
      constraints = { video: { facingMode: this.cameraFacing }, audio: false };
    } else {
      constraints = { video: this.videoSource ? { deviceId: { exact: this.videoSource } } : true, audio: false };
    }

    // self.localVideoStream = undefined;
    const videoRender = this.localVideoSnapshotElement;
    if (!videoRender) {
      FELoggerService.error(`startLocalVideoSnapshot local video render not found!!!`);
      return;
    }
    videoRender!.srcObject = null;
    console.info('video:startLocalVideoPreview Snapshot Trying get User Media VIDEO with constarints:', constraints);//, Framework.ReinviteDirection);
    try {

      const stream: MediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      this.detectDevices(true).then(()=>{
        this.setDevicesSource(true);
      });

      this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MediaPermissionUpdated, val: 1 }))


      this.atLeastACamera = true;

      this.letStreamPlayOrLoad({videoElement:videoRender!, name: 'localVideoSnapshot'}, stream, 'snapshot');//, this.localVideoPlaying, 'Local');

      this.localVideoSnapshotStream = stream;
    } catch (reason: any) {
      FELoggerService.error('Errore start video', LogSource.MediaAudio, reason);
      FELoggerService.error(`Issue Switching Camera`, LogSource.MediaAudio, reason);
      if (reason.toString().toLowerCase().indexOf('permission denied') > -1 || reason.toString().indexOf('The request is not allowed by the user agent or the platform in the current context') > -1) {
        this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MediaPermissionUpdated, val: 2 }))
      }
    }
  }


  public multiCamera(): boolean { return this.isMultiCamera; }

  public atLeast1Camera(): boolean { return this.atLeastACamera; }

  private switchCamera(): void {

    if (this.canUseFacingMode()) {
      this.cameraFacing = this.cameraFacing == 'user' ? 'environment' : 'user';
      console.log(`mediaService - switchCamera using '${this.cameraFacing}'`)
    } else if (this.videoDevices.length > 1) {
      var idx = this.videoDevices.findIndex(z => z.value == this.videoSource) + 1;
      if (idx >= this.videoDevices.length) idx = 0;
      this.videoSourceId = this.videoDevices[idx].value;
      console.log(`mediaService - switchCamera using ${this.videoDevices[idx].text} - ${this.videoSource}`)
    }
  }

  private switchCameraRollback(){
    if (this.canUseFacingMode()) {
      this.cameraFacing = this.cameraFacing == 'user' ? 'environment' : 'user';
      console.log(`mediaService - switchCameraRollback using '${this.cameraFacing}'`)
    } else if (this.videoDevices.length > 1) {
      var idx = this.videoDevices.findIndex(z => z.value == this.videoSource) - 1;
      if (idx < 0 ) idx = this.videoDevices.length - 1;
      this.videoSourceId = this.videoDevices[idx].value;
      console.log(`mediaService - switchCameraRollback using ${this.videoDevices[idx].text} - ${this.videoSource}`)
    }

  }

  public switchCameraForSnapshot(useSelectedCamera = false): void {
    // this.eventEmitter.toggleSwitchCamera(false);
    this.switchCamera();
    this.stopLocalVideoSnapshotStream();
    this.startLocalVideoSnapshot();
  }

  public switchCameraForCall(useSelectedId = false): Promise<MediaStream> {
    !useSelectedId  && this.switchCamera();
    return this.setupLocalMediaForCall(true, useSelectedId);
  }


  public setInputDevice(id: string) {

  }

  /**
   * Get the HTML element used to render local video (preview)
   */
  private getLocalVideoRender(): HTMLVideoElement | undefined {
    // return this.localVideoElement;
    const who = this.config.partecipantId;
    return this.getThumbVideoRender(who);
  }

  public getRemoteVideoRender(who:string | undefined): HTMLVideoElement | undefined {
    if (!who) {
      FELoggerService.error(`mediaService - remoteRenderer undefined!!`);
      return undefined;
    }
    return this.getThumbVideoRender(who);
  }

  /**
   * Get the HTML element will be used to render video coming from remote
   */
  private getThumbVideoRender(who:string): HTMLVideoElement | undefined {

    const remote = this.config.partecipantIds.find( z => z.partecipantId == who)
    const isGuest = remote?.partecipantRole == PartecipantRoles.GuestClient;
    console.log(`mediaService getThumbVideoRender ${who} guest => ${isGuest} ${remote?.partecipantRole}`)

    const t = this.remoteVideoRefs.find( z => z.instance.id == who);
    return t?.instance.video.nativeElement as HTMLVideoElement;
  }

  /**
   * Do stop Media (if any)
   */
  private stopLocalVideoRendererStream(): void {

    console.log(`mediaService - setupLocalMediaForCall - stopLocalVideoRendererStream`);
    // this.updateLocalVideoStream(undefined);

    const videoRender = this.getLocalVideoRender();
    if (!videoRender) {
      FELoggerService.error(`stopLocalVideoStream local video render not found!!!`);
      return;
    }
    
    videoRender!.pause();
    videoRender!.srcObject = null;

  }

  private stopLocalVideoStream() {
    if(this.localVideoStream) { 
      console.log(`mediaService - setupLocalMediaForCall - stopping localVideoStream: ${this.localVideoStream.id}`)
      this.localVideoStream.getTracks().forEach( t => t.stop());

      this.stopLocalVideoRendererStream();
    }
  }

  private stopLocalAudioStream() {
    if (this.localAudioStream) {
      console.log(`mediaService - stopping initial audio stream`, this.localAudioStream);
      this.localAudioStream.getTracks().forEach( t => t.stop());
      this.localAudioStream = undefined;
    }
  }

  public stopLocalVideoSnapshotStream(): void {
    FELoggerService.log(`mediaService - stopLocalVideoSnapshotStream`, LogSource.MediaAudio);
    if(this.localVideoSnapshotElement) {
      this.localVideoSnapshotElement!.pause();
      this.localVideoSnapshotElement!.srcObject = null;
    }
    if (this.localVideoSnapshotStream) {
      const tracks = this.localVideoSnapshotStream.getTracks();
      tracks.forEach(t => t.stop())
    }
  }

  public stopLocalStreams() {
    FELoggerService.log(`mediaService - stopping all streams`, LogSource.MediaAudio);
    this.stopLocalVideoStream();
    this.stopLocalAudioStream();

    this.currentMediaEnabled = undefined;

    const multistatus = [
      { evt: StatusChangeEvent.MicrophoneEnabled, val: false },
      { evt: StatusChangeEvent.VideoEnabled, val: false }
    ]
    this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MultipleUpdateStatus, val: multistatus }))
  }

  /**
   * Set new Device Audio output
   */
  private changeAudioOutputDestination(htmlElement: any) {
    // this.feLogger.verbose('Changing Audio Output Destination', htmlElement, Framework.MediaAudio);
    this.attachSinkId(htmlElement, this.audioOutputSource);
  }

  /**
   * Attach audio output device to video element using device/sink ID.
   */
  private attachSinkId(htmlElement: any, sinkId: string) {

    if (htmlElement.setSinkId && sinkId) {
      htmlElement.setSinkId(sinkId)
        .then(() => {
          console.debug(`Success, audio output device attached: ${sinkId}`);
        })
        .catch((error: any) => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          FELoggerService.error(errorMessage);
        });
    } 
  }

  private letStreamPlayOrLoad(opt:{videoElement: HTMLVideoElement, name: string}, stream: MediaStream, what: string) {//, alreadyPlay: boolean, type: string) {

    const videoElement = opt.videoElement;
    const msg = `${what} videoId [${stream.id}] of ${opt.name}`;
    if (videoElement.srcObject && (videoElement.srcObject as MediaStream).id == stream.id) {
      console.log(`mediaService - setupLocalMediaForCall- letStreamPlayOrLoad  already streaming ${msg}`);
      return
    }

    var track = stream.getVideoTracks()[0];
    console.log(`mediaService - setupLocalMediaForCall- letStreamPlayOrLoad ${msg}`, stream)

    videoElement.autoplay = true; // Safari does not allow calling .play() from a non user action
    // videoElement.srcObject = null;
    videoElement.srcObject = stream;

    // Load and start playback of media.
    // if (!stream.active || this.isFirefox) {
    const isPlaying = videoElement.currentTime > 0 && !videoElement.paused && !videoElement.ended 
        && videoElement.readyState > videoElement.HAVE_CURRENT_DATA;
    console.log(`mediaService **************** isPlaying ${isPlaying}`)

    videoElement.onload = () : void => {

    // if (!isPlaying)
        videoElement.play().catch((error: Error) => {
          FELoggerService.error(`mediaService - Failed to play media ${msg}`, LogSource.MediaAudio, error);
        });
    // }

      }

    // If a track is added, load and restart playback of media.
    stream.onaddtrack = (): void => {
      console.log(`mediaService stream addTrack`)
      videoElement.load(); // Safari does not work otheriwse
      videoElement.play().catch((error: Error) => {
        FELoggerService.error("mediaService - Failed to play remote media on add track", LogSource.MediaAudio, error);
      });
    };

    // If a track is removed, load and restart playback of media.
    stream.onremovetrack = (): void => {
      console.log(`mediaService stream removeTrack`)
      videoElement.load(); // Safari does not work otheriwse
      videoElement.play().catch((error: Error) => {
        FELoggerService.error("mediaService - Failed to play remote media on remove track", LogSource.MediaAudio, error);
      });
    };
  }

  //DummyCanvas
  private createDummyCanvas(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dummyCanvas = document.getElementById('dummyCanvas') as HTMLCanvasElement;
      // setTimeout(() => {
      if (this.dummyCanvas) {
        const self = this;
        this.dummyImage = new Image();
        this.dummyImage.onload = () => {
          this.drawToCanvas();
          resolve()
        }
        this.dummyImage.onerror = (err: any) => {
          FELoggerService.error('mediaService - createDummyCanvas Unable to load dummy image', LogSource.MediaAudio, err);
          reject()
        }
        this.dummyImage.src = '/assets/images/no_video.png';
      } else {
        FELoggerService.error(`mediaService - createDummyCanvas dummyCanvas id not found!`)
        reject();
      }
      // }, 500);
    });
  }

  private drawToCanvas() {
    if (!this.dummyCanvas) return;
    console.log(`mediaService - drawToCanvas`)
    const dummyCanvas = this.dummyCanvas;
    const dummyImage = this.dummyImage

    var canvasContext = this.dummyCanvas.getContext("2d");
    if (!canvasContext) return;

    (function loop() {
      canvasContext.drawImage(dummyImage, 0, 0, 640, 480, 0, 0, dummyCanvas.width, dummyCanvas.height);
      window.setTimeout(loop, 1000); // drawing at 1fps
    })();
  }

  private async createDummyVideoStream(initial = false): Promise<void> {
    return new Promise((resolve, reject) => {
      if(this.dummyVideoStream){
        resolve();
        return;
      }
      this.createDummyCanvas()
      .then(() => {
        if (!this.dummyCanvas) {
          FELoggerService.error(`why dummyCanvas is null???`)
          reject();
          return;
        }
        this.dummyVideoStream = this.dummyCanvas.captureStream(1);
        this.dummyCanvas.width = 640;
        this.dummyCanvas.height = 480;
  
        const tracks = this.dummyVideoStream.getVideoTracks();
        console.log(`mediaService - create dummy video tracks ${tracks.length}`);
        resolve();
      })
      .catch(()=>{reject();});


      // const videoStream 
    });
  }

  private async createDummyAudioStream(initial = false): Promise<void> {
    return new Promise((resolve, reject) => {
        if(this.dummyAudioStream){
          resolve();
          return;
        }
        this.silenceAudio().then( (ms: MediaStream) => {
          console.log(`mediaService - dummyaudiostream created`);
          this.dummyAudioStream = ms;
          resolve();
        })
    })
  }


  public localMediaStreamFactory(): MediaStreamFactory { // in base allo stato "restituisce" dummy o webcam (richiamata da sip-service!)
    console.log(`mediaService - localMediaStreamFactory request from sipService`)
    const self = this;

    return (constraints: MediaStreamConstraints): Promise<MediaStream> => {
      const x: MediaEnable = {
        audio: !!constraints.audio,
        video: !!constraints.video
      }
      return self.setupLocalMediaForCall()//x);
    };
  }

  setImageToEditStream(canvas: HTMLCanvasElement | null) {
    if (canvas) {
      
      this.videoEditStream = canvas.captureStream(15);
      this.getLocalAudioStream(true)
        .then( (audiostream: MediaStream) => {
          audiostream.getAudioTracks().forEach( t => {
            console.log(`mediaService setImageToEditStream audio track ${t.id}`);
            this.videoEditStream!.addTrack(t)
          });
          this.store.dispatch(appActions.streamVideoDraw({enabled: true}));
        });
    } else {
      console.log(`mediaService setImageToEditStream STOP`);
      this.videoEditStream?.getVideoTracks().forEach( t => t.stop());
      this.videoEditStream = undefined;
      this.store.dispatch(appActions.streamVideoDraw({enabled: false}));
    }


    const myLocalVideo = this.getLocalVideoRender();
    this.letStreamPlayOrLoad({videoElement: myLocalVideo!, name: `editvideo local my video` }, this.videoEditStream?this.videoEditStream:this.dummyVideoStream!, 'imageToEdit'+(this.videoEditStream?'':'dummy'));//????
  }

  public isCurrentMediaChanged() {
    const audioEnabled = !!this.config.AS?.microphoneEnabled; //muto
    const videoEnabled = !!this.config.AS?.videoEnabled; // se disabilitato si va di dummy!!

    return this.currentMediaEnabled == undefined || this.currentMediaEnabled.audio != audioEnabled || this.currentMediaEnabled.video != videoEnabled;

  }

  public setupLocalMediaForCall(switchCamera = false, useSelectedId = false): Promise<MediaStream> {

    const selfThis = this;

    const audioEnabled = !!selfThis.config.AS?.microphoneEnabled; //muto
    const videoEnabled = !!selfThis.config.AS?.videoEnabled; // se disabilitato si va di dummy!!


///sinkid (output audio)
    //this.changeAudioOutputDestination();
    if(this.remoteVideoRefs && this.remoteVideoRefs.length) {
      const el = this.remoteVideoRefs[0].instance.video.nativeElement;
      if (!el.setSinkId) {
        FELoggerService.warn(`${BrowserHelper.getBrowserInfo()} does not support output device selection.`);
      }
    }
    this.remoteVideoRefs.forEach( ref => {
      this.changeAudioOutputDestination(ref.instance.video.nativeElement);
    })


    // const currentMediaEnabled = {...this.currentMediaEnabled};
    const useInitialAudio = (this.currentMediaEnabled?.audio === audioEnabled) && !useSelectedId;
    const useInitialVideo = (this.currentMediaEnabled?.video === videoEnabled) && !switchCamera;

    console.log(`mediaService - setupLocalMediaForCall audio: ${audioEnabled} (initial: ${useInitialAudio}) - video: ${videoEnabled} (initial: ${useInitialVideo}) - prev: ${JSON.stringify(this.currentMediaEnabled)}`);

    this.currentMediaEnabled = {audio: audioEnabled, video: videoEnabled};

    const remoteStream = new MediaStream();
    return new Promise<MediaStream>( (resolve, reject) => {
      this.getLocalAudioStream(useInitialAudio).then( (audiostream: MediaStream) => {
          audiostream.getAudioTracks().forEach( t => {console.log(`mediaService audio track ${t.id}`); remoteStream!.addTrack(t)});//.clone())});
          
          selfThis.getLocalVideoStream(useInitialVideo).then( (videoStream: MediaStream) => {
              videoStream.getVideoTracks().forEach( t => remoteStream!.addTrack(t));//.clone()));
              resolve(remoteStream!);

              //fix #58172
              const audio = (this.currentMediaEnabled?.audio) ? 1 : this.hasMicrophonePermissionGiven || 0;
              const video = (this.currentMediaEnabled?.video) ? 1 : this.hasCameraPermissionGiven || 0;
              selfThis.dispatchMediaPermission(audio, video);
            })
            .catch((reason) => {            
              
              selfThis.dispatchMediaPermissionError(reason, {cause: 'video'});
              if (switchCamera && !useSelectedId){ // reimposta la precedente???
                  selfThis.switchCameraRollback();
              }
              reject(reason)})
        })
        .catch((reason) => {
          selfThis.dispatchMediaPermissionError(reason, {cause: 'audio'});
          reject(reason)
        })
    });

  }

  private dispatchMediaPermissionError(reason: any, what: {cause: string}){//audioEnabled: boolean, videoEnabled: boolean) {
    FELoggerService.error(`mediaService - setupLocalMedia ERROR for ${what.cause}`, LogSource.MediaAudio, reason);
    
    const problems = ['permission denied', 'the request is not allowed by the user agent or the platform in the current context'];

    if (problems.some(p => reason.toString().toLowerCase().indexOf(p) > -1)) {
      
      NotificationService.popupWarn({msg:I18Service.get('SNACKBAR.MEDIAPERMISSIONREQUIRED')});

      //fix #58172
      const audio = (what.cause === 'audio') ? 2 : this.hasMicrophonePermissionGiven || 0;
      const video = (what.cause === 'video') ? 2 : this.hasCameraPermissionGiven || 0;

      this.dispatchMediaPermission(audio, video);

    } else {
      NotificationService.popupWarn({msg:I18Service.get('SNACKBAR.MEDIADENIED', {device: what.cause})});
    }
  }
  private dispatchMediaPermission(audio: number, video: number){//permissions: { audio?: number, video?: number }) {
    const multiStatus: any = [];

    this.hasCameraPermissionGiven = video;
    this.hasMicrophonePermissionGiven = audio;

    multiStatus.push({ evt: StatusChangeEvent.MicrophonePermissionUpdated, val: audio })
    multiStatus.push({ evt: StatusChangeEvent.MediaPermissionUpdated, val: video })

    console.log(`dispatching audio: ${audio} - video ${video}`);
    this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MultipleUpdateStatus, val: multiStatus }));

  }

  private getLocalAudioStream(useLocal: boolean): Promise<MediaStream> {
    const selfThis = this;
    const audioEnabled = !!this.config.AS?.microphoneEnabled; //muto
    // const useLocal = (this.currentMediaEnabled?.audio === audioEnabled);
    return new Promise( async (resolve, reject) => {
      if (audioEnabled && this.localAudioStream && useLocal) {
        console.log(`mediaService - reuse initialAudioStream`)
        resolve(this.localAudioStream);
        return;
      } 
      if(this.currentMediaEnabled?.audio) { // prende mic
        const constraints = { audio: !!selfThis.audioInputSource ? { deviceId: { exact: selfThis.audioInputSource } } : true };
        
        this.stopLocalAudioStream();
        console.log(`mediaService - audio mic access setting initialAudioStream`)
        navigator.mediaDevices.getUserMedia(constraints)
          .then((stream) => { 
            this.localAudioStream = stream; 
            this.detectDevices(true).then(()=>{
              this.setDevicesSource(true);
            }); 
            resolve(stream); 
          })
          .catch((e) => {
            reject(e); 
            console.error('mic problem', e);
            this.currentMediaEnabled!.audio = false;
            this.store.dispatch(appActions.toggleMicrophone());
          });
        return;
      } 

      //use dummy audio
      console.log(`mediaService - audio dummy`)
      if (!this.dummyAudioStream) {
        console.log('mediaService - trying dummy audio creation')
        console.time('mediaService - dummyStream - dummyaudio');
        await this.createDummyAudioStream();
        console.timeEnd('mediaService - dummyStream - dummyaudio')
      }
      this.stopLocalAudioStream();
      console.log('mediaService - cloning dummy audio')

      const stream = new MediaStream();
      this.dummyAudioStream?.getAudioTracks().forEach(t => {
        const c = t.clone(); // per evitare che lo stopStream fermi anche il dummy
        stream.addTrack(c);
      });
      
      //this.initialAudioStream = stream;
      resolve(stream);
    })
  }

  private getLocalVideoStream(useLocal: boolean): Promise<MediaStream>{
    const selfThis = this;
    return new Promise( async (resolve, reject) => {
      if (useLocal) {
        if (this.videoEditStream) {  // nel caso sia in editing video
          console.log(`mediaService - reuse videoEditStream`)
          resolve(this.videoEditStream);
          return;
        }
        if(this.localVideoStream) {
          console.log(`mediaService - setupLocalMediaForCall - reuse remoteStream`)
          resolve(this.localVideoStream);
          return;
        }
      } 

      if(this.currentMediaEnabled?.video) { // prende webcam

        if (this.videoEditStream) {  // nel caso sia in editing video
          console.log(`mediaService - use videoEditStream`)
          resolve(this.videoEditStream);
          return;
        }
        
        let constraints: MediaStreamConstraints;
       
        if (this.canUseFacingMode()) {
          constraints = { video: { facingMode:  selfThis.cameraFacing } };
        } else {

          constraints = { video: !!selfThis.videoSource ? { deviceId: { exact: selfThis.videoSource } } : true };
        }

        this.stopLocalVideoStream();
        // this.stopLocalVideoRendererStream();
        console.log(`mediaService - setupLocalMediaForCall - video webcam access ${JSON.stringify(constraints.video)}`)
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => { 
          this.updateLocalVideoStream(stream);
          this.detectDevices(true).then(()=>{
            this.setDevicesSource(true);
          }); 
          resolve(stream); 
        }).catch((e) => {
          reject(e);
          this.currentMediaEnabled!.video = false;
          this.store.dispatch(appActions.toggleVideo());
        });
        return;
      } 

      //use dummy audio
      console.log(`mediaService - video dummy`)
      if (!this.dummyVideoStream) {
        console.log('mediaService - trying dummy video creation')
        
        console.time('mediaService - dummyStream - dummyvideo')
        await this.createDummyVideoStream();
        console.timeEnd('mediaService - dummyStream - dummyvideo')
      }
      this.stopLocalVideoStream();
      // this.stopLocalVideoRendererStream();
      const stream = this.cloneStream(this.dummyVideoStream, 'dummyVideo');
      this.updateLocalVideoStream(stream);

      resolve(stream);
    })
  }

  private cloneStream(s: MediaStream | undefined, what: string): MediaStream  {
    console.log(`MediaService - cloning stream ${what} - with ${s?.getTracks().length} tracks`);
    const stream = new MediaStream();
    s?.getTracks().forEach( t => {
      const c = t.clone(); // per evitare che lo stopStream fermi anche il dummy
      console.log(`MediaService cloned track ${c.kind} [${c.id}]`)
      stream.addTrack(c);
    })
    return stream
  }

  private updateLocalVideoStream(stream: MediaStream | undefined) {
    // this.stopCurrentVideoStream();
    console.log(`mediaService - setupLocalMediaForCall - update localVideoStream: ${stream?.id}`)
    this.localVideoStream = stream; 

    const myLocalVideo = this.getLocalVideoRender();
    stream && this.letStreamPlayOrLoad({videoElement:myLocalVideo!, name:`update my localvideo`}, stream, 'video');//????
    
    // this.remoteStream = new MediaStream();
    // stream && stream.getTracks().forEach( t => this.remoteStream?.addTrack(t.clone()));

  }


//https://blog.mozilla.org/webrtc/warm-up-with-replacetrack/
  private silenceAudio = (): Promise<MediaStream> => {
  return new Promise<MediaStream>( (res, rej) =>{
    try {

      const createAudioTrack = function(): MediaStreamTrack {
        const ctx = new AudioContext(), oscillator = ctx.createOscillator();
        const dst: any = oscillator.connect(ctx.createMediaStreamDestination());
        oscillator.start();
        return Object.assign(dst.stream.getAudioTracks()[0], {enabled: false});
      }
      const audioStrean = new MediaStream();
      audioStrean.addTrack(createAudioTrack());
      res(audioStrean);
    }
    catch(reason){
      FELoggerService.warn(`mediaService - dummy audio supsendend resume error!!! => popup`, LogSource.MediaAudio, reason);
      NotificationService.popupInfo({msg:I18Service.get('Video.starting_call')}).then(async () => {
          console.log(`mediaService - dummy audio resume....`);
          this.silenceAudio().then( (ms: MediaStream) => {
            res(ms);
          })
      })
    }
  })
  }

  private prevZoomId: ComponentRef<ThumbVideoComponent>| undefined;
  public setVideoZoom(id: string){
    
    if(id) {
      const prevZoomRef = this.remoteVideoRefs.find( z => z.instance.zoom);
      if (prevZoomRef) this.prevZoomId = prevZoomRef;
    }

    this.remoteVideoRefs.forEach( z => z.instance.zoom = false)

    // setTimeout( () => {
    var ref = this.remoteVideoRefs.find( z => z.instance.id == id);
    if (!ref && this.prevZoomId) {
      ref = this.prevZoomId;
    }
    if (ref ) {
      // this.actualThumbId = id;
      ref.instance.zoom = true;
      try {
        ref.instance.video.nativeElement.play();
      } catch(e: any){console.warn(e);}
      
      if(!this.prevZoomId)this.prevZoomId=ref; //primo set del prev

      console.log(`mediaService - setFullVideo zoomId ${id} ${ref.instance.name}`, this.remoteVideoRefs);

      if (this.config.isReceiver) {
        this.store.dispatch(appActions.operatorZoomId({id}));
        this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.OperatorZoomId,val:id, toIds: appActions.SEND_TO_ALL}))
      }

    }
    return ref;
  }

  private canUseFacingMode(): boolean {
    const supports = navigator.mediaDevices.getSupportedConstraints();
    return !!(supports['facingMode'] && BrowserHelper.isMobilePhone());
     
  }

  public fitVideoTo(id: string, value: boolean){
    
    var ref = this.remoteVideoRefs.find( z => z.instance.id == id);
    if (ref) {
      ref.instance.toolbar.videoCover = value;
    }
  }

  setZoomOnTalking() {

    // mantiene lo zoom precedente anche se video disattivato (ma deve essere in chiamata)
    const zooming = this.remoteVideoRefs.find( z => z.instance.zoom && z.instance.onCall);
    if (zooming) { 
        const videoEnabled = this.config.partecipantsStatus$.find( z => z.partecipantId == zooming.instance.id && z.videoEnabled);
      // if(videoEnabled){
        console.log(`mediaService - setupLocalMediaForCall - setZoomOnTalking: already zooming ${partToStr(videoEnabled)}`)
        return;
      // } 
    }

    // prende lo zoomoId dell'operatore (ma deve essere in chiamata)
    console.log(`this.config.AS?.operatorZoomId => ${this.config.AS?.operatorZoomId}`)
    const operatorZoom = this.config.partecipantsStatus$.find( z => z.partecipantId == this.config.AS?.operatorZoomId && this.isOnCall.transform(z.phoneStatus, true))
    if(operatorZoom) {
      console.log(`mediaService - setupLocalMediaForCall - setZoomOnTalking: setting zoom on same of OPERATOR ${partToStr(operatorZoom)}`)
      this.setVideoZoom(operatorZoom.partecipantId);
      return
    }

    // verifica se utente è in call.. 
    const caller = this.config.partecipantsStatus$.find( z => z.partecipantRole == PartecipantRoles.SecondaryClient && this.isOnCall.transform(z.phoneStatus, true))
    if (caller) {
      console.log(`mediaService - setupLocalMediaForCall - setZoomOnTalking: setting zoom on USER ${partToStr(caller)}`)
      this.setVideoZoom(caller.partecipantId);
      return
    }

    // verifica se un operatore è in call.. con video attivo!!
    const operator = this.config.partecipantsStatus$.find( z => OPERATOR_ROLES.includes( z.partecipantRole ) && z.videoEnabled)
    if (operator) {
      console.log(`mediaService - setupLocalMediaForCall - setZoomOnTalking: setting zoom on OPERATOR ${partToStr(operator)}`)
      this.setVideoZoom(operator.partecipantId);
      return
    }

    // alltrimenti metti il primo che ha il video abilitato
    const firstVideoEnabled = this.config.partecipantsStatus$.find( z => z.videoEnabled);
    if (firstVideoEnabled) {
      console.log(`mediaService - setupLocalMediaForCall - setZoomOnTalking: setting zoom on first video enabled ${partToStr(firstVideoEnabled)}`)
      this.setVideoZoom(firstVideoEnabled.partecipantId);
      return
    }

    // verifica se sono io in chiamata e nel caso metti me
    // const me =  this.config.partecipantsStatus$.find( z => z.partecipantId == this.config.partecipantId && z.videoEnabled)
    if (this.isOnCall.transform(this.config.myPartecipantStatus?.phoneStatus, true)) {
      console.log(`mediaService - setupLocalMediaForCall - setZoomOnTalking: setting zoom on me: ${partToStr(this.config.myPartecipantStatus)}`)
      this.setVideoZoom(this.config.partecipantId);
      return
    }

    console.log(`mediaService - setupLocalMediaForCall - setZoomOnTalking - no one with video enabled`)
  }
}
