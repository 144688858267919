import { Constants } from "src/app/constants"
import { PartecipantRoles } from "./partecipantRoles"

export type Disclaimer = {
  title: string,
  text: string,
  language: string,
  for: PartecipantRoles
}

export type AgencySettings = {
  // agency: string,
  // disclaimerHeaderText: string,
  // attachmentEnabled: boolean, // non usato
  //using defaults
  minAccuracy: number,
  sendPositionThreshold: number,
  sendPositionInterval: number,
  sendPositionTimeOut: number,
  checkBatteryInterval: number,
  checkInternetSpeed: number,
  snackbarDefaultTimer: number,
  defaultLanguage: string,
  defaultPreferredCountry: string,
  chatMulticolor: boolean,
  translationMaxConfidence: number,
  translationMinConfidence: number,
  videoEdit: boolean,
  showDetectAndTranslateChat: boolean,
  showTranslateChat: boolean,
  
  //defined in excel
  showAddGuest: boolean,
  sendChatToCad: boolean,
  enableTranslation: boolean,
  showDisclaimer: boolean,
  blockingDisclaimer: boolean,
  disclaimerMinTimeout: number,
  disclaimers: Disclaimer[]
}

export const initialAgencySettings: AgencySettings = {
  // agency: '',
  // attachmentEnabled: true,
  blockingDisclaimer: true,
  //bypassBrowserControl: true,
  defaultLanguage: Constants.DEFAULT_LANGUAGE,
  defaultPreferredCountry: 'it',
  // disclaimerHeaderText: '',
  disclaimerMinTimeout: 2880,
  minAccuracy: 300,
  sendPositionInterval: 30000,
  sendPositionThreshold: 20000,
  sendPositionTimeOut: 900000,
  showDisclaimer: true,
  snackbarDefaultTimer: 3000,

  checkBatteryInterval: 60000,
  checkInternetSpeed:  300000,
  showAddGuest: true,
  sendChatToCad: true,
  chatMulticolor: true,

  enableTranslation: true,
  translationMaxConfidence: 0.85,
  translationMinConfidence: 0.50,
  // tranlationConfidence
  videoEdit: false,
  showDetectAndTranslateChat: true,
  showTranslateChat: true,
  disclaimers: [{
    language: Constants.DEFAULT_LANGUAGE,
    text: '',
    title: '',
    for: PartecipantRoles.SecondaryClient
  }]
}