<div id="card-operator-actions" class="card-info basic-font-medium">
  <mat-label class="card-info-title">{{((config.isGuest) ?'LABELS.GUESTACTIONS' : 'LABELS.OPERATORACTIONS') | translate}}</mat-label>
  <!-- OPERATOR BUTTONS -->
  <div id="operator-buttons" class="card-info-content-buttons">
    <div *ngIf="AS.session.config.agencySettings.showAddGuest" [matTooltip]="((addGuestDisabled) ? 'LABELS.ADDGUESTDISABLED': 'LABELS.ADDGUEST') | translate" matTooltipClass="button-operator-tooltip"
      [ngClass]="{'disabled-label' : !(AS.session) || !SECONDARYSTATUS?.isRegistered || AS.sessionExpired}">
      <button mat-raised-button class="operator-button" onclick="this.blur()"
        [disabled]="!(AS.session) || AS.sessionExpired || addGuestDisabled" (click)="addGuest()">
        <span class="font-icon-button material-symbols-outlined">person_add_alt</span>
      </button>
      <input id="attachmentUploader" type="file" style="display: none;" accept="*" #fileInput
        (change)="attachDocument($event)"
        [disabled]="!(AS.session) || !SECONDARYSTATUS?.isRegistered || AS.sessionExpired" />
    </div>

    <div *ngIf="AS.session.config.operatorAttachments" [matTooltip]="'LABELS.SENDATTACHMENT' | translate" matTooltipClass="button-operator-tooltip"
      [ngClass]="{'disabled-label' : !(AS.session) || !SECONDARYSTATUS?.isRegistered || AS.sessionExpired}">
      <button mat-raised-button class="operator-button" (click)="openFileInput('attachmentUploader')" onclick="this.blur()"
        [disabled]="!(AS.session) || !SECONDARYSTATUS?.isRegistered || AS.sessionExpired">
        <span class="font-icon-button material-symbols-outlined">attach_file</span>
      </button>
      <input id="attachmentUploader" type="file" style="display: none;" accept="*" #fileInput
        (change)="attachDocument($event)"
        [disabled]="!(AS.session) || !SECONDARYSTATUS?.isRegistered || AS.sessionExpired" />
    </div>

    <div *ngIf="config.isReceiver && AS.session.config.chat && (AS.session.config.agencySettings)?.sendChatToCad" [matTooltip]="'LABELS.SENDCHCAD' | translate" matTooltipClass="button-operator-tooltip">
      <button mat-raised-button class="operator-button" onclick="this.blur()"
        aria-label="Forward Chat History" (click)="forwardChatHistory()"
        [disabled]="!(AS.session) || disableForwardChat || AS.messages.length == 0">
        <span class="font-icon-button material-symbols-outlined">chat_paste_go</span>
      </button>
    </div>

    <div *ngIf="AS.phoneStatus == phoneStatuses.TALKING && false" [matTooltip]="'LABELS.HARDRESET' | translate" matTooltipClass="button-operator-tooltip">
      <button mat-raised-button class="operator-button"
        aria-label="Hard Reset Sip Session" (click)="aksForNewInvite()" onclick="this.blur()"
        [disabled]="!(AS.session) || !SECONDARYSTATUS?.isRegistered || AS.sessionExpired">
        <span class="font-icon-button material-symbols-outlined">loop</span>
      </button>
    </div>

    <div *ngIf="config.isReceiver" [matTooltip]="'LABELS.TAKESCREENSHOT' | translate" matTooltipClass="button-operator-tooltip">
      <button mat-raised-button class="operator-button"
        aria-label="Hard Reset Sip Session" (click)="takeAndSendScreenshot()" onclick="this.blur()"
        [disabled]="!(AS.session) || !SECONDARYSTATUS?.isRegistered || AS.sessionExpired">
        <span class="font-icon-button material-symbols-outlined">screen_share</span>
      </button>
    </div>

    <div [matTooltip]="'LABELS.EXTENDSESSION' | translate" matTooltipClass="button-operator-tooltip">
      <button mat-raised-button class="operator-button" *ngIf="showOperatorButtons" onclick="this.blur()"
        aria-label="Add 10 minutes" (click)="add_n_Minutes()" [disabled]="!(AS.session) || AS.sessionExpired">
        <span class="font-icon-button material-symbols-outlined">access_time</span>
      </button>
    </div>

    <div [matTooltip]="'LABELS.STOPSESSION' | translate" matTooltipClass="button-operator-tooltip">
      <button mat-raised-button class="operator-button" aria-label="End Session" onclick="this.blur()"
        (click)="endSession()" [disabled]="!(AS.session) || AS.sessionExpired">
        <span class="font-icon-button material-symbols-outlined">power_settings_new</span>
      </button>
    </div>
  </div>
</div>

<!-- popup add guest -->
<div class="add-guest-div card-info" *ngIf="showAddGuestForm">
  <mat-label class="card-info-title">{{'AddGuest.Title' | translate}}
    <span class="tooltip-row" (click)="showAddGuestForm = false">
      <button mat-icon-button (click)="showAddGuestForm = false">
        <span class="material-symbols-outlined icon-close-button">close</span>
      </button>
    </span>
  </mat-label>
  <div class="add-guest-content card-info-content">
    <form #f="ngForm" [formGroup]="phoneForm">
      
      <div>{{'AddGuest.SuperUser' | translate}}</div>
      <mat-radio-group aria-label="Select an option" formControlName="superUser" color="primary">
        <mat-radio-button [value]="true" class="add-guest-radio">{{'AddGuest.Yes' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="add-guest-radio">{{'AddGuest.No' | translate}}</mat-radio-button>
      </mat-radio-group>

      <div style="margin-top: 12px;">{{'AddGuest.SendInvite' | translate}}</div>
      <mat-radio-group aria-label="Select an option" formControlName="sendSms" color="primary" (change)="sendSmsChange()">
        <mat-radio-button [value]="true" class="add-guest-radio">{{'AddGuest.SendSms' | translate}}</mat-radio-button>
        <mat-radio-button [value]="false" class="add-guest-radio">{{'AddGuest.SendMail' | translate}}</mat-radio-button>
      </mat-radio-group>

      <mat-form-field appearance="fill" class="formwidth" *ngIf="!!phoneForm.controls.sendSms.value">
        <mat-label>{{'AddGuest.PhoneNumber' | translate}}* </mat-label>
        <ngx-mat-intl-tel-input [preferredCountries]="preferredCountries" [enablePlaceholder]="true"
          formControlName="phoneNumber"
          [errorStateMatcher]="customErrorStateMatcher"
          #phone></ngx-mat-intl-tel-input>
        <mat-hint>{{'AddGuest.example' | translate}} {{ phone.selectedCountry?.placeHolder }}</mat-hint>
        <mat-error *ngIf="phoneForm.controls.phoneNumber.errors?.required">{{'AddGuest.RequiredField' | translate}}</mat-error>
        <mat-error *ngIf="phoneForm.controls.phoneNumber.errors?.validatePhoneNumber">{{'AddGuest.InvalidNumber' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="formwidth" *ngIf="!phoneForm.controls.sendSms.value">
        <mat-label>{{'AddGuest.Email' | translate}}*</mat-label>
        <input matInput formControlName="email" [errorStateMatcher]="customErrorStateMatcher">
        <mat-hint>{{'AddGuest.example' | translate}} user@domain.com</mat-hint>
        <mat-error *ngIf="phoneForm.controls.email.errors?.required">{{'AddGuest.RequiredField' | translate}}</mat-error>
        <mat-error *ngIf="phoneForm.controls.email.errors?.email">{{'AddGuest.WrongEmail' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="formwidth">
        <mat-label>{{'AddGuest.Alias' | translate}}</mat-label>
        <input matInput formControlName="alias" [errorStateMatcher]="customErrorStateMatcher">
        <mat-error *ngIf="phoneForm.controls.alias.errors?.required">{{'AddGuest.RequiredField' | translate}}</mat-error>
      </mat-form-field>
      <!-- <mat-form-field> -->
        <div>{{'AddGuest.ShowPreviousChat' | translate}}</div>
        <mat-radio-group aria-label="Select an option" formControlName="viewPreviousChat" color="primary">
          <mat-radio-button [value]="true" class="add-guest-radio">{{'AddGuest.Yes' | translate}}</mat-radio-button>
          <mat-radio-button [value]="false" class="add-guest-radio">{{'AddGuest.No' | translate}}</mat-radio-button>
        </mat-radio-group>
      <!-- </mat-form-field> -->
      <div class="add-guest-actions" style="margin-top: 12px;">
        <button mat-raised-button type="submit" (click)="createGuest()" 
          [ngClass]="(phoneForm.invalid || (!phoneForm.controls.phoneNumber.value && !phoneForm.controls.email.value)) ? '': 'button-blueb80'" 
          [disabled]="(phoneForm.invalid || (!phoneForm.controls.phoneNumber.value && !phoneForm.controls.email.value))"
        
        >{{'AddGuest.Add' | translate }}</button>
        <button mat-raised-button (click)="showAddGuestForm = false">{{'POPUP.CANCEL' | translate }}</button>
      </div>
    </form>
  </div>
</div>