<div class="remote-info-content">
 <div class="piece-info button-up-right">
    <button mat-icon-button matTooltipClass="tooltip-class" [matTooltip]="'LABELS.COPY' | translate"
      (click)="CopyInfoOnClipboard()">
      <span class="font-icon-button material-symbols-outlined">content_copy</span>
    </button>
  </div>

  <!-- <div id="remote-info" class="card-info-content"> -->
    <div class="for-scrolling" id="content-to-copy">
    <div class="piece-info" *ngIf="config.isGuest || true || true">
      <span>{{ 'TTP.EVENTID' | translate }}:</span>
      <span class="imp-info">{{ (!AS.session.entityCode ? 'LABELS.MISSINGINFO' : AS.session.entityCode)| translate}}
        <span *ngIf="AS.session.subEntityCode"> - {{AS.session.subEntityCode}} </span>
      </span>
    </div>
    <div class="piece-info" *ngIf="config.isGuest || true">
      <span>{{ 'TTP.USERCONTACT' | translate }}:</span>
      <span *ngIf="!SECONDARYSTATUS?.info" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span class="imp-info">{{ SECONDARYSTATUS?.info }}</span>
    </div>
    <div class="piece-info" *ngIf="config.isGuest || true">
      <span>{{ 'TTP.MODEL' | translate }}:</span>
      <span class="imp-info">
        <span *ngIf="!SECONDARYSTATUS?.parsedUA?.device?.vendor">{{'LABELS.MISSINGINFO' | translate}} / </span>
        <span *ngIf="SECONDARYSTATUS?.parsedUA?.device?.vendor">{{ SECONDARYSTATUS?.parsedUA?.device?.vendor }} / </span>
        <span *ngIf="!SECONDARYSTATUS?.parsedUA?.device?.model">{{'LABELS.MISSINGINFO' | translate}}</span>
        <span *ngIf="SECONDARYSTATUS?.parsedUA?.device?.model">{{ SECONDARYSTATUS?.parsedUA?.device?.model }}</span>
      </span>
    </div>
    <div class="piece-info" *ngIf="config.isGuest || true">
      <span>{{ 'TTP.BATTERYLVL' | translate }}:</span>
      <span *ngIf="SECONDARYSTATUS?.batteryLevel" class="imp-info">{{SECONDARYSTATUS?.batteryLevel }}</span>
    </div>
    <div class="piece-info" *ngIf="config.isGuest || true">
      <span>{{ 'TTP.CONNECTION' | translate }}:<app-status-circle *ngIf="SECONDARYSTATUS?.isRegistered"
          [color]="(SECONDARYSTATUS | statusColor).color"
          [tooltip]="(SECONDARYSTATUS | statusColor).tooltip"
          [size] = "StatusCircleSize.small"
          class="secondary-status"
        ></app-status-circle></span>
      <span *ngIf="!SECONDARYSTATUS?.connectionSpeed" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span *ngIf="SECONDARYSTATUS?.connectionSpeed && (SECONDARYSTATUS?.connectionSpeed |connectionSpeed) as status">
        {{status.speed}}<span *ngIf="status.msg" style="color:orange">{{status.msg}}</span>
      </span>
    </div>
    <div class="piece-info" *ngIf="AS.session.config.localization && config.isGuest || true">
      <span>{{ 'TTP.NEARTO' | translate }}:</span>
      <span *ngIf="!SECONDARYSTATUS?.addressInfo" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span class="imp-info" *ngIf="SECONDARYSTATUS?.addressInfo">
        [{{SECONDARYSTATUS?.addressInfo?.country}} - {{SECONDARYSTATUS?.addressInfo?.city}}]
        {{SECONDARYSTATUS?.addressInfo?.address}}
      </span>
    </div>
  

    <div class="piece-info">
      <span>{{ 'TTP.BROWSERSTATUS' | translate }}:</span>
      <span *ngIf="!SECONDARYSTATUS?.pageStatus" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span class="imp-info">{{ (SECONDARYSTATUS?.pageStatus ||'').toString().toUpperCase() | translate }}</span>
    </div>
    <!-- <div class="piece-info">
      <span>{{ 'TTP.USERSPEED' | translate }}:</span>
      <span *ngIf="!SECONDARYSTATUS?.connectionSpeed" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span *ngIf="SECONDARYSTATUS?.connectionSpeed" class="imp-info">{{ SECONDARYSTATUS?.connectionSpeed | connectionSpeed}} Mbps</span>
    </div> -->
    <div class="piece-info">
      <span>{{ 'TTP.BROWSANDOS' | translate }}:</span>
      <span *ngIf="!SECONDARYSTATUS?.parsedUA?.browser?.name && !SECONDARYSTATUS?.parsedUA?.os?.name" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span *ngIf="SECONDARYSTATUS?.parsedUA?.browser?.name || SECONDARYSTATUS?.parsedUA?.os?.name" class="imp-info">
        {{ SECONDARYSTATUS?.parsedUA?.browser?.name }} v{{SECONDARYSTATUS?.parsedUA?.browser?.major }} - {{ SECONDARYSTATUS?.parsedUA?.os?.name }}<span *ngIf="SECONDARYSTATUS?.parsedUA?.os?.version"> ({{SECONDARYSTATUS?.parsedUA?.os?.version}})</span></span>
    </div>
    <!-- <div class="piece-info">
      <span>{{ 'TTP.BROWSVERSION' | translate }}</span>
      <span *ngIf="!SECONDARYSTATUS?.parsedUA?.browser?.version" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span class="imp-info">{{ SECONDARYSTATUS?.parsedUA?.browser?.version }}</span>
    </div> -->
    <div class="piece-info">
      <span>{{ 'TTP.STATE' | translate }}:</span>
      <span *ngIf="!coordsData?.address?.country" class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span class="imp-info">{{ coordsData?.address?.country }}</span>
    </div>
    <div class="piece-info">
      <span>{{ 'TTP.COORDINATES' | translate }}:</span>
      <span class="imp-info">{{
        (!coordsData?.coords || (coordsData?.coords?.latitude == defaultLatitude && coordsData?.coords?.longitude == defaultLongitude))
        ? ('LABELS.MISSINGINFO' | translate) 
        : (coordsData?.coords | coordinates)
      }}</span>
      <!-- <span *ngIf="!coordsData?.coords || !(coordsData?.coords?.latitude == defaultLatitude && coordsData?.coords?.longitude == defaultLongitude)"  class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span *ngIf="(coordsData?.coords?.latitude || coordsData?.coords?.longitude || coordsData?.coords?.altitude)
        && (coordsData?.coords?.latitude != defaultLatitude || coordsData?.coords?.longitude != defaultLongitude)" class="imp-info">
        Lat: {{coordsData?.coords?.latitude | number : '.4'}} - 
        Long: {{coordsData?.coords?.longitude | number : '.4'}}
        {{coordsData?.coords?.altitude ? ' - Alt: '+ (coordsData?.coords?.altitude | number : '1.0-0') : ''}}
      </span> -->

      
    </div>
    <div class="piece-info">
      <span>{{ 'TTP.LASTLOCTIME' | translate }}:</span>
      <span *ngIf="!coordsData?.time || coordsData?.time?.getTime() == 0 " class="imp-info">{{'LABELS.MISSINGINFO' | translate}}</span>
      <span *ngIf="coordsData?.time && coordsData?.time?.getTime() != 0" class="imp-info">{{coordsData?.time | date: 'yyyy-MM-dd HH:mm:ss'}}</span>
    </div>
    <div class="piece-info">
      <span>{{ 'TTP.LOCATIONSOURCE' | translate }}:</span>
      <span *ngIf="realTimePosition" class="imp-info">Real Time</span>
      <span *ngIf="!realTimePosition" class="imp-info">Server</span>
    </div>
  </div>
</div>