import { Constants } from "../constants"
import { DeviceControl } from "./deviceControl"
import { LanguageCode } from "./languageCode"

export type RTRProxy = {
    domain: string,
    wssUrl: string,
    testUrl: string,
    customSipHeader: string,
    iceGatheringTimeout: number,
    traceSip: boolean,
    sipKeepAliveInterval: number,
    sipKeepAliveDebounce: number,
    registerExpires: number,
    primaryClient: {
      wssUrl: string,
      testUrl: string,
    }
}

export type AppConfig = {
  apiBaseUrl: string
  signalRUlr: string
  rtrProxy: RTRProxy,
  
  defaultLang: string,
  frontEndLogEnabled: boolean,
  frontEndLogLevel: string,
  deviceControls: DeviceControl[];
  operatorDeviceControls: DeviceControl[];
  desiredImageSize: number;
  geoPermissionTimeout: number;
  saveBusinessEvent: boolean;
  operatorButtons: boolean; //da configurare per agency!!
  includeImagesInChat: boolean,
  minutesExtension: number,
  translationLanguages: string[];
}

export const initialRTRPoxy: RTRProxy = {
  customSipHeader: '',
  domain: '',
  iceGatheringTimeout: 6000,
  sipKeepAliveInterval: 10,
  sipKeepAliveDebounce: 10,
  registerExpires: 300,
  testUrl: '',
  traceSip: false,
  wssUrl: '',
  primaryClient: {testUrl:'', wssUrl:''}
};

export const initialAppConfig: AppConfig = {
  apiBaseUrl: '',
  rtrProxy: initialRTRPoxy,
  signalRUlr: '',
  frontEndLogEnabled: false,
  frontEndLogLevel: '',
  defaultLang: Constants.DEFAULT_LANGUAGE,
  deviceControls: [],
  operatorDeviceControls: [],
  desiredImageSize: 2700000,
  geoPermissionTimeout: 60000,
  saveBusinessEvent: true,
  operatorButtons: true,
  includeImagesInChat: false,
  minutesExtension: 10,
  translationLanguages: []
}