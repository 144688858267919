<div class="loading" *ngIf="isLoading">
    <ng-container *ngTemplateOutlet="loadingTemplate ? loadingTemplate : defaultLoading"></ng-container>
</div>
<div class="error" *ngIf="hasError">
    <ng-container *ngTemplateOutlet="errorTemplate ? errorTemplate : defaultError"></ng-container>
</div>

<ng-template #defaultLoading><p>{{ getTextTranslated('loading') }}</p></ng-template>
<ng-template #defaultError> <p>{{ errorMessage }}</p> </ng-template>

<div #canvasContainerEditor class="canvas-container-editor" [ngStyle]="{ border: borderCss }">
    <canvas id="canvas"></canvas>
</div>
<div class="toolbar" *ngIf="!isLoading">
  <div class="tools">
      <div class="row">
          <i class="material-symbols-outlined btn" [class.selected]="currentTool == CanvasToolType.pencil" (click)="selectTool(CanvasToolType.pencil)"
             [title]="getTooltipTranslated('tools.brush')">brush</i>
         <i class="material-symbols-outlined btn" [class.selected]="currentTool == CanvasToolType.arrow" (click)="selectTool(CanvasToolType.arrow)"
             [title]="getTooltipTranslated('tools.brush')">north_east</i>
         <i class="material-symbols-outlined btn" [class.selected]="currentTool == CanvasToolType.circle" (click)="selectTool(CanvasToolType.circle)"
            [title]="getTooltipTranslated('tools.brush')">circle</i>             
         <i class="material-symbols-outlined btn" [class.selected]="currentTool == CanvasToolType.line" (click)="selectTool(CanvasToolType.line)"
            [title]="getTooltipTranslated('tools.brush')">pen_size_2</i>             
         <i class="material-symbols-outlined btn" [class.selected]="currentTool == CanvasToolType.rectangle" (click)="selectTool(CanvasToolType.rectangle)"
            [title]="getTooltipTranslated('tools.brush')">square</i>             
         <i class="material-symbols-outlined btn" [class.selected]="currentTool == CanvasToolType.text" (click)="selectTool(CanvasToolType.text)"
            [title]="getTooltipTranslated('tools.brush')">insert_text</i>
         <i class="material-symbols-outlined btn" [class.selected]="currentTool == CanvasToolType.select" (click)="selectTool(CanvasToolType.select)"
            [title]="getTooltipTranslated('tools.brush')">arrow_selector_tool</i>

          <span *ngFor="let drawingSizeName of drawingSizesName" class="size btn"
                [style.width.px]="drawingSizes[drawingSizeName] * 0.8 + 8"
                [style.height.px]="drawingSizes[drawingSizeName] * 0.8 + 8"
                [style.borderRadius.px]="drawingSizes[drawingSizeName] * 0.4 + 4"
                [class.selected]="currentSize == drawingSizeName"
                [title]="getTooltipTranslated('sizes.' + drawingSizeName)"
                (click)="selectDrawingSize(drawingSizeName)">
         </span>

          <input style="display: none" type="file" #fileInput (change)="importPhotoFromFile($event)"
                 accept="image/*"/>
          <i class="material-symbols-outlined btn" *ngIf="enableLoadAnotherImage && !hasImage" (click)="fileInput.click();"
             [title]="getTooltipTranslated('loadImage')">attach_file</i>
          <i class="material-symbols-outlined btn" *ngIf="enableLoadAnotherImage && !hasImage" (click)="importPhotoFromUrl()"
             [title]="getTooltipTranslated('loadImageUrl')">insert_drive_file</i>
          <i class="material-symbols-outlined btn" *ngIf="enableRemoveImage && hasImage" (click)="removeImage()"
             [title]="getTooltipTranslated('removeImage')">clear</i>

      </div>
      <div class="row">
          <div *ngFor="let colorName of colorsName" [class.selected]="currentColor == colorName" class="color"
               [ngClass]="colorName"
               [style.background]="colors[colorName]" [title]="getTooltipTranslated('colors.' + colorName)"
               (click)="selectColor(colorName)">
          </div>
          <i class="material-symbols-outlined btn" [class.disabled]="!canUndo" (click)="undo()"
             [title]="getTooltipTranslated('undo')">undo</i>
          <i class="material-symbols-outlined btn" [class.disabled]="!canRedo" (click)="redo()"
             [title]="getTooltipTranslated('redo')">redo</i>
          <i class="material-symbols-outlined btn" (click)="clearCanvas()" [title]="getTooltipTranslated('clear')">delete</i>
      </div>
  </div>
  <!-- <div class="buttons">
      <a href="#" class="button btn-primary"
         (click)="saveImage(); $event.preventDefault()">{{ getTextTranslated('saveBtn') }}</a>
      <a href="#" class="button btn-light" *ngIf="showCancelButton"
         (click)="cancelAction(); $event.preventDefault()">{{ getTextTranslated('cancelBtn') }}</a>
  </div> -->
  <!-- Any additional toolbar buttons to be projected by the consuming app -->
  <ng-content></ng-content>
</div>
