<div class="swal-without-margin credits-container">
  <mat-label class="section-title">{{'credits.title' | translate }}
    <span class="tooltip-row">
      <button mat-icon-button (click)="close()">
        <span class="material-symbols-outlined icon-close-button">close</span>
      </button>
    </span>
  </mat-label>
  <div style="padding: 15px;text-align: center;">
    <div><b>{{AppHelper.getTitleName()}}</b> v{{AppHelper.getRelease()}} <span style="color:red; font-size: 0.7em" *ngIf="config.inDebug()">[DEBUG ENABLED] </span></div>
    <div><b>{{'credits.developedby' | translate }}:</b> Beta 80 Group</div>
    <div class="licenses-container">
      <mat-accordion >
        <mat-expansion-panel >
          <mat-expansion-panel-header>
            <mat-panel-title style="justify-content: center;">
              <b>{{'credits.licenses' | translate}}</b>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="licenses-list">
            <div *ngFor="let item of NotificationService.licenseList" class="license-item">
              <p><b>{{item.name}}</b> {{item.version}}</p>  <!--- {{item.licenses}}-->
              <p>{{item.copyright}}</p>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <div id="dialog-actions">
    <button mat-raised-button class="ok-button" (click)="close()" cdkFocusInitial>OK</button>
  </div>
</div>
