
export enum CommandEvent {
  AcceptedBrowser = 'AcceptedBrowser',
  ChangeMode = 'ChangeMode',
  ToggleMicrophone = 'ToggleMicrophone',
  ToggleVideo = 'ToggleVideo',
  SwitchCamera = 'SwitchCamera',
  ToggleRemoteCommand = 'ToggleRemoteCommand',
  Session_Terminate = 'Session_Terminate',
  Session_Extend = 'Session_Extend',
  GeoPermissionInstructions = 'GeoPermissionInstructions',
  MediaPermissionInstructions = 'MediaPermissionInstructions',
  ForceRefresh = 'ForceRefresh',
  MediaMessageSent = 'MediaMessageSent', // ex: RefreshChatHistory
  MarkAllAsRead = 'MarkAllAsRead',
  MarkAsDelivered = 'MarkAsDelivered',
  SendMessage = 'SendMessage', 

  EnableDebug = 'EnableDebug',
  ReloadSession = 'ReloadSession',
  VideoZoomTo = 'VideoZoomTo',
  FitVideoTo = 'FitVideoTo',
  OperatorZoomId = 'OperatorZoomId',
}

