import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AppHelper } from '../helpers/app.helper';
import { EMPTY, Subject, catchError, takeUntil } from 'rxjs';
import { AppConfig, initialAppConfig } from '../models/appConfig';
import { AppState } from '../store/states/app.state';
import { Store } from '@ngrx/store';
import { configLoaded } from '../store/actions/app.actions';
import { selectAppState } from '../store/selectors/app.selectors';
import { selectPartecipantsStatus } from '../store/selectors/sessionStatus.selectors';
import { OPERATOR_ROLES, PartecipantRoles } from '../models/WebServer/partecipantRoles';
// import { Uuid } from "uuid-tool";
import { I18Service } from './i18.service';
import { HubConfig } from '../models/hubConfig';
import { PartecipantStatus } from '../models/WebServer/partecipantStatus';
import { PhoneStatusEnum } from '../models/phoneStatus';
import { BrowserHelper } from '../helpers/browser.helper';
import { AgencySettings, initialAgencySettings } from '../models/WebServer/agencySettings';
import { AppModesEnum } from '../models/WebServer/appModesEnum';
import { IsOnCallPipe } from '../helpers/isOnCall.pipe';
import { Constants } from '../constants';
import { LanguageCode } from '../models/languageCode';
import { stringify as uuidStringify } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService implements OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private appConfig: AppConfig = initialAppConfig
  private appState$: AppState | undefined;
  private  urlParams?: URLSearchParams
  // private sessionStatusState$: SessionStatusState | undefined

  public popupLabelNewDocument: string = '';
  public popupFilename: string = '';
  public popupUrl: string = '';
  
  
  public partecipantsStatus$: PartecipantStatus[]= [];
  public anyoneOnCall = false;
  public iamOnCall = false;

  static translationLanguages: string[] = [];


  public agencySettings: AgencySettings = initialAgencySettings;

  public partecipantId: string = '';
  public isCaller: boolean = false;
  public isReceiver: boolean = false;
  public isGuest: boolean = false;
  public isSuper: boolean = false;
  public static role: PartecipantRoles = PartecipantRoles.PrimaryClient;
  public static CHATHISTORY_RELOAD = 10; //seconds

  public isMobilePhone = BrowserHelper.isMobilePhone();

  private origLogDeug: { frontEndLogEnabled: boolean, frontEndLogLevel: string} = {
    frontEndLogEnabled: initialAppConfig.frontEndLogEnabled,
    frontEndLogLevel: initialAppConfig.frontEndLogLevel
  }
  private static _debugEnabled = false;
  static get debugEnabled() {return AppConfigService._debugEnabled; }
  public inDebug() { return AppConfigService._debugEnabled; }


  minGuestWidth: number = 700; 
  actualWidth: number = 700;

  constructor(private http: HttpClient, private store: Store<AppState>,/* private translate: TranslateService,*/ private translation: I18Service, private onCallPipe: IsOnCallPipe) {
    console.debug(`AppConfigService constructor`)
    this.minGuestWidth = parseInt(getComputedStyle(document.documentElement)
    .getPropertyValue('--guest-max-width').replace('px', '')); // 710px
    
    window.onresize = () => this.actualWidth = window.innerWidth;
    this.actualWidth = window.innerWidth;

    this.store.select(selectAppState).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.appState$ = data
      if (data ){this.agencySettings = data.session.config.agencySettings; }
    });

    // this.store.select(selectSessionStatus).pipe(
    //   takeUntil(this.destroy$)
    // ).subscribe(data => this.sessionStatusState$ = data);
    this.store.select(selectPartecipantsStatus).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.partecipantsStatus$ = data
      this.anyoneOnCall = data.some( z => [PhoneStatusEnum.CALLING, PhoneStatusEnum.RINGING, PhoneStatusEnum.REMOTE_RINGING, PhoneStatusEnum.TALKING].includes(z.phoneStatus))
      const myStatus = data.find(z => z.partecipantId == this.partecipantId);
      if (myStatus) this.iamOnCall = this.onCallPipe.transform(myStatus.phoneStatus, true);
    });

  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
  }


  load(): any {
    const jsonFile = `/assets/config.json?v=${AppHelper.getBuild()}`;

    const p = new Promise( (res, rej)=>{
      this.http.get(jsonFile).subscribe(
        (data: any)=>{
          this.setConfig(data).then( _ => res(true));
  
        }),
        catchError( (err: any) => {
          console.error(`Error loading config file: ${jsonFile}`,err);
          return EMPTY
        })
    });

    p.then( z => {
         // console.debug(`*********????????????????`,z);
    })

    this.loadLanguageCodes();


    return p;

  }

  private loadLanguageCodes() {
    const selfThis = this;
    const jsonFile = `/assets/i18n/language_codes.json?v=${AppHelper.getBuild()}`;
    this.http.get(jsonFile).subscribe(
      (data: any)=>{
        
        I18Service.setLanguageCodes(data as LanguageCode[]);
        console.log(`********* loaded ${data.length} languages codes`);

      }),
      catchError( (err: any) => {
        console.error(`Error loading config file: ${jsonFile}`,err);
        return EMPTY
      })

  }

  private async setConfig(data: AppConfig): Promise<any> {
    return new Promise( (res, rej) =>{

      const appConfig = { ...initialAppConfig, ...data, rtrProxy: {...initialAppConfig.rtrProxy, ...data.rtrProxy} };

      this.origLogDeug = {
        frontEndLogEnabled: appConfig.frontEndLogEnabled,
        frontEndLogLevel: appConfig.frontEndLogLevel
      }


      const queryString = window.location.search;
      this.urlParams = new URLSearchParams(queryString);
      const urlParams = this.urlParams;
      // console.log(`AppConfig loaded`, urlParams.get('c'));

      this.partecipantId = urlParams.get('caller') || urlParams.get('receiver') || urlParams.get('guest')|| urlParams.get('super') || '';
      if (!this.partecipantId) { // compact
        const compactGuid = urlParams.get('c') || urlParams.get('r') || urlParams.get('g')|| urlParams.get('s')
        if (compactGuid) {
          this.partecipantId = decodeCompactGuid(compactGuid);
        }
      }
      this.partecipantId = this.partecipantId?.toLowerCase();
      // if (!partecipantId) { // strange.., so.. ERROR
      //     errorInfo = new ErrorInfo();
      //     errorInfo.message = I18Service.get('POPUP.SESSIONNOTFOUNDBODY');
      //     errorInfo.showReloadButton = false;
      // }
      this.isCaller   = !!urlParams.get('c') || !!urlParams.get('caller')   || false;
      this.isReceiver = !!urlParams.get('r') || !!urlParams.get('receiver') || false;
      this.isGuest    = !!urlParams.get('g') || !!urlParams.get('guest')    || false;
      this.isSuper    = !!urlParams.get('s') || !!urlParams.get('super')    || false;
      
      
      const role: PartecipantRoles = (this.isReceiver)
        ? PartecipantRoles.PrimaryClient
        : (this.isCaller)
          ? PartecipantRoles.SecondaryClient
          : (this.isGuest)
            ? PartecipantRoles.GuestClient
            : PartecipantRoles.SuperClient;

      if (this.isSuper) this.isReceiver = true;

      console.log(`config loaded => partecipantId: ${this.partecipantId} - role: ${role}`);
      AppConfigService.role = role;

      // if (this.isReceiver) { // scambia le url per il server sip da contattare
      //   appConfig.rtrProxy.wssUrl = appConfig.rtrProxy.primaryClient.wssUrl;
      //   appConfig.rtrProxy.testUrl = appConfig.rtrProxy.primaryClient.testUrl;
      // }

      this.appConfig = {...appConfig};

      AppConfigService.translationLanguages = this.appConfig.translationLanguages;

      this.translation.setLanguage(appConfig.defaultLang).then(( lang )=>{
        console.log(`first translation loaded '${lang}'`)
        const initialLanguage = urlParams.get(Constants.PARAM_LANG);
        if (initialLanguage) { 
          this.translation.saveLanguage(initialLanguage).then((l:string) => {
            this.store.dispatch(configLoaded({config: appConfig, partecipant: {id: this.partecipantId, role}}))
            res(true);
          });
          return;
        }
        else {
          this.store.dispatch(configLoaded({config: appConfig, partecipant: {id: this.partecipantId, role}}))
          res(true)
        }
      })


    })
  }

  //use getter only on .ts not in html !!
  get apiBaseUrl() { return this.getValue('apiBaseUrl'); }
  get language() { return this.translation.getLanguage(); }
  
  get AS() { return this.appState$; } // rimuovere?? torna comodo in alcuni contesti e funzionano anche gli "aggiornamenti"
  get partecipantIds() { return this.appState$?.session.parameters.partecipantIds || [];}
  get sessionId() { return this.appState$?.session.sessionId; }
  
  get rtrProxy() { return this.appConfig.rtrProxy; }
  get frontEndLogLevel() { return this.appConfig.frontEndLogLevel; }
  get frontEndLogEnabled() { return this.appConfig.frontEndLogEnabled; }
  // get browserCompatibility() {return this.appState?.browserCompatibility; } //TODO: //dave da spostare su partecipantStatus
  get deviceControls() { return this.appConfig.deviceControls; }
  get operatorDeviceControls() { return this.appConfig.operatorDeviceControls; }
  get desiredImageSize() { return this.appConfig.desiredImageSize; }
  get geoPermissionTimeout() { return this.appConfig.geoPermissionTimeout; }
  get saveBusinessEvent() { return this.appConfig.saveBusinessEvent; }
  get operatorButtons() { return this.appConfig.operatorButtons; }
  get includeImagesInChat() { return this.appConfig.includeImagesInChat; }
  get minutesExtension() { return this.appConfig.minutesExtension; }

  // get countriesCodes() { return this.appConfig.countries.map(z => typeof z === 'string' ? z : z.flag)}
  get signalRHub(): HubConfig { return { 
    hubName: '',
    automaticReconnect: true, 
    url: `${this.appConfig.signalRUlr}?SM-PartecipantId=${this.partecipantId}`,  
    options: {headers:{'SM-PartecipantId': this.partecipantId}} 
  }; 
  }
  get myPartecipantStatus() { return this && this.partecipantsStatus$?.find(p => p.partecipantId == this.partecipantId); }
  get operatorsStatus() {return this && this.otherPartecipantsStatus.filter(z => OPERATOR_ROLES.includes(z.partecipantRole) ) || []; }
  get otherPartecipantsStatus() { return this && this.partecipantsStatus$?.filter(p => p.partecipantId != this.partecipantId) || []; }
  get otherPartecipantsOnCall() {
    return this.otherPartecipantsStatus
    .filter( z => !OPERATOR_ROLES.includes(z.partecipantRole)) // not operator
    .filter( z => this.onCallPipe.transform( z.phoneStatus)) // and already in call
  }

  
  get sessionConfig() { return this && this.appState$ && this.appState$?.session.config; }

  get showingVideo() { return this && this.appState$ && this.appState$.appModes.includes(AppModesEnum.Video); }

  getTargetPresence() { //TODO perde di significato quando multi
    
    if (!this || !this.partecipantsStatus$) {
      return undefined;
    }

    return (this.appState$?.isCaller)
      ? this.partecipantsStatus$?.some(z => z.partecipantRole == PartecipantRoles.PrimaryClient)
      : this.partecipantsStatus$?.some(z => z.partecipantRole == PartecipantRoles.PrimaryClient)    
  }

  getPartecipantsStatus() {return this && this.partecipantsStatus$ || []; }

  public logDebug(enable: boolean) {
    console.log(`remote log enabled: ${enable}`);
    if (enable) {
      this.appConfig.frontEndLogEnabled = true;
      this.appConfig.frontEndLogLevel = 'Error,Warning,Info,Debug';
      AppConfigService._debugEnabled = true;
    } else {
      this.appConfig.frontEndLogEnabled = this.origLogDeug.frontEndLogEnabled;
      this.appConfig.frontEndLogLevel = this.origLogDeug.frontEndLogLevel;
      AppConfigService._debugEnabled = false;
    }
  }

  public getUrlParam(k: string): string | null | undefined {
    return this.urlParams?.get(k);
  }

  private getValue(p: string): any {
    if (!this.appConfig) {
      throw Error('Config file not loaded!');
    }

    return this.appConfig[p as keyof AppConfig];

  }
}





function decodeCompactGuid(compactGuid: string): string {

  function replaceAll(original: string, find: string, replacement: string): string {
    const pieces = original.split(find);
    return pieces.join(replacement);
  }

  let str = replaceAll(compactGuid, "_", "/");
  str = replaceAll(str, "-", "+");
  str = `${str}==`;
  // `${compactGuid.replace("_", "/").replace("-", "+")}==`;
  let data = '';
  try { data = window.atob(str); } catch (e) {
    console.error(e);
    return '';
  }
  const array = Uint8Array.from(data, b => b.charCodeAt(0));
  let orderArray: number[] = [];
  if (array.length == 16) {
    orderArray = [
      array[3], array[2], array[1], array[0],
      array[5], array[4],
      array[7], array[6],
      array[8], array[9],
      array[10], array[11], array[12], array[13], array[14], array[15]
    ]
  }
  var decodedGuid = uuidStringify(orderArray);
  let guid = decodedGuid.toString();
  return guid;
}