export class DialogContent {
  public title: string;
  public htmlBody: string;
  public actions: DialogCustomActions[] = [];
  public disableClose: boolean;
  public showExitButton: boolean;

  constructor(title: string, htmlBody: string, disableClose: boolean = true, showExitButton: boolean = true, actions: DialogCustomActions[] = []) {
    this.title = title;
    this.htmlBody = htmlBody;
    this.disableClose = disableClose;
    this.actions = actions;
    this.showExitButton = showExitButton;
  }
}

export class DialogCustomActions {
  public actionText: string;
  public isSecondary: boolean;
  public returnValue: any;

  constructor(actionText: string, isSecondary: boolean, returnValue: any) {
    this.actionText = actionText;
    this.isSecondary = isSecondary;
    this.returnValue = returnValue;
  }
}
