export interface Device {
  value: any;
  text: string;
  deviceInfo: MediaDeviceInfo;
}

// export enum DeviceType {
//   AUDIO_IN,
//   AUDIO_OUT,
//   VIDEO
// }

export interface AvailableDevices {
    audioInput: Device[];
    audioOutput: Device[];
    video: Device[]
}

export const initialiAvailableDevices: AvailableDevices = {
  audioInput: [],
  audioOutput: [],
  video: []
}