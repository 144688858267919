import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../states/app.state";
import { createSignalRHub, findHub } from "ngrx-signalr-core";
import { map, tap, withLatestFrom } from "rxjs";
import { selectAppState } from "../selectors/app.selectors";
import * as appActions from "../actions/app.actions";
import * as sessionActions from "../actions/session.actions";
import * as sessionStatusActions from "../actions/sessionStatus.actions";
import { SipService } from "src/app/services/sip.service";
import { I18Service } from "src/app/services/i18.service";
import { AppHelper } from "src/app/helpers/app.helper";
import { NotificationService } from "src/app/services/notification.service";
import { OPERATOR_ROLES, PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";
import { PartecipantStatus, partToStr } from "src/app/models/WebServer/partecipantStatus";
import { AppModesEnum } from "src/app/models/WebServer/appModesEnum";
import { CheckerService } from "src/app/services/checker.service";
import { ManHelper } from "src/app/helpers/man.helper";
import { AppConfigService } from "src/app/services/app.config.service";
import { selectPartecipantsStatus, selectSessionStatusState } from "../selectors/sessionStatus.selectors";
import { MediaService } from "src/app/services/media.service";
import { Message } from "src/app/models/WebServer/message";
import { MarkingReadObject } from "src/app/models/WebServer/markingReadObject";
import { EventService } from "src/app/services/event.service";
import { MediaTypesEnum } from "src/app/models/WebServer/mediaTypesEnum";
import { initialSessionConfig } from "src/app/models/WebServer/sessionConfig";
import { SessionService } from "src/app/services/session.service";
import { FELoggerService, LogSource } from "src/app/services/feLogger.service";
// import { Uuid } from "uuid-tool";
import { v4 as uuidv4 } from 'uuid';

import { CreateEvent } from "src/app/models/WebServer/createEvent";
import { EventTypeEnum } from "src/app/models/WebServer/eventTypeEnum";
import { StatusChangeEvent } from "src/app/models/statusChangeEvent";
import { CommandEvent } from "src/app/models/commandEvent";
import { TextTranslationService } from "src/app/services/textTranslation.service";

const RETRY_COUNT = 5;
const RETRY_DELAY = 6000;
@Injectable()
export class AppEffects {

  constructor(
    private readonly eventService: EventService,
    private readonly checkerService: CheckerService,
    private readonly sessionService: SessionService,
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly sip: SipService,
    private readonly media: MediaService,
    private readonly config: AppConfigService,
    private readonly textTranslationService: TextTranslationService
  ) {
    console.debug(`AppEffects constructor`);
  }

  configLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.configLoaded),
    map((_) => sessionActions.sessionCheck())
  )
  );
  

  onSessionLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.sessionLoaded),
    // withLatestFrom(this.store.select(selectSignalrHub)),
    map((_) => createSignalRHub({...this.config.signalRHub, automaticReconnect: {
        nextRetryDelayInMilliseconds: (context) => {
          if(context.previousRetryCount > RETRY_COUNT -1 ) return null;
          console.log(`********* previousRetryCount ${context.previousRetryCount}`);
          this.store.dispatch(appActions.loadingMessage(
            {message: I18Service.get('SNACKBAR.RECONNECTIONATTEMPT', {current:context.previousRetryCount+1, max: RETRY_COUNT})}
          ));
          return RETRY_DELAY;
        }
      }
    }))

    
  ));

  wssSipInit$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.wssSipInit),
    tap(_ => { 
      this.sip.init(); 
      var firstLoad= true;

      const loadChatHistory = () => {

        this.eventService.getChatHistory(this.config.partecipantId).subscribe({
          next: (messages) => {
            console.log(`chat history loaded`);
            this.store.dispatch(sessionActions.chatHistoryLoaded({ messages}));

            if (firstLoad) {
              firstLoad = false;
              window.setTimeout(()=>{
                console.log(`load again chat history`);
                loadChatHistory();
              }, AppConfigService.CHATHISTORY_RELOAD * 1000) ;
            }
          },
          error: (error: any) => {
            // return of(sessionActions.sessionFailure({ error }));
            FELoggerService.error(`getChatHistory error`, LogSource.Default, error)
            NotificationService.showError(error + '');
          }
          
        })
      }
      loadChatHistory();
    })

  ), { dispatch: false });

  wssSipRegistered$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.wssSipRegistered),
    map((b) => {
      if (b.is) {
        this.store.dispatch(appActions.loadingMessage({message: ''}));
        NotificationService.showSuccess(I18Service.get('SNACKBAR.CONNECTED'));
        this.store.dispatch(appActions.notifyAlreadyConnected());

      } else {
        // this.store.dispatch(appActions.showErrorPage({ message: I18Service.get('POPUP.SESSIONEXPIREDTITLE') }))
        // NotificationService.swalWarn(, I18Service.get('POPUP.CLOSE'))
        NotificationService.showError(I18Service.get('SNACKBAR.DISCONNECTED'));
      }
      return sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.IsRegistered, val: b.is })
    })
  )
  );


  showErrorPage$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.showErrorPage),
    // withLatestFrom(this.store.select(selectSignalrHub)),
    tap((x) => {
      // chiude tutto quando mostra pagina di errore
      FELoggerService.info(`showErrorPage$ -> ${x.message}`)
      const hub = findHub(this.config.signalRHub);
      if (hub && hub.connectionId) {
        hub.stop(); // chiudere.. significa non poter inviare gli ultimi stati
      }
      this.checkerService.stop();
      this.sip.disconnect();
    })

  ), { dispatch: false });


  cmd_event$ = createEffect(() => this.actions$.pipe(
    ofType(sessionStatusActions.cmd_event),
    withLatestFrom(this.store.select(selectAppState), this.store.select(selectSessionStatusState)),
    tap(([{ evt, val }, state, sessionState]) => {
      console.log(`cmd_event$ - ${evt} => ${val}`);
      switch (evt) {
        case CommandEvent.AcceptedBrowser:
          if (val) { // operatore forza l'ok
            this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.BrowserControlResult, val: { success: true, message: '' } }));
          } else { // operatore rifiuta il browser non valido
            this.store.dispatch(appActions.showErrorPage({ message: I18Service.get('ERRORPAGE.UNSUPPORTEDDEVICE'), imagePath: '/assets/images/unsupported-device.png' }))
          }
          break;
        case CommandEvent.ChangeMode:
          NotificationService.closeMultimediaDialog();
          this.store.dispatch(appActions.appModeChange({ mode: val }));
          break;

        case CommandEvent.ToggleMicrophone:
          this.store.dispatch(appActions.toggleMicrophone());
          break;
        case CommandEvent.ToggleVideo:
          
          if(this.config.isCaller && !state.videoEnabled && !state.appModes.includes(AppModesEnum.Video)) { //il toggle lo abiliterà.. forza ad andare in video
              //const appModes = [...state.appModes];
              this.store.dispatch(appActions.appModeChange({mode: AppModesEnum.Video}));
          } else {
            this.store.dispatch(appActions.toggleVideo());
          }
          break;
        case CommandEvent.SwitchCamera:
          this.store.dispatch(appActions.switchCamera({forSnapshot: false}));
          break;          
        case CommandEvent.ToggleRemoteCommand:
          this.store.dispatch(appActions.toggleRemoteCommand({ enabled: val }));
          break;

        case CommandEvent.Session_Terminate:
          this.sip.endAllCalls();
          this.store.dispatch(appActions.sessionExpired());
          const errorMessage = I18Service.get('POPUP.SESSIONEXPIREDBODY');
          this.store.dispatch(appActions.showErrorPage({ message: errorMessage, showReload: false }))
          //     this.config.isCaller && this.eventEmitter.riseError(new ErrorInfo('', message, -1, undefined, false));
          break;

        case CommandEvent.GeoPermissionInstructions:

          ManHelper.getGeoMessageAccordingToBrowser(this.config.language)
            .finally(()=>{
              this.checkerService.newGeoLocAttempt();
            }); 
          
          break;
        case CommandEvent.MediaPermissionInstructions:
          ManHelper.getMediaMessageAccordingToBrowser(this.config.language);
          break;
        case CommandEvent.ForceRefresh:
          this.sip.endAllCalls();
          NotificationService.timedToastWarn(I18Service.get('LABELS.OPERATORASKREFRESH'), 'OK', 3000, 'top').then(() => {
            window.location.reload();
          });
          break;
        case CommandEvent.MediaMessageSent:
          const message = val as Message;
          this.store.dispatch(appActions.recvMediaMessage({ message }));

          if (message.type == MediaTypesEnum.Document) {
            const sender = sessionState.sessionStatus.partecipants.find(z => z.partecipantId == message.from);
            
            const who = sender ? whoIs(sender): I18Service.get(PartecipantRoles.PrimaryClient);
            this.config.popupFilename = message.fileName?.trim() || ''
            this.config.popupLabelNewDocument = I18Service.get('LABELS.NEWDOCUMENT').replace('__WHO__', who);
            this.config.popupUrl=message.content;
            NotificationService.showDocumentToDownload(message.content, message.fileName?.trim() || '', who);
          }


          break;
        case CommandEvent.MarkAsDelivered:
          this.store.dispatch(appActions.markMessageDelivered({ message: val }))
          break;
        case CommandEvent.MarkAllAsRead: //notifica che gli altri hanno letto: doppia spunta (in futuro si potrebbe controllare chi...)
          if (state.messages.some(m => m.me && !m.messageInfo?.readAt)) {
            this.eventService.markAllAsRead(state.partecipantId).subscribe({
              next: () => { this.store.dispatch(appActions.markMyMessageAsRead({ message: val })); }
            });

          }

          break;

        case CommandEvent.EnableDebug:
          const enable = val as boolean;
          this.config.logDebug(enable);
          break;
        case CommandEvent.ReloadSession:
          this.sessionService.getSession(state.partecipantId).subscribe({
            next: (session) => {
              this.store.dispatch(sessionActions.sessionReload({session}));
            },
            error: (error) => {
              const failure = (error.status == 404)
              ? { error: { message: I18Service.get('POPUP.SESSIONEXPIREDBODY') }, showReload: false }
              : { error };
      
              return this.store.dispatch(sessionActions.sessionFailure(failure))
            }
          })
          break;    
        case CommandEvent.SendMessage: //message arrived
          const msg: Message = val as Message;
          this.store.dispatch(appActions.recvTextMessage({ body: msg.content, contentLanguage: msg.contentLanguage, trInfo: msg.translationInfo, from: msg.from, rtpCallId: msg.rtpCallId }))
          break;

        case CommandEvent.VideoZoomTo:
          this.store.dispatch(appActions.zoomTo({id: val as string}));
          break;
        
        case CommandEvent.FitVideoTo:
          this.store.dispatch(appActions.fitVideoTo({id: val.id, val: val.value}));
          break;

        case CommandEvent.OperatorZoomId:
          this.store.dispatch(appActions.operatorZoomId({id: val}));
          break;          
      }
    })

  ), { dispatch: false });

  send_text_message$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.sendTextMessage),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([{ body, trInfo, toIds }, state]) => {

      const toPartecipants = toIds.includes(appActions.SEND_TO_ALL[0])
        ? state.session.parameters.partecipantIds.filter(z => z.partecipantId != state.partecipantId).map(z => z.partecipantId)
        : toIds

      // toPartecipants.forEach((p, idx) => {
      //   this.sip.sendMessage(body, p, idx === 0);
      // });
      const msg : Message = {
        content : body,
        contentLanguage: I18Service.getNavigatorLanguage(),
        from: state.partecipantId,
        idx: 0,
        timestamp: new Date(),
        translationInfo: trInfo,
        rtpCallId: uuidv4()//new Uuid().toString()         
      }

      this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.SendMessage, val: msg, toIds}));
    })
  ), { dispatch: false });

  updateMessage$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.updateMessage),
    tap(({message}) => {
      const event : CreateEvent = {
        data: {
          partecipantId : message.from,
          message : message.content,
          messageInfo: message.messageInfo,
          translationInfo: message.translationInfo,
        },
        eventType: EventTypeEnum.ChatMessage,
        rtpCallId: message.rtpCallId       
      }
      this.eventService.updateEvent(event).subscribe({
        next: (eventId : number) => {
          console.log(`updated message with eventId: ${eventId}`);
        },
        error: (e: any) => { 
          if(e.status != 404)
          NotificationService.showError(e.statusText); 
        }
      });
    })
  ), { dispatch: false })

  send_media_message$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.sendMediaMessage),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([{ content, mediaType, fileName, id, toIds }, state]) => {

      // const toPartecipants = toIds.includes(appActions.CHAT_TO_ALL)
      //   ? state.session.parameters.partecipantIds.filter(z => z.partecipantId != state.partecipantId).map(z => z.partecipantId)
      //   : toIds

      // toPartecipants.forEach((p, idx) => {
      //   this.sip.sendMessage(body, p, idx === 0);
      // });
      const m: Message = {
        idx: 0,
        from: state.partecipantId,
        content,
        timestamp: new Date(),
        type: mediaType,
        fileName,
        rtpCallId: id
      }
      this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.MediaMessageSent, val: m, toIds }))

      // invio anche a "se stesso" su chat a meno che non sia caller e ha inviato un immagine bug #62447
      if ( !(this.config.isCaller && mediaType == MediaTypesEnum.Image)) { 
        this.store.dispatch(appActions.recvMediaMessage({ message: m }));
      }

    })
  ), { dispatch: false });

  partecipantAdded = createEffect(() => this.actions$.pipe(
    ofType(sessionStatusActions.partecipantAdded),
    // withLatestFrom(this.store.select(selectAppState)),
    // tap(([{ partecipantStatus, sessionStatus }, state]) => {
    tap(({ partecipantStatus }) => {
      const who = whoIs(partecipantStatus);
      if (partecipantStatus.partecipantId != this.config.partecipantId) { // se non sono io
        
        NotificationService.showSuccess(`${who} ${I18Service.get('Connected')}`);

        //in caso di riconnessione.. eliminare (chiudere) eventuali chiamate attive
        console.log(`partecipantAdded - close any call with ${partToStr(partecipantStatus)}`)
        this.sip.endCall(partecipantStatus.partecipantId);

        if (this.config.isReceiver) { // se sono un operatore, setta il mio zoomid
          const id = this.config.AS?.operatorZoomId;
          this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.OperatorZoomId,val:id, toIds: [partecipantStatus.partecipantId]}))
        }
        // se si aggiunge un primary.. 
        // if (partecipantStatus.partecipantRole == PartecipantRoles.PrimaryClient) {
        //   var mode = AppHelper.getModeWhenPrimaryArrives(this.config!.sessionConfig || initialSessionConfig, this.config.AS!.role)
        //   if (mode) this.store.dispatch(appActions.appModeChange({ mode }));
        //   if (partecipantStatus.partecipantId != this.config.partecipantId && this.config.anyoneOnCall){
        //     // è entrato il primary.. siamo in chiamata.. chiamiamolo!
            
        //   }
        // }
      }
    })
  ), { dispatch: false });

  partecipantRemoved = createEffect(() => this.actions$.pipe(
    ofType(sessionStatusActions.partecipantRemoved),
    withLatestFrom(this.store.select(selectSessionStatusState)),
    tap(([{ partecipantStatus }, state]) => {
    //tap(({ partecipantStatus }) => {
      const who = whoIs(partecipantStatus);
      if (who) {
        NotificationService.showError(`${who} ${I18Service.get('Leave')}`);

        //se è uscito un operatore... e non ci sono altri operatori...
        if (OPERATOR_ROLES.includes(partecipantStatus.partecipantRole)) {
          const anyOperatorOnline = state.sessionStatus.partecipants.some( z=> [PartecipantRoles.PrimaryClient, PartecipantRoles.SuperClient].includes(z.partecipantRole));
          if(!anyOperatorOnline){
            
            const mode = AppHelper.getInitialAppMode(this.config.sessionConfig!, AppConfigService.role)
            const modes: AppModesEnum[] = (mode) ? [ mode ] : [];
            console.log(`Nessun operatore online, torno alla pagina iniziale ${modes} e chiudo chiamate`);
            this.store.dispatch(appActions.setAppModes({ modes }));
            this.sip.endAllCalls();
          }
        }

        if (this.config.isReceiver && partecipantStatus.partecipantRole == PartecipantRoles.SecondaryClient) { // è uscito il cittadino, ricaricare l'ultima posizione
          // console.log('********************************* reload last server position')
          this.store.dispatch(sessionActions.lastServerPositionLoad());
        }
      }
      this.sip.endCall(partecipantStatus.partecipantId); //chiude la eventuale chiamata con chi è uscito
    })
  ), { dispatch: false });

  alreadyConnected = createEffect(() => this.actions$.pipe(
    ofType(appActions.notifyAlreadyConnected),
    withLatestFrom(this.store.select(selectPartecipantsStatus)),
    tap(([_, partecipants]) => {// vediamo se ci sono "altri utenti connessi"
      partecipants.forEach(p => {
        if (p.partecipantId != this.config.partecipantId) {
          // const who = whoIs(p);
          // if(!this.config.isCaller) //evita notifiche al cittadino
          // NotificationService.showSuccess(`${who} ${I18Service.get('Connected')}`);

          // se si aggiunge un primary.. 
          if (p.partecipantRole == PartecipantRoles.PrimaryClient) {
            var mode = AppHelper.getModeWhenPrimaryArrives(this.config!.sessionConfig || initialSessionConfig, this.config.AS!.role)
            if (mode) this.store.dispatch(appActions.appModeChange({ mode }));
          }
        }
      })
    })
  ), { dispatch: false })

  switchCamera$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.switchCamera, appActions.useSelectedCamera),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([action, state]) => {
      const useSelectedCamera = action.type == appActions.useSelectedCamera.type 
      
      if (action.forSnapshot) {
        this.media.switchCameraForSnapshot(useSelectedCamera);
      }
      else {
        this.sip.switchCamera(useSelectedCamera);
      }
    })

  ), { dispatch: false });

  changeMediaDevice$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.changeMediaDevice),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([action, state]) => {

        this.sip.changeMediaDevice();
    })

  ), { dispatch: false });


  zoomTo$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.zoomTo),
    tap( (action) =>{
      this.media.setVideoZoom(action.id);
    })
  ), {dispatch: false})

  fitVideoTo$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.fitVideoTo),
    tap( (action) =>{
      this.media.fitVideoTo(action.id, action.val);
    })
  ), {dispatch: false})

  markMediaAsSeen$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.markMediaAsSeen),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([{ mediaIdx }, state]) => {

      const m = state.mediaMessages[mediaIdx];
      if (!m) return;
      var markObj: MarkingReadObject = {
        mediaLink: m.content,
        sessionId: state.session.sessionId
      }
      this.eventService.markMediaAsSeen(markObj).subscribe({
        error: (e) => { console.log(`markMediaAsSeen ERROR`, e) }
      })

    })

  ), { dispatch: false })

  chatHistoryLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.chatHistoryLoaded),
    map(() => sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.MarkAsDelivered, val: 'all', toIds: appActions.SEND_TO_ALL }))
  ))

  markMessageAsRead$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.markMessageAsRead),
    map(({ message }) => sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.MarkAllAsRead, val: message, toIds: appActions.SEND_TO_ALL }))

  ))
  recvTextMessage$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.recvTextMessage),
    withLatestFrom(this.store.select(selectAppState)),
    map(([{ from, body, trInfo, contentLanguage, rtpCallId }, state]) => {

      if (state.translationAuto) {// abilitata autotranslate.. e non è stato ancora tradotto nella mia lingua
        if (!(trInfo && trInfo.some(z => z.targetLanguage == this.config.language && z.startingLanguage == contentLanguage))) {
          const message = state.messages.find(z => z.rtpCallId == rtpCallId);
          if (message) {

            this.textTranslationService.translateChatMessage(message, state.translationSelectedLanguage.code, true);
          }
        }
      }



      const m: Message = { idx:0, content: body, from, timestamp: new Date() }
      return sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.MarkAsDelivered, val: m, toIds: [from] })
    })
  ))
  recvMediaMessage$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.recvMediaMessage),
    map(({ message }) => {
      return sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.MarkAsDelivered, val: message, toIds: [message.from] })
    })
  ))


  myPartecipantStatusChange = createEffect(() => this.actions$.pipe(
    ofType(sessionStatusActions.partecipantStatusChange),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([{ partecipantStatus }, appState]) => {
      if (appState.partecipantId == partecipantStatus.partecipantId) {
        //console.log("************* myAppStateChange");
        this.store.dispatch(appActions.myAppStateChange({partecipantStatus}));
      }
    })
  ), { dispatch: false })
}


function whoIs(partecipantStatus: PartecipantStatus): string {

  return I18Service.roleAndAlias(partecipantStatus);
}
