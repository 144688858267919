import { createAction, props } from '@ngrx/store';
import { AppConfig } from '../../models/appConfig';
import { PageStatusEnum } from 'src/app/helpers/app.helper';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
import { PartecipantRoles } from 'src/app/models/WebServer/partecipantRoles';
import { MediaTypesEnum } from 'src/app/models/WebServer/mediaTypesEnum';
import { Message } from 'src/app/models/WebServer/message';
import { PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';
import { AvailableDevices } from 'src/app/models/device';
import { TranslationInfo } from 'src/app/models/WebServer/translationInfo';
import { LanguageCode } from 'src/app/models/languageCode';

export const SEND_TO_ALL = ['*']

export const configLoaded = createAction('[App] config loaded', props<{ config: AppConfig, partecipant: { id: string, role: PartecipantRoles } }>());
export const loadingMessage = createAction('[App] loading message', props<{ message: string }>());

export const showErrorPage = createAction('[App] show error page', props<{ message: string, imagePath?: string, showReload?: boolean }>());


export const signalrHubConnected = createAction('[App] signalr connected hub', props<{ connectionId: string }>());

export const wssSipInit = createAction('[App] wss sip init');
export const wssSipConnected = createAction('[App] wss sip onnected');
export const wssSipRegistered = createAction('[App] wss sip registered', props<{ is: boolean }>());
export const wssSipDisconnect = createAction('[App] wss sip disconnect');

export const pageStateChange = createAction('[App] page state change', props<{ nextState: PageStatusEnum }>())

//da rivedee se qui o dove (sposstato con evt_changestat)
// export const geolocationPermission = createAction('[App] geolocationPermission', props<{value: number}>());

export const appModeChange = createAction('[App] app mode change', props<{ mode: AppModesEnum }>());
export const setAppModes = createAction('[App] set app mode', props<{ modes: AppModesEnum[] }>());

export const sendTextMessage = createAction('[App] send text message', props<{ body: string, trInfo: TranslationInfo[] | undefined, toIds: string[] }>());
export const recvTextMessage = createAction('[App] recv text message', props<{ body: string, contentLanguage?: string | undefined, trInfo?: TranslationInfo[] | undefined, from: string, rtpCallId?: string}>());
export const updateMessage = createAction('[App] update text message', props<{ message: Message }>()); 
export const changeLanguage = createAction('[App] change current language', props<{ lang: string }>()); 
export const translationSelectedLanguage = createAction('[App] translation SelectedLanguage', props<{ lang: LanguageCode }>()); 
export const translationAuto = createAction('[App] translation auto', props<{ auto: boolean }>()); 

export const sendMediaMessage = createAction('[App] send Media message', props<{ content: string, mediaType: MediaTypesEnum, fileName: string, id: string, toIds: string[] }>());
export const recvMediaMessage = createAction('[App] recv Media message', props<{ message: Message }>());

export const retrieveDevices = createAction('[App] Retrieve devices', props<{ availableDevices: AvailableDevices }>());


export const toggleRemoteCommand = createAction('[App] toggle remoteCommand', props<{ enabled: boolean | null }>());
export const mirrorUser = createAction('[App] Mirror User', props<{ enabled: boolean }>());
export const toggleMicrophone = createAction('[App] toggle microphone');
export const toggleVideo = createAction('[App] toggle video');
export const openVideoSection = createAction('[App] open video section'); // only receiver

export const switchCamera = createAction('[App] switch camera', props<{ forSnapshot: boolean }>());
export const useSelectedCamera = createAction('[App] use selected camera', props<{ forSnapshot: boolean }>());

export const zoomTo = createAction('[App] zoomTo', props<{id: string}>())
export const fitVideoTo = createAction('[App] fitVideoTo', props<{id: string, val: boolean}>())
export const operatorZoomId = createAction('[App] operator zoom id',props<{id: string}>());

export const sessionExpired = createAction('[App] session expired');
export const batteryLevel = createAction('[App] battery level', props<{ level: string }>());
export const connectionSpeed = createAction('[App] connection speed', props<{ speed: string }>());

export const notifyAlreadyConnected = createAction('[App] notify already connected');


export const markMediaAsSeen = createAction('[App] mark media as seen', props<{ mediaIdx: number }>());

export const markMessageDelivered = createAction('[App] mark message delivered', props<{ message: Message | string }>());

export const markMessageAsRead = createAction('[App] mark message read', props<{ message: Message | string }>());
export const markMyMessageAsRead = createAction('[App] mark my message read', props<{ message: Message | string }>());

export const myAppStateChange = createAction('[App] my app state change', props<{partecipantStatus: PartecipantStatus}>())

export const videoFull = createAction('[App] video full', props<{id: string}>())

export const streamVideoDraw = createAction('[App] stream video draw', props<{enabled: boolean}>())

export const changeMediaDevice = createAction('[App] change media device');