import { Action, createReducer, on } from "@ngrx/store";
import * as sessionStatusActions from "../actions/sessionStatus.actions";
import { SessionStatusOperation, SessionStatusState, initialSessionStatusState } from "../states/sessionStatus.state";
import { SessionStatus } from "src/app/models/WebServer/sessionStatus";
import { PartecipantStatus, partToStr } from "src/app/models/WebServer/partecipantStatus";



const sessionStatusStateReducer = createReducer(
    initialSessionStatusState,

    on(sessionStatusActions.partecipantAdded, (state: SessionStatusState, { partecipantStatus, sessionStatus }) => {
        console.log(`partecipant added => ${partToStr(partecipantStatus)}: `, partecipantStatus);
        const operation = SessionStatusOperation.ADDED;

        return {
            ...state,
            ...{ partecipantStatus, sessionStatus, operation }
        }
    }),
    on(sessionStatusActions.partecipantRemoved, (state: SessionStatusState, { partecipantStatus, sessionStatus }) => {
        console.log(`partecipant removed => ${partToStr(partecipantStatus)}: `, partecipantStatus);

        const operation = SessionStatusOperation.REMOVED;
        return {
            ...state,
            ...{ partecipantStatus, sessionStatus, operation }
        }
    }),
    on(sessionStatusActions.partecipantStatusChange, (state: SessionStatusState, { partecipantStatus, sessionStatus }) => {
        // console.log(`partecipant status change => partecipant: `, partecipantStatus);
        console.log(`partecipant change => ${partToStr(partecipantStatus)}: `, partecipantStatus);
        const operation = SessionStatusOperation.UPDATED;

        // const newPartecipants = [...sessionStatus.partecipants].map( u => u.partecipantId !== partecipantStatus.partecipantId ? u : partecipantStatus )
        const newSessionStatus: SessionStatus = {
            ...state.sessionStatus, ...{
                partecipants: sessionStatus.partecipants.map(p => {
                    const oldP = state.sessionStatus.partecipants.find(z => z.partecipantId === p.partecipantId)
                    if (oldP && itemEqual(p, oldP)) return oldP;
                    return p;
                })
            }
        };
        return {
            ...state,
            ...{ partecipantStatus, sessionStatus: newSessionStatus, operation }
        }
    }),
    // on(sessionStatusActions.disclaimer, (state: SessionStatusState, {disclaimer})=> {
    //     const partStatus = {...state.partecipantStatus};
    //     partStatus.disclaimerAccepted = disclaimer;
    //     return {
    //         ...state,
    //         ...{partecipantStatus: partStatus, operation: SessionStatusOperation.UPDATED}
    //     }
    // })
    // on (sessionStatusActions.browserCompatibility, (state: SessionState, {caller, operator}) => {
    //     return {
    //         ...state,
    //         ...{ browserCompatibility: caller, operato}
    //     }
    // })
    // on(sessionActions.updatePartecipantParameters, (state: SessionState, parameters) => {
    //     const partecipantStatus: PartecipantStatus = {...state.status.partecipantStatus, ...{parameters}};
    //     const sessionStatus: SessionStatus = {...state.status.sessionStatus};
    //     return {
    //         ...state,
    //         ...{status: { partecipantStatus, sessionStatus, operation: SessionStatusOperation.UPDATED }}
    //     }
    // })
);

export function sessionStatusReducer(state: SessionStatusState | undefined, action: Action) {
    return sessionStatusStateReducer(state, action);
}

function itemEqual(p: PartecipantStatus, oldP: PartecipantStatus) {
    return JSON.stringify(p) == JSON.stringify(oldP);
}
