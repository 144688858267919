import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { I18Service } from '../services/i18.service';
import { PartecipantRoles } from '../models/WebServer/partecipantRoles';
import { PartecipantStatus } from '../models/WebServer/partecipantStatus';
import { AppHelper } from './app.helper';
import { AppConfigService } from '../services/app.config.service';
import { ParsedUA } from '../models/parsedUA';
import { BrowserHelper } from './browser.helper';

@Pipe({
  name: 'isMobilePhone'
})
export class IsMobilePhonePipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer, private config: AppConfigService) { }

  public transform(parsedUA : ParsedUA | undefined = undefined): boolean {

    if( !parsedUA ) return false;

    return BrowserHelper.isMobilePhone(parsedUA);
  }
}
