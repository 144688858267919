<div id="gallery-container">
  <div id="gallery" class="loading" *ngIf="AS.mediaMessages && AS.mediaMessages.length > 0">
    <div class="photoContainer" *ngFor="let message of AS.mediaMessages; let i = index"
      [hidden]="i != currentPhoto">
      <img ngDefaultControl (click)="setCurrentImageAsRead()" *ngIf="i == currentPhoto" class="img-item"
        [src]="message?.content | safeResourceUrl" (error)="manageImageError($event, message)" (load)="imageLoaded()"/>
    </div>
  </div>
  <div id="carosel-manager" *ngIf="AS.mediaMessages && AS.mediaMessages.length > 0">
    <b id="img-counter">{{currentPhoto + 1}}/{{AS.mediaMessages.length}}</b>
    <div id="carosel-buttons">
      <button mat-icon-button (click)="prevPhoto()" [matTooltip]="'LABELS.BACK' | translate">
        <span class="font-icon-button material-symbols-outlined">arrow_back</span>
      </button>
      <!-- <div> -->
        <button mat-icon-button (click)="downloadImage(currentPhoto)" [matTooltip]="'LABELS.DOWNLOAD' | translate"
          [matBadge]="'New' | translate" [matBadgeHidden]="!badgeBlink" matBadgeColor="warn" matBadgeSize="large">

          <span class="font-icon-button material-symbols-outlined">file_download</span>
        </button>
      <!-- </div> -->
      <button mat-icon-button (click)="nextPhoto()" [matTooltip]="'LABELS.FORWARD' | translate">
        <span class="font-icon-button material-symbols-outlined">arrow_forward</span>
      </button>
    </div>
  </div>
  <div class="basic-font-medium" id="no-media-screen" *ngIf="AS.mediaMessages && AS.mediaMessages.length <= 0">
    <span>{{'LABELS.NOMEDIA' | translate}}</span>
  </div>
</div>