import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ErrorInfo } from 'src/app/models/errorInfo';

@Component({
  selector: 'app-business-error',
  templateUrl: './business-error.component.html',
  styleUrls: ['./business-error.component.scss']
})
export class BusinessErrorComponent implements OnInit, OnDestroy {
  @Input() errorInfo: ErrorInfo = new ErrorInfo();
  
  constructor( ) { }

  ngOnDestroy(): void { }

  ngOnInit() { }

  reload() { window.location.reload(); }
}
