<div class="chat-container"><!-- (click)="comunicateAllRead()" > -->
  <mat-card class="main-card">
    <div id="chat-list-container" [hidden]="!AS.messages || !(AS.messages.length > 0)" #messagelist ngx-auto-scroll lock-y-offset="10">
      <mat-list  class="chat-list"  >
        <mat-list-item ngDefaultControl class="chat-list-item" *ngFor="let message of AS.messages; let i = index">
          <div class="chat-element" [ngClass]="message.me ? 'my-message' : 'other-message chat_' + message.role"
              *ngIf="{translation: !message.type && message.translatedInfo && (message.me && !config.isCaller || !message.me)} as show">
            <div class="chat-element-content" 
                [ngClass]="{'first': i==0 || message.from != AS.messages[i-1].from}"
                [ngStyle]="{'filter': message | guestChatColor}" >
              <label class="chat-header">
                <span *ngIf="(message.timestamp | date: 'dd/MM/yyyy') != (today | date: 'dd/MM/yyyy')">[{{message.timestamp | date: 'dd/MM/yyyy'}}]&nbsp;</span>
                {{message.alias}}
              </label>          
              <pre class="chat-content" *ngIf="show.translation"
                [ngStyle]="{'color': (message.translatedInfo | confidenceColor).color, 'filter': message | guestChatColor : true}"
                [matTooltip]="(message.translatedInfo | confidenceColor).tooltip" [matTooltipDisabled]="config.isCaller"
                >{{message.translatedInfo?.translatedText}}</pre>
              <pre class="chat-content" [ngClass]="show.translation ? 'chat-translated' : ''" *ngIf="!message.type">{{message.content}}</pre>            
              <!-- <div class="chat-content" *ngIf="message?.type == MediaTypesEnum.Document" (click)="appHelper.openTab(message.content)">
                  <span> {{message.fileName?.trim()}} &nbsp; 
                    <img class="doc-type-icon" [src]="message | textMessageIcon" 
                      [matTooltip]="'TTP.DOWNLOAD_ATTACHMENT' | translate"
                      matTooltipShowDelay="800" />
                  </span>
              </div>             -->
              <div class="chat-content" *ngIf="message?.type == MediaTypesEnum.Document && message.fileName?.trim()">
                <a class="doc-type-link" [download]="message.fileName?.trim()" [href]="message.content" target="_blank"> 
                  <img class="doc-type-icon" [src]="message | textMessageIcon" 
                    [matTooltip]="'TTP.DOWNLOAD_ATTACHMENT' | translate"
                    [ngStyle]="{'filter': message | guestChatColor : true}"
                    matTooltipShowDelay="800" />
                    {{message.fileName?.trim()}} 
                </a>
            </div>              
              <pre class="time-info">{{message.timestamp | date: 'HH:mm'}}</pre>
              <svg xmlns="http://www.w3.org/2000/svg" *ngIf="message.me && message?.messageInfo?.deliveredAt"
                viewBox="0 0 250 250" class="tick"
                [innerHtml]="message?.messageInfo?.deliveredAt && message?.messageInfo?.readAt ? (svgConstants.doppio_baffo | safeHtml) :
                  (message?.messageInfo?.deliveredAt ? (svgConstants.baffo | safeHtml) : '')">
              </svg>
              <div class="chatTranslate" *ngIf="(AS.session.config.agencySettings)?.enableTranslation && (!autoTranslateChecked) && !config.isCaller && !message.me && message.type != MediaTypesEnum.Document && !message.translatedInfo">
                <div class="button-translate" *ngIf="(AS.session.config.agencySettings)?.showDetectAndTranslateChat"
                    matTooltipShowDelay="800" [matTooltip]="'LABELS.TRANSLATE' | translate"
                    (click)="detectAndTranslateChatMessage(message)" [ngStyle]="{'filter': message | guestChatColor : true}">
                  <span class="font-icon-button material-symbols-outlined" >tour</span>
                </div>
                <div class="button-translate" *ngIf="(AS.session.config.agencySettings)?.showTranslateChat"
                    matTooltipShowDelay="800" [matTooltip]="'LABELS.REQUESTRANSLATE' | translate"
                    (click)="translateChatMessage(message)" [ngStyle]="{'filter': message | guestChatColor : true}">
                  <span class="font-icon-button material-symbols-outlined" >translate</span>
                </div>
              </div>
            </div>   
          </div>
        </mat-list-item> 
      </mat-list>
      <button mat-mini-fab class="scroll-bottom-button" aria-label="Scroll Bottom" (click)="scrollBottom()"
          [disabled]="disabled"
          [hidden]="!scrollBottomVisible"
          [matTooltip]="'LABELS.LASTMESSAGE' | translate">
        <span class="material-symbols-outlined" id="scroll-bottom-id">keyboard_arrow_down</span>
      </button>
    </div>
    <div class="basic-font-medium" id="no-chat-message" *ngIf="AS.messages && AS.messages.length <= 0">
      <span>{{( AS.messagesLoaded ? 'LABELS.NOCHAT' :'LABELS.RETRIEVINGCHAT') | translate}}</span>
    </div>
    <div id="chatbox">
      <div id="translationbox" *ngIf="(AS.session.config.agencySettings)?.enableTranslation && !config.isCaller" [hidden]="true && !showTranslationBox">

        <div id="translationtype" [ngClass]="autoTranslateChecked?'add-flex1':'hide-label'">{{(autoTranslateChecked?'chat.autoTranslation':'')|translate}}</div>
        <div class="chat-footer-translation-container" [ngClass]="autoTranslateChecked?'remove-flex1':''">
          <div style="display: flex;" [matTooltip]="'LABELS.CLEARMESSAGE' | translate">
          </div>
          <span id="translate-text" #translateText class="textarea" [hidden]="autoTranslateChecked"
            [attr.data-hint]="'chat.translationOnDemand' | translate"
            ngDefaultControl [contentEditable]="!(disabled || autoTranslateChecked || !inputMessage) ? contentEditable : false">
          </span>     <!---->
          <mat-checkbox class="auto-translation-checkbox"
            [matTooltip]="'chat.autoTranslation'|translate"
            (change)="changeAutoTranslate($event.checked)"
            [disabled]="false && selectedLanguageCode.language == config.language"
            [(ngModel)]="autoTranslateChecked" >
          </mat-checkbox>            
          <ngx-flag-picker
            [selectedLanguageCode]="selectedLanguageCode.language"
            [languageList]="languageList"
            [buttonDisabled]="disabled || autoTranslateChecked || !inputMessage"
            [selectDisabled]="autoTranslateChecked"
            (changedLanguageCode)="changeSelectedLanguageCode($event)"
            (flagClickEvent)="translateSendMessageClick()"
            >            
          </ngx-flag-picker>                 
        </div>  
      </div>
      <div class="chat-footer-container" [ngClass]="showTranslationBox && !autoTranslateChecked ? 'remove-top-corner': showTranslationBox?'remove-top-right-corner':''">
        <div style="display: flex;" [matTooltip]="'LABELS.CLEARMESSAGE' | translate">
          <button mat-icon-button class="clear-message-button" aria-label="Clear Message" (click)="clearMessage()" [disabled]="disabled || !inputMessage">
            <span class="font-icon-button material-symbols-outlined">clear</span>
          </button>
        </div>
        
        <span style="color: black;" id="chat-text" #chatText class="textarea" 
          ngDefaultControl [contentEditable]="!disabled ? contentEditable : false" [attr.data-hint]="'Chat.Edit-Hint' | translate"
          (keyup)="spanKeyPress($event)" (paste)="managePaste()">
        </span>
        <div style="display: flex;" >
          <div class="translation-container" *ngIf="(AS.session.config.agencySettings)?.enableTranslation && !config.isCaller">

            <!-- <button mat-icon-button class="open-translate-message-button" aria-label="Translate Message" (click)="translateMessage()"
              [disabled]="disabled || !inputMessage || autoTranslateChecked" >
              <span class="font-icon-button material-symbols-outlined" [matTooltip]="'chat.translation' | translate">translate</span>
            </button> -->
            <div [matTooltip]="'chat.showTanslation' | translate">
              <button mat-icon-button class="open-translate-message-button" aria-label="Translate Message" (click)="showTranslationBox=!showTranslationBox"
                [disabled]="disabled">
                <span class="font-icon-button material-symbols-outlined">letter_switch</span>
              </button>
            </div>
            <div [matTooltip]="'LABELS.REQUESTRANSLATE' | translate" [ngClass]="'border-left'">
              <button mat-icon-button class="open-translate-message-button" aria-label="Translate Message" (click)="translateSendMessageClick()"
                [disabled]="!inputMessage"
                *ngIf="!(disabled || autoTranslateChecked || !showTranslationBox)">
              <span class="font-icon-button material-symbols-outlined">double_arrow</span>
            </button>
          </div>
          </div>
          <div [matTooltip]="'LABELS.SENDMESSAGE' | translate">
            <button mat-icon-button class="send-message-button" aria-label="Send Message" (click)="sendSpanMessageWithButton()"
              
              [disabled]="disabled || !inputMessage">
              <span class="font-icon-button material-symbols-outlined">send</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
