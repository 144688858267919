<div id="dialog-container">
  <div id="dialog-title">
    <span>{{ data.title}}</span>
    <div *ngIf="data.showExitButton" [mat-dialog-close]="undefined">
      <span id="close-icon" class="material-symbols-outlined icon-close-button">close</span>
    </div>
  </div>

  <div class="dialog-body" id="dialog-body-{{bodyUniqueID}}">
  </div> 

  <div *ngIf="!data.actions || data.actions.length == 0" id="dialog-actions">
    <button mat-raised-button class="primary-button" (click)="close(undefined)">
      {{ 'POPUP.CLOSE' | translate }}
    </button>
  </div>

  <div id="dialog-actions">
    <button mat-raised-button *ngFor="let btn of data.actions" class="common-button" (click)="close(btn.returnValue)"
    [ngClass]="{'primary-button': !btn.isSecondary, 'secondary-button': btn.isSecondary}">
      {{ btn.actionText }}
    </button>
  </div>
</div>
