<div id="multimedia-window">
  <div class="multimedia-title">
    <span>{{ 'LABELS.CHOOSEMULTIMEDIA' | translate }}</span>
    <div [mat-dialog-close]="undefined">
      <span class="material-symbols-outlined icon-close-button">close</span>
    </div>
    <!-- <span class="font-icon-button material-symbols-outlined" (click)="openAccessibilityDialog()" id="icon-disability-button">accessibility_new</span> -->
  </div>
  <div class="multimedia-row">
    <button mat-raised-button class="button-style" aria-label="Chat" [mat-dialog-close]="appModes.Chat"
      [matBadge]="(AS.unreadMessage )" [matBadgeHidden]="!(AS.unreadMessage)" matBadgePosition="above after" matBadgeColor="warn"
      [disabled]="(AS.commandsDisabled) || !AS.session.config.chat">
      <div class="icon-text">
        <span class="font-icon-button material-symbols-outlined">chat</span>
        <span class="button-text">{{ 'LABELS.CHAT' | translate }}</span>
      </div>
    </button>
  </div>
  <div class="multimedia-row">
    <button mat-raised-button class="button-style" aria-label="Photo" [mat-dialog-close]="appModes.Photo"
      [disabled]="(AS.commandsDisabled)|| !AS.session.config.customerAttachments">
      <div class="icon-text">
        <span class="font-icon-button material-symbols-outlined">photo_camera</span>
        <span class="button-text">{{ 'LABELS.SENDPHOTO' | translate }}</span>
      </div>
    </button>
    <button mat-raised-button class="button-style" aria-label="Video" [mat-dialog-close]="appModes.Video"
      [disabled]="(AS.commandsDisabled) || !AS.session.config.video">
      <div class="icon-text">
        <span class="font-icon-button material-symbols-outlined">videocam</span>
        <span class="button-text">{{ 'LABELS.VIDEOCALL' | translate }}</span>
      </div>
    </button>
  </div>
  <div class="multimedia-row">
    <button mat-raised-button class="button-style" aria-label="Phone" [mat-dialog-close]="'mic'"
      [disabled]="(AS.commandsDisabled) || !AS.session.config.audio">
      <div class="icon-text">
        <span class="font-icon-button material-symbols-outlined">{{!(AS.microphoneEnabled) ? 'mic' : 'mic_off'}}</span>
        <span class="button-text">{{ (!(AS.microphoneEnabled) ? 'LABELS.ENABLEMIC' : 'LABELS.DISABLEMIC') | translate }}</span>
      </div>
    </button>
  </div>
</div>
