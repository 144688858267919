import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogContent } from 'src/app/models/dialogContent';
// import { UuidTool } from 'uuid-tool';
import { v4 as uuidv4 } from 'uuid';



@Component({
  selector: 'app-dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.scss']
})
export class DialogBodyComponent implements OnInit {
  bodyUniqueID: string

  constructor(public dialogRef: MatDialogRef<DialogBodyComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogContent) {
    this.bodyUniqueID =  uuidv4();// UuidTool.newUuid();;
  }

  ngOnInit(): void {
    setTimeout(() => {
      const bodyEle = document.getElementById(`dialog-body-${this.bodyUniqueID}`);
      bodyEle && (bodyEle.innerHTML = this.data.htmlBody);
    }, 300);
  }

  close(returnValue: any) {
    this.dialogRef.close(returnValue);
  }
}
