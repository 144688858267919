import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { I18Service } from '../services/i18.service';
import { PartecipantStatus } from '../models/WebServer/partecipantStatus';
import { AppConfigService } from '../services/app.config.service';

@Pipe({
  name: 'permissionStatusTooltip'
})
export class PermissionStatusTooltipPipe implements PipeTransform {

  private SECONDARYSTATUS?: PartecipantStatus;
  constructor(protected sanitizer: DomSanitizer, private config: AppConfigService) { }

  public transform(secondaryStatus: PartecipantStatus | undefined): string  {
    this.SECONDARYSTATUS = secondaryStatus;
    let ttip = '';
    if (this.SECONDARYSTATUS?.geoLocPermissionGiven != 1 || this.SECONDARYSTATUS?.mediaPermissionGiven != 1 || this.SECONDARYSTATUS?.microphonePermissionGiven != 1)
      ttip = `${I18Service.get('LABELS.NEEDPERMISSIONS')}\n`;
    else
      ttip = `${I18Service.get('LABELS.PERMISSIONOK')}\n`;

    ttip += `${this.getGeoPermissionListItemTooltip()}\n`;
    ttip += `${this.getMediaPermissionListItemTooltip()}\n`;
    ttip += `${this.getMicrophonePermissionListItemTooltip()}\n`;
    ttip += `${this.getFinalTipTooltip()}`;
    

    // console.log(`===== geo:${this.SECONDARYSTATUS?.geoLocPermissionGiven}, video:${this.SECONDARYSTATUS?.mediaPermissionGiven}, audio:${this.SECONDARYSTATUS?.microphoneEnabled} ==> ttp:${ttip}`)

    return ttip;
  }

  private getGeoPermissionListItemTooltip(): string {
    var neededPermission = ``;
    if (this.SECONDARYSTATUS?.geoLocPermissionGiven != 1 || this.SECONDARYSTATUS?.mediaPermissionGiven != 1 || this.SECONDARYSTATUS?.microphonePermissionGiven != 1) {
      var status = this.getStatus(this.SECONDARYSTATUS?.geoLocPermissionGiven);
      neededPermission = `- ${I18Service.get('LABELS.GEOPERMISSION')} > ${status}`;      
    }
    return neededPermission;
  }

  private getMediaPermissionListItemTooltip(): string {
    var neededPermission = ``;
    if (this.SECONDARYSTATUS?.geoLocPermissionGiven != 1 || this.SECONDARYSTATUS?.mediaPermissionGiven != 1 || this.SECONDARYSTATUS?.microphonePermissionGiven != 1) {
      var status = this.getStatus(this.SECONDARYSTATUS?.mediaPermissionGiven);
      neededPermission = `- ${I18Service.get('LABELS.MEDIAPERMISSION')} > ${status}`;
    }
    return neededPermission;
  }

  private getMicrophonePermissionListItemTooltip(): string {
    var neededPermission = ``;
    if (this.SECONDARYSTATUS?.geoLocPermissionGiven != 1 || this.SECONDARYSTATUS?.mediaPermissionGiven != 1 || this.SECONDARYSTATUS?.microphonePermissionGiven != 1) {
      var status = this.getStatus(this.SECONDARYSTATUS?.microphonePermissionGiven);
      neededPermission = `- ${I18Service.get('LABELS.MICROPHONEPERMISSION')} > ${status}`;
    }
    return neededPermission;
  }

  private getFinalTipTooltip(): string {
    var neededPermission = ``;
    if (this.SECONDARYSTATUS?.geoLocPermissionGiven != 1 || this.SECONDARYSTATUS?.mediaPermissionGiven != 1) {
      neededPermission = `${I18Service.get('LABELS.GIVETIPFORPERMISSION')}\n`;
    }
    return neededPermission;
  }

  private getStatus(permission: number | undefined): string {
    var status = (!permission || permission == 0) ? I18Service.get('LABELS.UNKNOWN') :
        (permission == 2 ? I18Service.get('LABELS.DENIED') : I18Service.get('LABELS.GIVEN'));
    return status
  }
}
