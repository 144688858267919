import { SessionParameters, initialSessionParameters } from './sessionParameters';
import { SessionTypes } from './sessionTypes';
import { PartecipantLink } from './partecipantLink';
import { SessionConfig, initialSessionConfig } from './sessionConfig';

export type Session = {
  sessionId: string;
  tenantId: string;
  expiration: Date;
  parameters: SessionParameters;
  type: SessionTypes;
  links: PartecipantLink[];
  agency: string;
  entityCode: string;
  entityLabel: string;
  subEntityCode: string;
  subEntityLabel: string;
  config: SessionConfig;
}

export const initialSession: Session = {
  sessionId: '',
  tenantId: '',
  expiration: new Date(),
  parameters: initialSessionParameters,
  type: SessionTypes.full,
  links: [],
  agency: '',
  entityCode: '',
  entityLabel: '',
  subEntityCode: '',
  subEntityLabel: '',
  
  config: initialSessionConfig

}