import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { AppState } from "../states/app.state";
import { EMPTY, Observable, catchError, last, map, merge, mergeMap, of, withLatestFrom } from "rxjs";
import { findHub, hubNotFound, ofHub, mergeMapHubToAction} from "ngrx-signalr-core";
import * as signalrActions from "ngrx-signalr-core";
import { SessionStatusEvent, ZippedSessionStatusEvent } from "../../models/WebServer/sessionStatusEvent";
import * as sessionActions from "../actions/session.actions";
import * as sessionStatusActions from "../actions/sessionStatus.actions";
import * as appActions from "../actions/app.actions";
import { Command } from "src/app/models/WebServer/command";
import { I18Service } from "src/app/services/i18.service";
import { AppConfigService } from "src/app/services/app.config.service";
import { NotificationService } from "src/app/services/notification.service";
import { selectSession } from "../selectors/session.selectors";
import { FELoggerService } from "src/app/services/feLogger.service";
import * as pako from 'pako';
import { StatusChangeEvent } from "src/app/models/statusChangeEvent";
import { CommandEvent } from "src/app/models/commandEvent";

enum SessionManagerHubEvents {
    PartecipantAdded = 'PartecipantAdded',
    PartecipantRemoved = 'PartecipantRemoved',
    PartecipantStatus = 'PartecipantStatus',
    CommandEvent = 'CommandEvent',
    ExpirationUpdated = 'ExpirationUpdated'
}

enum SessionManagerHubCommands {
    SubscribeToSession = 'SubscribeToSession',
    UpdatePartecipantStatus = 'UpdatePartecipantStatus',
    UpdateSessionStatus = 'UpdateSessionStatus',
    DistpatchCommand = 'DistpatchCommand'
}

const SEND_DELAY = 200;
@Injectable()
export class SignalRHubEffects {
    private timeoutToSend: number = 0;
    private lastUpdateSent: number = Date.now()
    private updateToSend: { partecipantId: string, eventType: StatusChangeEvent, content: any, targetPartecipants: string[] }[] = []

    constructor(
        private config: AppConfigService,
        private readonly actions$: Actions,
        private readonly store: Store<AppState>
    ) {
        console.debug(`SignalRHubEffects constructor`);
    }

    initSignalR$ = createEffect(() => this.actions$.pipe(
        ofType(signalrActions.signalrHubUnstarted),
        mergeMap((hubConfig) => of(hubConfig).pipe(
            ofHub(hubConfig),
            map((hub) => signalrActions.startSignalRHub(hub))
        ))
        // map(([action, hub]) => startSignalRHub(hub))
    ));

    signalrError$ = createEffect(() => this.actions$.pipe(
        ofType(signalrActions.signalrError),
        map( ({error}) => {
            console.error(`signalrActions.signalrError`,error);
            this.notifyError(error);
        })
    ),{ dispatch: false });

    signalrConnected$ = createEffect(() => this.actions$.pipe(
        ofType(signalrActions.signalrConnected),
        // mergeMap((x) => of(x).pipe(
        //     ofHub(x),
        mergeMapHubToAction(({ action, hub }) => {

            this.store.dispatch(appActions.loadingMessage({message: ''}))
            
            this.store.dispatch(appActions.signalrHubConnected({ connectionId: hub.connectionId || '' }));

            console.log(`signalr: signalrConnected hubid:${hub.connectionId}`, action);

            const subscribeToSessionOnStart$ = of(sessionActions.subscribeToSession({reconnect: false}));

            const whenExpirationUpdated$ = hub
                .on<Date>(SessionManagerHubEvents.ExpirationUpdated)
                .pipe(
                    map((newExpiration) => sessionActions.expirationUpdated({ newExpiration }))
                );

            const whenPartecipantAdded$ = hub
                .on<ZippedSessionStatusEvent>(SessionManagerHubEvents.PartecipantAdded)
                .pipe(
                    map(({ data }) => {
                        const {partecipant, session} = this.unzipSessionEvent(data);
                        const myPartecipantId = this.config.partecipantId;
                        if (partecipant.partecipantId === myPartecipantId) {
                            console.log(`signalr: SessionManagerHubEvents.PartecipantAdded MY ${myPartecipantId} hubid:${hub.connectionId}`);
                            this.store.dispatch(appActions.wssSipInit())
                        }
                        return sessionStatusActions.partecipantAdded({ partecipantStatus: partecipant, sessionStatus: session })
                    })
                );

            const whenPartecipantRemoved$ = hub
                .on<ZippedSessionStatusEvent>(SessionManagerHubEvents.PartecipantRemoved)
                .pipe(
                    map(({ data }) => {
                        const {partecipant, session} = this.unzipSessionEvent(data);
                        return sessionStatusActions.partecipantRemoved({ partecipantStatus: partecipant, sessionStatus: session })
                    })
                );

            const whenPartecipantStatusChange$ = hub
                .on<ZippedSessionStatusEvent>(SessionManagerHubEvents.PartecipantStatus)
                .pipe(
                    map(({ data }) => {
                        const {partecipant, session} = this.unzipSessionEvent(data);
                        return sessionStatusActions.partecipantStatusChange({ partecipantStatus: partecipant, sessionStatus: session })
                    })
                );
            const whenCommandEvent$ = hub
                .on<Command>(SessionManagerHubEvents.CommandEvent)
                .pipe(
                    map(({ eventType, content }) => {
                        console.log(`signalr: whenCommandEvent => ${eventType} - ${JSON.stringify(content)}`)
                        return sessionStatusActions.cmd_event({ evt: eventType, val: content })
                    })
                );
            return merge(subscribeToSessionOnStart$, whenPartecipantAdded$, 
                whenPartecipantRemoved$, whenPartecipantStatusChange$, 
                whenCommandEvent$, whenExpirationUpdated$);
        })
        // ))
    ));

    signalrReconnecting$ = createEffect(() => this.actions$.pipe(
        ofType(signalrActions.signalrReconnecting),
        map( (_) => {
            console.log(`signalr: signalrReconnecting`);
            // this.store.dispatch(appActions.signalrHubConnected({ connectionId: '' }));
            // return appActions.loadingMessage({message: I18Service.get('SNACKBAR.DISCONNECTED')})
            return appActions.signalrHubConnected({ connectionId: '' });
        })
    ));
    
    signalrReConnected$ = createEffect(() => this.actions$.pipe(
        ofType(signalrActions.signalrReconnected),
        mergeMapHubToAction(({ action, hub }) => {
            this.store.dispatch(appActions.loadingMessage({message: ''}))
            this.store.dispatch(appActions.signalrHubConnected({ connectionId: hub.connectionId || '' }));
            this.store.dispatch(sessionActions.subscribeToSession({reconnect: true}));
            console.log(`signalr: signalrReconnected hubid:${hub.connectionId}`, hub);
            return EMPTY;
        })
    ),{ dispatch: false });

    signalrDisconnected$ = createEffect(() => this.actions$.pipe(
        ofType(signalrActions.signalrDisconnected),
        map( (x) => {
            console.log(`signalr: signalrDisconnected`, x);
            NotificationService.showError(I18Service.get('SNACKBAR.DISCONNECTED'));
            return appActions.showErrorPage({message: I18Service.get('SNACKBAR.DISCONNECTED')})
        })
    ));

    subscribeToSession$ = createEffect(() => this.actions$.pipe(
        ofType(sessionActions.subscribeToSession),
        mergeMap((action) => {
            const partecipantId = this.config.partecipantId;
            return this.send(SessionManagerHubCommands.SubscribeToSession, partecipantId, action.reconnect);
        }),
        catchError((error: any) => {
            // console.error(`subscribeToSession error: `, error);
            FELoggerService.warn(`subscribeToSession error ${error}`);
            const s = error.toString().split('- error:');
            if (s.length > 1) {
                error = I18Service.get(`Error.${s[1]}`);
            } else {
                if (error.toString().includes('Session Expired')) {
                    error = I18Service.get(`POPUP.SESSIONEXPIREDBODY`);
                }
            }

            this.store.dispatch(sessionActions.sessionFailure({ error, showReload: false }));
            return EMPTY;
        })
    ), { dispatch: false });

    evt_changeStatus$ = createEffect(() => this.actions$.pipe(
        ofType(sessionStatusActions.evt_changeStatus),
        mergeMap(({ evt, val }) => {
            const partecipantId = this.config.partecipantId
            if (evt == StatusChangeEvent.MultipleUpdateStatus) {
                const statusArray = val.map((z: any) => { return { partecipantId, eventType: z.evt, content: z.val, targetPartecipants: [] } })
                this.lastUpdateSent = Date.now();
                return this.send(SessionManagerHubCommands.UpdatePartecipantStatus, { partecipantId, eventType: evt, content: statusArray, targetPartecipants: [] });
            }

            return this.send(SessionManagerHubCommands.UpdatePartecipantStatus, { partecipantId, eventType: evt, content: val, targetPartecipants: [] });


            // if (Date.now() - SEND_DELAY < this.lastUpdateSent) {
            //     if(this.timeoutToSend) {clearTimeout(this.timeoutToSend); this.timeoutToSend = 0;}
            //     this.updateToSend.push({ partecipantId, eventType: evt, content: val, targetPartecipants: [] });
            //     this.timeoutToSend  = window.setTimeout(()=>{
            //         .... // creare un multiple ed inviare nuovo "evento"
            //     }, SEND_DELAY * 1.2);
            //     return EMPTY;
            // }
            // if(this.timeoutToSend) {clearTimeout(this.timeoutToSend); this.timeoutToSend = 0;}
            // this.lastUpdateSent = Date.now();
            // this.updateToSend.push({ partecipantId, eventType: evt, content: val, targetPartecipants: [] });
            // let newData: { partecipantId: string, eventType: StatusChangeEvent, content: any, targetPartecipants: string[] } | undefined;
            // if (this.updateToSend.length > 1) {
            //     const multiStatus: any = [];
            //     this.updateToSend.forEach( s => multiStatus.push(s));
            //     newData = {partecipantId, eventType: StatusChangeEvent.MultipleUpdateStatus, content: multiStatus, targetPartecipants:[]}
            // }
            // else {
            //     newData = this.updateToSend.pop();
            // }
            
            // this.updateToSend = [];
            // if (!newData){
            //     console.error(`***** STRANGE no change status to send ****`)
            //     return EMPTY;
            // }
            // console.log(`sending `)
            // return this.send(SessionManagerHubCommands.UpdatePartecipantStatus, newData);



        }),
        catchError((error: any) => {
            this.notifyError(error);
            return EMPTY;
        })
    ), { dispatch: false });

    cmd_dispatch$ = createEffect(() => this.actions$.pipe(
        ofType(sessionStatusActions.cmd_dispatch),
        mergeMap(({ cmd, val, toIds }) => {
            const partecipantId = this.config.partecipantId;

            const command: Command = {
                partecipantId, eventType: cmd, content: val, targetPartecipants: toIds
            }

            return this.send(SessionManagerHubCommands.DistpatchCommand, command);
        }),
        catchError((error: any) => {
            this.notifyError(error);
            return EMPTY;
        })
    ), { dispatch: false });

    terminateSession$ = createEffect(() => this.actions$.pipe(
        ofType(sessionActions.terminateSession),
        withLatestFrom(this.store.select(selectSession)),
        mergeMap(([_, session]) => {
            const partecipantId = this.config.partecipantId;
            const expiration=new Date(session.expiration).getTime();
            const now = new Date().getTime();
            if (!this.config.isReceiver || expiration + 30 * 1000 < now ) {
                // solo operatore manda il termina sessione.. gli altri.. escono e boh
                return EMPTY;
            }

            const command: Command = {
                partecipantId, eventType: CommandEvent.Session_Terminate, content: '', targetPartecipants: ['*']
            }
            return this.send(SessionManagerHubCommands.DistpatchCommand, command);
        }),
        catchError((error: any) => {
            this.notifyError(error);
            return EMPTY;
        })
    ), { dispatch: false });

    extendSession$ = createEffect(() => this.actions$.pipe(
        ofType(sessionActions.extendSession),
        mergeMap( _ => {
            const partecipantId = this.config.partecipantId;
            console.log(`signalr: sending Session_Extend => ${partecipantId} `)

            const command: Command = {
                partecipantId, eventType: CommandEvent.Session_Extend, content: '', targetPartecipants: ['*']
            }
            return this.send(SessionManagerHubCommands.DistpatchCommand, command);
        }),
        catchError((error: any) => {
            this.notifyError(error);
            return EMPTY;
        })
    ), { dispatch: false });


    private send(cmd: SessionManagerHubCommands, data: any, reconnect: boolean | undefined= undefined): Observable<any> {
        const hubConfig = this.config.signalRHub;
        const hub = findHub(hubConfig);
        if (!hub || !hub.connectionId) {
            return of(hubNotFound(hubConfig));
        }
        const strcmd = data.eventType || cmd
        const strval = JSON.stringify( data.content );
        console.log(`signalr: sending ${strcmd} => ${strval} - hubid:${hub.connectionId}`)

        let sendHub: Observable<any>;
        if (reconnect !== undefined) {
            sendHub = hub.send(cmd, data, reconnect);
        } else {
            sendHub = hub.send(cmd, data);
        }

        return sendHub.pipe(
            map( _ =>{}),
            catchError((error: any) => {
                this.notifyError(error);
                return EMPTY;
            })
        );
    }

    private notifyError(error: any) {
        if ( (''+error).includes('Invocation canceled due to the underlying connection being closed')) return;
        if ( (''+error).includes('WebSocket closed with status code')) return;
        console.trace(`signalr error: ${error}`, error)

        FELoggerService.warn(`subscribeToSession error ${error}`);
        let showReload = true;
        let errMsg = I18Service.get('Error.Signalr');
        const s = error.toString().split('- error:');
        if (s.length > 1) {
            errMsg = I18Service.get(`Error.${s[1]}`);
            showReload = false;
        } else {
            if (error.toString().includes('Session Expired')) {
                errMsg = I18Service.get(`POPUP.SESSIONEXPIREDBODY`);
                showReload = false;
            }
        }
        if (errMsg) {
            this.store.dispatch(sessionActions.sessionFailure({ error: errMsg, showReload }));
        }
        else        
        NotificationService.showError(I18Service.get('Error.Signalr'));
    }

    private unzipSessionEvent(data: string): SessionStatusEvent {
        const strData     = window.atob(data);

        // Convert binary string to character-number array
        const charData    = strData.split('').map( z => z.charCodeAt(0) );

        // Turn number array into byte-array
        const binData     = new Uint8Array(charData);

        // Pako magic
        const dataInflated = pako.inflate(binData);

        const restored =  new TextDecoder().decode(dataInflated);
        const x = JSON.parse(restored);
        console.log(`unzipSessionEvent`,x);
        return x;
    }
}