import { TranslationInfo } from "./translationInfo";

export type TranslationResponse = TranslationInfo & {
    inputText: string;
}


export function toTranslationInfo(tr: TranslationResponse, owner: string): TranslationInfo {
    return {
        owner,
        confidence: tr.confidence,
        startingLanguage: tr.startingLanguage,
        targetLanguage: tr.targetLanguage,
        translatedText: tr.translatedText
    }
}