<div class="swal-without-margin info-tooltip">
  <mat-label class="section-title">{{'menu.settings' | translate }}
    <span class="tooltip-row">
      <button mat-icon-button (click)="close()">
        <span class="material-symbols-outlined icon-close-button">close</span>
      </button>
    </span>
  </mat-label>
  <div class="settings-container">
    <div class="loading-spinner" *ngIf="loading"><div class="spinner-load"></div></div>
    <h5>{{'menu.settings.language' | translate}}</h5>
    <mat-form-field appearance="fill">
        <mat-select (selectionChange)="changeLanguage($event)" [(value)]="selectedLang" >
          <mat-option [value]="lang.value" *ngFor="let lang of LANGUAGES">{{lang.label}}</mat-option>
      </mat-select>
    </mat-form-field>
    <h5>{{'menu.settings.audiooutput' | translate}}</h5>
    <mat-form-field appearance="fill">
        <mat-select (selectionChange)="changeMediaDevice('audioOutput', $event)" [(value)]="selectedAudioOutput" >
          <mat-option [value]="dev.value" *ngFor="let dev of mediaService.audioOutputDevices">{{dev.text}}</mat-option>
      </mat-select>
    </mat-form-field>
    <h5>{{'menu.settings.audiointput' | translate}}</h5>
    <mat-form-field appearance="fill">
        <mat-select (selectionChange)="changeMediaDevice('audioInput', $event)" [(value)]="selectedAudioInput" >
          <mat-option [value]="dev.value" *ngFor="let dev of mediaService.audioInputDevices">{{dev.text}}</mat-option>
      </mat-select>
    </mat-form-field>
    <h5>{{'menu.settings.camera' | translate}}</h5>
    <mat-form-field appearance="fill">
        <mat-select (selectionChange)="changeMediaDevice('camera', $event)" [(value)]="selectedCamera" >
          <mat-option [value]="dev.value" *ngFor="let dev of mediaService.videoDevices">{{dev.text}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

