import { ResponseOptions } from "../core/messages/outgoing-response";
import { IncomingRequestMessage } from "../core/messages/incoming-request-message";
import { IncomingOptionsRequest } from "../core/messages/methods/options";

/**
 * An exchange of information (incoming OPTIONS).
 * @public
 */
export class Options {
  /** @internal */
  public constructor(private incomingOptionsRequest: IncomingOptionsRequest) {}

  /** Incoming MESSAGE request message. */
  public get request(): IncomingRequestMessage {
    return this.incomingOptionsRequest.message;
  }

  /** Accept the request. */
  public accept(options?: ResponseOptions): Promise<void> {
    this.incomingOptionsRequest.accept(options);
    return Promise.resolve();
  }

  /** Reject the request. */
  public reject(options?: ResponseOptions): Promise<void> {
    this.incomingOptionsRequest.reject(options);
    return Promise.resolve();
  }
}
