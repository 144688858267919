import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SessionState } from "../states/session.state";
import { selectAppState } from "./app.selectors";
import { AppState } from "../states/app.state";
import { Session } from "src/app/models/WebServer/session";
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";


// const sessionFeatureKey = 'session';

// export const selectSessionState = createFeatureSelector<SessionState>(sessionFeatureKey);

// export const selectSession = createSelector(
//     selectSessionState,
//     (state: SessionState) => state.session
// );

export const selectSession = createSelector(
    selectAppState,
    (state: AppState) => state.session
);

export const remainingGuests = createSelector(
    selectSession,
    (session: Session) => {
        if (!session.config.agencySettings.showAddGuest) return 0;
        
        const created = session.parameters.partecipantIds
            .filter( x => (x.partecipantRole == PartecipantRoles.GuestClient || x.partecipantRole == PartecipantRoles.SuperClient) && x.active);
        const remaining = session.config.maxGuestsNumber - created.length;

        return remaining;
    }
);

export const selectPartecipants = createSelector(
    selectSession,
    (session: Session ) => {
        return session.parameters.partecipantIds
    }
)