import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { I18Service } from '../services/i18.service';
import { PartecipantRoles } from '../models/WebServer/partecipantRoles';
import { PartecipantStatus } from '../models/WebServer/partecipantStatus';
import { AppHelper } from './app.helper';
import { AppConfigService } from '../services/app.config.service';
import { TranslationInfo } from '../models/WebServer/translationInfo';

@Pipe({
  name: 'confidenceColor'
})
export class ConfidenceColorPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer, private config: AppConfigService) { }

  public transform(tr: TranslationInfo | undefined): {color: string, tooltip: string } {

    if( !tr ) return { color: '', tooltip: ''}
    const color = (tr.confidence < this.config.agencySettings.translationMinConfidence) ? 'red'
      : (tr.confidence < this.config.agencySettings.translationMaxConfidence) ? 'orange'
      : '';

     const confidence = Math.floor(tr.confidence * 100);
     const startingLanguage = I18Service.languageName(tr.startingLanguage.split('-')[0], I18Service.language) || tr.startingLanguage;
     const targetLanguage = I18Service.languageName(tr.targetLanguage.split('-')[0], I18Service.language) || tr.targetLanguage;
     const tooltip = I18Service.get('chat.translation.tooltip', {startingLanguage, targetLanguage, confidence})
    return {color, tooltip};
  }
}
