import { OnInit, OnDestroy, Injectable } from '@angular/core';

import { FrontEndLog } from '../models/WebServer/frontEndLog';
import { HttpClient } from '@angular/common/http';
import { Session } from '../models/WebServer/session';
import { AppConfigService } from './app.config.service';
import { AppHelper } from '../helpers/app.helper';

const enum LogLevel {
  INFO = 'Info',
  WARNING = 'Warning',
  ERROR = 'Error',
  VERBOSE = 'Verbose'
}

export enum LogSource {
  Default = 'Default',
  PhoneEvents = 'PhoneEvents',
  Localization = 'Localization',
  SIPEvent = 'SIPEvent',
  ReinviteDirection = 'ReinviteDirection',
  MediaAudio = 'MediaAudio',
  OuterUnmanagedError = 'OuterUnmanagedError',
  InnerUnmanagedError = 'InnerUnmanagedError',
  PageStatusChange = 'PageStatusChange',
  KamailioLog = 'KamailioLog',
  StartGeoLocationTracking = 'StartGeoLocationTracking'
}


@Injectable()
export class FELoggerService implements OnInit, OnDestroy {
  // saveLogSubscription!: Subscription;
  private static logsFE: FrontEndLog[] = [];
  private static maxLines = 500;
  private static intervalSecs = 7;
  private static interval = 0;
  private static config: AppConfigService;
  private static http: HttpClient;

  constructor(http: HttpClient, config: AppConfigService) {
    FELoggerService.config = config;
    FELoggerService.http = http;
    FELoggerService.interval = window.setInterval(() => {

      FELoggerService.sendBulkLogs();
      // this.logLines = [];

    }, FELoggerService.intervalSecs * 1000);
  }

  ngOnInit() {
    // this.saveLogSubscription = this.logService
    //   .getSubscription()
    //   .subscribe(() => {}, err => {
    //     Logger.error(`Error during Logging on backend: '${err.message}'`, null, Framework.OuterUnmanagedError);
    //   });
  }

  ngOnDestroy() {
    if (FELoggerService.interval) {
      clearInterval(FELoggerService.interval);
      FELoggerService.interval = 0;
    }
    FELoggerService.sendBulkLogs();
  }

  static log(message: string, source: LogSource = LogSource.Default, obj: any = undefined) { this.info(message, source, obj);}
  static info(message: string, source: LogSource = LogSource.Default, obj: any = undefined) {
    console.info(message, obj);
    this.sendLog(LogLevel.INFO, message, source, obj);
  }
  static warn(message: string, source: LogSource = LogSource.Default, obj: any = undefined) {
    console.warn(message, obj)
    this.sendLog(LogLevel.WARNING, message, source, obj);
  }
  static error(message: string, source: LogSource = LogSource.Default, obj: any = undefined) {
    console.error(message, obj)
    this.sendLog(LogLevel.ERROR, message, source, obj);
  }

  static debug(message: string, source: LogSource = LogSource.Default, obj: any = undefined) { this.verbose(message, source, obj);}
  static verbose(message: string, source: LogSource = LogSource.Default, obj: any = undefined) {
    console.debug(message, obj)
    this.sendLog(LogLevel.VERBOSE, message, source, obj);
  }

  private static sendLog(level: string, message: string, source: LogSource = LogSource.Default, obj: any = undefined) {
    try {
      if (this.config.frontEndLogEnabled && this.config.frontEndLogLevel.includes(level)) {
        const m: any ={
          msg: message,
          obj: obj
        }
        const log: FrontEndLog = {
          message: JSON.stringify(m),
          sessionId: this.config.sessionId || '',
          logType: level,
          logSource: `${source || ''} - ${AppConfigService.role} - ${this.config.partecipantId}`,
          projectSource: AppHelper.getTitleVersion(),
          timeStamp: new Date(),
          //logId: 0
        };
        this.logFE(log);
      }
    } catch {
      console.error(`Errore nel log Verbose verso Back End '${message}'`);
    }

  }
  private static logFE(msg: FrontEndLog) {
    this.logsFE.push(msg);
    if (this.logsFE.length > this.maxLines) {
      this.logsFE.shift(); // remove first or .splice(0, this.logLines.length - this.maxLines)
    }
  }
  private static sendBulkLogs() {
    if (this.logsFE.length === 0) { return; }

    try {
      this.http.post<number>(`${this.config.apiBaseUrl}/frontendlog/bulk`, this.logsFE, {headers:{'SM-PartecipantId': this.config.partecipantId}})
      .subscribe((e) => {
        console.log(`bulkLogs sent: ${JSON.stringify(e)}`);
      });
      this.logsFE = [];
    } catch (error) {
      console.error(`error while logging on server, I'm going to run the Disaster Recovery procedure...`);
    }
  }
}
