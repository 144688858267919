import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { GeoLocCoords } from '../models/coordinates';

@Pipe({
  name: 'coordinates'
})
export class CoordinatesPipe implements PipeTransform {

  constructor(protected number: DecimalPipe) { }

  public transform(coords: GeoLocCoords | undefined): string {
    var res=''
    if (!coords) return res;

    if(coords.latitude){
      res +=  'Lat: ' + this.number.transform(coords.latitude, '.4');
    } 
    
    if(coords.longitude) {
      if(res) res+=' - ';
      res += 'Long: ' + this.number.transform(coords.longitude, '.4');
    }
    
    if(coords.altitude) {
      if(res) res+=' - ';
      res += 'Alt: ' + this.number.transform(coords.altitude, '1.0-0');
    }
    return res;
  }
}
