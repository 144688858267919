import { Component } from "@angular/core";

@Component({
    selector: 'video-fill-thumbnail',
    template: `<div style="width:30px"></div>`,
    styles: [`
    :host {
      display: block;
      flex:1;
    }`],
})

export class VideFillThumbnailComponent {
}
