import { Component, OnInit, OnDestroy, AfterViewInit, Input, EventEmitter, Output } from "@angular/core";
import { Store } from "@ngrx/store";
import { Constants } from "src/app/constants";
import { AddressInfo } from "src/app/models/WebServer/addressInfo";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { GeoLocCoords } from "src/app/models/coordinates";
import { AppConfigService } from "src/app/services/app.config.service";
import { I18Service } from "src/app/services/i18.service";
import { NotificationService } from "src/app/services/notification.service";

import { AppState } from "src/app/store/states/app.state";
import { SvgConstants } from "src/app/svgConstants";
import { StatusCircleSize } from "../status-circle.component";

@Component({
  selector: 'app-remote-info',
  templateUrl: './remote-info.component.html',
  styleUrls: ['./remote-info.component.scss']
})
export class RemoteInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  // _as!: AppState;
  // get AS(): AppState { return this._as;}
  // @Input() set AS(as: AppState){
  //   this._as = as;
  //   if (!this.realTimePosition) {
  //     // console.log('************************* AS change ! realtimepo')
  //     this.coordsData = { time: new Date(this.AS.lastPositionData?.time || 0), coords: this.AS.lastPositionData?.coords };
  //   }
  // };

  // realTimePosition = false;
  // _secondaryStatus?: PartecipantStatus
  // get SECONDARYSTATUS(): PartecipantStatus | undefined { return this._secondaryStatus; }
  // @Input() set SECONDARYSTATUS(val: PartecipantStatus | undefined) {
  //   this._secondaryStatus = val;
  //   if (val && val.geoLocCoords) {
  //     this.coordsData = { time: new Date(val.lastLocalizationDate || 0), coords: val.geoLocCoords, address: val.addressInfo };
  //     this.realTimePosition = true;
  //   } 
  //   else {
  //     this.realTimePosition = false
  //     // console.log('************************* set secondaryStatus')
  //     // this.coordsData = { time: new Date(this.AS.lastPositionData?.time || 0), coords: this.AS.lastPositionData?.coords };
  //   }
  // }

  @Input() AS!: AppState;
  @Input() SECONDARYSTATUS: PartecipantStatus | undefined;

  @Input() showUserInfo!: boolean
  @Output() userInfoClose = new EventEmitter<boolean>();

  @Input() showMenu!: boolean;
  @Output() showSessionInfo = new EventEmitter<boolean>;

  // defaultLatitude = Constants.DEFAULT_LATITUDE;
  // defaultLongitude = Constants.DEFAULT_LONGITUDE;
  svgConstants = SvgConstants
  StatusCircleSize = StatusCircleSize;

  // coordsData?: { time: Date, coords?: GeoLocCoords, address?: AddressInfo }


  constructor(
    public store: Store<AppState>,
    public config: AppConfigService,
  ) { }

  ngOnInit(): void { }
  ngOnDestroy(): void { }
  ngAfterViewInit(): void { }


  close() {
    this.userInfoClose.emit(false);
  }

  showGuestSessionInfo(show: boolean) {
    this.showSessionInfo.emit(true);
  }

  // CopyInfoOnClipboard() {
  //   // let cardPart = '';
  //   // let infoPart = ''
  //   // const info = document.getElementById('info-tooltip')
  //   // if (info) {
  //   //   var r = document.createRange();
  //   //   r.selectNode(info!);
  //   //   window.getSelection()?.removeAllRanges();
  //   //   window.getSelection()?.addRange(r);
  //   //   infoPart = window.getSelection()?.toString() || '';
  //   // }

  //   // const card = document.getElementById('card-info')
  //   // if(card) {
  //   //   var q = document.createRange();
  //   //   q.selectNode(card!);
  //   //   window.getSelection()?.removeAllRanges();
  //   //   window.getSelection()?.addRange(q);
  //   //   cardPart = window.getSelection()?.toString() || '';
  //   // }
  //   // const elem = document.createElement('textarea');
  //   // elem.value = `${cardPart}\n\n${infoPart!.replace('close', '')}`;
  //   // document.body.appendChild(elem);
  //   // elem.select();
  //   // document.execCommand('copy');
  //   // document.body.removeChild(elem);
  //   // window.getSelection()?.removeAllRanges();
  //   const card = document.getElementById('content-to-copy');
  //   if (card) {
  //     var q = document.createRange();
  //     q.selectNode(card!);
  //     window.getSelection()?.removeAllRanges();
  //     window.getSelection()?.addRange(q);
  //     const cardPart = window.getSelection()?.toString() || '';
  //     const elem = document.createElement('textarea');
  //     elem.value = `${cardPart}\n`.replace(/:\n/g, ': ');
  //     document.body.appendChild(elem);
  //     elem.select();
  //     document.execCommand('copy');
  //     document.body.removeChild(elem);
  //     window.getSelection()?.removeAllRanges();
  //     NotificationService.showSuccess(`${I18Service.get(`SNACKBAR.TEXTCOPIED`)}`);
  //   }
  // }
}