import { createAction, props } from '@ngrx/store';
import { PartecipantParameters, PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';
import { SessionStatus } from 'src/app/models/WebServer/sessionStatus';
import { CommandEvent } from 'src/app/models/commandEvent';
import { StatusChangeEvent } from 'src/app/models/statusChangeEvent';




// client sent
export const partecipantAdded = createAction('[SessionStatus] Partecipant added', props<{ partecipantStatus: PartecipantStatus, sessionStatus: SessionStatus }>());
export const partecipantRemoved = createAction('[SessionStatus] Partecipant removed', props<{ partecipantStatus: PartecipantStatus, sessionStatus: SessionStatus }>());

//sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.AskForNewInvite);


export const evt_changeStatus = createAction('[SessionStatus] change status event', props<{ evt: StatusChangeEvent, val: any }>());

// export const evt_disclaimer = createAction('[SessionStatus] disclaimer', props<{disclaimer: boolean}>());

// export const evt_browserCompatibility = createAction('[SessionStatus] browser compatibility', props<BrowserCompatibility>());


export const updatePartecipantParameters = createAction('[SessionStatus] update partecipant parameters', props<PartecipantParameters>());


//client sent
export const cmd_dispatch = createAction('[SessionStatus] Command Dispatch', props<{ cmd: CommandEvent, val: any, toIds: string[] }>());


//client received
export const partecipantStatusChange = createAction('[SessionStatus] Partecipant status change', props<{ partecipantStatus: PartecipantStatus, sessionStatus: SessionStatus }>());
export const cmd_event = createAction('[SessionStatus] Command Event', props<{ evt: CommandEvent, val: any }>())

// export const terminateSession = createAction('[SessionStatus] Terminate session');