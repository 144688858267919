import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { BrowserHelper } from './browser.helper';
import { FELoggerService, LogSource } from '../services/feLogger.service';
import { ErrorInfo } from '../models/errorInfo';


@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler  implements ErrorHandler {
  ignoredErrors = [
    // 'No value accessor for form control with unspecified name attribute',
    // 'ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value:'
  ]

  constructor(private injector: Injector) { }
  
  handleError(error: any) {
    // const feLogger = this.injector.get<FELoggerService>(FELoggerService);
    const parsedUA = BrowserHelper.getParsedUa();
    const model = BrowserHelper.getModel(parsedUA);
    const browserInfo = BrowserHelper.getBrowserInfo();
    
    if (error instanceof HttpErrorResponse) {
      console.debug(error.error.status, error);//, Framework.OuterUnmanagedError);
    }
    else {
      if (error && error?.message && !this.ignoredErrors.some(e => e === error.message || (error.message.toString().indexOf(e) > -1))) {
        console.debug('An unhandler error occurred', error);//, Framework.InnerUnmanagedError);
      }
      const message = `${error}\n${browserInfo}\n${model}\n${parsedUA.ua}\n${error?.stack}`.trim();
      FELoggerService.error(message, LogSource.OuterUnmanagedError);
      const e: string = error.toString();
      if (e.indexOf('Failed to parse incoming message') > -1) {
        const ei: ErrorInfo = {imagePath: '', message, type: 1, originalError: error, showReloadButton: true};
        this.manageSpecialError(ei);
      }
    }
    // const errorInfo = new ErrorInfo(null, `An unhandler error occurred\nError --> '${error}'`, error);
    // this.eventEmitterService.riseError(errorInfo);
  }

  private manageSpecialError(errorInfo: ErrorInfo) {
    console.info('Managing Special Error', errorInfo);
    // this.eventEmitterService.riseSpecialError(errorInfo);
  }
}
