import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { async } from 'rxjs';
import { AppHelper } from 'src/app/helpers/app.helper';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
// import { MultimediaMachineStatus } from 'src/app/models/multimediaMachineState';
import { PhoneStatusEnum } from 'src/app/models/phoneStatus';
import { AppConfigService } from 'src/app/services/app.config.service';
import { FELoggerService } from 'src/app/services/feLogger.service';
import { AppState } from 'src/app/store/states/app.state';
import { Logger } from 'src/libs/SipJS/core';



@Component({
  selector: 'app-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.css']
})
export class PhoneComponent implements OnInit, OnDestroy {

  @Input() AS!: AppState;
  // @Input() selectorPlaceHolder: Map<string, string>;

  // sessionOkSubscription: Subscription;
  // sipEventSubscription: Subscription;
  // automaticCallManagerInterval: any;
  // automaticAnswerManagerInterval: any;
  forcedExtension?: string;
  phoneStatus: PhoneStatusEnum = PhoneStatusEnum.NONE;

  telInput: any;
  public telDisplay?: string;

  constructor(
    // private signalr: SignalrService,
    // private partecipantHelper: PartecipantHelper,
    public config: AppConfigService,
    // private sipService: GenericSipServer,
    private feLogger: FELoggerService,
    private _ngZone: NgZone) { }

  ngOnDestroy(): void {
    // if (this.sessionOkSubscription) {
    //   this.sessionOkSubscription.unsubscribe();
    // }
    // if (this.sipEventSubscription) {
    //   this.sipEventSubscription.unsubscribe();
    // }
    // this.clearCallIntervals();
  }

  ngOnInit() {
    // const self = this;
    // this.sessionOkSubscription = this.partecipantHelper.sessionOkSubject.subscribe(() => {
    //   this.AS.localBig = this.AS.isCaller;
    //   // if (ConfigService.settings.AutomaticCallAnswer) {
    //   //   this.placeCallIntervals();
    //   // }
    // });
    // this.sipEventSubscription = this.sipService.subscribe(
    //   // it use NgZone.run to force UI update, caused event out of Angular context
    //   x => this._ngZone.run(() => self.handlePhoneEvent(x)),
    //   err => {
    //     Logger.error('Observer got an error: ' + err, null, Framework.PhoneEvents);
    //   }
    // );
  }

  // handlePhoneEvent(e: PhoneEvent): void {
  //   if (e == null) {
  //     return;
  //   }

  //   if (e.phoneStatus !== PhoneStatusEnum.GEOLOCATION) {
  //     this.phoneStatus = e.phoneStatus;
  //   }
  //   // tslint:disable-next-line:no-console
  //   console.debug(`PhoneStatus Changed: ${e.phoneStatus}(${e.message})`, null);//, Framework.PhoneEvents);

  //   if (e.phoneStatus === PhoneStatusEnum.RINGING) {
  //     this.telDisplay = e.message;
  //   }

  //   if (e.phoneStatus === PhoneStatusEnum.IDLE) {
  //     this.telDisplay = '';
  //   }
  // }

  telInputObject(obj: any) {
    this.telInput = obj;
    // obj.intlTelInput('setCountry', ConfigService.settings.Country);

    // other HTML element settings
    // set 100% of input width
    obj[0].parentElement.style.width = '100%';
    obj[0].style.width = '100%';
    obj[0].style.height = '36px';

    // set the <ul class=country-list></ul> element
    // with custom width
    if (obj[0].parentElement.getElementsByTagName('ul')[0]) {
      obj[0].parentElement.getElementsByTagName('ul')[0].style.width = '300px';
      obj[0].parentElement.getElementsByTagName('ul')[0].style['overflow-x'] = 'hidden';
    }
  }

  isOkButtonDisabled(): boolean {
    if (!this.AS.isRegistered || this.AS.errorInfo) {
      return true;
    }
    if (!this.AS.phoneStatus) {
      return false;
    }
    if (this.AS.phoneStatus === PhoneStatusEnum.RINGING) {
      return false;
    }
    if (this.AS.phoneStatus === PhoneStatusEnum.REMOTE_RINGING) {
      return true;
    }
    // if (this.AS.phoneStatus !== PhoneStatusEnum.IDLE && this.AS.phoneStatus !== PhoneStatusEnum.GEOLOCATION) {
    //   return true;
    // }
    return false;
  }

  isCancelButtonDisabled(): boolean {
    if (this.AS.errorInfo || !this.AS.phoneStatus)
      return true;
    if (this.AS.phoneStatus === PhoneStatusEnum.RINGING ||
      this.AS.phoneStatus === PhoneStatusEnum.REMOTE_RINGING ||
      this.AS.phoneStatus === PhoneStatusEnum.TALKING ||
      this.AS.phoneStatus === PhoneStatusEnum.CALLING ||
      // this.AS.phoneStatus === PhoneStatusEnum.GEOLOCATION ||
      (!this.AS.phoneStatus && this.AS.isRegistered)) {
      return false;
    }
    return true;
  }

  showTelDisplay(): boolean {
    return this.phoneStatus === PhoneStatusEnum.REMOTE_RINGING ||
      this.phoneStatus === PhoneStatusEnum.RINGING ||
      this.phoneStatus === PhoneStatusEnum.TALKING;
  }

  // UI Phone Actions
  async callStartOrAnswer() {
    // if (!this.AS.isRegistered) {
    //   Logger.warn('Trying to call but not registered to Kamailio', this.AS, Framework.InnerUnmanagedError);
    //   this.sipService.initialize(this.AS.partecipantId);
    //   this.signalr.connect();
    //   return;
    // }

    // if (this.AS.phoneStatus === PhoneStatusEnum.RINGING) {
    //   this.sipService.toggleMicrophone(this.AS.microphoneOn);
    //   // this.setAutomaticVideo();
    //   this.sipService.accept(this.AS.videoEnabled, this.AS.microphoneOn);
    //   return;
    // }

    // if ((this.AS.targetPartecipantId !== '' || this.AS.phoneStatus === PhoneStatusEnum.IDLE) &&
    //   this.AS.phoneStatus !== PhoneStatusEnum.CALLING && this.AS.phoneStatus !== PhoneStatusEnum.REMOTE_RINGING && this.AS.phoneStatus !== PhoneStatusEnum.TALKING) {
    //   this.telDisplay = this.AS.targetPartecipantId;
    //   this.sipService.toggleMicrophone(this.AS.microphoneOn);
    //   // this.setAutomaticVideo();

    //   if (this.AS.selectedCoordType.toString().startsWith('from_lis')) {
    //     this.sipService.inviteLISRequest(this.AS.targetPartecipantId, this.AS.selectedCoordType.toString(), this.AS.videoEnabled);
    //   } else {
    //     try {
    //       if (this.AS.isCaller && MultimediaMachineStatus.isVisible(this.AS.currentMultimediaState, AppModesEnum.Video)) {
    //         this.AS.videoEnabled = true;
    //       }
    //       await this.sipService.sendInvite(this.AS.targetPartecipantId, this.AS.videoEnabled, this.AS.microphoneOn);
    //     } catch (error) {
    //       if (this.AS.inviteAttempts < 4 && (AppHelper.isSafari() || AppHelper.isAppleOS())) {
    //         this.feLogger.LogError(`First attempts in IOS for invite failed. Trying second one, ${error}`);
    //         await this.sipService.sendInvite(this.AS.targetPartecipantId, this.AS.videoEnabled, this.AS.microphoneOn);
    //       }
    //     }
    //   }
    // }
  }

  isOnActiveSession(): boolean {//TODO
    // return this.sipService.sessionStore.currentSession !== null ||
    //   (this.sipService.sipServiceDelegate && this.sipService.sipServiceDelegate.currentChatSession !== null);
    return false;
  }

  public async onCancelClick(reloadEvent: boolean = false) {
    // console.debug('Pressing cancel CALL', this.phoneStatus);//, Framework.PhoneEvents);
    // AppHelper.checkDropConnection(this.AS.phoneStatus, 'undefined', 'onCancelClick');
    // this.sipService.phoneDown();
    // this.AS.phoneStatus = undefined;
    // this.AS.localBig = true;
  }

  public sendDtmf(tone: string): any {
    // if (this.phoneStatus === PhoneStatusEnum.TALKING) {
    //   this.sipService.sendDtmf(tone);
    // }
  }
}
