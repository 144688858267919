import { Component, Input } from "@angular/core";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { AppConfigService } from "src/app/services/app.config.service";
import { I18Service } from "src/app/services/i18.service";
import { NotificationService } from "src/app/services/notification.service";
import { AppState } from "src/app/store/states/app.state";
import { StatusCircleSize } from "../status-circle.component";
import { GeoLocCoords } from "src/app/models/coordinates";
import { AddressInfo } from "src/app/models/WebServer/addressInfo";
import { Constants } from "src/app/constants";

@Component({
    selector: 'app-remote-info-content',
    templateUrl: './remote-info-content.component.html',
    styleUrls: ['./remote-info-content.component.scss']
  })
  export class RemoteInfoContentComponent {
    _as!: AppState;
    get AS(): AppState { return this._as;}
    @Input() set AS(as: AppState){
      this._as = as;
      if (!this.realTimePosition) {
        // console.log('************************* AS change ! realtimepo')
        this.coordsData = { time: new Date(this.AS.lastPositionData?.time || 0), coords: this.AS.lastPositionData?.coords };
      }
    };

    _secondaryStatus?: PartecipantStatus
    get SECONDARYSTATUS(): PartecipantStatus | undefined { return this._secondaryStatus; }
    @Input() set SECONDARYSTATUS(val: PartecipantStatus | undefined) {
        this._secondaryStatus = val;
        if (val && val.geoLocCoords) {
        this.coordsData = { time: new Date(val.lastLocalizationDate || 0), coords: val.geoLocCoords, address: val.addressInfo };
        this.realTimePosition = true;
        } 
        else {
        this.realTimePosition = false
        // console.log('************************* set secondaryStatus')
        // this.coordsData = { time: new Date(this.AS.lastPositionData?.time || 0), coords: this.AS.lastPositionData?.coords };
        }
    }
    StatusCircleSize = StatusCircleSize;
    coordsData?: { time: Date, coords?: GeoLocCoords, address?: AddressInfo }
    realTimePosition = false;
    defaultLatitude = Constants.DEFAULT_LATITUDE;
    defaultLongitude = Constants.DEFAULT_LONGITUDE;

    constructor(public config: AppConfigService){}


    CopyInfoOnClipboard() {
        const card = document.getElementById('content-to-copy');
        if (card) {
          var q = document.createRange();
          q.selectNode(card!);
          window.getSelection()?.removeAllRanges();
          window.getSelection()?.addRange(q);
          const cardPart = window.getSelection()?.toString() || '';
          const elem = document.createElement('textarea');
          elem.value = `${cardPart}\n`.replace(/:\n/g, ': ');
          document.body.appendChild(elem);
          elem.select();
          document.execCommand('copy');
          document.body.removeChild(elem);
          window.getSelection()?.removeAllRanges();
          NotificationService.showSuccess(`${I18Service.get(`SNACKBAR.TEXTCOPIED`)}`);
        }
      }
  }