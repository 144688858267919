import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { I18Service } from '../services/i18.service';

@Pipe({
  name: 'connectionSpeed'
})
export class ConnectionSpeedPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(s_value?: string): {speed:string, msg:string} {
    if (!s_value) return {speed: I18Service.get('LABELS.MISSINGINFO'), msg:''};

    const value = parseFloat(s_value);

    if(value < 0.1 ) return { speed:`G (${value.toFixed(3)} Mbps)`, msg:` - ${I18Service.get('LABELS.TIPNOSTREAM')}`};
    if(value >= 0.1 && value < 1.5 ) return { speed:`3G (${value.toFixed(3)} Mbps)`, msg:` - ${I18Service.get('LABELS.TIPNOSTREAM')}`};
    if(value >= 1.5 && value < 4 ) return { speed:`H (${value.toFixed(3)} Mbps)`, msg:` - ${I18Service.get('LABELS.TIPNOSTREAM')}`};
    if(value >= 4 && value < 15 ) return { speed:`H+ (${value.toFixed(3)} Mbps)`, msg:''};
    if(value >= 15 && value < 30 ) return { speed:`4G (${value.toFixed(3)} Mbps)`, msg:''};
    if(value >= 30 && value < 150 ) return { speed:`4G+ (${value.toFixed(3)} Mbps)`, msg:''};
    
    return {speed:`5G (${value.toFixed(3)} Mbps)`, msg:''};
  }
}
