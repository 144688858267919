<div class="phone-container">
  <button id="toggle-microphone" color="primary" mat-mini-fab aria-label="Call" [disabled]="isOkButtonDisabled()" 
    (click)="callStartOrAnswer()" class="ok-button">
    <span class="material-symbols-outlined">call</span>
  </button>
  <button id="toggle-microphone" color="warn" mat-mini-fab aria-label="End Call" [disabled]="isCancelButtonDisabled()" 
    (click)="onCancelClick()">
    <span class="material-symbols-outlined">call_end</span>
  </button>
</div>

<div id="agent-wrapper">
  <div id="phone-call-actions">
    <div class="bottom-border">
    </div>
    <div id="phone-col-others" class="col-xs-12 no-padding">
      <!-- CONFERENCE LINK -->
      <div id="link-conference-wrapper" class="link-line">
      </div>
      <!-- CONSULTAZIONE LINK -->
      <div id="link-consultazione-wrapper" class="link-line">
      </div>
    </div>
  </div>
</div>