import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from "../states/app.state";

const appFeatureKey = 'app';

export const selectAppState = createFeatureSelector<AppState>(appFeatureKey);

// export const selectSignalrHub = createSelector(
//     selectAppState,
//     (state: AppState) => state.signalRHub
// );

export const selectPartecipantId = createSelector(
    selectAppState,
    (state: AppState) => state.partecipantId
);

export const selectErrorInfo = createSelector(
    selectAppState,
    (state: AppState) => state.errorInfo
);


export const selectPartecipants = createSelector(
    selectAppState,
    (state: AppState) => state.session.parameters.partecipantIds
);

export const guiLanguage = createSelector(
    selectAppState,
    (s: AppState) => s.guiLanguage
);

export const translationSelectedLanguage = createSelector(
    selectAppState,
    (s: AppState) => s.translationSelectedLanguage
);

// export const selectChatMessages = createSelector(
//     selectAppState,
//     (state: AppState) => state.messages.filter( z => !z.type || z.type == MediaTypesEnum.Document )
// );

// export const selectMediaMessages = createSelector(
//     selectAppState,
//     (state: AppState) => state.messages.filter( z => z.type == MediaTypesEnum.Image )
// );