import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { Action, Store } from "@ngrx/store";
import { AppHelper } from "src/app/helpers/app.helper";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { AppConfigService } from "src/app/services/app.config.service";
import * as appActions from "src/app/store/actions/app.actions";
import * as sessionStatusActions from "src/app/store/actions/sessionStatus.actions";
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { NgxFullscreenDirective } from "@ultimate/ngx-fullscreen";
import { CommandEvent } from "src/app/models/commandEvent";
import { BrowserHelper } from "src/app/helpers/browser.helper";

export type ThumbVideoToolbarData = {
    video: HTMLVideoElement;
    partecipantStatus: PartecipantStatus;
    fullscreen: NgxFullscreenDirective;
    camsCount: number;
    zoom: boolean;
    videoCover: boolean;
}


@Component({
    selector: 'app-thumb-video-toolbar',
    templateUrl: './thumb-video-toolbar.component.html',
    styleUrls: ['./thumb-video-toolbar.component.scss']
  })

export class ThumbVideoToolbarComponent implements OnChanges {

    @Input() video!: HTMLVideoElement;
    @Input() partecipantStatus!: PartecipantStatus;
    @Input() fullscreen!: NgxFullscreenDirective;
    @Input() camsCount!: number;
    @Input() zoom!: boolean;
    
    pipAPIEnabled = !BrowserHelper.isFirefox()

    itsMe = false;
    videoCover = true;

    @Output() imageToEdit: EventEmitter<Blob | null> = new EventEmitter<Blob | null>()

    @ViewChild('children') children!: ElementRef;

    
    

    constructor (public config: AppConfigService, private store: Store, 
            @Inject(MAT_BOTTOM_SHEET_DATA) private data: ThumbVideoToolbarData | undefined = undefined,
            @Inject(MatBottomSheetRef) private bottomSheetRef: MatBottomSheetRef<ThumbVideoToolbarComponent> | undefined = undefined
            ) {
        if (data) {
            this.video = data.video;
            this.partecipantStatus = data.partecipantStatus

            this.fullscreen = data.fullscreen;
            this.camsCount = data.camsCount;
            this.zoom = data.zoom;
            this.videoCover = data.videoCover;
            this.itsMe = data.partecipantStatus.partecipantId == config.partecipantId;
        }

    }

    ngOnChanges(): void {
        this.itsMe = this.partecipantStatus?.partecipantId == this.config.partecipantId;
    }

    pictureInPictureEnabled = document.pictureInPictureElement != null;
    togglePictureInPicture(videoEl: HTMLVideoElement) {
        if(BrowserHelper.isFirefox()){return;}
        if (!this.pictureInPictureEnabled) {
            videoEl.requestPictureInPicture().finally( () => this.pictureInPictureEnabled = document.pictureInPictureElement != null );
        } else {
            document.exitPictureInPicture().finally( () => this.pictureInPictureEnabled = document.pictureInPictureElement != null)
        }
        this.close();
    }

    edit(videoEl: HTMLVideoElement) { 
        if (videoEl.paused) { 
            videoEl.play();
            this.imageToEdit.emit(null);
        }
        else { 
            videoEl.pause();
            const canvas = AppHelper.getCanvasFromVideo(videoEl);
            if (canvas) {
                canvas.toBlob( blob => {
                    this.imageToEdit.emit(blob);
                })
            }
        }
        this.close();
    }



    toggleLocalStream() { 
        this.doAction(appActions.toggleVideo(), CommandEvent.ToggleVideo)
    }

    switchCamera() {
        this.doAction(appActions.switchCamera({forSnapshot: false}), CommandEvent.SwitchCamera);
    }
    switchMicrophone() {
        this.doAction(appActions.toggleMicrophone(), CommandEvent.ToggleMicrophone);
    }

    public showBigVideo(){
        this.doAction(appActions.zoomTo({id: this.partecipantStatus.partecipantId}), undefined)
    }
    public showBigVideoForAll() {
        this.showBigVideo(); //for me
        //for others
        this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.VideoZoomTo, val: this.partecipantStatus.partecipantId, toIds: appActions.SEND_TO_ALL}));
        this.close();
    }

    private doAction(localAction: Action, remoteCommand: CommandEvent | undefined) {

        if (this.itsMe || !remoteCommand) {
            this.store.dispatch(localAction);
        }
        else if (this.config.isReceiver) {
            this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: remoteCommand, val: '', toIds: [this.partecipantStatus!.partecipantId] }));
        }
        this.close();
    }

    toggleVideoCover(){
        this.videoCover = !this.videoCover;


        if(this.bottomSheetRef) {
            this.store.dispatch(appActions.fitVideoTo({id: this.partecipantStatus.partecipantId, val: this.videoCover}));
        }
        this.close();
    }
    videoCoverForAll() {
        this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.FitVideoTo, 
            val: {id: this.partecipantStatus.partecipantId, value: this.videoCover}, 
            toIds: appActions.SEND_TO_ALL}));
        

        // this.store.dispatch(appActions.fitVideoTo({id: this.partecipantStatus.partecipantId, val: this.videoCover}));

        this.close();
    }

    private close() {
        if(this.bottomSheetRef) {
            this.bottomSheetRef.dismiss();
        }
    }
}