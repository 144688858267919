import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppHelper } from "src/app/helpers/app.helper";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";
import { AppConfigService } from "src/app/services/app.config.service";

import { AppState } from "src/app/store/states/app.state";
import { StatusCircleSize } from "../status-circle.component";
import { NotificationService } from "src/app/services/notification.service";
import { I18Service } from "src/app/services/i18.service";
import {Clipboard} from '@angular/cdk/clipboard';
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";

@Component({
  selector: 'app-session-info',
  templateUrl: './session-info.component.html',
  styleUrls: ['./session-info.component.scss']
})
export class SessionInfoComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() AS!: AppState;
  @Input() PARTECIPANTS!: PartecipantStatus[];
  @Input() expiresIn!: number; //Date;
  @Input() showSessionInfo!: boolean
  @Input() inTab = false;
  @Output() sessionInfoClose = new EventEmitter<boolean>();

  StatusCircleSize = StatusCircleSize;
  PartecipantRoles = PartecipantRoles;

  constructor(
    public store: Store<AppState>,
    public config: AppConfigService,
    private clipboard: Clipboard
  ) {  }

  ngOnInit(): void { }
  ngOnDestroy(): void { }
  ngAfterViewInit(): void { }

  close() {
    this.sessionInfoClose.emit(false);
  }
  
  // copyLinksOnClipboard() {
  //   // let cardPart = '';
  //   // let infoPart = ''
  //   // const info = document.getElementById('info-tooltip')
  //   // if (info) {
  //   //   var r = document.createRange();
  //   //   r.selectNode(info!);
  //   //   window.getSelection()?.removeAllRanges();
  //   //   window.getSelection()?.addRange(r);
  //   //   infoPart = window.getSelection()?.toString() || '';
  //   // }

  //   // const card = document.getElementById('card-info')
  //   // if(card) {
  //   //   var q = document.createRange();
  //   //   q.selectNode(card!);
  //   //   window.getSelection()?.removeAllRanges();
  //   //   window.getSelection()?.addRange(q);
  //   //   cardPart = window.getSelection()?.toString() || '';
  //   // }
  //   // const elem = document.createElement('textarea');
  //   // elem.value = `${cardPart}\n\n${infoPart!.replace('close', '')}`;
  //   // document.body.appendChild(elem);
  //   // elem.select();
  //   // document.execCommand('copy');
  //   // document.body.removeChild(elem);
  //   // window.getSelection()?.removeAllRanges();
  //   let str = '';
  //   this.AS.session.parameters.partecipantIds.forEach( (p, idx) => {
  //     const link = this.AS.session.links[idx];
  //     str = `${str}${I18Service.roleAndAlias(p)} => ${link.href}\n`;
  //   })

  //   this.clipboard.copy(str);
  //   NotificationService.showSuccess(`${I18Service.get(`SNACKBAR.TEXTCOPIED`)}`);
  // }
  // copyLink(idx: number) {
  //   const link = this.AS.session.links[idx];
  //   this.clipboard.copy(link.href);
  //   NotificationService.showSuccess(`${I18Service.get(`SNACKBAR.TEXTCOPIED`)}`);
  // }

}