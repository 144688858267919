<div id="dialog-container">
  <div id="dialog-title">
    <span>{{ 'LABELS.ACCESSIBILITY' | translate }}</span>
    <div [mat-dialog-close]="undefined">
      <span id="close-icon" class="material-symbols-outlined icon-close-button">close</span>
    </div>
  </div>
  <progress *ngIf="loading"></progress>
  <div id="accessibility-dialog-body">
    <div class="accessibility-row">
      <mat-checkbox [disabled]="loading">
        {{ 'ACCESSIBILITY.DEAF' | translate }}
      </mat-checkbox>
    </div>
    <div class="accessibility-row">
      <mat-checkbox [disabled]="loading">
        <!-- [(ngModel)]="this.config.partecipant?.parameters?.accessibility.mute"> -->
        {{ 'ACCESSIBILITY.MUTE' | translate }}
      </mat-checkbox>
    </div>
    <div class="accessibility-row">
      <mat-checkbox [disabled]="loading">
        <!-- [(ngModel)]="this.config.partecipant?.parameters?.accessibility.handless"> -->
        {{ 'ACCESSIBILITY.HANDLESS' | translate }}
      </mat-checkbox>
    </div>
  </div>

  <div id="dialog-actions">
    <button mat-raised-button [disabled]=" loading" class="save-button" (click)="close(1)" cdkFocusInitial
      [ngStyle]="{'opacity': loading ? '0.5' : '1'}">
      {{ 'POPUP.SAVE' | translate }}
    </button>
  </div>

</div>

