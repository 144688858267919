import { Component, ElementRef, HostListener, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState, initialAppState } from './store/states/app.state';
import * as appSelectors from './store/selectors/app.selectors';
import { Subject, takeUntil } from 'rxjs';
import { ErrorInfo } from './models/errorInfo';
import { RootState } from './store/root.state';
import * as sessionSelectors from './store/selectors/session.selectors';
import { Session, initialSession } from './models/WebServer/session';
import * as sessionStatusActions from "./store/actions/sessionStatus.actions";
import { Constants } from './constants';
import { AppConfigService } from './services/app.config.service';
import { BrowserHelper } from './helpers/browser.helper';
import { SessionStatusState } from './store/states/sessionStatus.state';
import { I18Service } from './services/i18.service';
import { NotificationService } from './services/notification.service';

import { PartecipantStatus, initialPartecipantStatus } from './models/WebServer/partecipantStatus';
import * as selectorSessionStatus from "src/app/store/selectors/sessionStatus.selectors";
import { CheckerService } from './services/checker.service';
import * as appActions from './store/actions/app.actions';
import { AppHelper } from './helpers/app.helper';
import { initialSessionConfig } from './models/WebServer/sessionConfig';
import { FELoggerService } from './services/feLogger.service';
import { DisclaimerHelper } from './helpers/disclaimer.helper';
import { FileSenderHelper } from './helpers/fileSender.helper';
import { StatusChangeEvent } from './models/statusChangeEvent';
import { SipService } from './services/sip.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  errorInfo: ErrorInfo | undefined;
  AS: AppState = initialAppState;
  MYSTATUS: PartecipantStatus = initialPartecipantStatus;
  session: Session = initialSession;

  sessionStatus: SessionStatusState | undefined;
  afterInit = false;
  afterInitLock = false;
  title = AppHelper.getTitleName();

  @ViewChild('popupDownload',{static: false})
  popupDownload!: ElementRef;

  constructor(
    public config: AppConfigService,
    private checker: CheckerService,

    private readonly store: Store<RootState>,
    //instantiate
    private s: NotificationService,
    private l: FELoggerService,
    private d: DisclaimerHelper,
    private f: FileSenderHelper,
    private sipService: SipService,
    private transHelper: I18Service) {

    // console.debug(`********** AppComponent constructor`)
    document.title = AppHelper.getTitleVersion();

    this.store.dispatch(appActions.loadingMessage({ message: I18Service.get("Loading.Configuration") }));

    this.store.select(appSelectors.selectAppState).pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.AS = data;
        this.errorInfo = data?.errorInfo;
        data.appReady && this.afterLoadInit();

        // console.debug(`******* appReady ${data.appReady}`);
        if (!data.appReady) {
          this.afterInit = false;
          this.afterInitLock = false;
          this.checker.stop();
        }
      });

    this.store.select(sessionSelectors.selectSession).pipe(takeUntil(this.destroy$))
      .subscribe(data => { this.session = data; });

    this.store.select(selectorSessionStatus.selectSessionStatus).pipe(takeUntil(this.destroy$))
      .subscribe(data => this.sessionStatus = data);


    this.store.select(selectorSessionStatus.myPartecipantStatus).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      // this.sessionStatus = data; 
      if (data) this.MYSTATUS = data
      console.debug(`AppComponent myStatus`, data)
    });

  }

  private afterLoadInit() {
    if (this.afterInitLock) return;
    console.log(`AppComponent afterLoadInit`)
    this.afterInitLock = true;
    this.manageLanguageSet(this.session.config.agencySettings.defaultLanguage).then((lang: string) => {
      this.checkBrowserComp(this.AS.isCaller);
      console.log(`translation agencySetting language ${this.session.config.agencySettings.defaultLanguage} [${lang}]`)
      this.checker.init();
      this.store.dispatch(appActions.loadingMessage({message: ''}))
      
      this.afterInit = true;  

      const mode = AppHelper.getInitialAppMode(this.config.sessionConfig || initialSessionConfig, this.MYSTATUS.partecipantRole);
      if (mode) this.store.dispatch(appActions.appModeChange({ mode }))

    });

    NotificationService.popupDownload = this.popupDownload.nativeElement;


  }
  private manageLanguageSet(defaultLan: string): Promise<string> {
    let rawLang = navigator.language;
    const lanParts = rawLang.split('-');
    let languageSet = Constants.DEFAULT_LANGUAGE;
    defaultLan = defaultLan || Constants.DEFAULT_LANGUAGE;

    if (lanParts.length > 1) {
      rawLang = lanParts[0];
    }
    if (Constants.ALLOWED_LANGUAGES.some(l => l === rawLang)) {
      console.info(`AppComponent manageLanguageSet using ${rawLang} Language`);
      languageSet = rawLang;
    } else {
      console.info(`AppComponent manageLanguageSet using default Language --> ${defaultLan}. Language ${rawLang} not supported`);
      languageSet = defaultLan;
    }

    return this.transHelper.setLanguage(defaultLan);
  }



  private checkBrowserComp(isCaller: boolean) {

    const deviceControls = (isCaller) ? this.config.deviceControls : this.config.operatorDeviceControls;
    const resultBrowserComp = BrowserHelper.getBrowserCompatibility(deviceControls);
    const parsedUA = BrowserHelper.getParsedUa();

    var techErrors = BrowserHelper.checkBrowserTech();
    if (techErrors.length) {
      resultBrowserComp.success = false;
      resultBrowserComp.message += `\n${techErrors.join('\n')}`
    }
    const language = (this.config.getUrlParam(Constants.PARAM_BROWSER_LANG) || navigator.language || (navigator as any)['userLanguage']).split('-')[0];
    (isCaller) && I18Service.setNavigatorLanguage(language);

    const multiStatus = [
      { evt: StatusChangeEvent.UserLanguage, val: language },
      { evt: StatusChangeEvent.BrowserControlResult, val: resultBrowserComp },
      { evt: StatusChangeEvent.DeviceInfoUpdate, val: parsedUA },
      { evt: StatusChangeEvent.PageInfoUpdate, val: AppHelper.getPageState() }
    ]

    this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MultipleUpdateStatus, val: multiStatus }));

    // const operatorResultBrowserComp = BrowserHelper.checkBroserCompatibility(this.config.operatorDeviceControls);

    // this.config.browserCompatibility = new BrowserCompatibility(resultBrowserComp, techErrors);
    // this.config.operatorBrowserCompatibility = new BrowserCompatibility(operatorResultBrowserComp, []);
    // console.log('Browser COMP', this.config.browserCompatibility, this.config.operatorBrowserCompatibility, this.AS.parsedUA);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();

  }
  @HostListener('document:keydown.control.alt.shift.q', ['$event'])
  openDebug(e: KeyboardEvent) {
    e.preventDefault();
    this.sipService.showCurrentSessions();
  }
}

