import { InvitationAcceptOptions } from "../../../api/invitation-accept-options";
import { InviterInviteOptions } from "../../../api/inviter-invite-options";
import { InviterOptions } from "../../../api/inviter-options";
import { Logger } from "../../../core/log/logger";
import { Message } from "../../../api/message";
import { RegistererRegisterOptions } from "../../../api/registerer-register-options";
import { RegistererUnregisterOptions } from "../../../api/registerer-unregister-options";
import { Session } from "../../../api/session";
import { SessionManager } from "../session-manager/session-manager";
import { SessionManagerOptions } from "../session-manager/session-manager-options";
import { SimpleUserDelegate } from "./simple-user-delegate";
import { SimpleUserOptions } from "./simple-user-options";
import { Invitation, Inviter, MessagerOptions } from "../../..";

/**
 * A simple SIP user class.
 * @remarks
 * While this class is completely functional for simple use cases, it is not intended
 * to provide an interface which is suitable for most (must less all) applications.
 * While this class has many limitations (for example, it only handles a single concurrent session),
 * it is, however, intended to serve as a simple example of using the SIP.js API.
 * @public
 */
export class SimpleUser {
  /** Delegate. */
  public delegate: SimpleUserDelegate | undefined;

  private logger: Logger;
  private options: SimpleUserOptions;
  // private session: Session | undefined = undefined;
  private sessionManager: SessionManager;

  //dave: multiSession-conference
  // public sessions: Session[] = [];
  get sessions():Session[] { return this.sessionManager.managedSessions.map( ms => ms.session); }
  get session() { return this.sessions.length ? this.sessions[ this.sessions.length -1] : undefined }
  public onCallWith(to: string) { const s = this.sessionManager.managedSessions.find( z => z.session.remoteIdentity.uri.user == to); return !!s;}
  public sessionCount(to: string){ const s = this.sessionManager.managedSessions.filter( z => z.session.remoteIdentity.uri.user == to); return s.length;}
  /**
   * Constructs a new instance of the `SimpleUser` class.
   * @param server - SIP WebSocket Server URL.
   * @param options - Options bucket. See {@link SimpleUserOptions} for details.
   */
  constructor(server: string, options: SimpleUserOptions = {}) {
    // Delegate
    this.delegate = options.delegate;

    // Copy options
    this.options = { ...options };

    // Session manager options
    const sessionManagerOptions: SessionManagerOptions = {
      aor: this.options.aor,
      delegate: {
        onCallAnswered: (session: Session) => this.delegate?.onCallAnswered?.(session),
        onCallCreated: (session: Session) => {
          // this.sessions.push(session);//dave - multisession - addd
          // this.session = session; //OLD
          this.delegate?.onCallCreated?.(session);
        },
        onCallReceived: (invitation: Invitation) => this.delegate?.onCallReceived?.(invitation),
        onCallHangup: (session: Session) => {

          // this.session = undefined; //OLD
          // if(!this.sessions.length) //dave  multisession - hangup only for last call.. spostato su sipservice
          this.delegate?.onCallHangup && this.delegate?.onCallHangup(session);
        },
        onCallHold: (s: Session, held: boolean) => this.delegate?.onCallHold?.(held),
        onCallDTMFReceived: (s: Session, tone: string, dur: number) => this.delegate?.onCallDTMFReceived?.(tone, dur),
        onMessageReceived: (message: Message) => this.delegate?.onMessageReceived?.(message.request.from.uri, message.request.body),
        onRegistered: () => this.delegate?.onRegistered?.(),
        onUnregistered: () => this.delegate?.onUnregistered?.(),
        onServerConnect: () => this.delegate?.onServerConnect?.(),
        onServerDisconnect: (e) => this.delegate?.onServerDisconnect?.(e),
        onAttemptReconnection: (n,m) => this.delegate?.onAttemptReconnection?.(n,m),
      },
      maxSimultaneousSessions: 0,
      media: this.options.media,
      reconnectionAttempts: this.options.reconnectionAttempts,
      reconnectionDelay: this.options.reconnectionDelay,
      registererOptions: this.options.registererOptions,
      sendDTMFUsingSessionDescriptionHandler: this.options.sendDTMFUsingSessionDescriptionHandler,
      userAgentOptions: this.options.userAgentOptions
    };

    this.sessionManager = new SessionManager(server, sessionManagerOptions);

    // Use the SIP.js logger
    this.logger = this.sessionManager.userAgent.getLogger("sip.SimpleUser");
  }

  /**
   * Instance identifier.
   * @internal
   */
  get id(): string {
    return (this.options.userAgentOptions && this.options.userAgentOptions.displayName) || "Anonymous";
  }

  // get currentSession() : Session | undefined { return this.session; }

  /** The local media stream. Undefined if call not answered. */
  // get localMediaStream(): MediaStream | undefined {
  //   return this.session && this.sessionManager.getLocalMediaStream(this.session);
  // }

  // /** The remote media stream. Undefined if call not answered. */
  // get remoteMediaStream(): MediaStream | undefined {
  //   return this.session && this.sessionManager.getRemoteMediaStream(this.session);
  // }

  gerRemoteMediaStream(session: Session): MediaStream | undefined {
    return this.session && this.sessionManager.getRemoteMediaStream(session);
  }

  /**
   * The local audio track, if available.
   * @deprecated Use localMediaStream and get track from the stream.
   */
  // get localAudioTrack(): MediaStreamTrack | undefined {
  //   return this.session && this.sessionManager.getLocalAudioTrack(this.session);
  // }

  // /**
  //  * The local video track, if available.
  //  * @deprecated Use localMediaStream and get track from the stream.
  //  */
  // get localVideoTrack(): MediaStreamTrack | undefined {
  //   return this.session && this.sessionManager.getLocalVideoTrack(this.session);
  // }

  /**
   * The remote audio track, if available.
   * @deprecated Use remoteMediaStream and get track from the stream.
   */
  // get remoteAudioTrack(): MediaStreamTrack | undefined {
  //   return this.session && this.sessionManager.getRemoteAudioTrack(this.session);
  // }

  /**
   * The remote video track, if available.
   * @deprecated Use remoteMediaStream and get track from the stream.
   */
  // get remoteVideoTrack(): MediaStreamTrack | undefined {
  //   return this.session && this.sessionManager.getRemoteVideoTrack(this.session);
  // }

  /**
   * Connect.
   * @remarks
   * Start the UserAgent's WebSocket Transport.
   */
  public connect(): Promise<void> {
    this.logger.log(`[${this.id}] Connecting UserAgent...`);
    return this.sessionManager.connect();
  }

  /**
   * Disconnect.
   * @remarks
   * Stop the UserAgent's WebSocket Transport.
   */
  public disconnect(): Promise<void> {
    this.logger.log(`[${this.id}] Disconnecting UserAgent...`);
    return this.sessionManager.disconnect();
  }

  /**
   * Return true if connected.
   */
  public isConnected(): boolean {
    return this.sessionManager.isConnected();
  }

  /**
   * Start receiving incoming calls.
   * @remarks
   * Send a REGISTER request for the UserAgent's AOR.
   * Resolves when the REGISTER request is sent, otherwise rejects.
   */
  public register(registererRegisterOptions?: RegistererRegisterOptions): Promise<void> {
    this.logger.log(`[${this.id}] Registering UserAgent...`);
    return this.sessionManager.register(registererRegisterOptions);
  }

  /**
   * Stop receiving incoming calls.
   * @remarks
   * Send an un-REGISTER request for the UserAgent's AOR.
   * Resolves when the un-REGISTER request is sent, otherwise rejects.
   */
  public unregister(registererUnregisterOptions?: RegistererUnregisterOptions): Promise<void> {
    this.logger.log(`[${this.id}] Unregistering UserAgent...`);
    return this.sessionManager.unregister(registererUnregisterOptions);
  }

  /**
   * Make an outgoing call.
   * @remarks
   * Send an INVITE request to create a new Session.
   * Resolves when the INVITE request is sent, otherwise rejects.
   * Use `onCallAnswered` delegate method to determine if Session is established.
   * @param destination - The target destination to call. A SIP address to send the INVITE to.
   * @param inviterOptions - Optional options for Inviter constructor.
   * @param inviterInviteOptions - Optional options for Inviter.invite().
   */
  public call(
    destination: string,
    inviterOptions?: InviterOptions,
    inviterInviteOptions?: InviterInviteOptions
  ): Promise<void> {
    this.logger.log(`[${this.id}] Beginning Session...`);
    // if (this.session) {
    //   return Promise.reject(new Error("Session already exists."));
    // }
    return this.sessionManager.call(destination, inviterOptions, inviterInviteOptions).then(() => {
      return;
    });
  }

  /**
   * Hangup a call.
   * @remarks
   * Send a BYE request, CANCEL request or reject response to end the current Session.
   * Resolves when the request/response is sent, otherwise rejects.
   * Use `onCallHangup` delegate method to determine if and when call is ended.
   */
  public hangup(user: string): Promise<void> { //dave: multisession-- close all sessions
    this.logger.log(`[${this.id}] Hangup... actual sessions ${this.sessions.length}`);
    // if (!this.session) {
    //   return Promise.reject(new Error("Session does not exist."));
    // }
    // return this.sessionManager.hangup(this.session).then(() => {
    //   this.session = undefined;
    // });
    if (!this.sessions.length) {
      return Promise.reject(new Error("Session does not exist."));
    }
    // if (user) { // close single call
      const calls = this.sessions.filter( s => user == s.remoteIdentity?.uri?.user || user == s.localIdentity?.uri?.user)
      const promises = calls.map( (s: Session) => { 
        return this.sessionManager.hangup(s).then(()=>{
          this.logger.log(`[${this.id}] Hangup single session: ${s.id}`);
          // this.sessions = this.sessions.filter( z => z.id != s.id);
          //this.session = undefined; //old
        })
      })
      return Promise.all(promises).then();
    // }
  }
  public hangupAll(): Promise<void> {
    if (!this.sessions.length) {
      return Promise.reject(new Error("Session does not exist."));
    }
    //close all calls
    const promises = this.sessions.map( (s: Session) => { 
      return this.sessionManager.hangup(s).then(()=>{
        this.logger.log(`[${this.id}] Hangup all session: ${s.id}`);
        // this.sessions = this.sessions.filter( z => z.id != s.id);
        //this.session = undefined; //old
      })
    })

    return Promise.all(promises).then();
  }

  /**
   * Answer an incoming call.
   * @remarks
   * Accept an incoming INVITE request creating a new Session.
   * Resolves with the response is sent, otherwise rejects.
   * Use `onCallAnswered` delegate method to determine if and when call is established.
   * @param invitationAcceptOptions - Optional options for Inviter.accept().
   */
  public answer(invitationAcceptOptions?: InvitationAcceptOptions): Promise<void> { //todo answer what session?
    this.logger.log(`[${this.id}] Accepting Invitation...`);
    if (!this.session) {
      return Promise.reject(new Error("Session does not exist."));
    }
    return this.sessionManager.answer(this.session, invitationAcceptOptions);
  }

  /**
   * Decline an incoming call.
   * @remarks
   * Reject an incoming INVITE request.
   * Resolves with the response is sent, otherwise rejects.
   * Use `onCallHangup` delegate method to determine if and when call is ended.
   */
  public decline(invitation: Invitation): Promise<void> {
    // this.logger.log(`[${this.id}] rejecting Invitation...`);
    // if (!this.session) {
    //   return Promise.reject(new Error("Session does not exist."));
    // }
    // return this.sessionManager.decline(this.session);
    this.logger.log(`[${invitation.id}] rejecting Invitation...`);
    return this.sessionManager.decline(invitation);
  }

  /**
   * Hold call
   * @remarks
   * Send a re-INVITE with new offer indicating "hold".
   * Resolves when the re-INVITE request is sent, otherwise rejects.
   * Use `onCallHold` delegate method to determine if request is accepted or rejected.
   * See: https://tools.ietf.org/html/rfc6337
  //  */
  // public hold(): Promise<void> {
  //   this.logger.log(`[${this.id}] holding session...`);
  //   if (!this.session) {
  //     return Promise.reject(new Error("Session does not exist."));
  //   }
  //   return this.sessionManager.hold(this.session);
  // }

  /**
   * Unhold call.
   * @remarks
   * Send a re-INVITE with new offer indicating "unhold".
   * Resolves when the re-INVITE request is sent, otherwise rejects.
   * Use `onCallHold` delegate method to determine if request is accepted or rejected.
   * See: https://tools.ietf.org/html/rfc6337
   */
  // public unhold(): Promise<void> {
  //   this.logger.log(`[${this.id}] unholding session...`);
  //   if (!this.session) {
  //     return Promise.reject(new Error("Session does not exist."));
  //   }
  //   return this.sessionManager.unhold(this.session);
  // }

  /**
   * Hold state.
   * @remarks
   * True if session is on hold.
   */
  // public isHeld(): boolean {
  //   return this.session ? this.sessionManager.isHeld(this.session) : false;
  // }

  /**
   * Mute call.
   * @remarks
   * Disable sender's media tracks.
   */
  // public mute(): void {
  //   this.logger.log(`[${this.id}] disabling media tracks...`);
  //   return this.session && this.sessionManager.mute(this.session);
  // }

  /**
   * Unmute call.
   * @remarks
   * Enable sender's media tracks.
   */
  // public unmute(): void {
  //   this.logger.log(`[${this.id}] enabling media tracks...`);
  //   return this.session && this.sessionManager.unmute(this.session);
  // }

  /**
   * Mute state.
   * @remarks
   * True if sender's media track is disabled.
   */
  // public isMuted(): boolean {
  //   return this.session ? this.sessionManager.isMuted(this.session) : false;
  // }

  /**
   * Send DTMF.
   * @remarks
   * Send an INFO request with content type application/dtmf-relay.
   * @param tone - Tone to send.
   */
  // public sendDTMF(tone: string): Promise<void> { //todo send to all session
  //   this.logger.log(`[${this.id}] sending DTMF...`);
  //   if (!this.session) {
  //     return Promise.reject(new Error("Session does not exist."));
  //   }
  //   return this.sessionManager.sendDTMF(this.session, tone);
  // }

  /**
   * Send a message.
   * @remarks
   * Send a MESSAGE request.
   * @param destination - The target destination for the message. A SIP address to send the MESSAGE to.
   */
  public message(destination: string, message: string, contentType = "text/plain", options: MessagerOptions = {}): Promise<void> {
    this.logger.log(`[${this.id}] sending message to ${destination}...`);
    return this.sessionManager.message(destination, message, contentType, options);
  }


  public stopSendingStreams() {
    console.log(`simpleUser - setupLocalMediaForCall - stopSendingStreams for ${this.sessions.length} sessions`)
    this.sessions.forEach( s =>  {
      const ms = this.sessionManager.getLocalMediaStream(s);
      if (ms) {
        console.log(`simpleUser - setupLocalMediaForCall - stopSendingStream for ${ms.getTracks().length} tracks`)
        ms.getTracks().forEach( t => t.stop())
      };
    } )
  }


  public showCurrentSessions(who: string): any {
    console.log(`showCurrentSessions: ${this.sessionManager.managedSessions.length} for ${who} =========>`)
    const list: any[] = [];
    this.sessionManager.managedSessions.forEach((s, idx) => {
      const z = {
        count: idx,
        callId: s.session.id,
        state: s.session.state,
        remote: `${s.session.remoteIdentity.displayName} - ${s.session.remoteIdentity.uri.user}`,
        direction: (s.session instanceof Inviter ) ? 'OUTGOING': 'INCOMING'
      }
      console.log(z);
      list.push(z);
    })
    return list;
  }

}
