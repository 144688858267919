import { Component, OnInit, OnDestroy, AfterViewInit, Input, ViewChild } from "@angular/core";
import { Subject, Subscription, takeUntil } from "rxjs";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AppModesEnum } from "src/app/models/WebServer/appModesEnum";
import { PhoneStatusEnum } from "src/app/models/phoneStatus";
import { AppConfigService } from "src/app/services/app.config.service";
import { PositionManagerComponent } from "../position-manager.component";
import { AppState, initialAppState } from "src/app/store/states/app.state";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store/root.state";
// import { selectSessionState } from "src/app/store/selectors/session.selectors";
import { DisclaimerHelper } from "src/app/helpers/disclaimer.helper";
import { Session } from "src/app/models/WebServer/session";
import * as appActions from "src/app/store/actions/app.actions";
import * as sessionStatusActions from "src/app/store/actions/sessionStatus.actions";
import * as selectorSessionStatus from "src/app/store/selectors/sessionStatus.selectors";
// import { selectSessionStatusState } from "src/app/store/selectors/sessionStatus.selectors";
import { PartecipantStatus } from "src/app/models/WebServer/partecipantStatus";

import { I18Service } from "src/app/services/i18.service";
import { CheckTypeEvent, CheckerService, TickEvent } from "src/app/services/checker.service";
import { MultimediaManagerCallerComponent } from "../../multimedia-manager/multimedia-manager-caller.component";
import { NotificationService } from "src/app/services/notification.service";
import { StatusChangeEvent } from "src/app/models/statusChangeEvent";
import { FELoggerService } from "src/app/services/feLogger.service";

@Component({
  selector: 'app-caller',
  templateUrl: './caller.component.html',
  styleUrls: ['./caller.component.scss']
})
export class CallerComponent implements OnInit, OnDestroy, AfterViewInit {
  multimediaDialogRef?: MatDialogRef<any>;
  _AS: AppState = initialAppState;
  get AS() { return this._AS;}
  @Input() set AS (val: AppState){
    this._AS = val;
    if(this.multimediaDialogRef)this.multimediaDialogRef.componentInstance.AS = this.AS;
  };
  @Input() MYSTATUS!: PartecipantStatus;

  @Input() session!: Session;
  @ViewChild('multimediamanager') multimediamanager?: MultimediaManagerCallerComponent;
  @ViewChild('position') position!: PositionManagerComponent;
  

  private destroy$: Subject<boolean> = new Subject<boolean>();
  ticksubscription: Subscription;
 

  //JQ Element
  header: any;
  menuItems: any;
  headerBg: any;

  appModes = AppModesEnum;
  phoneStatuses = PhoneStatusEnum;
  videoPreviewWidth!: number;
  videoPreviewHeight!: number;

  // afterInit = false;

  PRIMARYSTATUS?: PartecipantStatus;


  constructor(
    public checkerService: CheckerService,
    public config: AppConfigService,
    private store: Store<RootState>,
    public matDialog: MatDialog,
  ) {

    this.store.select(selectorSessionStatus.primaryPartecipantStatus).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => this.PRIMARYSTATUS = data);

    this.ticksubscription =this.checkerService.tick$.subscribe({
      next: (x: TickEvent) => this.checkTick(x)
    })

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    // this.checkerService.tick$.unsubscribe();
    this.ticksubscription.unsubscribe();
  }

  async ngOnInit() {
    if (!navigator.geolocation) {
      NotificationService.popupWarn({msg: I18Service.get('POPUP.UNUSABLEDEVICE')});
      return;
    }

    if (!this.AS.errorInfo) {

      DisclaimerHelper.showDisclaimer( () => {
        this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.DisclaimerChoiceRevealed, val: true }));
      })
        .then(result => {
          const afterInit = result || !this.config.agencySettings.blockingDisclaimer;
          // console.log(`***** DisclaimerAccepted ${afterInit}`)
          FELoggerService.log(`***** DisclaimerAccepted ${afterInit}`)
          
          const multiStatus = [
            { evt: StatusChangeEvent.DisclaimerAccepted, val: afterInit },
            { evt: StatusChangeEvent.DisclaimerChoiceRevealed, val: false }
          ]
          this.store.dispatch(sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MultipleUpdateStatus, val: multiStatus }));
          


          if (!afterInit) {
            this.store.dispatch(appActions.showErrorPage({ message: I18Service.get('ERRORPAGE.DISCLAIMER'), imagePath: 'assets/images/alert-error.svg' }));
          }
        });
    }

  }

  async ngAfterViewInit() { }

  private checkTick(x: TickEvent) {
    // console.log(`checkTick => ${JSON.stringify(x)}`);
    switch (x.type) {
      case CheckTypeEvent.sendLocationInfo:
        if(this.config.sessionConfig?.localization) {
          this.position?.sendCurrentLocation();
        }
        break;
      case CheckTypeEvent.tryNewGeoLocAttempt:
        this.position.newGeoAttempt();
        break;
    }
  }

  
  changeMode(mode: AppModesEnum, local: boolean = true) {
    // const appInMultimedia = mode != AppModesEnum.Map;
    // appInMultimedia && this.forceCloseMultimediaDialog();
    this.multimediamanager && this.multimediamanager.changeMode(mode, local);
    // this.closeHeaderBgIfOpen();
    // this.store.dispatch(appActions.appModeChange({mode}));
  }

  sendLocationImmediately() {
    if (this.position) {
      this.position.sendLocationImmediately(true);
    }
  }

  showMultimediaDialog() {
    // console.log(`MultimediaDialogComponent show`)
    this.multimediaDialogRef = NotificationService.showMultimediaDialog(this.AS);
    this.multimediaDialogRef.componentInstance.AS = this.AS;

    this.multimediaDialogRef.afterClosed().subscribe(result => {
      this.multimediaDialogRef = undefined;
      console.log(`MultimediaDialogComponent afterClose: ${result}`)
      if (result || result === 0) {
        if (result === 'mic' && this.multimediamanager) {
          this.manageMicToggle();
          return;
        } else {
          this.changeMode(result);
          return;
        }
      }
    });
    
  }

  forceCloseMultimediaDialog() {
    NotificationService.closeMultimediaDialog();
  }

  async manageMicToggle() {
    this.store.dispatch(appActions.toggleMicrophone());
  }
}
