import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { EMPTY, catchError, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import { selectAppState } from "../selectors/app.selectors";
import * as appActions from "../actions/app.actions";
import * as sessionActions from "../actions/session.actions";
import * as sessionStatusActions from "../actions/sessionStatus.actions";
import { SessionService } from "src/app/services/session.service";
import { RootState } from "../root.state";
import { I18Service } from "src/app/services/i18.service";
// import { selectSessionState } from "../selectors/session.selectors";
import { EventService } from "src/app/services/event.service";
import { NotificationService } from "src/app/services/notification.service";
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";
import { DatePipe } from "@angular/common";
import { FELoggerService, LogSource } from "src/app/services/feLogger.service";

import { AppConfigService } from "src/app/services/app.config.service";
import { CommandEvent } from "src/app/models/commandEvent";




@Injectable()
export class SessionEffects {

  constructor(
    private datePipe: DatePipe,
    private eventService: EventService,
    private sessionService: SessionService,
    private readonly config: AppConfigService,
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
  ) {
    console.debug(`SessionEffects constructor`);
  }

  sessionCheck$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.sessionCheck),
    withLatestFrom(this.store.select(selectAppState)),
    switchMap(([action, state]) => {
      //console.debug(`******** sessionCheck$ !!!!!!!!!`);
      if (state.errorInfo) {
        return EMPTY;
      }
      this.store.dispatch(appActions.loadingMessage({ message: I18Service.get("Loading.CheckSession") }));
      return this.sessionService.getSession(state.partecipantId).pipe(
        map(response => sessionActions.sessionOk({ session: response }))
      );
    }
    ),
    catchError((error: any) => {

      const failure = (error.status == 404)
        ? { error: { message: I18Service.get('POPUP.SESSIONEXPIREDBODY') }, showReload: false }
        : { error };

      return of(sessionActions.sessionFailure(failure))
    })
  )
    //   ,
    //   { dispatch: false }
  );

  sessionOk$ = createEffect(() => this.actions$.pipe(() => this.actions$.pipe(
    ofType(sessionActions.sessionOk),
    map((_) => sessionActions.partecipantCheck())
  )
  ));

  partecipantCheck$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.partecipantCheck),
    withLatestFrom(this.store.select(selectAppState)),
    switchMap(([action, state]) => {
      if (!state.isReceiver && state.sessionExpired) {
        return of(appActions.showErrorPage({ message: I18Service.get('POPUP.SESSIONEXPIREDTITLE'), showReload: false }));
      }
      //console.debug(`******** sessionActions.partecipantCheck ppAction`)
      return this.sessionService.getPartecipant(state.partecipantId).pipe(
        map(response => sessionActions.partecipantOk({ partecipant: response }))
      );
    }
    ),
    catchError((error: any) => {
      return of(sessionActions.sessionFailure({ error }))
    })
  ));

  sessionLoaded$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.partecipantOk),
    // switchMap((action) => this.eventService.getLastPositionEvent(action.partecipant.partecipantId)),
    switchMap((evt) => { 
      
      return [
      sessionActions.sessionLoaded(),
      sessionActions.lastServerPositionLoad()
    ]})
  ));

  lastPositionLoad$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.lastServerPositionLoad),
    switchMap((action) => this.eventService.getLastPositionEvent(this.config.partecipantId)),
    switchMap((evt) => [
      sessionActions.lastServerPosition({ evt })
    ])
  ));

  

  failure$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.sessionFailure),
    tap(z => {
      FELoggerService.error("failureEffect", LogSource.Default, z)
      const error = z.error?.statusText || z.error?.message || z.error?.toString() || 'failure';
      const showReload = z.showReload !== undefined ? z.showReload : true;
      this.store.dispatch(appActions.showErrorPage({ message: error, showReload }))
    })

  ), { dispatch: false }
  );

  // getChatHistory$ = createEffect(() => this.actions$.pipe(
  //   ofType(sessionActions.sessionLoaded),
  //   withLatestFrom(this.store.select(selectSession)),
  //   switchMap(([_, session]) => {

  //     return this.eventService.getChatHistory(this.config.partecipantId).pipe(
  //       map(response => sessionActions.chatHistoryLoaded({ messages: response }))
  //     )
  //   }),
  //   catchError((error: any) => {
  //     return of(sessionActions.sessionFailure({ error }))
  //   })
  // ));

  addGuest$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.addGuest),
    map(({ phoneNumber, email, alias, viewPreviousChat, superUser }) => {
      this.sessionService.addGuest(phoneNumber, email, alias, viewPreviousChat, superUser).subscribe(
        session => {
          if (session.parameters.partecipantIds.some(z => z.partecipantRole == PartecipantRoles.GuestClient)) {
            NotificationService.showSuccess(I18Service.get('Notify.Guest.Added'))
            this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.ReloadSession, val:'', toIds: appActions.SEND_TO_ALL}));
            this.store.dispatch(sessionActions.sessionReload({session}));
          }
          else {
            NotificationService.showSuccess(I18Service.get('Notify.Guest.NotAdded'))
          }
        }
      );
    }),
    catchError((error: any) => {
      FELoggerService.error(`addGuest$`, LogSource.Default, error)
      NotificationService.showError(`${error}`)
      return of(0)
    })
  ), { dispatch: false });

  removeGuest$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.removeGuest),
    map(({ guestId }) => {
      this.sessionService.removeGuest(guestId).subscribe(
        session => {
          const guestPartecipant = session.parameters.partecipantIds.find(z => z.partecipantId == guestId);
          if (!guestPartecipant?.active) {
            NotificationService.showSuccess(I18Service.get('Notify.Guest.Removed'))
            this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.ReloadSession, val:'', toIds: appActions.SEND_TO_ALL}));
            this.store.dispatch(sessionActions.sessionReload({session}));
          }
          else {
            NotificationService.showError(I18Service.get('Notify.Guest.NotRemoved'))
          }
        }
      );
    }),
    catchError((error: any) => {
      FELoggerService.error(`addGuest$`, LogSource.Default, error)
      NotificationService.showError(`${error}`)
      return of(0)
    })
  ), { dispatch: false });


  notifyExpiration = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.notifyExpiration),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([action, state]) => {
      if (state.sessionExpired || state.sessionExtending) {
        return; //donothing
      }

      // const minutes_or_seconds = ( action.in_sec / 60 < 1) ? `${action.in_sec}sec` : `${action.in_sec / 60}min`;
      const minutes_or_seconds = this.datePipe.transform(new Date(action.in_msec), 'mm:ss')
      NotificationService.showError(`${I18Service.get('Notify.Expiring.Session')} ${minutes_or_seconds}`)
    })

  ), { dispatch: false })

  expirationUpdate = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.expirationUpdated),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([action, state]) => {
      // const minutes_or_seconds = ( action.in_sec / 60 < 1) ? `${action.in_sec}sec` : `${action.in_sec / 60}min`;
      // const x = new Date(action.newExpiration).getTime() - MAX_NOTIFY_EXPIRATION
      // if ( x > new Date().getTime())
      if (state.sessionExpired || state.sessionExtending) {
        return; //donothing
      }
      NotificationService.showSuccess(`${I18Service.get('Notify.Expiring.Update')}`)
    })

  ), { dispatch: false })
  // updateParameters = createEffect( ()=> this.actions$.pipe(
  //   ofType(sessionActions.updatePartecipantParameters),
  //   withLatestFrom(this.store.select(selectAppState)),
  //   tap( ([parameters, s]) => {
  //     this.sessionService.updatePartecipantParameters(s.partecipantId, parameters)
  //   })
  // ),
  // { dispatch: false })
}
