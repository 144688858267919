import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
import { PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';
// import { MultimediaMachineStatus } from 'src/app/models/multimediaMachineState';
import { PhoneStatusEnum } from 'src/app/models/phoneStatus';

import { AppConfigService } from 'src/app/services/app.config.service';
import { AppState } from 'src/app/store/states/app.state';
import { SvgConstants } from 'src/app/svgConstants';
import * as appActions from "src/app/store/actions/app.actions";
import { SipService } from 'src/app/services/sip.service';


@Component({
  selector: 'app-multimedia-buttons',
  templateUrl: './multimedia-buttons.component.html',
  styleUrls: ['./multimedia-buttons.component.scss']
})
export class MultimediaButtonsComponent implements OnInit {
  @Input() AS!: AppState;
  @Input() SECONDARYSTATUS?: PartecipantStatus;
  @Input() MYSTATUS!: PartecipantStatus;
  // @Input() selectorPlaceHolder: Map<string, string>;
  @Output() changeModeEvent: EventEmitter<AppModesEnum> = new EventEmitter<AppModesEnum>();
  @Output() showSessionInfo = new EventEmitter<boolean>;
  @Output() showCitizenInfo = new EventEmitter<boolean>;
  
  appModes = AppModesEnum;
  // multimediaStates = MultimediaNodeEnum;
  // machineState = MultimediaMachineStatus;
  phoneStatuses = PhoneStatusEnum;
  svgConstants = SvgConstants;

  constructor(
    private store: Store<AppState>,
    public config: AppConfigService,
    public dialog: MatDialog,
    // public sanitizer: SafeHtmlPipe,
    private sipService: SipService,
    // private snackBar: MatSnackBar
  ) { }

  // minGuestWidth: number = 700; 
  // actualWidth: number = 700;
  ngOnInit(): void {
    // if(this.AS.isGuest) {
    //   this.minGuestWidth = parseInt(getComputedStyle(document.documentElement)
    //         .getPropertyValue('--media-max-width').replace('px', '')); // 710px

    //   window.onresize = () => this.actualWidth = window.innerWidth;
    // }
  }

  changeMode(mode: AppModesEnum) {
    this.changeModeEvent.emit(mode);
  }

  toggleLocalStream() { 
    // this.changeMode(AppModesEnum.Video);
    this.store.dispatch(appActions.toggleVideo());
  }

  exit() {
    // this.AS.selectedMode = AppModesEnum.Map;
    // this.AS.appInMultimedia = false;
    // this.config.currentMultimediaState = undefined;
    this.changeModeEvent.emit(AppModesEnum.Map);
  }

  toggleMic() {
    this.store.dispatch(appActions.toggleMicrophone());

    // if (this.config.isCaller && this.config.phoneStatus != this.phoneStatuses.TALKING) {
    //   this.config.microphoneOn = !this.config.microphoneOn;
    //   this.sipService.sendInvite(this.config.targetPartecipantId, this.config.sendVideoOn, this.config.microphoneOn);
    //   return;
    // }
    // if (this.config.microphoneOn) {
    //   if (this.sipService.toggleMicrophone(false)) {
    //     this.config.microphoneOn = false;
    //     AppHelper.openSnackBar(this.snackBar, `${this.selectorPlaceHolder.get('SNACKBAR.MUTEMICROPHONE')} ${AppHelper.getIcon('microphone')}`, 'OK', this.config.agencySettings.SnackbarDefaultTimer);
    //   }
    // } else {
    //   if (this.sipService.toggleMicrophone(true)) {
    //     this.config.microphoneOn = true;
    //     AppHelper.openSnackBar(this.snackBar, `${this.selectorPlaceHolder.get('SNACKBAR.ENABLEMICROPHONE')} ${AppHelper.getIcon('microphone')}`, 'OK', this.config.agencySettings.SnackbarDefaultTimer);
    //   }
    // }
    // this.sipService.saveInvitationBusinessEvent('accepted', this.config.microphoneOn, this.config.sendVideoOn, BusinessEventTypes.SIPReinvite);
  }

  openDeviceSettings() {
    // const dialogRef = this.dialog.open(DeviceSettingsComponent, {
    //   width: '95%',
    //   data: {}
    // });
  }

  showGuestSessionInfo(show: boolean) {
    this.showSessionInfo.emit(true);
  }
  showGuestCitizenInfo(show: boolean) {
    this.showCitizenInfo.emit(true);
  }

  clickCount=0;
  lastClick=0;
  openDebug(){
    const now = Date.now();
    if ((now - this.lastClick) > 2000) {
      this.lastClick = now;
      this.clickCount = 0;
    } else {
      this.clickCount++;
      if (this.clickCount > 7){
        this.clickCount = 0;
        this.sipService.showCurrentSessions();
      }
    }
  }
}
