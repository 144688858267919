import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { map, tap, withLatestFrom } from "rxjs";
import { selectAppState } from "../selectors/app.selectors";
import * as appActions from "../actions/app.actions";
import * as sessionActions from "../actions/session.actions";
import * as sessionStatusActions from "../actions/sessionStatus.actions";
import { SessionService } from "src/app/services/session.service";
import { RootState } from "../root.state";
import { secondaryPartecipantStatus } from "../selectors/sessionStatus.selectors";

import { SipService } from "src/app/services/sip.service";
import { PartecipantRoles } from "src/app/models/WebServer/partecipantRoles";
import { AppHelper } from "src/app/helpers/app.helper";
import { AppConfigService } from "src/app/services/app.config.service";
import { initialSessionConfig } from "src/app/models/WebServer/sessionConfig";
import { I18Service } from "src/app/services/i18.service";
import { phoneChangeStatus } from "../actions/phone.actions";
import { MediaService } from "src/app/services/media.service";
import { StatusChangeEvent } from "src/app/models/statusChangeEvent";
import { CommandEvent } from "src/app/models/commandEvent";




@Injectable()
export class SessionStatusEffects {

  constructor(
    private media: MediaService,
    private sipService: SipService,
    private sessionService: SessionService,
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly config: AppConfigService
  ) {
    console.debug(`SessionStatusEffects constructor`);
  }
  // evt_disclaimer = createEffect( ()=> this.actions$.pipe(
  //   ofType(sessionStatusActions.evt_changeStatus),
  //   withLatestFrom(this.store.select(selectSessionStatusState), this.store.select(selectPartecipantId)),
  //   tap(([disclaimer, sessionStatus, partecipantId])=> {


  //   })
  // ),
  // { dispatch: false })

  // updateParameters = createEffect( ()=> this.actions$.pipe(
  //   ofType(sessionActions.updatePartecipantParameters),
  //   withLatestFrom(this.store.select(selectAppState)),
  //   tap( ([parameters, s]) => {
  //     this.sessionService.updatePartecipantParameters(s.partecipantId, parameters)
  //   })
  // ),
  // { dispatch: false })

  phoneStateChange$ = createEffect(() => this.actions$.pipe(
    ofType(phoneChangeStatus),
    map(action => {
      this.media.setZoomOnTalking();
      return sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.PhoneStatus, val: action.status })
    })
  ));

  pageStateChange$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.pageStateChange),
    map(action => sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.PageInfoUpdate, val: action.nextState }))
  ));
  batteryLevel$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.batteryLevel),
    map(action => sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.BatteryStatusUpdate, val: action.level }))
  ));

  connectionSpeed$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.connectionSpeed),
    map(action => sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.NotifySpeed, val: action.speed }))
  ));

  appModeChange$ = createEffect(() => this.actions$.pipe( //notifica verso server cambio appModes
    ofType(appActions.appModeChange, appActions.setAppModes),
    withLatestFrom(this.store.select(selectAppState)),
    map(([_, appState]) => {
      const multiStatus = [
        { evt: StatusChangeEvent.AppModesChange, val: appState.appModes },
        { evt: StatusChangeEvent.AppInMultimedia, val: appState.appInMultimedia },
        { evt: StatusChangeEvent.VideoEnabled, val: appState.videoEnabled },
        { evt: StatusChangeEvent.MicrophoneEnabled, val: appState.microphoneEnabled }
      ];
      if ((this.config.iamOnCall || appState.videoEnabled || appState.microphoneEnabled)) {
        this.sipService.handleCalls();
      }

      return sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MultipleUpdateStatus, val: multiStatus });
    }
    )));

  endSession$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.endSession),
    tap(_ => {
      this.sipService.endAllCalls();
      // this.sipService.disconnect(); // se si disconnette.. va in pagina di errore! non va bene per op ma guest si
      if (this.config.isGuest) {
        this.store.dispatch(appActions.showErrorPage({ message: I18Service.get('POPUP.SESSIONENDEDTITLE'), imagePath: '/assets/images/waving-hand.svg', showReload: true }))
      }
    })
  ), { dispatch: false });

  terminateSession$ = createEffect(() => this.actions$.pipe(
    ofType(sessionActions.terminateSession),
    tap(_ => {
      this.sipService.endAllCalls();
      // this.sipService.disconnect(); // se si disconnette.. va in pagina di errore! non va bene per op ma guest si
      })
  ), { dispatch: false });

  toggleMicrophone = createEffect(() => this.actions$.pipe(
    ofType(appActions.toggleMicrophone),
    withLatestFrom(this.store.select(selectAppState)),
    map(([_, appState]) => {

      this.sipService.handleCalls();

      return sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.MicrophoneEnabled, val: appState.microphoneEnabled })
    })
  ))

  toggleVideo = createEffect(() => this.actions$.pipe(
    ofType(appActions.toggleVideo),
    withLatestFrom(this.store.select(selectAppState)),
    map(([_, appState]) => {
      
      this.sipService.handleCalls();

      if (!appState.videoEnabled && this.config.isReceiver && false) { //reset impostazioni edit image
        // this.media.setVideoZoom('');
        this.media.imageToEdit = null;
        this.media.setImageToEditStream(null);
        // this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.VideoZoomTo, val:'', toIds: [appActions.SEND_TO_ALL]}));
      }
      return sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.VideoEnabled, val: appState.videoEnabled })

    })
  ))
  streamVideoDraw$ = createEffect(() => this.actions$.pipe(
    ofType(appActions.streamVideoDraw),
    withLatestFrom(this.store.select(selectAppState)),
    map(([_, appState]) => {
      // if (appState.microphoneEnabled) {
      //   const to = appState.session.parameters.partecipantIds.find( z => z.partecipantRole == PartecipantRoles.PrimaryClient)?.partecipantId || '';
      //   this.sipService.enableMic(to)
      // } else { 
      //   this.sipService.disableMic();
      // }
      // if (handleCallerCall(this.config, this.sipService) == false) {
      //   this.sipService.enableMedia()//{ audio: appState.microphoneEnabled!, video: appState.videoEnabled! });
      // }
      this.sipService.videoEditStream(appState.videoEnabled);
      
      const zoomTo = (appState.videoEnabled) ? this.config.partecipantId : '';

      this.store.dispatch(sessionStatusActions.cmd_dispatch({cmd: CommandEvent.VideoZoomTo, val:zoomTo, toIds: appActions.SEND_TO_ALL}));

      return sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.VideoEnabled, val: appState.videoEnabled })

    })
  ))

  toggleRemoteCommand = createEffect(() => this.actions$.pipe(
    ofType(appActions.toggleRemoteCommand),
    withLatestFrom(this.store.select(selectAppState)),
    map(([_, appState]) => {
      return sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.CommandsDisabled, val: appState.commandsDisabled })
    })
  ))

  mirrorUser = createEffect(() => this.actions$.pipe(
    ofType(appActions.mirrorUser),
    withLatestFrom(this.store.select(selectAppState), this.store.select(secondaryPartecipantStatus)),
    map(([{ enabled }, appState, secondaryAppState]) => {
      if (enabled) {
        if (secondaryAppState?.appInMultimedia) {
          const callerModes = secondaryAppState.appModes;
          this.store.dispatch(appActions.setAppModes({ modes: callerModes }));
        } else {
          const mode = AppHelper.getModeWhenPrimaryArrives(
            { ...initialSessionConfig, ...this.config.sessionConfig, ...{ localization: false } }
            , PartecipantRoles.SecondaryClient)
          this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.ChangeMode, val: mode, toIds: [secondaryAppState?.partecipantId || '*'] }));
        }
      }
      return sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.ToggleRemoteCommand, val: enabled, toIds: [secondaryAppState?.partecipantId || '*'] })
    })
  ))

  partecipantStatusChange = createEffect(() => this.actions$.pipe(
    ofType(sessionStatusActions.partecipantStatusChange),
    withLatestFrom(this.store.select(selectAppState)),
    tap(([{ partecipantStatus }, appState]) => {
      if (appState.isReceiver && appState.mirrorUser && partecipantStatus.partecipantRole == PartecipantRoles.SecondaryClient) {
        this.store.dispatch(appActions.setAppModes({ modes: partecipantStatus.appModes }));
      }
    })
  ), { dispatch: false })

  availableDevices = createEffect(() => this.actions$.pipe(
    ofType(appActions.retrieveDevices),
    map( (action) => sessionStatusActions.evt_changeStatus({ evt: StatusChangeEvent.AvailableDevices, val: action.availableDevices }))
  ))
}

// function handleCallerCall(config: AppConfigService, sipService: SipService, forceStart = false): boolean {
//   const appState = config.AS!;
//   if (config.isReceiver && !config.isSuper) return false;

//   const handleCall = (to: string, type: PartecipantRoles) => {
//     if (appState.appModes.includes(AppModesEnum.Video) || forceStart) {
//       console.log(`handleCall sipService startCall to ${type} ${to}`);
//       //start  call
//       sipService.startCall({ to: to, toType: type, enableAudio: appState.microphoneEnabled, enableVideo: appState.videoEnabled, force: forceStart })
//     } else {
//       sipService.enableMedia()//{ audio: appState.microphoneEnabled!, video: appState.videoEnabled! });
//     }
//   }

//   const callInterval = 2000;
//   //const roleOperators = [PartecipantRoles.PrimaryClient, PartecipantRoles.SuperClient];
//   const partecipants =  config.getPartecipantsStatus().filter( z => z.partecipantId !== config.partecipantId); // not me

//   const toOperators = partecipants.filter(z => OPERATOR_ROLES.includes(z.partecipantRole) );//?.partecipantId || '';
//   toOperators.forEach( (toOperator, idx) => {
//     setTimeout(()=>{
//       handleCall(toOperator.partecipantId, toOperator.partecipantRole);
//     }, callInterval*idx)
//   });

//   const toOthersAlreadyInCall = partecipants
//     .filter( z => !OPERATOR_ROLES.includes(z.partecipantRole)) // not operator
//     .filter( z => z.phoneStatus == PhoneStatusEnum.TALKING) // and already in call
    
//   console.log(`othersInCall tot ${toOthersAlreadyInCall.length}`);
//   toOthersAlreadyInCall.forEach( (other, idx) => {
//     setTimeout(()=>{

//       handleCall(other.partecipantId, other.partecipantRole);

//     }, callInterval + callInterval*idx)
//   })

//   setTimeout(() => {
//     const partecipants =  config.getPartecipantsStatus().filter( z => z.partecipantId !== config.partecipantId); // not me
//     const toOthersAlreadyInCall = partecipants
//       .filter( z => !OPERATOR_ROLES.includes(z.partecipantRole)) // not operator
//       .filter( z => z.phoneStatus == PhoneStatusEnum.TALKING) // and already in call
    
//     console.log(`Retrying othersInCall tot ${toOthersAlreadyInCall.length}`);

//     toOthersAlreadyInCall.forEach( (other, idx) => {
//       setTimeout(()=>{

//         handleCall(other.partecipantId, other.partecipantRole);

//       }, callInterval + callInterval*idx)
//     })

//   }, callInterval*2 + callInterval*toOthersAlreadyInCall.length); 

//   return true;
// }

