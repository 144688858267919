import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app.config.service';
import { GeoCastingResult } from '../models/WebServer/geoCastingResult';
import { AddressInfo } from '../models/WebServer/addressInfo';
import { Constants } from '../constants';
import { BaseHttp } from './base.http';
import { Store } from '@ngrx/store';


@Injectable()
export class GeoCastingService extends BaseHttp {

  constructor(http: HttpClient, config: AppConfigService, store: Store) {
    super(http, store, config);
  }

  geoCastingBackEndRaw(latitude: string, longitude: string): Observable<GeoCastingResult> {
    return this.getRequest<GeoCastingResult>(`${this.config.apiBaseUrl}/externalservices/geocastingraw/${latitude}/${longitude}`);
  }

  geoCastingBackEnd(latitude: string, longitude: string): Observable<AddressInfo> {
    if (Constants.ALLOWED_LANGUAGES.some(al => al == this.config.language)) {
      return this.getRequest<AddressInfo>(`${this.config.apiBaseUrl}/externalservices/geocasting/${latitude}/${longitude}/${this.config.language}`);
    } else {
      return this.getRequest<AddressInfo>(`${this.config.apiBaseUrl}/externalservices/geocasting/${latitude}/${longitude}`);
    }
  }
}
