import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "./app.config.service";

import { Observable, catchError, map, of } from "rxjs";

import { PartecipantParameters } from "../models/WebServer/partecipantStatus";
import { Session } from "../models/WebServer/session";
import { Partecipant } from "../models/WebServer/partecipant";
import { PartecipantInfo } from "../models/WebServer/partecipantInfo";
import { BaseHttp } from "./base.http";
import { AddGuest } from "../models/WebServer/addGuest";
import { Store } from "@ngrx/store";
import { RemoveGuest } from "../models/WebServer/removeGuest";


@Injectable()
export class SessionService extends BaseHttp {
  // private getSessSub: Subject<any> = new Subject<any>();
  // private endSessSub: Subject<any> = new Subject<any>();
  // private extendSessSub: Subject<any> = new Subject<any>();

  constructor(http: HttpClient, config: AppConfigService, store: Store) {
    super(http, store, config);
  }




  // getGetSessSubscription(): Subject<any> {
  //   return this.getSessSub;
  // }

  // getEndSessSubscription(): Subject<any> {
  //   return this.endSessSub;
  // }

  // getExtendSessSubscription(): Subject<any> {
  //   return this.extendSessSub;
  // }

  // getByPartecipantId(partecipantId: string): Observable<Session> {
  //   return this.http.get<Session>(`${this.config.apiBaseUrl}/partecipant/${partecipantId}`);
  // }

  getSession(partecipantId: string): Observable<Session> {
    return this.getRequest<Session>(`${this.config.apiBaseUrl}/session/partecipant/${partecipantId}`);
  }

  getPartecipant(partecipantId: string): Observable<PartecipantInfo> {
    return this.getRequest<PartecipantInfo>(`${this.config.apiBaseUrl}/partecipant/${partecipantId}`);
  }
  updatePartecipantParameters(partecipantId: string, parameters: PartecipantParameters): Observable<Partecipant> {
    return this.putRequest<Partecipant>(`${this.config.apiBaseUrl}/partecipant/update/${partecipantId}`, parameters);
  }

  addGuest(phoneNumber: string, email: string, alias: string, viewPreviousChat: boolean, superUser: boolean): Observable<Session> {
    const req: AddGuest = {
      alias,
      phoneNumber,
      email,
      primaryPartecipantId: this.config.partecipantId,
      viewPreviousChat,
      super: superUser
    }
    const sessionId: string = this.config.sessionId || '';
    //TODO: riaggiungere questo check header? lo si fa solo per l'extend-session (non per il terminate)
    // var headers_object = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': "Basic " + basicToken // calcolato come part[0].id-part[1].id
    // });

    // const httpOptions = {
    //   headers: headers_object
    // };
    return this.putRequest<Session>(`${this.config.apiBaseUrl}/session/addGuest/${sessionId}`, req, true);
  }
  removeGuest(guestId: string): Observable<Session> {
    const req: RemoveGuest = {
      primaryPartecipantId: this.config.partecipantId,
      guestPartecipantId: guestId
    }
    const sessionId: string = this.config.sessionId || '';
    //TODO: riaggiungere questo check header? lo si fa solo per l'extend-session (non per il terminate)
    // var headers_object = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'Authorization': "Basic " + basicToken // calcolato come part[0].id-part[1].id
    // });

    // const httpOptions = {
    //   headers: headers_object
    // };
    return this.postRequest<Session>(`${this.config.apiBaseUrl}/session/removeGuest/${sessionId}`, req, true);
  }

  // private getRequest<T>(url: string): Observable<T> {
  //   return this.http.get<T>(url).pipe(
  //     map((response: T) => response)
  //     //, catchError(err => {
  //     //   console.log(err);
  //     //   return of<T>(err);
  //     // })
  //   );
  // }


  // endSession(sessionId: string, basicToken: string): void {
  //   var headers_object = new HttpHeaders({
  //     'Content-Type': 'application/json',
  //     'Authorization': "Basic " + basicToken
  //   });

  //   const httpOptions = {
  //     headers: headers_object
  //   };
  //   this.http.put<Session>(`${this.config.getSessionEndPoint()}/endsession/${sessionId}`, null, httpOptions).toPromise().then((e) => {
  //     this.endSessSub.next(e);
  //   }, err => {
  //     console.error(err);
  //     this.endSessSub.next(err);
  //   });
  // }

  extendSession(sessionId: string, minutes: number, basicToken: string): void {
    // // var headers_object = new HttpHeaders({
    // //   'Content-Type': 'application/json',
    // //   'Authorization': "Basic " + basicToken
    // // });
    // var headers_object = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //   'ext-auth': basicToken
    // });
    // const httpOptions = {
    //   headers: headers_object
    // };
    // this.putRequest<Session>(`${this.config.apiBaseUrl}/session/extendsession/${sessionId}/${minutes}`, null, httpOptions).toPromise().then((e) => {
    //   this.extendSessSub.next(e);
    // }, err => {
    //   console.error(err);
    //   this.extendSessSub.next(err);
    // });
  }
}
