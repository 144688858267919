import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";

export enum StatusCircleSize {
  small = 'small',
  medium= 'medium',
  large = 'large'
}

const definedSizes = {
  null: {cx:0, cy: 0, r: 0, l: 25},
  small: { cx:8, cy: 8, r: 6, l: 15},
  medium:{ cx:12, cy: 15, r: 9, l: 25},
  large: { cx:12, cy: 15, r: 12, l: 30},
}


@Component({
  selector: 'app-status-circle',
  templateUrl: './status-circle.component.html',
  styleUrls: ['./status-circle.component.scss'],

})
export class StatusCircleComponent implements AfterViewInit {

  @ViewChild('circleContainer')  circleContainer!: ElementRef;

  circleSize: {cx: number, cy: number, r: number, l: number} = definedSizes.null;
  defaultCircleSize: {cx: number, cy: number, r: number, l: number} = definedSizes.null;
  @Input() tooltip: string = '';
  @Input() color!: string;
  @Input() set size(value: StatusCircleSize) {
    this.defaultCircleSize = definedSizes[value];
  }

  constructor() {
    // const h = this.circleContainer.nativeElement.parentElement.offsetHeight;
    // console.log(`StatusCircleComponent h: ${h}`)
    //   this.circleSize = {
    //     cx: h/2,
    //     cy: h/2,
    //     r: h/3,
    //     l: h
    //   }

  }

  ngAfterViewInit() {
    let h = this.circleContainer.nativeElement.parentElement.offsetHeight;
    if(!h) h=this.defaultCircleSize.l;
    // console.log(`StatusCircleComponent h: ${h}`)
    window.setTimeout(() => {
      this.circleSize = {
        cx: h/2,
        cy: h/2,
        r: h/3,
        l: h
      }
        
    }, 0);
  }
}