
<div *ngIf="AS.errorInfo || !MYSTATUS?.disclaimerAccepted; else ok"></div>
<ng-template #ok>
<!-- MAP -->
<app-position-manager id="position-manager" #position *ngIf="!AS.errorInfo && AS?.appReady && !!MYSTATUS" [AS]="AS" [MYSTATUS]="MYSTATUS"
  [hidden]="AS.appInMultimedia">
</app-position-manager>

<app-multimedia-manager-caller #multimediamanager id="multimedia-manager-container" 
  [AS]="AS" [MYSTATUS]="MYSTATUS" [PRIMARYSTATUS]="PRIMARYSTATUS"
  [hidden]="!AS.appInMultimedia">
</app-multimedia-manager-caller>
<div id="button-container-new" *ngIf="!AS.appInMultimedia &&     true      &&     MYSTATUS?.disclaimerAccepted    && AS.session.config.localization">
  <button mat-raised-button class="bottom-button" #multimediaButton
    *ngIf="AS.session?.config?.audio || AS.session?.config?.video || AS.session?.config?.customerAttachments || AS.session?.config?.chat"
    [matBadge]="AS.unreadMessage" [matBadgeHidden]="!AS.unreadMessage" matBadgePosition="above after" matBadgeColor="warn"
    [disabled]="AS.sessionExpired || AS.commandsDisabled || !(config.partecipantsStatus$ | targetPresence)" (click)="showMultimediaDialog()">
    {{ 'BUTTONS.MULTIMEDIA' | translate}}
  </button>
  <button mat-raised-button class="bottom-button"
    [disabled]="!(MYSTATUS.isRegistered ) || AS.sessionExpired || MYSTATUS.geoLocPermissionGiven == 2 || !session.config.localization"
    (click)="sendLocationImmediately()">
    {{ 'BUTTONS.SENDPOSITION' | translate}}
  </button>
</div> 
</ng-template>
