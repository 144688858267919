<!-- PHONE -->
<div id="media-container" >
  <div id="chat-container" [hidden]="!AS.appModes.includes(appModes.Chat)">
    <!-- CHAT -->
    <app-chat #chat 
      [AS]="AS" 
      [disabled]="!MYSTATUS.isRegistered || AS.sessionExpired || !AS.session.config.chat" 
      [userLanguage]="SECONDARYSTATUS?.userLanguage || AS.translationSelectedLanguage.language"
      [shown]="AS.appModes.includes(appModes.Chat)"></app-chat>
  </div>

  <div class="app-video-container" [hidden]="!AS.appModes.includes(appModes.Video) && !AS.appModes.includes(appModes.Photo)">
    <!-- VIDEO -->
    <app-video #video class="video-container" [AS]="AS" [MYSTATUS]="MYSTATUS" [OTHERSTATUS]="SECONDARYSTATUS" 
      [hidden]="!AS.appModes.includes(appModes.Video)">
    </app-video>
    <app-camera-gallery #cameraGallery class="video-container" [AS]="AS" [display]="AS.appModes.includes(appModes.Photo)"
      [hidden]="!AS.appModes.includes(appModes.Photo)">
    </app-camera-gallery>
  </div>
</div>
