import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { AppHelper } from 'src/app/helpers/app.helper';
import { AppModesEnum } from 'src/app/models/WebServer/appModesEnum';
// import { MultimediaMachineStatus, MultimediaNodeEnum } from 'src/app/models/multimediaMachineState';
import { PhoneStatusEnum } from 'src/app/models/phoneStatus';
import { AppConfigService } from 'src/app/services/app.config.service';
import { EventService } from 'src/app/services/event.service';
import { SessionService } from 'src/app/services/session.service';
import { AppState } from 'src/app/store/states/app.state';
import { SvgConstants } from 'src/app/svgConstants';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { MultimediaButtonsComponent } from '../../multimedia-manager/multimedia-buttons.component';
import { MultimediaManagerReceiverComponent } from '../../multimedia-manager/multimedia-manager-receiver.component';
import { PositionManagerComponent } from '../position-manager.component';
import { FELoggerService } from 'src/app/services/feLogger.service';
import { I18Service } from 'src/app/services/i18.service';
import { PartecipantStatus } from 'src/app/models/WebServer/partecipantStatus';
import { RootState } from 'src/app/store/root.state';
import { Store } from '@ngrx/store';
import * as sessionStatusSelectors from 'src/app/store/selectors/sessionStatus.selectors';
import { BrowserCompatibility } from 'src/app/models/WebServer/browserCompatibilty';
import * as sessionStatusActions from "src/app//store/actions/sessionStatus.actions";
import * as appActions from "src/app//store/actions/app.actions";
import { CheckTypeEvent, CheckerService, MAX_NOTIFY_EXPIRATION, TickEvent } from 'src/app/services/checker.service';
import { NotificationService } from 'src/app/services/notification.service';
import { StatusCircleSize } from '../status-circle.component';
import { DisclaimerHelper } from 'src/app/helpers/disclaimer.helper';
import { CommandEvent } from 'src/app/models/commandEvent';

@Component({
  selector: 'app-receiver',
  templateUrl: './receiver.component.html',
  styleUrls: ['./receiver.component.scss']
})
export class ReceiverComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() AS!: AppState;
  @Input() MYSTATUS!: PartecipantStatus;
  
  @ViewChild('downloadUrl') downloadUrl!: ElementRef;
  @ViewChild('multimediamanager') multimediamanager!: MultimediaManagerReceiverComponent;
  @ViewChild('multimediabuttons') multimediabuttons!: MultimediaButtonsComponent;
  @ViewChild('position') position!: PositionManagerComponent;


  @ViewChild('callerinfo',{static: false}) callerinfo!: ElementRef;
  @ViewChild('sessioninfo',{static: false}) sessioninfo!: ElementRef;
  @ViewChild('panelShowHide',{static: false}) panelShowHide!: ElementRef;

  mirrorUser: boolean = false;

  mapVisible: boolean = false;
  showDeviceTooltip = false;
  showLocationTooltip = false;
  
  svgConstants = SvgConstants;
  appModes = AppModesEnum;

  StatusCircleSize = StatusCircleSize;
  phoneStatuses = PhoneStatusEnum;
  defaultLatitude?: number;
  defaultLongitude?: number;
  videoPreviewWidth?: number;
  videoPreviewHeight?: number;


  showRightPanel = false;
  showSessionInfo = false;
  showUserInfo = false;
  
  private destroy$: Subject<boolean> = new Subject<boolean>();
  ticksubscription: Subscription;
  SECONDARYSTATUS?: PartecipantStatus;
  // GUESTSTATUS?: PartecipantStatus;

  private showingSecondaryBrowserCompatibility: boolean = false;
  private acceptedBrowser?: boolean;

  MAX_NOTIFY_EXPIRATION = MAX_NOTIFY_EXPIRATION * 1000;

  expirationMillisec: number = this.MAX_NOTIFY_EXPIRATION;
  // expiresIn = (new Date()).getTime()

  title =  AppHelper.getTitleName();
  constructor(
    public checkerService: CheckerService,
    public store: Store<RootState>,
    public config: AppConfigService,
  ) {
    this.ticksubscription =this.checkerService.tick$.subscribe({
      next: (x: TickEvent) => {
        if (x.type === CheckTypeEvent.sessionExpiration) {
          var z =  Math.ceil((x.val / 1000) / 5) * 5;
          this.expirationMillisec = z * 1000; // arrotondare...
          // this.expiresIn = this.expirationMillisec;
        }
      }
    })

    this.store.select(sessionStatusSelectors.secondaryPartecipantStatus).pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      console.log(`secondaryStatus`, data);
      this.SECONDARYSTATUS = data;
      if (!data) Swal.close();

      if (data && data.browserCompatibility?.success === false) {
        if (!this.showingSecondaryBrowserCompatibility && this.acceptedBrowser !== true) {
          this.manageBrowserCompatibility(data.browserCompatibility, data.partecipantId);
        } else if (this.acceptedBrowser) {
          this.sendAcceptedBrowser(data.partecipantId, true);
        }
      }
    });
  }

  @HostListener('document:keydown.control.alt.shift.d', ['$event'])
  openDialog(e: KeyboardEvent) {
    e.preventDefault();
    const enable = !AppConfigService.debugEnabled;
    this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.EnableDebug, val: enable, toIds: ['*']}));
    this.config.logDebug(enable);
  }


  ngOnDestroy(): void {
    console.log(`ngOndestroy receiver.component`)
    this.destroy$.next(true);
    this.destroy$.complete();
    this.destroy$.unsubscribe();
    // this.checkerService.tick$.unsubscribe();
    this.ticksubscription.unsubscribe();
  }

  async ngOnInit() {
    if(this.config.isSuper)
      DisclaimerHelper.showDisclaimer()
        .then(result => {
          const afterInit = result || !this.config.agencySettings.blockingDisclaimer;
          console.log(`***** DisclaimerAccepted ${afterInit}`)
          if (!afterInit) {
            this.store.dispatch(appActions.showErrorPage({ message: I18Service.get('ERRORPAGE.DISCLAIMER'), imagePath: 'assets/images/alert-error.svg' }));
          }
        });


  }

  async ngAfterViewInit() {
    // this.panelShowHide.nativeElement.addEventListener('click', function(event: any) {
    //   event.target.style.pointerEvents = 'none';
    //   const elemBelow = window.document.elementFromPoint(event.clientX, event.clientY) as HTMLElement;
    //   event.target.style.pointerEvents = 'auto';
    //   if (elemBelow && elemBelow.id === 'scroll-bottom-id') {
    //     elemBelow.click();
    //   }
    // });
  }

  AskForReinvite() {
    // Swal.fire({
    //   // title: I18Service.get('LABELS.SETNOTINCALL'),
    //   html: I18Service.get('LABELS.SETNOTINCALLBODY'),
    //   showCancelButton: true,
    //   icon: 'question',
    //   cancelButtonText: I18Service.get('POPUP.CANCEL')
    // }).then((value) => {
    //   if (value.isConfirmed) {
    //     this.config.duringPSTNCall = !this.config.duringPSTNCall;
    //     this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.NotifyInCallSession, this.config.duringPSTNCall);
    //     this.feLogger.LogInfo(`Operator is preparing to notify to Caller that duringPSTNCall = ${this.config.duringPSTNCall}`);
    //   }
    // });
    // this.config.firstReinviteAfterInvite = false;
  }

  toggleDeviceTooltip() {
    this.showDeviceTooltip = !this.showDeviceTooltip;
    if (this.showDeviceTooltip) {
      this.showLocationTooltip = false;
    }
  }

  toggleLocationTooltip() {
    this.showLocationTooltip = !this.showLocationTooltip;
    if (this.showLocationTooltip) {
      this.showDeviceTooltip = false;
    }
  }
  changeMode(mode: AppModesEnum) {
    this.store.dispatch(appActions.appModeChange({ mode }));
  }

  changeUserMode(mode: AppModesEnum) {
    this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.ChangeMode, val: mode, toIds: [this.SECONDARYSTATUS!.partecipantId] }))
    if (this.config.isReceiver && mode == AppModesEnum.Video && !this.config.showingVideo) //porta anche il recv sul video 
    {
      this.store.dispatch(appActions.appModeChange({ mode: AppModesEnum.Video }));
    }
  }


  toggleRemoteMicrophone() {
    this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.ToggleMicrophone, val: '', toIds: [this.SECONDARYSTATUS!.partecipantId] }));
  }

  toggleRemoteCommand() {

    this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.ToggleRemoteCommand, val: undefined, toIds: [this.SECONDARYSTATUS!.partecipantId] }));
  }


  printStuff() {
    // Logger.debug('Printing Stuff', { pageStatus: this.AS.pageStatus, batteryLevel: this.AS.batteryLevel, UA: this.AS?.parsedUA, Vendor: this.AS?.parsedUA?.device?.vendor });
    // Logger.info('INFO STATS SUMMARY', this.config.streamStatSummary);
    // var decoded = AppHelper.decodeCompactGuid('uDxifn3QJkOPiqpZNhoLBw');
    // console.log("DECODED", decoded);
    // // Swal.fire({
    // //   html:
    // //     `<div style="display:flex;flex-direction:column;">
    // //       <div>${I18Service.get('LABELS.NEWDOCUMENT')}</div>
    // //       <div style="display:flex;align-self:center;justify-content:center;">
    // //         <a style="display:flex;align-items:center;align-self:center" href="https://webserverbackend-stage.azurewebsites.net/api/event/media/YXp1cmVibG9iJTNhJTJmJTJmaW1hZ2VzJTJmYmY1N2Q5OGEtZGQwYS00NmY2LWI1MTAtZjAyNDljM2ZmNGEwJTJmNjM3ODM0NzAyMjAwODgxMjQ2X0lNR18yMDIyMDMxNF8xNDE1MTAuanBn">
    // //           ${I18Service.get('LABELS.DOWNLOADDOCUMENT')}
    // //           <img src="../../assets/images/download.png" style="width: 40px; height: auto;margin-left:10px;"/>
    // //         </a>
    // //       </div>
    // //       ${I18Service.get('LABELS.ORCHATDOCUMENT')}
    // //     </div>`,
    // //   allowOutsideClick: false,
    // //   showConfirmButton: false,
    // //   showCloseButton: true,
    // //   icon: 'info'
    // // });
  }

  onMirrorChange() {
    this.store.dispatch(appActions.mirrorUser({ enabled: this.mirrorUser }));
  }

  forceRefresh() {
    NotificationService.popupQuestion({msg:I18Service.get('LABELS.FORCERELOADBODY')})
    .then((value) => {
      if (value.isConfirmed) {
        // this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.ForceRefresh);
        // this.eventService.saveBusinessEvent(AppHelper.getDynamicCreateData(this.config, BusinessEventTypes.ForceRefresh));
        this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.ForceRefresh, val: '', toIds: [this.SECONDARYSTATUS?.partecipantId || '*'] }))
      }
    });
  }


  askPermission() {
    if (this.SECONDARYSTATUS?.geoLocPermissionGiven != 1 && this.AS.session.config.localization)
      this.askGeoPermission();
    else
      this.askMediaPermission();
  }

  askGeoPermission() {//TODO - done!
    //this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.GeoPermissionInstructions);
    this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.GeoPermissionInstructions, val: '', toIds: [this.SECONDARYSTATUS?.partecipantId || '*'] }))
  }

  askMediaPermission() {//TODO - done!
    // this.sipService.sendSTATUS(this.config.targetPartecipantId, this.config.partecipantId, DialogStatusEnum.MediaPermissionInstructions);
    this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.MediaPermissionInstructions, val: '', toIds: [this.SECONDARYSTATUS?.partecipantId || '*'] }))

  }


  public getUserMedia(options: any) {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(options);
    }
    if ((<any>navigator).getUserMedia) {
      return (<any>navigator).getUserMedia(options);
    }
    if ((<any>navigator).webkitGetUserMedia) {
      return (<any>navigator).webkitGetUserMedia(options);
    }
    if ((<any>navigator).mozGetUserMedia) {
      return (<any>navigator).mozGetUserMedia(options);
    }
    throw new Error('getUserMedia is not defined');
  }

  // from: https://stackoverflow.com/a/46182044/5221762
  public async getJpegBlob(canvas: any): Promise<Blob> {
    return new Promise((resolve) => {
      // docs: https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
      canvas.toBlob((blob: any) => resolve(blob), 'image/jpeg', 0.95);
    });
  }

  public async getJpegBytes(canvas: any) {
    return new Promise(async (resolve, reject) => {
      const fileReader = new FileReader();
      const blob = await this.getJpegBlob(canvas);
      fileReader.addEventListener('loadend', function () {
        if (this.error) {
          reject(this.error);
          return;
        }
        resolve(this.result);
      });
      fileReader.readAsArrayBuffer(blob);
    });
  }


  private manageBrowserCompatibility(bc: BrowserCompatibility, secondaryId: string) {
    if (!bc) return;

    let message: string = '';
    let replacedMess: string = '';
    message = bc.message;
    if (message) {
      replacedMess = message.replace('%%UNSUPPORTEDOS%%', I18Service.get('POPUP.UNSUPPORTEDOS'));
      replacedMess = replacedMess.replace('%%UNSUPPORTEDBROWFOROS%%', I18Service.get('POPUP.UNSUPPORTEDBROWFOROS'));
      replacedMess = replacedMess.replace('%%UNSUPPORTEDOSVERSION%%', I18Service.get('POPUP.UNSUPPORTEDOSVERSION'));
      replacedMess = replacedMess.replace('%%UNSUPPORTEDBROWSERVERSION%%', I18Service.get('POPUP.UNSUPPORTEDBROWSERVERSION'));
      replacedMess = replacedMess.replace('%%UNSUPPORTEDGEOLOC%%', I18Service.get('POPUP.UNSUPPORTEDGEOLOC'))
    }
    if (replacedMess) {
      this.showingSecondaryBrowserCompatibility = true;
      NotificationService.popupWarn({msg: replacedMess, confirmButton: I18Service.get('POPUP.CONTINUE'), cancelButton: I18Service.get('POPUP.SUSPEND')})
      .then((res: SweetAlertResult) => {
        this.sendAcceptedBrowser(secondaryId, res.isConfirmed);
      });
    }
  }

  private sendAcceptedBrowser(secondaryId: string, val: boolean) {
    this.store.dispatch(sessionStatusActions.cmd_dispatch({ cmd: CommandEvent.AcceptedBrowser, val: val, toIds: [secondaryId] }))
    this.acceptedBrowser = val;
    this.showingSecondaryBrowserCompatibility = false
  }


  toggleSessionInfo(show: boolean) {
    !show && (this.showSessionInfo = show);
    !show && NotificationService.popupClose();

    if( this.panelShowHide.nativeElement.offsetParent === null ) {
      this.showSessionInfo = show;
    }
    else{
      show && NotificationService.showElement(this.sessioninfo.nativeElement, '')
    }
  }

  toggleUserInfo(show: boolean) {
    !show && (this.showUserInfo = show);
    !show && NotificationService.popupClose();

    if( this.panelShowHide.nativeElement.offsetParent === null ) {
      this.showUserInfo = show;
    }
    else{
      show && NotificationService.showElement(this.callerinfo.nativeElement, '')
    }
  }
}
