import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PartecipantRoles } from '../models/WebServer/partecipantRoles';
import { Message } from '../models/WebServer/message';
import { AppConfigService } from '../services/app.config.service';

@Pipe({
  name: 'guestChatColor'
})
export class GuestChatColorPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer, private config: AppConfigService) { }

  public transform(m: Message | undefined, negative=false): string {
    if (!m || m.role == PartecipantRoles.SecondaryClient || !this.config.agencySettings?.chatMulticolor) return 'unset';

    const s = m.alias || m.from;
    var h = 0, l = s.length, i = 0;
    if ( l > 0 )
      while (i < l)
        h = (h << 5) - h + s.charCodeAt(i++) | 0;

    const deg  = m.idx * 60; //h % 360;      
    // console.log(`guest color h: ${h} deg:${deg} alias: ${s} id: ${m.idx}`)
    const minus=(negative) ? '-' : '';
    return `hue-rotate(${minus}${deg}deg)`;
  }
}
